import React, { useState, useEffect, useRef } from "react";
import { Table, Checkbox, Modal, message } from "antd";
import {
  FindContainer,
  FindSalonText,
  SearchBarContainer,
  EmailInput,
  SearchButton,
  Find,
} from "./SalonList.styled";
import { useNavigate } from "react-router-dom";
import {
  fetchSalon,
  deleteSalon,
} from "../../Api/ApiServerRequests/SalonApiServerRequests";
import { Button } from "antd/es/radio";
import {
  grantAccess,
  revokeAccess,
} from "../../Api/ApiServerRequests/UsersApiServerRequests";
import SearchBox from "../../Components/SearchBox/SearchBox";
import { SearchContainer } from "../SalonServices/SalonServices.styled";
import { Theme } from "../../Themes/Theme";

const SalonList = () => {
  const navigate = useNavigate();
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deletingKey, setDeletingKey] = useState(null);
  const [searchEmail, setSearchEmail] = useState("");
  const emailInputRef = useRef(null);
  const [data, setData] = useState([]);
  const [billingCycleModalVisible, setBillingCycleModalVisible] =
    useState(false);
  const [billingCycleDetails, setBillingCycleDetails] = useState({
    salonName: "",
    salonOwner: "",
    billingCycle: "Monthly",
    billingExpiry: "dd/mm/yyyy",
  });

  useEffect(() => {
    fetchSalon(true, "premium status")
      .then((response) => {
        const formattedData = response.data.data.map((salon, index) => {
          const joinedDate = new Date(salon.created_at);
          const formattedDate = `${joinedDate.getDate()}-${
            joinedDate.getMonth() + 1
          }-${joinedDate.getFullYear()}`;

          return {
            key: index.toString(),
            salonName: salon.name,
            owner: salon.owner_name,
            email: salon.owner_email,
            joinedDate: formattedDate,
            premiumStatus: salon.premium_membership_status,
            description: salon.description,
          };
        });
        setData(formattedData);
      })
      .catch((error) => {
        console.error("Error fetching salon data:", error);
      });
  }, []);

  useEffect(() => {
    if (searchEmail) {
      const filteredData = data.filter((salon) =>
        salon.email.includes(searchEmail)
      );
      setData(filteredData);
    } else {
      fetchSalon(true, "premium status")
        .then((response) => {
          const formattedData = response.data.data.map((salon, index) => {
            const joinedDate = new Date(salon.created_at);
            const formattedDate = `${joinedDate.getDate()}-${
              joinedDate.getMonth() + 1
            }-${joinedDate.getFullYear()}`;

            return {
              key: index.toString(),
              salonName: salon.name,
              owner: salon.owner_name,
              email: salon.owner_email,
              joinedDate: formattedDate,
              premiumStatus: salon.premium_membership_status,
              description: salon.description,
            };
          });
          setData(formattedData);
        })
        .catch((error) => {
          console.error("Error fetching salon data:", error);
        });
    }
  }, [searchEmail]);

  const handleExtendSubscription = async (record) => {
    if (record.premiumStatus === "Active") {
      console.log("Revoking access from", record.email);
      try {
        const queryParams = new URLSearchParams({
          email: record.email,
        });
        const response = await revokeAccess(queryParams);

        if (response.data.status === 201) {
          message.success(response.data.data);
          const updatedData = data.map((row) =>
            row.email === record.email
              ? {
                  ...row,
                  premiumStatus: "Inactive",
                }
              : row
          );

          setData(updatedData);
        } else {
          message.error(response.data.data);
        }
      } catch (error) {
        console.error("Error revoking access:", error);
        message.error("An error occurred while revoking access.");
      }
    } else {
      console.log("Granting access to", record.email);
      try {
        const queryParams = new URLSearchParams({
          email: record.email,
        });
        const response = await grantAccess(queryParams);

        if (response.data.status === 201) {
          message.success(response.data.data);
          const updatedData = data.map((row) =>
            row.email === record.email
              ? {
                  ...row,
                  premiumStatus: "Active",
                }
              : row
          );

          setData(updatedData);
        } else {
          message.error(response.data.data);
        }
      } catch (error) {
        console.error("Error granting access:", error);
        message.error("An error occurred while granting access.");
      }
    }
  };

  const columns = [
    {
      title: "Salon Name",
      dataIndex: "salonName",
    },
    {
      title: "Owner",
      dataIndex: "owner",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "Joined Date",
      dataIndex: "joinedDate",
    },
    {
      title: "Premium Status",
      dataIndex: "premiumStatus",
    },
    {
      title: "Trial",
      dataIndex: "extendTrial",
      render: (_, record) => (
        <Button
          style={{
            backgroundColor:
              record.premiumStatus === "Inactive"
                ? "rgba(109,49,237,1)"
                : "black",
            color: "#fff",
            border: "none",
          }}
          onClick={() => handleExtendSubscription(record)}
        >
          {record.premiumStatus === "Inactive"
            ? "Grant Access"
            : "Revoke Access"}
        </Button>
      ),
    },
    {
      title: "Billing Cycle",
      dataIndex: "View",
      render: (_, record) => (
        <button
          style={{
            backgroundColor: "rgba(109,49,237,1)",
            color: "#fff",
            border: "none",
          }}
          onClick={() => handleBillingCycleView(record)}
        >
          View
        </button>
      ),
    },
    {
      title: "Button",
      dataIndex: "edit",
      render: (_, record) => (
        <button
          style={{
            backgroundColor: "rgba(109,49,237,1)",
            color: "#fff",
            border: "none",
          }}
          onClick={() => handleEdit(record)}
        >
          Edit
        </button>
      ),
    },
    {
      title: "Button",
      dataIndex: "delete",
      render: (_, record) => (
        <button
          style={{
            backgroundColor: "rgba(109,49,237,1)",
            color: "#fff",
            border: "none",
          }}
          onClick={() => showDeleteModal(record.key)}
        >
          Delete
        </button>
      ),
    },
  ];

  const handleBillingCycleView = (record) => {
    setBillingCycleDetails({
      salonName: record.salonName,
      salonOwner: record.owner,
      billingCycle: "Monthly",
      billingExpiry: "dd/mm/yyyy",
    });

    setBillingCycleModalVisible(true);
  };

  const showDeleteModal = (key) => {
    const selectedRecord = data.find((record) => record.key === key);

    if (selectedRecord.premiumStatus === "Active") {
      message.warning("Salon needs to be inactive before it can be deleted.");
    } else {
      setDeleteModalVisible(true);
      setDeletingKey(key);
    }
  };

  const handleDeleteConfirm = () => {
    deleteSalon(data[deletingKey].email)
      .then((response) => {
        if (response.data.status === 200) {
          message.success(response.data.data);
          setDeletingKey(null);
          const updatedData = data.filter((row) => row.key !== deletingKey);
          setData(updatedData);
        } else {
          message.error(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error deleting salon:", error);
      });

    setDeleteModalVisible(false);
  };

  const handleDeleteCancel = () => {
    setDeleteModalVisible(false);
    setDeletingKey(null);
  };

  const handleEdit = (record) => {
    const { email, salonName, owner, description } = record;
    const queryParams = `email=${email}&salonName=${salonName}&owner=${owner}&description=${description}`;
    navigate(`/dashboard/admin/edit-salon?${queryParams}`);
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchEmail(value);
  };

  return (
    <>
      <FindContainer>
        <SearchContainer>
          <SearchBox onChange={handleSearch} />
        </SearchContainer>
      </FindContainer>
      <Table columns={columns} dataSource={data} />
      <Modal
        title="Delete Salon"
        visible={deleteModalVisible}
        onOk={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
        okText="Yes"
        cancelText="No"
        okButtonProps={{
          style: {
            backgroundColor: Theme.primaryColor,
            borderColor: Theme.primaryColor,
          },
        }}
      >
        <p>Are you sure you want to delete this salon?</p>
      </Modal>

      <Modal
        title={<div style={{ textAlign: "center" }}>Billing Cycle</div>}
        visible={billingCycleModalVisible}
        onCancel={() => setBillingCycleModalVisible(false)}
        footer={null}
      >
        <p>
          <strong>Salon Name:</strong> {billingCycleDetails.salonName}
        </p>
        <p>
          <strong>Salon Owner:</strong> {billingCycleDetails.salonOwner}
        </p>
        <p>
          <strong>Billing Cycle:</strong> {billingCycleDetails.billingCycle}
        </p>
        <p>
          <strong>Billing Expiry:</strong> {billingCycleDetails.billingExpiry}
        </p>
      </Modal>
    </>
  );
};

export default SalonList;
