import styled from "styled-components";
import { Theme } from "../../Themes/Theme";
import { Fonts } from "../../Fonts/Fonts";

export const AddButton = styled.button`
  color: ${(props) => props?.color || 'rgba(255, 255, 255, 1)'};
  background: ${(props) => props?.background || Theme.salonColor};
  opacity: 1;
  border: none;
  border-radius: 4px;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => props?.width || "67px"};
  height: 33px;
`;

export const ServiceAndProductTitle = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: ${Theme.primaryBlack};
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  white-space: normal; 
  word-wrap: break-word; 
`;


export const ServiceAndProduct = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export const ServiceAndProductImage = styled.img`
  width: 40%;
  height: 100px;
`;

export const ServiceAndProductInfoContainer = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TitleBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const TimeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${Fonts.primaryFont};
  font-size: 11px;
  font-weight: 400;
  opacity: 1;
  background: rgba(243, 244, 246, 1);
  width: 40%;
  padding: 5px;
  border-radius: 30px;
`;

export const CountContainer = styled.div`
  display: flex;
  justify-Content: center;
  color: ${Theme.primaryRed};
`;

export const CountText = styled.div`
  width: 28px; 
  align-items: center; 
  display: flex; 
  justify-content: center;
  color: black;
`;
 