import React from "react";

const RightChevronIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
<path d="M5.83301 16.5373L7.17392 17.8782L14.6739 10.3782L7.17392 2.87823L5.83301 4.21914L11.9921 10.3782L5.83301 16.5373Z" fill="#323743"/>
</svg>
  );
};

export default RightChevronIcon;
