import React from "react";

const ReviewIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
<path d="M19.2008 16.7756C19.8839 16.1366 20.4328 15.3678 20.8154 14.5142C21.198 13.6606 21.4068 12.7394 21.4294 11.8042C21.4294 7.51851 17.2294 4.08994 12.0008 4.08994C6.77227 4.08994 2.57227 7.51851 2.57227 11.8042C2.57227 16.0899 6.77227 19.5185 12.0008 19.5185C12.8987 19.5368 13.7942 19.4212 14.658 19.1756L19.7151 21.2328L19.2008 16.7756Z" stroke="#6D31ED" stroke-width="2.05714" stroke-miterlimit="10" stroke-linecap="square"/>
<path d="M12 7.51852L12 12.6614" stroke="#6D31ED" stroke-width="2.05714" stroke-miterlimit="10" stroke-linecap="square"/>
<path d="M11.9997 16.9471C12.4731 16.9471 12.8569 16.5633 12.8569 16.09C12.8569 15.6166 12.4731 15.2328 11.9997 15.2328C11.5263 15.2328 11.1426 15.6166 11.1426 16.09C11.1426 16.5633 11.5263 16.9471 11.9997 16.9471Z" fill="#6D31ED"/>
</svg>
  );
};

export default ReviewIcon;
