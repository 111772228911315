import ApiServer from "../ApiServer";

export const createOffer = (queryParams) => {
    return ApiServer({
      method: "POST",
      url: `/offers?${queryParams.toString()}`,
    });
  };

  export const getOffer = (queryParams) => {
    return ApiServer({
      method: "GET",
      url: `/offers?${queryParams.toString()}`,
    });
  };

  export const deleteOffer = (owner_email, offer_id) => {
    return ApiServer({
      method: "DELETE",
      url: `/offers?owner_email=${owner_email}&offer_id=${offer_id}`,
    });
  };

  export const updateOffer = (queryParams) => {
    return ApiServer({
      method: "PUT",
      url: `/offers?${queryParams.toString()}`,
    });
  };