import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import LeftChevronIcon from "../../../Assets/Svgs/LeftChevronIcon";
import WarningIcon from "../../../Assets/Svgs/WarningIcon";
import { getServices } from "../../../Api/ApiServerRequests/ServicesApiServerRequests";
import { getProducts } from "../../../Api/ApiServerRequests/ProductsApiServerRequests";
import {
  Header,
  TotalItemContainer,
  MainWrapper,
  ErrorText,
  ItemContainer,
  ServiceAndProductTitle,
  SearchBarContainer,
} from "./Cart.styled";
import { Button } from "antd";
import ProductCard from "../../../Components/ProductCard/ProductCard";
import SearchBox from "../../../Components/SearchBox/SearchBox";

const CartPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const salonName = localStorage.getItem("salonName");
  useEffect(() => {
    const pathArray = window.location.pathname.split("/");
    const potentialSalonName = pathArray[1];
    if (potentialSalonName!==salonName) {
      localStorage.setItem("salonName", potentialSalonName);
        window.location.href = `/${potentialSalonName}`;
    }
  }, [salonName]);
  const cartItem = location.state?.cartItem || {};    
  const [products, setProducts] = useState([]);
  const [services, setServices] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  
  const [error, setError] = React.useState(
    "Please add at least one service. You cannot purchase a product alone."
  );
  const [totalCount, setTotalCount] = useState(1);
  const [cartItems, setCartItems] = useState([cartItem]);

  const [productCounts, setProductCounts] = useState({});

  

  const addNewItem = (item) => {
    setCartItems((prevArray) => [...prevArray, item]);
    setTotalCount((previous) => previous + 1);

    if (item.itemType === "service") {
      const updatedService = services.filter(
        (value) => value.name !== item.name
      );
      setServices(updatedService);
      setFilteredServices(updatedService);
    }

    if (item.itemType === "product") {
      const updatedProduct = products.filter(
        (value) => value.name !== item.name
      );
      setProducts(updatedProduct);
      setFilteredProducts(updatedProduct);
    }
  };

  const removeItem = (removeItem) => {
    const updatedItems = cartItems.filter(
      (item) => item.name !== removeItem.name
    );

    if (removeItem.itemType === "service") {
      setServices((prevArray) => [...prevArray, removeItem]);
      setFilteredServices((prevArray) => [...prevArray, removeItem]);
    }

    if (removeItem.itemType === "product") {
      setProducts((prevArray) => [...prevArray, removeItem]);
      setFilteredProducts((prevArray) => [...prevArray, removeItem]);
    }

    setCartItems(updatedItems);
    setTotalCount((previous) => previous - 1);
  };

  useEffect(() => {
    if (!salonName) return;

    getProducts("name", salonName)
      .then((response) => {
        let products =
          cartItems[0]?.itemType === "product"
            ? response.data.data.filter(
                (item) => item.name !== cartItems[0].name
              )
            : response.data.data;
        const productsWithType = products.map((item) => ({
          ...item,
          itemType: "product",
        }));
        setProducts(productsWithType);
        setFilteredProducts(productsWithType);
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
      });

    getServices("name", salonName)
      .then((response) => {
        let services =
          cartItems[0]?.itemType === "service"
            ? response.data.data.filter(
                (item) => item.name !== cartItems[0].name
              )
            : response.data.data;
        const servicesWithType = services.map((item) => ({
          ...item,
          itemType: "service",
        }));
        setServices(servicesWithType);
        setFilteredServices(servicesWithType);
      })
      .catch((error) => {
        console.error("Error fetching services:", error);
      });
  }, [salonName]);

  const updateProductCount = (item, count) => {
    setProductCounts((prevCounts) => ({
      ...prevCounts,
      [item.name]: count,
    }));
  };

  const handleCheckout = () => {
    const formattedServices = cartItems
      .filter((item) => item.itemType === "service")
      .map(({ name, price }) => ({ name, unit_price: price }));

    const formattedProducts = cartItems
      .filter((item) => item.itemType === "product")
      .map(({ name, price }) => ({
        name,
        quantity: productCounts[name] || 1,
        unit_price: price,
      }));

    sessionStorage.setItem("services", JSON.stringify(formattedServices));
    sessionStorage.setItem("products", JSON.stringify(formattedProducts));

    navigate(`/${salonName}/schedule`);
  };

  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    const filteredProducts = products.filter((item) =>
      item.name.toLowerCase().includes(query)
    );
    const filteredServices = services.filter((item) =>
      item.name.toLowerCase().includes(query)
    );

    setFilteredProducts(filteredProducts);
    setFilteredServices(filteredServices);
  };

  return (
    <div>
      <Header>
        <div
          onClick={() => {
            navigate(-1);
          }}
          style={{ display: "flex" }}
        >
          <LeftChevronIcon />
        </div>
        <div>{salonName}</div>
      </Header>

      <TotalItemContainer>
        <SearchBarContainer>
          <SearchBox onChange={handleSearchChange} />
        </SearchBarContainer>
        <MainWrapper>
          <div>
            <b>{totalCount} item added</b>
          </div>
          <Button
            disabled={!cartItems.some((item) => item.itemType === "service")}
            onClick={handleCheckout}
          >
            Check out
          </Button>
        </MainWrapper>
      </TotalItemContainer>
      
      {!cartItems.some((item) => item.itemType === "service") && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "0px 20px",
            color: "#c12126",
          }}
        >
          <WarningIcon />
          <ErrorText>{error}</ErrorText>
        </div>
      )}
      
      <ItemContainer>
        <ProductCard
          data={cartItems}
          buttonType="calculator"
          onClickHandler={(item) => removeItem(item)}
          onUpdateCount={updateProductCount}
        />
      </ItemContainer>
      
      <>
        <ItemContainer>
          <ServiceAndProductTitle>
            Service & Products Offered
          </ServiceAndProductTitle>
          
          <ProductCard
            data={filteredServices}
            onClickHandler={(item) => addNewItem(item)}
          />
          
          <ProductCard
            data={filteredProducts}
            onClickHandler={(item) => addNewItem(item)}
          />

          {!(filteredServices.length > 0 || filteredProducts.length > 0) && (
            <h4>No items available</h4>
          )}
        </ItemContainer>
      </>
    </div>
  );
};

export default CartPage;
