import styled from "styled-components";
import { Theme } from "../../Themes/Theme";
import { Fonts } from "../../Fonts/Fonts";

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #F5F1FE;
`;

export const LeftHeaderContainer = styled.div`
  display: flex;
 align-items: center;
 padding-left: 20px;
`;

export const RightHeaderContainer = styled.div`
  display: flex; 
  align-items: center;
  padding-right: 20px;
  gap:16px;
`;

export const ProfileMenu = styled.div`
  position: absolute;
  right: 10px;
  width: 206px; 
  height: 115px; 
  background: rgba(255,255,255,1);
  border-radius: 6px; 
  box-shadow: 0px 8px 17px rgba(23, 26, 31, 0.15), 0px 0px 2px rgba(23, 26, 31, 0.12); 
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 100;
`;

export const AccountHeading = styled.div`
width:90%;
   font-family: ${Fonts.primaryFont}; 
  font-size: 12px; 
  font-weight: 400; 
  line-height: 20px; 
  color: rgba(23,26,31,1); 
`;

export const ProfileUserName = styled.div`
width:90%;
  font-family: ${Fonts.primaryFont};
  font-size: 14px; 
  font-weight: 600; 
  line-height: 22px; 
  color: rgba(86,93,109,1);
`;

export const ProfileButton = styled.button`
width:90%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${Fonts.primaryFont};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: ${Theme.primaryColor};
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  border-radius: 3px; 
  border-width: 1px;
  border-color: ${Theme.primaryColor};
  border-style: solid;
`;