import ApiServer from "../ApiServer";

export const getAlerts = (queryParams) => {
  return ApiServer({
    method: "GET",
    url: `/alerts?${queryParams.toString()}`
  });
};

export const markAsSeen = (queryParams) => {
  return ApiServer({
    method: "POST",
    url: `/alerts/seen?${queryParams.toString()}`
  });
};