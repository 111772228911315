import styled from "styled-components";
import { Fonts } from "../../Fonts/Fonts";
import { Theme } from "../../Themes/Theme";

export const UpcomingBooking = styled.div`
  height: 100vh;
  overflow-y: auto;
`;

export const UpcomingBookingTitle = styled.div`
  font-family: Epilogue;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: ${Theme.primaryBlack};
`;

export const TitleBar = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-left: 20px;
  flex-direction: row;
  height: 80px;
`;

export const BookingId = styled.div`
  font-family: Inter;
  font-size: 11px;
  font-weight: 400;
  line-height: 18px;
  color: ${Theme.primaryBlack};
`;

export const Titles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const DetailsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SeperateLine = styled.div`
  width: 90%;
  height: 0px;
  border-width: 1px;
  border-color: rgba(222, 225, 230, 1);
  border-style: solid;
  transform: rotate(0deg);
  margin: 5px 0;
`;

export const StylistDetails = styled.div`
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ChangeDateButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Inter;
  font-size: 11px;
  font-weight: 400;
  line-height: 18px;
  color: rgba(255, 255, 255, 1);
  background: ${Theme.salonColor};
  opacity: 1;
  border: none;
  border-radius: 3px;
  padding: 3px;
`;

export const StylistContainer = styled.div`
  width: 70%;
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ImageContainer = styled.div``;

export const ProfileImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
`;

export const StylistInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Name = styled.div`
  font-family: Epilogue;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: ${Theme.primaryBlack};
`;

export const AdvancedPayment = styled.div`
  font-family: Epilogue;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: ${(props) => props.color};
`;

export const DescriptionText = styled.div`
  font-family: Inter;
  font-size: 11px;
  font-weight: 400;
  line-height: 18px;
  color: ${Theme.primaryBlack};
`;

export const DetailContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  padding: 15px 0;
  gap: 5px;
`;

export const CostContainer = styled.div`
  width: 100%;
`;

export const ServiceType = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const PaymentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DateContainer = styled.div`
  font-family: Inter;
  font-size: 8px;
  font-weight: 600;
  line-height: 14px;
  color: rgba(144, 149, 161, 1);
`;

export const Footer = styled.div`
  width: 90%;
  gap: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const DownloadButton = styled.button`
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: rgba(131, 83, 226, 1);
  background: rgba(245, 242, 253, 1);
  opacity: 1;
  border: none;
  border-radius: 4px;
  width: 100%;
`;

export const CancelButton = styled.button`
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: ${Theme.primaryRed};
  background: ${Theme.lightRed};
  opacity: 1;
  border: none;
  border-radius: 4px;
  width: 100%;
`;

export const GoBackButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: ${Theme.primaryBlack};
  display: inline-block;
  background: rgba(255, 255, 255, 1);
  border-radius: 4px;
  border-width: 1px;
  border-color: ${Theme.primaryBlack};
  padding: 10px;
`;

export const ConfirmButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: rgba(255, 255, 255, 1);
  background: ${Theme.primaryRed};
  opacity: 1;
  border: none;
  border-radius: 4px;
  display: inline-block;
  padding: 10px;
`;

export const Buttons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CompletedText = styled.div`
  display: flex; 
  align-items: center; 
  justify-content: space-between; 
  font-family: ${Fonts.primaryFont}; /* Body */
  font-size: 12px; 
  line-height: 20px; 
  font-weight: 400; 
  opacity: 1;
  color: rgba(17,123,52,1); /* success-700 */
  background: rgba(238,253,243,1);
  padding: 5px;
  border-radius: 10px;
`;
