import { fetchSalon } from "../Api/ApiServerRequests/SalonApiServerRequests";

export const getSalonColor = async () => {
    try {
      const salonName = localStorage.getItem("salonName");
      const response = await fetchSalon(salonName, "salon_url_name");
      return response.data.data.color;
    } catch (error) {
      console.error("Error fetching salon color:", error);
      return null;
    }
};

export const Theme = {
    primaryColor: "rgba(109, 49, 237, 1)",
    primaryBlack: "rgba(23, 26, 31, 1)",
    primaryYellow: "rgba(255, 211, 23, 0.11)",
    primaryWhite: "#fff",
    primaryRed: "rgba(222, 59, 64, 1)",
    lightViolet: "rgba(245, 241, 254, 1)",
    lightRed: "rgba(253, 242, 242, 1)",
    salonColor: await getSalonColor() || "rgba(109, 49, 237, 1)"
};
