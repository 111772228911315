import React, { useEffect, useState } from "react";
import { Table, Tag, Modal, Select, message, DatePicker } from "antd";
import {
  Heading,
  NameRow,
  Name,
  FilterContainer,
} from "./SalonBookings.styled";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  deleteBookings,
  filteredAmountOfBookings,
  getBookingsInformation,
  getFilteredBookingsInformation,
  assignStylist
} from "../../Api/ApiServerRequests/BookingsApiServerRequests";
import {
  getAvailableStylist,
} from "../../Api/ApiServerRequests/StylistsApiServerRequests";
import {
  getServices,
} from "../../Api/ApiServerRequests/ServicesApiServerRequests";
import Button from "../../Components/Button/Button";
import { getUserEmails } from "../../Components/UserEmails/userEmails";

const { Option } = Select;
const { RangePicker } = DatePicker;

const SalonBookings = () => {
  const [activeTab, setActiveTab] = useState("all");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedBookingKey, setSelectedBookingKey] = useState(null);
  const salonEmail = getUserEmails().salonOwnerEmail;
  const [bookings, setBookings] = useState([]);
  const [stylistData, setStylistData] = useState([]);
  const [selectedStylistEmail, setSelectedStylistEmail] = useState(null);
  const [service, setService] = useState(null);
  const [dateRange, setDateRange] = useState(null);
  const [buttonText, setButtonText] = useState("Filter");
  const [showTextBox, setShowTextBox] = useState(false);
  const [services, setServices] = useState([]);
  const [amount, setAmount] = useState(null);
  const [deleteBookingId, setDeleteBookingId] = useState(null);

  useEffect(() => {
    const fetchBookingInformation = async () => {
      try {
        const response = await getBookingsInformation(
          salonEmail,
          "owner email"
        );
        if (response.data.status === 200) {
          const filteredBookings = response.data.data.filter(
            (booking) => !booking.deleted
          );
          setBookings(filteredBookings);
        }
      } catch (error) {
        console.error("Error fetching booking information:", error);
      }
    };

    const fetchServices = async () => {
      try {
        if (salonEmail) {
          const response = await getServices("email", salonEmail);
          setServices(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchServices();
    fetchBookingInformation();
    // console.log("salonEmail", salonEmail);
  }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleAssignStylist = (record) => {
    const { _id, date, selected_time } = record;
    const startTime = selected_time[0];
    const endTime = selected_time[1];
    showModal(_id, date, startTime, endTime);
  };

  const showModal = async (booking_id, date, startTime, endTime) => {
    try {
      const queryParams = new URLSearchParams({
        date: date,
        start_time: startTime,
        end_time: endTime,
        salon_owner_email: salonEmail,
      });

      const response = await getAvailableStylist(queryParams);
      if (response.data.status === 200) {
        setStylistData(response.data.data);
      }
      setSelectedBookingKey(booking_id);
    } catch (error) {
      console.error("Error fetching stylist data:", error);
    } finally {
      setIsModalVisible(true);
    }
  };

  const handleOk = async (selectedStylistEmail) => {
    if (!selectedStylistEmail) {
      message.error("Select one stylist");
      return;
    }
    try {
      const response = await assignStylist(
        selectedBookingKey,
        selectedStylistEmail
      );
      if (response.data.status === 201) {
        message.success(response.data.data);
        const selectedStylist = stylistData.find(stylist => stylist.stylist_email === selectedStylistEmail);
        if (selectedStylist) {
          const stylistName = selectedStylist.name;
          setBookings(prevBookings =>
            prevBookings.map(booking =>
              booking._id === selectedBookingKey
                ? { ...booking, stylist_name: stylistName }
                : booking
            )
          );
        }
      }
    } catch (error) {
      console.error("Error assigning stylist:", error);
    } finally {
      handleCancel();
    }
  };

  const handleCancel = () => {
    setSelectedStylistEmail(null);
    setIsModalVisible(false);
    setStylistData([]);
  };

  const columns = [
    { title: "Name", dataIndex: ["address", "name"], key: "name" },
    { title: "Contact Number", dataIndex: "phone", key: "contactNumber" },
    { title: "Address", dataIndex: ["address", "address"], key: "address" },
    {
      title: "Services",
      dataIndex: "services",
      key: "services",
      render: (services) => (
        <>
          {services.map((service, index) => (
            <span key={index}>
              {service.name}
              {index !== services.length - 1 && ", "}
            </span>
          ))}
        </>
      ),
    },
    { title: "Total payment", dataIndex: "total_price", key: "totalPayment" },
    {
      title: "Booking Status",
      dataIndex: "cancelled",
      key: "bookingStatus",
      render: (cancelled) => (
        <Tag color={cancelled ? "red" : "green"}>
          {cancelled ? "Cancelled" : "Success"}
        </Tag>
      ),
    },
    {
      title: "Stylist",
      dataIndex: "stylist_name",
      key: "stylist",
      render: (stylist, record) => {
        if (record.cancelled) {
          if (stylist) {
            return (
              <span>
                {stylist}
                {/* {!record.cancelled && (
                  <>
                    <EditOutlined
                      style={{ marginLeft: 8, cursor: "pointer" }}
                      onClick={() => handleAssignStylist(record.key)}
                    />
                    <DeleteOutlined
                      style={{ marginLeft: 8, cursor: "pointer" }}
                    />
                  </>
                )} */}
              </span>
            );
          } else {
            return "--";
          }
        } else {
          if (stylist) {
            return (
              <span>
                {stylist}

                <EditOutlined
                  style={{ marginLeft: 8, cursor: "pointer" }}
                  onClick={() => handleAssignStylist(record)}
                />
                {/* <DeleteOutlined
                      style={{ marginLeft: 8, cursor: "pointer" }}
                    /> */}
              </span>
            );
          }
          return (
            <button
              style={{
                fontFamily: "${Fonts.primaryFont}",
                fontSize: "14px",
                fontWeight: 700,
                lineHeight: "22px",
                color: "rgba(109,49,237,1)",
                background: "none",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => handleAssignStylist(record)}
            >
              + Assign
            </button>
          );
        }
      },
    },

    { title: "Booking Date", dataIndex: "date", key: "bookingDate" },
    {
      title: "Time",
      dataIndex: "selected_time",
      key: "time",
      render: (selectedTime) => {
        const startTime = selectedTime[0];
        const endTime = selectedTime[1];
        const startTimeStr = formatTime(startTime);
        const endTimeStr = formatTime(endTime);
        return `${startTimeStr} - ${endTimeStr}`;
      },
    },
    {
      title: "Modify",
      key: "modify",
      render: (text, record) => (
        <span>
          <DeleteOutlined
            style={{ marginLeft: 8, cursor: "pointer" }}
            onClick={() => showDeleteConfirmation(record._id)}
          />
        </span>
      ),
    },
  ];

  const formatTime = (time) => {
    let hour = parseInt(time);
    let meridiem = "am";
    if (hour >= 12) {
      meridiem = "pm";
      if (hour > 12) {
        hour -= 12;
      }
    }
    if (hour === 0) {
      hour = 12;
    }

    return `${hour}:00${meridiem}`;
  };

  const handleServiceChange = (value) => {
    setService(value);
  };

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
  };

  const handleSearch = async () => {
    setShowTextBox(true);
    setButtonText("Reset");

    // console.log("date",dateRange)
    let queryParamsString = `salon_owner_email=${salonEmail}`;

    if (service.length !== 0) {
      service.forEach((serviceName) => {
        queryParamsString += `&service_names=${serviceName}`;
      });
    }

    if(dateRange===null){

    }else if (dateRange.length === 2) {
      const fromDate = dateRange[0].format("YYYY-MM-DD");
      const toDate = dateRange[1].format("YYYY-MM-DD");

      queryParamsString += `&date_range=${fromDate}&date_range=${toDate}`;
    } else if ( dateRange.length === 1) {
      message.error(
        "Please select both 'from' and 'to' dates to specify the date range accurately."
      );
      return;
    }

    const queryParams = new URLSearchParams(queryParamsString);

    try {
      const response = await getFilteredBookingsInformation(queryParams);
      if (response.data.status === 200) {
        setBookings(response.data.data);
      } else if (response.data.status === 404) {
        message.warning(response.data.data);
        setBookings([]);
      }
    } catch (error) {
      console.error("Error fetching booking information:", error);
    }

    let amountqueryParamsString = `owner_email=${salonEmail}`;

    if (service.length !== 0) {
      service.forEach((serviceName) => {
        amountqueryParamsString += `&service_names=${serviceName}`;
      });
    }

    if(dateRange===null){

    }else if (dateRange.length === 2) {
      const fromDate = dateRange[0].format("YYYY-MM-DD");
      const toDate = dateRange[1].format("YYYY-MM-DD");

      amountqueryParamsString += `&start_date=${fromDate}&end_date=${toDate}`;
    } else if ( dateRange.length === 1) {
      message.error(
        "Please select both 'from' and 'to' dates to specify the date range accurately."
      );
      return;
    }

    service.forEach((serviceName) => {
      amountqueryParamsString += `&service_name=${serviceName}`;
    });

    const amountqueryParams = new URLSearchParams(amountqueryParamsString);

    try {
      const response = await filteredAmountOfBookings(amountqueryParams);
      if (response.data.status === 200) {
        setAmount(response.data.data);
      }else if(response.data.status===404){
        setAmount(null)
      }
    } catch (error) {
      console.error("Error fetching booking information:", error);
    }
  };

  const handleReset = async () => {
    setShowTextBox(false);
    setButtonText("Filter");
    setService(null);
    setDateRange(null);
    setAmount(null);

    try {
      const response = await getBookingsInformation(salonEmail, "owner email");
      if (response.data.status === 200) {
        setBookings(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching booking information:", error);
    }
  };

  const handleDelete = async (bookingId) => {
    try {
      const response = await deleteBookings(bookingId);
      if (response.data.status === 201) {
        setBookings(prevBookings => prevBookings.filter(booking => booking._id !== bookingId));
        message.success(response.data.data);
      }
    } catch (error) {
      console.error("Error deleting bookings", error);
    }
    // console.log(bookingId);
  };

  const showDeleteConfirmation = (bookingId) => {
    Modal.confirm({
      title: "Delete Booking",
      content: `Do you want to delete this booking with ID ${bookingId}?`,
      okText: "Delete",
      cancelText: "Cancel",
      onOk: () => handleDelete(bookingId),
    });
  };

  return (
    <>
      <Heading>
        Bookings
        <FilterContainer>
          <Select
            style={{ width: 130, marginRight: 16 }}
            placeholder="Select service"
            onChange={handleServiceChange}
            value={service}
            mode="multiple"
          >
            {services.map((service) => (
              <Option key={service.name} value={service.name}>
                {service.name}
              </Option>
            ))}
          </Select>
          <RangePicker
            style={{ marginRight: 16 }}
            onChange={handleDateRangeChange}
            value={dateRange}
          />
          <Button type="primary" text="Filter" onClick={handleSearch} />
          <Button type="primary" text="Reset" onClick={handleReset} />

          {amount && <Name>SAR {amount}</Name>}
        </FilterContainer>
      </Heading>
      <NameRow>
        <Name
          active={activeTab === "all"}
          onClick={() => handleTabClick("all")}
        >
          All
        </Name>
      </NameRow>
      {activeTab === "all" && <Table dataSource={bookings} columns={columns} />}
      <Modal
        title="Select Stylist"
        visible={isModalVisible}
        onOk={() => handleOk(selectedStylistEmail)}
        onCancel={handleCancel}
        afterClose={() => setSelectedStylistEmail(null)}
      >
        <Select
          showSearch
          style={{ width: 200 }}
          placeholder="Select a stylist"
          optionFilterProp="children"
          onChange={(value) => setSelectedStylistEmail(value)}
          value={selectedStylistEmail}
        >
          {stylistData.map((stylist) => (
            <Option key={stylist.stylist_email} value={stylist.stylist_email}>
              {stylist.name}
            </Option>
          ))}
        </Select>
      </Modal>
    </>
  );
};

export default SalonBookings;
