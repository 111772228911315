import styled from "styled-components";
import { Theme } from "../../Themes/Theme";
import { Fonts } from "../../Fonts/Fonts";

export const SeparateLine = styled.div`
  width: 98%;
  height: 0px;
  border-width: 1px;
  border-color: ${Theme.primaryBlack};
  border-style: solid;
  transform: rotate(0deg);
`;

export const Settings = styled.div`
  width: 90%;
  margin: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
export const SettingsContainer = styled.div`
  background: #fff;
  border-radius: 4px;
  width: 21%;
  margin: 10px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  cursor: pointer;
  background: rgba(255, 255, 255, 1);
  border-radius: 4px;
  box-shadow: 0px 0px 1px rgba(23, 26, 31, 0.07),
    0px 0px 2px rgba(23, 26, 31, 0.12);
`;

export const SettingsNameText = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  color: ${Theme.primaryBlack};
  word-wrap: break-word;  
  word-break: break-word; 
  white-space: normal; 
`;

export const SettingsDescription = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: ${Theme.primaryBlack};
   word-wrap: break-word;  
  word-break: break-word; 
  white-space: normal; 
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
`;

export const EditButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${Fonts.primaryFont};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: ${Theme.primaryColor};
  background: ${Theme.lightViolet};
  opacity: 1;
  border: none;
  border-radius: 3px;
  width: 68px;
  height: 28px;
`;

export const DeleteButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${Fonts.primaryFont};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: ${Theme.primaryRed};
  background: ${Theme.lightRed};
  opacity: 1;
  border: none;
  border-radius: 3px;
  width: 68px;
  height: 28px;
`;

export const OfferNameContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const OfferNameDescription = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  color: ${Theme.primaryBlack};
  margin-bottom: 8px;
  width: 100%;
`;

export const OfferNameInput = styled.input`
  width: 99%;
  font-family: ${Fonts.primaryFont};
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: ${Theme.primaryBlack};
  border: 1px solid ${Theme.primaryBlack};
  border-radius: 4px;
  outline: none;

  ::placeholder {
    color: ${Theme.primaryBlack};
  }
`;
