import styled from "styled-components";
import { Theme } from "../../Themes/Theme";


export const LoginFormContainer = styled.div`
  display: flex;
  width:100%;
  padding: 20px;
  align-items: center;
  justify-content: center;
`;

export const FormWrapper = styled.div`
  width: 30%;
`;

export const FormHeading = styled.h2`
  margin-bottom: 20px;
`;

export const FormGroup = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid ${Theme.primaryBlack};
`;

export const TextInput = styled.input`
  width:100%;
  border: none;
  outline: none;
`;


export const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const CheckboxInput = styled.input`
  margin-right: 10px;
  background-color: ${Theme.primaryColor};
  border: none;
  cursor: pointer;
`;

export const SignInButton = styled.button`
  width: 100%;
  padding: 15px;
  background-color: ${Theme.primaryColor};
  color: #fff;
  border: none;
  cursor: pointer;
`;

export const SignUpLink = styled.p`
  text-align: center;
  margin-top: 20px;
   color: ${Theme.primaryColor};
   cursor: pointer;
`;

export const ForgotPasswordContainer = styled.div`
  text-align: right;
  padding: 10px 0;
`;

export const ForgotPasswordText = styled.span`
  color: ${Theme.primaryColor};
  cursor: pointer;
`;
