import styled from "styled-components";
import { Fonts } from "../../Fonts/Fonts";
import { Theme } from "../../Themes/Theme";

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${Fonts.primaryFont};
  font-size: 32px;
  font-weight: 400;
  line-height: 48px;
  color: ${Theme.primaryBlack};
`;

export const LoginFormContainer = styled.div`
  display: flex;
  align-items: left;
  justify-content: center;
  flex-direction: column;
  height: 100vh;

`;

export const LoginWrapper = styled.div`
`;