import styled from "styled-components";
import { Theme } from "../../Themes/Theme";
import { Fonts } from "../../Fonts/Fonts";

export const CouponContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  background: ${Theme.lightViolet};
  border-radius: 0px;
  padding: 5px;
  gap: 5px;
`;

export const Coupons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CouponName = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  color: ${Theme.primaryDark};;
`;

export const CouponDescription = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 11px;
  line-height: 18px;
  font-weight: 400;
  color: ${Theme.primaryBlack};
`;

export const ReferContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap:10px;
`;

export const CouponCodeContainer = styled.div`
  background: rgba(240, 249, 255, 0);
  border-radius: 4px;
  border-width: 1px;
  border-color: rgba(211, 193, 250, 1);
  border-style: solid;
  font-family: ${Fonts.primaryFont};
  font-size: 11px;
  font-weight: 400;
  line-height: 18px;
  color: ${Theme.primaryColor};;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  padding: 5px;
`;

export const ButtonContainer = styled.div`
  display: flex; 
  align-items: center; 
  justify-content: center; 
  font-family: ${Fonts.primaryFont}; 
  font-size: 11px; 
  line-height: 18px; 
  font-weight: 400; 
  color: rgba(255,255,255,1);
  background: rgba(109,49,237,1); 
  opacity: 1; 
  border: none; 
  border-radius: 3px;
  gap: 4px; 
  padding: 5px;
`;