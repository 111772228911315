import React from "react";

const ShieldIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
    <path d="M19.7137 12.6614C19.7137 18.6614 11.9994 21.2328 11.9994 21.2328C11.9994 21.2328 4.28516 18.6614 4.28516 12.6614L4.28516 4.94709L11.9994 3.2328L19.7137 4.94709V12.6614Z" stroke="#6D31ED" stroke-width="2.05714" stroke-miterlimit="10" stroke-linecap="square"/>
    <path d="M8.57227 11.8042L11.1437 14.3757L16.2866 8.37566" stroke="#6D31ED" stroke-width="2.05714" stroke-miterlimit="10" stroke-linecap="square"/>
    </svg>
  );
};

export default ShieldIcon;
