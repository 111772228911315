import React, { useEffect, useState } from "react";
import { CancelIcon, SidebarTitle, TitleBar } from "../Sidebar/Sidebar.styled";
import LeftChevronIcon from "../../Assets/Svgs/LeftChevronIcon";
import UpcomingBookingDetails from "../UpcomingBookingDetails/UpcomingBookingDetails";
import {
  SettingContainer,
  SettingDescription,
  SettingName,
  Settings,
} from "./CustomerSettings.styled";
import { message, Modal } from "antd";
import { deleteCustomer } from "../../Api/ApiServerRequests/CustomersApiServerRequests";
import { useNavigate } from 'react-router-dom';

const CustomerSettings = ({ onCancel }) => {
  const [showDetails, setShowDetails] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const customerNumber = localStorage.getItem("customerPhoneNumber");
  const salonName = localStorage.getItem("salonName");
  const navigate = useNavigate();

  useEffect(() => {
    const pathArray = window.location.pathname.split("/");
    const potentialSalonName = pathArray[1];
    if (potentialSalonName!==salonName) {
      localStorage.setItem("salonName", potentialSalonName);
        window.location.href = `/${potentialSalonName}`;
    }
  }, []);

  const handleCancel = () => {
    setShowDetails(false);
    setShowDeleteConfirmation(false);
  };

  const handleDeleteConfirm = async () => {
    try {
      const queryparams= new URLSearchParams({
        phone:customerNumber,
        salon_url_name:salonName
      })
      const response = await deleteCustomer(queryparams);
      // console.log(response.data.status)
      if(response.data.status===200){
        message.success(response.data.data);
        navigate(`/${salonName}/customer`);
      }else{
        message.error(response.data.data);
      }
    } catch {
      console.error("Error in sending data");
    } finally {
      setShowDeleteConfirmation(false);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteConfirmation(false);
  };

  if (showDetails) {
    return <UpcomingBookingDetails onCancel={handleCancel} />;
  }

  return (
    <>
      <TitleBar>
        <CancelIcon onClick={onCancel}>
          <LeftChevronIcon />
        </CancelIcon>
        <SidebarTitle>Settings</SidebarTitle>
      </TitleBar>

      <Settings>
        <SettingContainer onClick={() => setShowDeleteConfirmation(true)}>
          <SettingName>Delete Your Account</SettingName>
          <SettingDescription>
            This Action will permanently delete your account
          </SettingDescription>
        </SettingContainer>
      </Settings>

      <Modal
        title="Delete Account"
        visible={showDeleteConfirmation}
        onOk={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
        okText="Yes"
        cancelText="No"
      >
        <p>Are you sure you want to delete your account?</p>
      </Modal>
    </>
  );
};

export default CustomerSettings;
