import styled from "styled-components";
import { Fonts } from "../../Fonts/Fonts";
import { Theme } from "../../Themes/Theme";

export const FindContainer = styled.div`
  /* margin-top: 20px;
  margin-bottom: 20px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  /* gap: 30px; */
  width: 100%;
  justify-content: end;
`;

export const Find = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
`;

export const FindSalonText = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 18px;
  font-weight: 600;
  color: ${Theme.primaryBlack};
`;

export const SearchBarContainer = styled.div`
  display: flex;
  margin-top: 10px;
`;

export const EmailInput = styled.input`
  width: 70%;
  padding: 10px;
  border: 1px solid #ccc;
  font-family: ${Fonts.primaryFont};
  font-size: 14px;
`;

export const SearchButton = styled.button`
  background-color: #7d4cdb;
  color: #fff;
  padding: 10px 20px;
  border: none;
  font-family: ${Fonts.primaryFont};
  font-size: 14px;
  cursor: pointer;
`;
