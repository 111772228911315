import React from "react";
import { CustomerHeader, SearchContainer, HelloContainer, HelloText, WelcomeBackText } from "./CustomHeader.styled";
import SearchBox from "../../Components/SearchBox/SearchBox";
import MenuIcon from "../../Assets/Svgs/MenuIcon";

const CustomHeader = ({ onMenuClick, menuIcon ,salonName}) => {
  const handleClick = () => {
    // console.log("Menu icon clicked");
    onMenuClick();
  };

  return (
    <CustomerHeader>
      <SearchContainer>
        {/* <SearchBox /> */}
        <HelloContainer>
          <HelloText>Hello</HelloText>
          <WelcomeBackText>Welcome to {salonName} salon</WelcomeBackText>
        </HelloContainer>
        {menuIcon && (
          <div onClick={handleClick} style={{ cursor: "pointer" }}>
            <MenuIcon />
          </div>
        )}
      </SearchContainer>
    </CustomerHeader>
  );
};

export default CustomHeader;