import styled from "styled-components";
import { Theme } from "../../Themes/Theme";
import { Fonts } from "../../Fonts/Fonts";

export const SalonSettingsContainer = styled.div`
width:100%;
display: flex;
justify-content: left;
flex-direction: column;
`;

export const SettingsHeader = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 24px;
  line-height: 46px;
  font-weight: 400;
  color: ${Theme.primaryDark};;
`;

export const SeparatorLine = styled.div`
  width: 90%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.2);
`;

export const Settings = styled.div`
 width:95%;
 margin: 20px;
 display: flex;
 flex-direction: row;
 flex-wrap: wrap;
`;
export const SettingsContainer = styled.div`
  background: rgba(245,241,254,1);
  border-radius: 4px;
  width:30%;
  margin: 10px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  cursor: pointer;
`;

export const SettingsName = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`;

export const SettingsNameText = styled.div`
   font-family: ${Fonts.primaryFont};
  font-size: 18px; 
  line-height: 28px; 
  font-weight: 600; 
  color: rgba(109,49,237,1); 
`;

export const SettingsDescription = styled.div`
   font-family: ${Fonts.primaryFont};
  font-size: 14px; 
  line-height: 22px; 
  font-weight: 400; 
  color: rgba(23,26,31,1);
`;
