import styled from "styled-components";
import { Theme } from "../../Themes/Theme";
import { Fonts } from "../../Fonts/Fonts";

export const HeadingRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NameRow = styled.div`
  display: flex;
  margin-top: 40px;
  gap: 40px;
  margin-bottom: 30px;
`;

export const Name = styled.div`
  cursor: pointer;
  color: ${(props) => (props.active ? "#6D31ED" : "#000")};
  position: relative;

  &::after {
    content: "";
    display: ${(props) => (props.active ? "block" : "none")};
    position: absolute;
    bottom: -12px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #6d31ed;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  margin-top: 40px;
  margin-bottom: 30px;
  margin-right: 30px;
  gap: 20px;
`;

export const AddButton = styled.button`
  color: rgba(255, 255, 255, 1);
  background: ${Theme.primaryColor};
  opacity: 1;
  border: none;
  border-radius: 4px;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ServiceProductContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-evenly;
  gap: 20px; */
  align-items: center;
  width: 97%;
  margin-left: 30px;
`;

export const InformationContainer = styled.div`
  /* flex-grow: 1; */
  max-width: 235px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 1);
  border-radius: 4px;
  box-shadow: 0px 2px 5px rgba(23, 26, 31, 0.09),
    0px 0px 2px rgba(23, 26, 31, 0.12);
  padding: 5px;
  margin: 10px;
`;

export const ImageContainer = styled.div`
  width: 225px;
  height: 165px;
  /* height:100px; */

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
`;

export const InfoContainer = styled.div`
  width: 100%;
`;

export const ServiceName = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: ${Theme.primaryBlack};
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  white-space: normal;
  word-wrap: break-word;
`;

export const ServiceInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export const ServiceCost = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: ${Theme.primaryColor};
`;

export const ServiceTime = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: rgba(144, 149, 161, 1);
`;

export const RequirementLabel = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 11px;
  font-weight: 400;
  line-height: 18px;
  color: ${Theme.primaryBlack};
`;

export const Requirements = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: rgba(50, 55, 67, 1);
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
`;

export const EditButton = styled.button`
  /* padding: 3px 29px; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${Fonts.primaryFont};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: ${Theme.primaryColor};
  background: ${Theme.lightViolet};
  opacity: 1;
  border: none;
  border-radius: 3px;
  width: 68px;
  height: 28px;
`;

export const DeleteButton = styled.button`
  /* padding: 3px 29px; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${Fonts.primaryFont};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: ${Theme.primaryRed};
  background: ${Theme.lightRed};
  opacity: 1;
  border: none;
  border-radius: 3px;
  width: 68px;
  height: 28px;
`;

export const RemoveImage = styled.button`
  padding: 3px 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${Fonts.primaryFont};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: ${Theme.primaryRed};
  background: ${Theme.lightRed};
  opacity: 1;
  border: none;
  border-radius: 3px;
  margin-top: 10px;
  cursor: pointer;
`;

export const UploadImageContainer = styled.div`
  width: 191px;
  height: 73px;
  background: rgba(243, 244, 246, 1);
  border-radius: 2px;
  border-width: 1px;
  border-color: ${Theme.primaryBlack};
  border-style: dashed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  img {
    border-radius: 2px;
  }
`;

export const HiddenFileInput = styled.input`
  display: none;
`;

export const ImageInput = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
`;

export const LogoText = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: ${Theme.primaryBlack};
`;

export const WarningText = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 5px;
`;

export const ShopNameContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ToggleContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center; /* Align items in the center vertically */
  justify-content: space-between; /* Add space between the items */
`;

export const ShopNameDescription = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  color: ${Theme.primaryBlack};
  margin-bottom: 8px;
  width: 100%;
`;

export const ShopNameInput = styled.input`
  width: 99%;
  font-family: ${Fonts.primaryFont};
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: ${Theme.primaryBlack};
  border: 1px solid ${Theme.primaryBlack};
  border-radius: 4px;
  outline: none;

  ::placeholder {
    color: ${Theme.primaryBlack};
  }
`;

export const DescriptionTextarea = styled.textarea`
  width: 99%;
  font-family: ${Fonts.primaryFont};
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: ${Theme.primaryBlack};
  border: 1px solid ${Theme.primaryBlack};
  border-radius: 4px;
  resize: vertical;
  min-height: 70px;
  outline: none;

  ::placeholder {
    color: ${Theme.primaryBlack};
  }
`;

export const SeparateLine = styled.div`
  width: 100%;
  height: 0px;
  border-width: 1px;
  border-color: ${Theme.primaryBlack}; /* neutral-400 */
  border-style: solid;
  transform: rotate(0deg);
  margin-top: 20px;
`;

export const ItemsNeededContainer = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;

export const DropdownContainer1 = styled.div`
  width: 70%;
`;

export const DropdownContainer2 = styled.div`
  width: 30%;
`;

export const AddMore = styled.button`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: end;
  font-family: ${Fonts.primaryFont};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: ${Theme.lightViolet};
  background: ${Theme.primaryColor};
  border: none;
  padding: 10px;
`;

export const RemoveItemButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: end;
`;
