import React from "react";
import {
  SalonSettingsContainer,
  SeparatorLine,
  Settings,
  SettingsHeader,
} from "../SalonSettings/SalonSettings.styled";
import {
  Description,
  Heading,
  SettingsContainer,
} from "./PaymentSettings.styled";
import RightChevronIcon from "../../Assets/Svgs/RightChevronIcon";
import { Route, Routes, Link, useNavigate, useLocation } from "react-router-dom";
import AdvancePaymentSettings from "../AdvancePaymentSettings/AdvancePaymentSettings";
import Urls from "../../Urls/Urls";


const CustomSettingsContainer = ({ heading, description, to }) => {
  return (
      <SettingsContainer>
            <Link to={to}>
        <Heading>
          {heading}
          <RightChevronIcon />
        </Heading>
        </Link>
        <Description>{description}</Description>
      </SettingsContainer>
  );
};

const PaymentSettings = () => {
  return (
   
      <>
        <Settings>
          <CustomSettingsContainer
            heading="Payment Method"
            description="Configure secure transactions seamlessly with our integrated payment gateway settings."
            to={Urls.paymentMethod}
          />
          <CustomSettingsContainer
            heading="Advance Payment"
            description="Configure advance payment options for your pre-bookings."
            to={Urls.advancePaymentSettings}
          />
        </Settings>
      </>
  );
};


export default PaymentSettings;
