import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import {
  ButtonContainer,
  CustomerWelcomePageContainer,
  GetStartedButton,
  LoginButton,
  LogoContainer,
  SalonNotAvailable,
  WelcomeText,
  WelcomeTextContainer,
} from "./CustomerWelcomePage.styled";
import { useNavigate } from "react-router-dom";
import { fetchSalon } from "../../../Api/ApiServerRequests/SalonApiServerRequests";
import NoDataIcon from "../../../Assets/Svgs/NoDataIcon";

const CustomerWelcomePage = () => {
  localStorage.removeItem("role");
  // localStorage.removeItem("email");
  localStorage.removeItem("customerPhoneNumber");
  const [isSalonAvailable, setIsSalonAvailable] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const salonName = localStorage.getItem("salonName");
  const [salonDescription, setSalonDescription] = useState("");
  const [logoPath, setLogoPath] = useState("");
  const [name, setName] = useState("");

  useEffect(() => {
    const pathArray = window.location.pathname.split("/");
    const potentialSalonName = pathArray[1];
    if (potentialSalonName!==salonName) {
      localStorage.setItem("salonName", potentialSalonName);
        window.location.href = `/${potentialSalonName}`;
    }
  }, []);

  useEffect(() => {
    if (salonName) {
      setIsLoading(true);
      fetchSalon(salonName, "salon_url_name")
        .then((response) => {
          console.log(response)
          if (response.data.data.trial_membership_status === "Inactive" && response.data.data.premium_membership_status === "Inactive") {
            setIsSalonAvailable(false);
            localStorage.removeItem("salonName")
          } else if (response.data.data.trial_membership_status === "Active" || response.data.data.premium_membership_status === "Active") {
            setIsSalonAvailable(true);
            setLogoPath(response.data.file.path);
            setSalonDescription(response.data.data.description);
            setName(response.data.data.name);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching salon:", error);
          setIsSalonAvailable(false);
          setIsLoading(false);
        });
    }
  }, [salonName]);

  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate(`/${salonName}/login`);
  };

  const handleGetStarted = () => {
    navigate(`/${salonName}/getting-started`);
  };

  return (
    <div>
      <CustomerWelcomePageContainer>
        {isLoading ? (
          <Spin size="large" />
        ) : isSalonAvailable ? (
          <>
            <WelcomeTextContainer>
            <WelcomeText>
                {name}
              </WelcomeText>
              <LogoContainer>
                <img src={logoPath} alt="Salon Logo" style={{ width: '100px', height: '100px' }} />
              </LogoContainer>
              <WelcomeText>
                {salonDescription}
              </WelcomeText>
            </WelcomeTextContainer>
            <ButtonContainer>
              <GetStartedButton onClick={handleGetStarted}>
                Get Started
              </GetStartedButton>
              <LoginButton onClick={handleLoginClick}>Login</LoginButton>
            </ButtonContainer>
          </>
        ) : (
          <WelcomeTextContainer>
            <SalonNotAvailable>
              <NoDataIcon />
              The salon is unavailable. Please verify the URL.
            </SalonNotAvailable>
          </WelcomeTextContainer>
        )}
      </CustomerWelcomePageContainer>
    </div>
  );
};

export default CustomerWelcomePage;
