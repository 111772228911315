import React, {useEffect, useState } from "react";
import { CancelIcon, SidebarTitle, TitleBar } from "../Sidebar/Sidebar.styled";
import LeftChevronIcon from "../../Assets/Svgs/LeftChevronIcon";
import {Info, InfoContainer, InfoDescription, InfoHeading} from "./CustomerHelp.styled";
import {
  fetchSalon,
} from "../../Api/ApiServerRequests/SalonApiServerRequests";

const CustomerHelp = ({ onCancel }) => {
  const [formattedData, setFormattedData] = useState();
  const salonName=localStorage.getItem("salonName");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const pathArray = window.location.pathname.split("/");
    const potentialSalonName = pathArray[1];
    if (potentialSalonName!==salonName) {
      localStorage.setItem("salonName", potentialSalonName);
        window.location.href = `/${potentialSalonName}`;
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchSalon(salonName, "salon_url_name");
        // console.log("response", response);

        if (response.data.status === 200) {
          const changedData = Object.entries(response.data.data.help).map(
            ([key, value]) => ({
              key: key,
              value: value,
            })
          );
          setFormattedData(changedData);
          // console.log("formattedData", changedData);
        }
        // console.log("response", response.data.data.help);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [salonName]);


  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <TitleBar>
        <CancelIcon onClick={onCancel}>
          <LeftChevronIcon />
        </CancelIcon>
        <SidebarTitle>Help</SidebarTitle>
      </TitleBar>

      <InfoContainer>
        {formattedData.length > 0 ? (
          formattedData.map((dataEntry) => (
            <Info key={dataEntry.key}>
              <InfoHeading>{dataEntry.key.charAt(0).toUpperCase() + dataEntry.key.slice(1)}</InfoHeading>
              <InfoDescription>{dataEntry.value}</InfoDescription>
            </Info>
          ))
        ) : (
          <Info>
            <InfoHeading>No data available</InfoHeading>
          </Info>
        )}
      </InfoContainer>
    </>
  );
};

export default CustomerHelp;
