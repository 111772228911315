import styled from "styled-components";

export const ReviewSetupContainer = styled.div`
  width: 90%;
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const TextInputContainer = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  margin-left: 10px;
`;


