import styled from "styled-components";
import { Button as AntButton } from 'antd';

export const StyledButton = styled(AntButton)`
  height: ${(props) => props?.height || "36px"};
  border-radius: 4px;
  gap: 8px;
  display: flex;
  align-items: center;
  width: ${(props) => props?.width || "auto"};
  justify-content: center;
  &.ant-btn-primary {
    background-color: ${(props) => props?.backgroundColor || '#6D31ED !important'}; ; 
    border-color: ${(props) => props?.borderColor || 'none !important'}; 
    color: ${(props) => props?.color || '#fff !important'}; 
  }
  &.ant-btn-default {
    background-color: ${(props) => props?.backgroundColor || '#fff !important'}; ; 
    border-color: ${(props) => props?.borderColor || 'none !important'};  
    color: ${(props) => props?.color || '#6D31ED !important'}; 
  }
  &.ant-btn-ghost {
    background-color: ${(props) => props?.backgroundColor || '#fff !important'}; ; 
    border-color: ${(props) => props?.borderColor || 'none !important'};  
    color: ${(props) => props?.color || '#6D31ED !important'}; 
  }
`;