import React, { useState } from "react";
import { Form, Input, message } from "antd";
import { useNavigate } from "react-router-dom";
import {
  ForgotPasswordContainer,
  Header,
  StyledButton,
} from "./ForgotPassword.styled";

const ForgotPassword = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [timer, setTimer] = useState(900);
  const [otp, setOtp] = useState("");
  const [resettingPassword, setResettingPassword] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (values) => {
    setLoading(true);
    setTimeout(() => {
      //console.log("Sending OTP to:", values.email);
      setLoading(false);
      setOtpSent(true);
      startTimer();
    }, 1000);
  };

  const handlePasswordSubmit = (values) => {
    const password = values.newPassword;

    if (password.length < 12) {
      message.error({
        title: "Weak Password",
        content: "Password must be at least 12 characters long.",
      });
      return;
    }

    if (!/[a-z]/.test(password)) {
      message.error({
        title: "Weak Password",
        content: "Password must contain at least one lowercase letter.",
      });
      return;
    }

    if (!/[A-Z]/.test(password)) {
      message.error({
        title: "Weak Password",
        content: "Password must contain at least one uppercase letter.",
      });
      return;
    }

    if (!/\d/.test(password)) {
      message.error({
        title: "Weak Password",
        content: "Password must contain at least one number.",
      });
      return;
    }

    if (!/[@$!%*?&]/.test(password)) {
      message.error({
        title: "Weak Password",
        content: "Password must contain at least one special character.",
      });
      return;
    }

    setResettingPassword(true)
  };

  const handleOtpSubmit = () => {
    setResettingPassword(true)
  };

  const startTimer = () => {
    const countdown = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 0) {
          clearInterval(countdown);
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleResendOtp = () => {
    //console.log("Resending OTP...");
    setTimer(900);
    startTimer();
    setOtpSent(true);
  };

  const handleResetPasswordSubmit = (values) => {
    //console.log("New Password:", values.newPassword);
    //console.log("Confirm New Password:", values.confirmNewPassword);
    const password = values.newPassword;

    if (password.length < 12) {
      message.error({
        title: "Weak Password",
        content: "Password must be at least 12 characters long.",
      });
      return;
    }

    if (!/[a-z]/.test(password)) {
      message.error({
        title: "Weak Password",
        content: "Password must contain at least one lowercase letter.",
      });
      return;
    }

    if (!/[A-Z]/.test(password)) {
      message.error({
        title: "Weak Password",
        content: "Password must contain at least one uppercase letter.",
      });
      return;
    }

    if (!/\d/.test(password)) {
      message.error({
        title: "Weak Password",
        content: "Password must contain at least one number.",
      });
      return;
    }

    if (!/[@$!%*?&]/.test(password)) {
      message.error({
        title: "Weak Password",
        content: "Password must contain at least one special character.",
      });
      return;
    }
    
    message.success("Password changed successfully!");
    setTimeout(() => {
      navigate("/dashboard"); 
    }, 2000);
  };

  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;

  return (
    <ForgotPasswordContainer>
      <Header>Forgot Password</Header>
      {!resettingPassword ? (
        <Form
          form={form}
          onFinish={handleSubmit}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Form.Item
            name="email"
            label="Please enter your email"
            rules={[
              {
                required: true,
                message: "Please enter your email!",
                type: "email",
              },
            ]}
          >
            <Input />
          </Form.Item>

          {otpSent && (
            <Form.Item wrapperCol={{ span: 24 }}>
              <StyledButton onClick={handleResendOtp}>Resend OTP</StyledButton>
            </Form.Item>
          )}

          {otpSent && (
            <>
              <Form.Item
                name="otp"
                label="Please enter OTP"
                rules={[
                  {
                    required: true,
                    message: "Please enter OTP!",
                  },
                ]}
              >
                <Input value={otp} onChange={handleOtpChange} />
              </Form.Item>

              <Form.Item>
                <p>
                  Time remaining: {minutes}:
                  {seconds < 10 ? `0${seconds}` : seconds}
                </p>
              </Form.Item>

              <Form.Item wrapperCol={{ span: 24 }}>
                <StyledButton
                  type="submit"
                  loading={loading}
                  onClick={handleOtpSubmit}
                >
                  Submit
                </StyledButton>
              </Form.Item>
            </>
          )}

          {!otpSent && (
            <Form.Item>
              <StyledButton type="submit" loading={loading}>
                Send OTP
              </StyledButton>
            </Form.Item>
          )}
        </Form>
      ) : (
        <Form
          form={form}
          onFinish={handleResetPasswordSubmit}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Form.Item
            name="newPassword"
            label="New Password"
            rules={[
              {
                required: true,
                message: "Please enter a new password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="confirmNewPassword"
            label="Confirm New Password"
            dependencies={["newPassword"]}
            rules={[
              {
                required: true,
                message: "Please confirm your new password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The two passwords do not match!")
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item wrapperCol={{ span: 24 }}>
            <StyledButton type="submit" loading={loading} onclick={handlePasswordSubmit}>
              Submit
            </StyledButton>
          </Form.Item>
        </Form>
      )}
    </ForgotPasswordContainer>
  );
};

export default ForgotPassword;