import styled from "styled-components";

export const ActivitesContainer = styled.div`
  Width:100%;
  display: flex;
  align-items: center;
  margin-top: 20px;
  flex-direction: column;
  overflow-y: scroll;
  height: calc(80vh);
`;

export const Activites = styled.div`
  width: 90%;
  /* height: 100px; */
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
`;

export const ProfileImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
`;

export const DetailsContainer = styled.div`
display: flex;
  flex-direction: row;
  padding-right: 5px;
  justify-content: space-between;
`;

export const DateContainer = styled.div`
font-family: Epilogue;
  font-size: 12px; 
  font-weight: 400; 
  line-height: 20px; 
  color: rgba(23,26,31,1);
`;

export const Services = styled.div`
font-family: Inter; 
  font-size: 11px; 
  font-weight: 400; 
  line-height: 18px; 
  color: rgba(23,26,31,1);
  `;

export const ImageContainer = styled.div`
display: flex;
flex-direction: row;
align-items: center;
`;

export const StarContainer = styled.div``;