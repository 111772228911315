import React, { useState } from "react";
import {
  DescriptionTextarea,
  ImageContainer,
  LogoText,
  NextButton,
  SetupContent,
  SetupForm,
  SetupFormContainer,
  SetupFormHeading,
  ShopNameContainer,
  ShopNameDescription,
  ShopNameInput,
  ImageInput,
  HiddenFileInput,
  WarningText,
  RecommendedText,
  ButtonContainer,
  DeleteButton,
} from "./SetupStore.styled";
import PlusIcon from "../../../Assets/Svgs/PlusIcon";
import { useNavigate } from "react-router-dom";
import defaultImage from "../../../Assets/Images/default_image.png";
import { createSalon } from "../../../Api/ApiServerRequests/SalonApiServerRequests";
import { message } from "antd";
import { getUserEmails } from "../../../Components/UserEmails/userEmails";
import Urls from "../../../Urls/Urls";

const SetupStore = () => {
  const [selectedImage, setSelectedImage] = useState();
  const [shopName, setShopName] = useState("");
  const [description, setDescription] = useState("");
  const [shopNameValidationError, setShopNameValidationError] = useState(false);
  const [descriptionValidationError, setDescriptionValidationError] =
    useState(false);
  const navigate = useNavigate();
  const [fileSizeError, setFileSizeError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [shopNameCharCount, setShopNameCharCount] = useState(false);
  const [shopDescriptionCharCount, setShopDescriptionCharCount] =
    useState(false);
  const [shopOwnerName, setShopOwnerName] = useState("");
  const [shopOwnerNameValidationError, setShopOwnerNameValidationError] =
    useState(false);
  const [shopOwnerNameCharCount, setShopOwnerNameCharCount] = useState(false);
  const [shopOwnerNameSpecialCharacter, setShopOwnerNameSpecialCharacter] =
    useState(false);

  const handleShopOwnerNameChange = (e) => {
    const inputValue = e.target.value;
    const specialCharsRegex = /[!@#$%^&*(),.?":{}|<>]/;
    setShopOwnerNameSpecialCharacter(false);
    setShopOwnerNameCharCount(false);

    if (!specialCharsRegex.test(inputValue)) {
      if (inputValue.length <= 25) {
        setShopOwnerName(inputValue);
        setShopOwnerNameCharCount(false);
      } else {
        setShopOwnerNameCharCount(true);
        setShopOwnerNameValidationError(false);
      }
    } else {
      setShopOwnerNameSpecialCharacter(true);
    }
  };

  const handleShopNameChange = (e) => {
    const inputValue = e.target.value;

    if (inputValue.length <= 25) {
      setShopName(inputValue);
      setShopNameCharCount(false);
    } else {
      setShopNameCharCount(true);
      setShopNameValidationError(false);
    }
  };

  const handleShopDescriptionChange = (e) => {
    const inputValue = e.target.value;

    if (inputValue.length <= 100) {
      setDescription(inputValue);
      setShopDescriptionCharCount(false);
    } else {
      setShopDescriptionCharCount(true);
      setDescriptionValidationError(false);
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const maxSizeInBytes = 10 * 1024 * 1024;
      if (file.size > maxSizeInBytes) {
        setFileSizeError(true);
        return;
      } else {
        setFileSizeError(false);
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleNextClick = async () => {
    setShopNameValidationError(!shopName);
    setDescriptionValidationError(!description);
    setShopOwnerNameValidationError(!shopOwnerName);
    setShopOwnerNameSpecialCharacter(false);

    if (!shopName || !description || !shopOwnerName || loading) {
      setShopNameValidationError(!shopName);
      setDescriptionValidationError(!description);
      setShopOwnerNameValidationError(!shopOwnerName);
      return;
    }

    try {
      setLoading(true);
      const queryParams = new URLSearchParams({
        name: shopName,
        description: description,
        owner_email: getUserEmails().salonOwnerEmail,
        owner_name: shopOwnerName,
      });
      // const response = await fetch(selectedImage);
      // const imageBlob = await response.blob();

      let imageBlob;
      if (selectedImage) {
        const response = await fetch(selectedImage);
        imageBlob = await response.blob();
      } else {
        // If selectedImage is null, use defaultImage
        const defaultImageResponse = await fetch(defaultImage);
        imageBlob = await defaultImageResponse.blob();
      }

      const formData = new FormData();
      formData.append("file", imageBlob);

      const uploadResponse = await createSalon(queryParams, formData);
      //console.log("uploadresponse", uploadResponse);

      if (uploadResponse.data.status === 200) {
        message.success("Your salon has been successfully updated!");
        localStorage.setItem("ownerSalonName", shopName);
        navigate(Urls.home);
      } else {
        message.error(
          "The creation of the salon was unsuccessful. Please try again."
        );
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteClick = () => {
    setSelectedImage(null);
  };

  return (
    <>
      <SetupForm>
        <SetupFormContainer>
          <SetupFormHeading>Setup Your Store</SetupFormHeading>
          <SetupContent>Fill basic details of your store</SetupContent>
          <ImageContainer>
            {selectedImage ? (
              <img
                src={selectedImage}
                alt="Selected Logo"
                style={{ width: "100%", height: "100%", borderRadius: "2px" }}
              />
            ) : (
              <>
                <HiddenFileInput
                  id="fileInput"
                  type="file"
                  onChange={handleImageChange}
                  accept="image/*"
                />
                <ImageInput
                  onClick={() => document.getElementById("fileInput").click()}
                >
                  <PlusIcon />
                  <LogoText>Logo</LogoText>
                </ImageInput>
              </>
            )}
          </ImageContainer>
          <RecommendedText>
            400 X 100px recommended | Maximum file size upto 10MB
          </RecommendedText>
          {fileSizeError && (
            <WarningText>Maximum file size is 10MB.</WarningText>
          )}
          <ButtonContainer>
            <DeleteButton onClick={handleDeleteClick}>
              Remove Image
            </DeleteButton>
          </ButtonContainer>
          <ShopNameContainer>
            <ShopNameDescription>*Shop/Business Name</ShopNameDescription>
            <ShopNameInput
              placeholder="Enter your shop name"
              value={shopName}
              onChange={handleShopNameChange}
            />
            {shopNameValidationError && (
              <WarningText>Please enter shop name</WarningText>
            )}
            {shopNameCharCount && (
              <WarningText>
                Please enter a maximum of 25 characters for the shop name.
              </WarningText>
            )}
          </ShopNameContainer>
          <ShopNameContainer>
            <ShopNameDescription>*Shop Owner Name</ShopNameDescription>
            <ShopNameInput
              placeholder="Enter the shop owner's name"
              value={shopOwnerName}
              onChange={handleShopOwnerNameChange}
            />
            {shopOwnerNameValidationError && (
              <WarningText>Please enter the shop owner's name</WarningText>
            )}
            {shopOwnerNameCharCount && (
              <WarningText>
                Please enter a maximum of 25 characters for the shop owner's
                name.
              </WarningText>
            )}

            {shopOwnerNameSpecialCharacter && (
              <WarningText>Special characters are not allowed. </WarningText>
            )}
          </ShopNameContainer>
          <ShopNameContainer>
            <ShopNameDescription>
              *Brief description about business/shop
            </ShopNameDescription>
            <DescriptionTextarea
              placeholder="Enter a brief description about your business/shop"
              value={description}
              onChange={handleShopDescriptionChange}
            />
            {descriptionValidationError && (
              <WarningText>Please enter the shop description.</WarningText>
            )}
            {shopDescriptionCharCount && (
              <WarningText>
                Please enter a maximum of 100 characters for the description.
              </WarningText>
            )}
          </ShopNameContainer>
          <NextButton onClick={handleNextClick}>
            {loading ? "Please wait..." : "Next"}
          </NextButton>{" "}
        </SetupFormContainer>
      </SetupForm>
    </>
  );
};

export default SetupStore;
