import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Upload,
  ColorPicker,
  message,
} from "antd";
import { EditOutlined, UploadOutlined } from "@ant-design/icons";
import defaultImage from "../../Assets/Images/default_image.png";
import { getUserEmails } from "../../Components/UserEmails/userEmails";
import { fetchSalon, editSalonBranding } from "../../Api/ApiServerRequests/SalonApiServerRequests";
import { Theme } from "../../Themes/Theme";

const BrandingSettings = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [tempImage, setTempImage] = useState(null);
  const [form] = Form.useForm();
  const [brandingData, setBrandingData] = useState(null);
  const owner_email = getUserEmails().salonOwnerEmail;

  useEffect(() => {
    const fetchBrandingDetails = async () => {
      try {
        const response = await fetchSalon(owner_email, "email");
        setBrandingData(response.data.data);
      } catch (error) {
        console.error("Error fetching branding details:", error);
      }
    };

    fetchBrandingDetails();
  }, [owner_email]);

  const data = brandingData
    ? [
        {
          key: "1",
          setting: "Logo",
          value: (
            <img
              src={brandingData.file_path || defaultImage}
              alt="Logo"
              style={{ width: "50px" }}
            />
          ),
        },
        // {
        //   key: "2",
        //   setting: "Salon Name",
        //   value: brandingData.name,
        // },
        {
          key: "3",
          setting: "Salon Description",
          value: brandingData.description,
        },
        {
          key: "4",
          setting: "Favicon",
          value: (
            <img
              src={brandingData.favicon || defaultImage}
              alt="Favicon"
              style={{ width: "20px" }}
            />
          ),
        },
        {
          key: "5",
          setting: "Color",
          value: brandingData.color || "rgba(109, 49, 237, 1)",
        },
      ]
    : [];

  const columns = [
    {
      title: "Setting",
      dataIndex: "setting",
      key: "setting",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      render: (_, record) => {
        if (record.key === "5") {
          return (
            <div
              style={{
                width: "30px",
                height: "30px",
                backgroundColor: record.value,
                border: "1px solid #ddd",
              }}
            ></div>
          );
        } else {
          return record.value;
        }
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button icon={<EditOutlined />} onClick={() => openEditModal(record)} />
      ),
    },
  ];

  const openEditModal = (record) => {
    setCurrentRow(record);
    setTempImage(null);
    if (record.setting === "Logo") {
      form.setFieldsValue({ value: brandingData.file_path || defaultImage });
    } else if (record.setting === "Favicon") {
      form.setFieldsValue({ value: brandingData.favicon || defaultImage });
    } else {
      form.setFieldsValue({ value: record.value });
    }
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();

      if (!values.value) {
        message.error("Please enter a value");
        return;
      }

      const queryParams = new URLSearchParams({ owner_email });
      const data = {};

      // if (currentRow.setting === "Salon Name") {
      //   queryParams.append("salon_name", values.value);
      // }  
      if (currentRow.setting === "Salon Description") {
        queryParams.append("salon_description", values.value);
      } else if (currentRow.setting === "Color") {
        const colorValue = convertColorToString(values.value);
        queryParams.append("color", colorValue);
      } else if (currentRow.setting === "Logo") {
        data["logo"] = values.value;
      } else if (currentRow.setting === "Favicon") {
        data["favicon"] = values.value;
      }

      if (currentRow.setting === "Logo" || currentRow.setting === "Favicon") {
        const response = await editBranding(queryParams, data, true);
        if (response.data.status === 201) {
          if (currentRow.setting === "Logo") {
            setBrandingData({
              ...brandingData,
              file_path: URL.createObjectURL(values.value),
            });
          } else {
            setBrandingData({
              ...brandingData,
              favicon: URL.createObjectURL(values.value),
            });
          }
          message.success(response.data.data);
        } else {
          message.error(response.data.data);
        }
      } else {
        const response = await editBranding(queryParams, values.value, false);
        if (response.data.status === 201) {
          const updatedBrandingData = { ...brandingData };
          // if (currentRow.setting === "Salon Name") {
          //   updatedBrandingData.name = values.value;
          // } 
           if (currentRow.setting === "Salon Description") {
            updatedBrandingData.description = values.value;
          } else if (currentRow.setting === "Color") {
            updatedBrandingData.color = convertColorToString(values.value);
          }
          setBrandingData(updatedBrandingData);
          message.success(response.data.data);
        } else {
          message.error(response.data.data);
        }
      }

      setIsModalVisible(false);
    } catch (error) {
      console.error("Validation failed:", error);
      // Handle validation errors here if needed
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const convertColorToString = (color) => {
    if (color && color.metaColor && color.metaColor.originalInput) {
      const { originalInput, format } = color.metaColor;
      if (format === "hsv") {
        const { h, s, v, a } = originalInput;
        let r, g, b;
        const c = v * s;
        const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
        const m = v - c;
        if (h < 60) {
          r = c;
          g = x;
          b = 0;
        } else if (h < 120) {
          r = x;
          g = c;
          b = 0;
        } else if (h < 180) {
          r = 0;
          g = c;
          b = x;
        } else if (h < 240) {
          r = 0;
          g = x;
          b = c;
        } else if (h < 300) {
          r = x;
          g = 0;
          b = c;
        } else {
          r = c;
          g = 0;
          b = x;
        }
        const rgbaString = `rgba(${Math.round((r + m) * 255)}, ${Math.round(
          (g + m) * 255
        )}, ${Math.round((b + m) * 255)}, ${a})`;
        color.metaColor.originalInput = rgbaString;
        return rgbaString;
      }
    }
    return color;
  };

  const editBranding = async (queryParams, data, isImage) => {
    try {
      console.log(isImage, data, "checking");
      if (isImage) {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          formData.append(key, data[key]);
        });
        return await editSalonBranding(queryParams, formData);
      } else {
        return await editSalonBranding(queryParams);
      }
    } catch (error) {
      console.error("Error editing branding details:", error);
      throw error;
    }
  };

  return (
    <div>
      <Table columns={columns} dataSource={data} pagination={false} />
      <Modal
        title={`Edit ${currentRow ? currentRow.setting : ""}`}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ style: { backgroundColor: Theme.primaryColor } }}
        >
        <Form form={form} layout="vertical">
          <Form.Item
            name="value"
            rules={[{ required: true, message: "Please enter a value" }]}
          >
            {currentRow &&
            (currentRow.setting === "Logo" ||
              currentRow.setting === "Favicon") ? (
              <>
                <img
                  src={
                    tempImage
                      ? URL.createObjectURL(tempImage)
                      : (currentRow.setting === "Logo"
                          ? brandingData.file_path
                          : brandingData.favicon) || defaultImage
                  }
                  alt={currentRow.setting}
                  style={{
                    marginBottom: "10px",
                    display: "block",
                    maxWidth: "200px",
                  }}
                />
                <Upload
                  name="image"
                  listType="picture"
                  showUploadList={false}
                  beforeUpload={() => false}
                  onChange={(info) => {
                    const { file } = info;
                    if (file.status !== "uploading") {
                      setTempImage(file);
                      form.setFieldsValue({ value: file });
                    }
                  }}
                >
                  <Button icon={<UploadOutlined />}>Upload Image</Button>
                </Upload>
              </>
            ) : currentRow && currentRow.setting === "Color" ? (
              <>
                <ColorPicker
                  onChange={(color) => form.setFieldsValue({ value: color })}
                />
                <span style={{ fontSize: "12px", marginLeft: "10px" }}>
                  For extra color information, visit&nbsp;
                  <a
                    href="https://www.jootoor.com/list-of-colors-with-names-hex-rgb-cmyk-codes/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    this website
                  </a>
                </span>
              </>
            ) : (
              <Input />
            )}
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default BrandingSettings;
