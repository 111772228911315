import styled from "styled-components";
import { Theme } from "../../Themes/Theme";
import { Fonts } from "../../Fonts/Fonts";

export const Heading = styled.p`
  font-family: ${Fonts.primaryFont};
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  color: ${Theme.primaryDark};;
  display: flex;
  justify-content: space-between;
`;

export const NameRow = styled.div`
  display: flex;
  margin-top: 10px;
  gap: 40px;
  margin-bottom: 30px;
  justify-content: space-between;
`;

export const Name = styled.div`
  cursor: pointer;
  color: ${(props) => (props.active ? "#6D31ED" : "#000")};
  position: relative;

  &::after {
    content: "";
    display: ${(props) => (props.active ? "block" : "none")};
    position: absolute;
    bottom: -12px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #6d31ed;
  }
`;

export const FilterContainer = styled.div`
  margin-bottom: 10px;
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
