import styled from "styled-components";
import { Fonts } from "../../../Fonts/Fonts";
import { Theme } from "../../../Themes/Theme";

export const SetupForm = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
`;

export const SetupFormContainer = styled.div`
  display: flex;
  width: 40%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
`;

export const SetupFormHeading = styled.div`
  width: 100%;
  font-family: ${Fonts.primaryFont};
  font-size: 30px;
  font-weight: 400;
  line-height: 46px;
  color: ${Theme.primaryBlack};
`;

export const SetupContent = styled.div`
  width: 100%;
  font-family: ${Fonts.primaryFont};
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  color: ${Theme.primaryBlack};
`;

export const ImageContainer = styled.div`
  width: 191px;
  height: 73px;
  background: rgba(243, 244, 246, 1);
  border-radius: 2px;
  border-width: 1px;
  border-color: ${Theme.primaryBlack};
  border-style: dashed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  img {
    border-radius: 2px;
  }
`;

export const LogoText = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: ${Theme.primaryBlack};
`;

export const ShopNameContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ShopNameDescription = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  color: ${Theme.primaryBlack};
  margin-bottom: 8px;
  width: 100%;
`;

export const ShopNameInput = styled.input`
  width: 99%;
  font-family: ${Fonts.primaryFont};
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: ${Theme.primaryBlack};
  border: 1px solid ${Theme.primaryBlack};
  border-radius: 4px;
  outline: none;

  ::placeholder {
    color: ${Theme.primaryBlack};
  }
`;

export const DescriptionTextarea = styled.textarea`
  width: 99%;
  font-family: ${Fonts.primaryFont};
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: ${Theme.primaryBlack};
  border: 1px solid ${Theme.primaryBlack};
  border-radius: 4px;
  resize: vertical;
  min-height: 70px;
  outline: none;

  ::placeholder {
    color: ${Theme.primaryBlack};
  }
`;

export const NextButton = styled.button`
  width: 100%;
  background-color: #6d31ed;
  color: #fff;
  padding: 10px;
  font-family: ${Fonts.primaryFont};
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  margin-top: 20px;
`;

export const HiddenFileInput = styled.input`
  display: none;
`;

export const ImageInput = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
`;

export const WarningText = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 5px;
`;

export const RecommendedText = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 11px;
  font-weight: 400;
  line-height: 28px;
  color: ${Theme.primaryBlack};
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  /* margin-top: 40px; */
  gap: 20px;
`;

export const DeleteButton = styled.button`
  padding: 3px 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${Fonts.primaryFont};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: ${Theme.primaryRed};
  background: ${Theme.lightRed};
  opacity: 1;
  border: none;
  border-radius: 3px;
  cursor: pointer;
`;
