import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const ApiServer = axios.create({
  baseURL: API_URL,
  timeout: 100000,
  headers: {
    'X-API-KEY': process.env.REACT_APP_API_KEY, 
    'Content-Type': 'application/json',
  },
});

export default ApiServer;
