import React from "react";

const CalendarIcon = ({ isActive }) => {
  const strokeColor = isActive ? "#6D31ED" : "#171A1F";
  const fillColor = isActive ? "#6D31ED" : "#171A1F";

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M6.85712 2.57143V4.28571" stroke={strokeColor} strokeWidth="2.05714" strokeLinecap="square" strokeMiterlimit="10" />
      <path d="M17.1429 2.57143V4.28571" stroke={strokeColor} strokeWidth="2.05714" strokeLinecap="square" strokeMiterlimit="10" />
      <path d="M7.71429 12H6V12.8571H7.71429V12Z" stroke={strokeColor} strokeWidth="2.05714" strokeLinecap="square" strokeMiterlimit="10" />
      <path d="M12.8572 12H11.1429V12.8571H12.8572V12Z" stroke={strokeColor} strokeWidth="2.05714" strokeLinecap="square" strokeMiterlimit="10" />
      <path d="M7.71429 16.2857H6V17.1429H7.71429V16.2857Z" stroke={strokeColor} strokeWidth="2.05714" strokeLinecap="square" strokeMiterlimit="10" />
      <path d="M12.8572 16.2857H11.1429V17.1429H12.8572V16.2857Z" stroke={strokeColor} strokeWidth="2.05714" strokeLinecap="square" strokeMiterlimit="10" />
      <path d="M18 12H16.2857V12.8571H18V12Z" stroke={strokeColor} strokeWidth="2.05714" strokeLinecap="square" strokeMiterlimit="10" />
      <path d="M7.71429 12H6V12.8571H7.71429V12Z" fill={fillColor} />
      <path d="M12.8572 12H11.1429V12.8571H12.8572V12Z" fill={fillColor} />
      <path d="M7.71429 16.2857H6V17.1429H7.71429V16.2857Z" fill={fillColor} />
      <path d="M12.8572 16.2857H11.1429V17.1429H12.8572V16.2857Z" fill={fillColor} />
      <path d="M18 12H16.2857V12.8571H18V12Z" fill={fillColor} />
      <path d="M2.57141 8.57143L21.4286 8.57143" stroke={strokeColor} strokeWidth="2.05714" strokeLinecap="square" strokeMiterlimit="10" />
      <path d="M19.7143 5.14286L4.2857 5.14286C3.33892 5.14286 2.57141 5.91037 2.57141 6.85715L2.57141 18.8571C2.57141 19.8039 3.33892 20.5714 4.2857 20.5714L19.7143 20.5714C20.661 20.5714 21.4286 19.8039 21.4286 18.8571L21.4286 6.85715C21.4286 5.91037 20.661 5.14286 19.7143 5.14286Z" stroke={strokeColor} strokeWidth="2.05714" strokeLinecap="square" strokeMiterlimit="10" />
    </svg>
  );
};

export default CalendarIcon;