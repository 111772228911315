import styled from "styled-components";
import { Theme } from "../../Themes/Theme";
import { Fonts } from "../../Fonts/Fonts";

export const HeadingRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SearchContainer = styled.div`
  display: flex;
  margin-top: 40px;
  margin-bottom: 30px;
  margin-right: 30px;
  gap: 20px;
`;

export const AddButton = styled.button`
  color: rgba(255, 255, 255, 1);
  background: ${Theme.primaryColor};
  opacity: 1;
  border: none;
  border-radius: 4px;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  cursor: pointer;
`;

export const Heading = styled.div`
  font-family: ${Fonts.primaryFont}; /* Heading */
  font-size: 24px;
  font-weight: 400;
  line-height: 46px;
  color: ${Theme.primaryDark};;
`;

export const ServiceProductContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-evenly;
gap: 20px; */
  /* align-items: center; */
  width: 97%;
  margin-left: 30px;
`;

export const InformationContainer = styled.div`
  flex-grow: 1;
  max-width: 235px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 1);
  border-radius: 4px;
  box-shadow: 0px 2px 5px rgba(23, 26, 31, 0.09),
    0px 0px 2px rgba(23, 26, 31, 0.12);
  padding: 5px;
  margin: 10px;
`;

export const ImageContainer = styled.div`
  position: relative; /* Add position relative for absolute positioning of delete icon */
  width: 225px;
  height: 165px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
`;

// New styled component for delete icon
export const DeleteIconButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  cursor: pointer;
`;

export const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

export const StylistName = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: ${Theme.primaryBlack};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
`;

export const ServiceInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export const StylistPosition = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: ${Theme.primaryColor};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
`;

export const ServiceTime = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: rgba(144, 149, 161, 1);
`;

export const RequirementLabel = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 11px;
  font-weight: 400;
  line-height: 18px;
  color: ${Theme.primaryBlack};
`;

export const Requirements = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: rgba(144, 149, 161, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
`;

export const EditButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${Fonts.primaryFont};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: rgba(255, 255, 255, 1);
  background: ${Theme.primaryColor};
  opacity: 1;
  border: none;
  border-radius: 3px;
  width: 30%;
  cursor: pointer;
`;

export const UnavailabilityButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${Fonts.primaryFont};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: ${Theme.primaryColor};
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  border-radius: 3px;
  border-width: 1px;
  border-color: ${Theme.primaryColor};
  width: 70%;
  cursor: pointer;
`;

export const UploadImageContainer = styled.div`
  width: 191px;
  height: 73px;
  background: rgba(243, 244, 246, 1);
  border-radius: 2px;
  border-width: 1px;
  border-color: ${Theme.primaryBlack};
  border-style: dashed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  img {
    border-radius: 2px;
  }
`;

export const HiddenFileInput = styled.input`
  display: none;
`;

export const ImageInput = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
`;

export const LogoText = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: ${Theme.primaryBlack};
`;

export const WarningText = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 5px;
`;

export const StylistNameContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StylistNameDescription = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  color: ${Theme.primaryBlack};
  margin-bottom: 8px;
  width: 100%;
`;

export const StylistNameInput = styled.input`
  width: 99%;
  font-family: ${Fonts.primaryFont};
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: ${Theme.primaryBlack};
  border: 1px solid ${Theme.primaryBlack};
  border-radius: 4px;
  outline: none;

  ::placeholder {
    color: ${Theme.primaryBlack};
  }
`;

export const DescriptionTextarea = styled.textarea`
  width: 99%;
  font-family: ${Fonts.primaryFont};
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: ${Theme.primaryBlack};
  border: 1px solid ${Theme.primaryBlack};
  border-radius: 4px;
  resize: vertical;
  min-height: 70px;
  outline: none;

  ::placeholder {
    color: ${Theme.primaryBlack};
  }
`;
