import React, { useState, useEffect } from "react";
import SearchBox from "../../Components/SearchBox/SearchBox";
import PlusIcon from "../../Assets/Svgs/PlusIcon";
import {
  AddButton,
  ButtonContainer,
  DescriptionTextarea,
  EditButton,
  Heading,
  HeadingRow,
  HiddenFileInput,
  ImageContainer,
  ImageInput,
  InfoContainer,
  InformationContainer,
  LogoText,
  Requirements,
  SearchContainer,
  ServiceInfo,
  ServiceProductContainer,
  StylistName,
  StylistPosition,
  UnavailabilityButton,
  UploadImageContainer,
  WarningText,
  DeleteIconButton,
} from "./SalonStylists.styled";
import defaultImage from "../../Assets/Images/default_image.png";
import { Modal, Button, message } from "antd";
import DeleteIcon from "../../Assets/Svgs/DeleteIcon";
import {
  RemoveImage,
  ShopNameContainer,
  ShopNameDescription,
  ShopNameInput,
} from "../SalonServices/SalonServices.styled";
import {
  createStylist,
  deleteStylists,
  getStylists,
  updateStylist,
} from "../../Api/ApiServerRequests/StylistsApiServerRequests";
import UnavailabilityModal from "../UnAvailabilityModal/UnAvailabilityModal";
import {
  Link,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { getUserEmails } from "../../Components/UserEmails/userEmails";
import Urls from "../../Urls/Urls";

const SalonStylists = () => {
  const [isModalVisible, setModalVisibility] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [description, setDescription] = useState("");
  const [descriptionValidationError, setDescriptionValidationError] =
    useState(false);
  const [roleValidationError, setRoleValidationError] = useState(false);
  const [role, setRole] = useState("");
  const [shopName, setShopName] = useState("");
  const [shopNameValidationError, setShopNameValidationError] = useState(false);
  const [shopNameCountError, setShopNameCountError] = useState(false);
  const [roleNameCountError, setRoleNameCountError] = useState(false);
  const [shopDescriptionCountError, setShopDescriptionCountError] =
    useState(false);
  const [stylistData, setStylistData] = useState([]);
  const [isDeleteModalVisible, setDeleteModalVisibility] = useState(false);
  const [stylistToDelete, setStylistToDelete] = useState(null);
  const [isEditModalVisible, setEditModalVisibility] = useState(false);
  const [editStylistData, setEditStylistData] = useState(null);
  const [isUnavailabilityModalVisible, setUnavailabilityModalVisibility] =
    useState(false);
  const [selectedStylist, setSelectedStylist] = useState(null);
  const [stylistEmail, setStylistEmail] = useState("");
  const [emailValidationError, setEmailValidationError] = useState(false);
  const [stylistPassword, setStylistPassword] = useState("");
  const [passwordValidationError, setPasswordValidationError] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const [currentWeekStartDate, setCurrentWeekStartDate] = useState(new Date());

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleUnavailabilityRequest = (stylist) => {
    //console.log("stylist", stylist);
    setSelectedStylist(stylist);
    setUnavailabilityModalVisibility(true);
  };

  const handleCloseUnavailabilityModal = () => {
    setSelectedStylist(null);
    setUnavailabilityModalVisibility(false);
    setCurrentWeekStartDate(new Date());
  };

  const handleOkUnavailabilityModal = () => {
    setSelectedStylist(null);
    setUnavailabilityModalVisibility(false);
    setCurrentWeekStartDate(new Date());
  };

  useEffect(() => {
    async function fetchStylists() {
      try {
        const ownerEmail = getUserEmails().salonOwnerEmail;
        const response = await getStylists("owner email", ownerEmail);
        //console.log(response.status);
        //console.log("Stylists Response:", response);
        if (response.status === 200) {
          setStylistData(response.data.data);
        } else {
          message.error("Failed to fetch stylists");
        }
      } catch (error) {
        console.error("Error fetching stylists:", error);
        message.error("Failed to fetch stylists");
      }
    }

    fetchStylists();
  }, []);

  const handleLeaveHandlingButtonClick = () => {
    navigate(Urls.stylistLeaveManagement);
  };

  const handleAddButtonClick = () => {
    setModalVisibility(true);
  };

  const closeModal = () => {
    setShopName("");
    setDescription("");
    setRole("");
    setSelectedImage(null);
    setFileSizeError(false);
    setShopNameValidationError(false);
    setShopNameCountError(false);
    setShopDescriptionCountError(false);
    setDescriptionValidationError(false);
    setRoleValidationError(false);
    setModalVisibility(false);
    setEditModalVisibility(false);
    setRoleNameCountError(false);
    setEmailValidationError(false);
    setPasswordValidationError(false);
    setStylistEmail("");
    setStylistPassword("");
  };

  const handleOkButtonClick = async () => {
    if (!shopName) {
      setShopNameValidationError(true);
      return;
    }

    if (!role) {
      setRoleValidationError(true);
      return;
    }

    if (!validateEmail(stylistEmail)) {
      setEmailValidationError(true);
      return;
    } else {
      setEmailValidationError(false);
    }

    if (!stylistPassword) {
      setPasswordValidationError(true);
      return;
    } else {
      setPasswordValidationError(false);
    }

    if (!description) {
      setDescriptionValidationError(true);
      return;
    }

    if (stylistPassword < 12) {
      message.error({
        title: "Weak Password",
        content: "Password must be at least 12 characters long.",
      });
      return;
    }

    if (!/[a-z]/.test(stylistPassword)) {
      message.error({
        title: "Weak Password",
        content: "Password must contain at least one lowercase letter.",
      });
      return;
    }

    if (!/[A-Z]/.test(stylistPassword)) {
      message.error({
        title: "Weak Password",
        content: "Password must contain at least one uppercase letter.",
      });
      return;
    }

    if (!/\d/.test(stylistPassword)) {
      message.error({
        title: "Weak Password",
        content: "Password must contain at least one number.",
      });
      return;
    }

    if (!/[@$!%*?&]/.test(stylistPassword)) {
      message.error({
        title: "Weak Password",
        content: "Password must contain at least one special character.",
      });
      return;
    }

    try {
      const ownerEmail = getUserEmails().salonOwnerEmail;

      const queryParams = new URLSearchParams({
        owner_email: ownerEmail,
        name: shopName,
        role: role,
        description: description,
        stylist_email: stylistEmail,
        stylist_password: stylistPassword,
      });

      let imageBlob;
      if (selectedImage) {
        const response = await fetch(selectedImage);
        imageBlob = await response.blob();
      } else {
        const defaultImageResponse = await fetch(defaultImage);
        imageBlob = await defaultImageResponse.blob();
      }

      const formData = new FormData();
      formData.append("file", imageBlob);

      const response = await createStylist(queryParams, formData);
      //console.log("responsee", response);
      if (response.data.status === 201) {
        const newStylist = {
          name: shopName,
          role: role,
          description: description,
          stylist_email: stylistEmail,
          file_path: selectedImage ? selectedImage : defaultImage
      };

      setStylistData(prevStylistData => [...prevStylistData, newStylist]);
        message.success("Stylist has been successfully added!");
      } else if (response.data.status === 409) {
        message.error(response.data.data);
      } else {
        message.error("Failed to add stylist");
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      message.error("Failed to add stylist");
    } finally {
      closeModal();
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const maxSizeInBytes = 10 * 1024 * 1024;
      if (file.size > maxSizeInBytes) {
        setFileSizeError(true);
        return;
      } else {
        setFileSizeError(false);
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDeleteClick = (stylist) => {
    setStylistToDelete(stylist);
    setDeleteModalVisibility(true);
  };

  const handleDeleteConfirmation = async () => {
    try {
      const ownerEmail = getUserEmails().salonOwnerEmail;
      const queryParams = new URLSearchParams({
        owner_email: ownerEmail,
        stylist_email: stylistToDelete.stylist_email,
      });

      const response = await deleteStylists(queryParams);
      if (response.status === 200) {
        setStylistData(prevStylistData => prevStylistData.filter(stylist => stylist.stylist_email !== stylistToDelete.stylist_email));
        message.success("Stylist deleted successfully");
      } else {
        console.error("Failed to delete stylist");
      }
    } catch (error) {
      message.error("Failed to delete stylist");
      console.error("Error deleting stylist:", error);
    } finally {
      setDeleteModalVisibility(false);
    }
  };

  const handleEditClick = (stylist) => {
    setEditStylistData(stylist);
    setShopName(stylist.name);
    setRole(stylist.role);
    setDescription(stylist.description);
    setEditModalVisibility(true);
    setSelectedImage(stylist.file_path);
  };

  const handleEditOkButtonClick = async () => {
    if (!shopName) {
      setShopNameValidationError(true);
      return;
    }

    if (!description) {
      setDescriptionValidationError(true);
      return;
    }

    if (!role) {
      setRoleValidationError(true);
      return;
    }

    try {
      const ownerEmail = getUserEmails().salonOwnerEmail;

      const queryParams = new URLSearchParams({
        owner_email: ownerEmail,
        stylist_email: editStylistData.stylist_email,
        modified_name: shopName,
        role: role,
        description: description,
      });

      let imageBlob;
      if (selectedImage===editStylistData.file_path) {
      }else{
        const response = await fetch(selectedImage);
        imageBlob = await response.blob();
      }

      const formData = new FormData();
      formData.append("file", imageBlob);
      let response;
      if (selectedImage===editStylistData.file_path) {
        response = await updateStylist(queryParams);
      }else{
        response = await updateStylist(queryParams, formData);
      }

      
      //console.log("responsee", response.data.status);
      if (response.data.status === 201) {
        const updatedStylist = {
          ...editStylistData,
          name: shopName,
          role: role,
          description: description,
          file_path: selectedImage ? selectedImage : defaultImage
      };

      setStylistData(prevStylistData => prevStylistData.map(stylist => stylist.stylist_email === editStylistData.stylist_email ? updatedStylist : stylist));

      message.success("Stylist has been successfully updated!");
      } else {
        message.error("Failed to update stylist");
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      message.error("Failed to update stylist");
    } finally {
      closeModal();
    }
  };

  const handleRemoveImage = () => {
    setSelectedImage(null);
  };

  const filteredStylistData = stylistData.filter((stylist) => {
    return stylist.name.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <>
      <HeadingRow>
        <Heading>Stylists</Heading>
        <SearchContainer>
          <SearchBox onChange={handleSearch} />
          <AddButton onClick={handleAddButtonClick}>
            <PlusIcon color="#fff" /> Add Stylist
          </AddButton>
          <AddButton onClick={handleLeaveHandlingButtonClick}>
            Leave handling
          </AddButton>
        </SearchContainer>
      </HeadingRow>

      <ServiceProductContainer>
        {filteredStylistData.map((stylist) => (
          <InformationContainer key={stylist.name}>
            <ImageContainer>
              <DeleteIconButton onClick={() => handleDeleteClick(stylist)}>
                <DeleteIcon />
              </DeleteIconButton>
              <img src={stylist.file_path} alt={`Stylist Image`} />
            </ImageContainer>
            <InfoContainer>
              <StylistName>Name: {stylist.name}</StylistName>
              <StylistPosition>
                Stylist email: {stylist.stylist_email}
              </StylistPosition>
              <ServiceInfo>
                <StylistPosition>Role: {stylist.role}</StylistPosition>
              </ServiceInfo>
              <Requirements>Description: {stylist.description}</Requirements>
              <ButtonContainer>
                <UnavailabilityButton
                  onClick={() => handleUnavailabilityRequest(stylist)}
                >
                  Set availability
                </UnavailabilityButton>
                <EditButton onClick={() => handleEditClick(stylist)}>
                  Edit
                </EditButton>
              </ButtonContainer>
            </InfoContainer>
          </InformationContainer>
        ))}
      </ServiceProductContainer>

      <Modal
        title={`Add new stylist`}
        visible={isModalVisible}
        onCancel={closeModal}
        footer={[
          <Button key="Cancel" onClick={closeModal}>
            Cancel
          </Button>,
          <Button
            key="Add"
            type="primary"
            style={{ backgroundColor: "rgba(109, 49, 237, 1)" }}
            onClick={handleOkButtonClick}
          >
            OK
          </Button>,
        ]}
      >
        <UploadImageContainer>
          {selectedImage ? (
            <img
              src={selectedImage}
              alt="Selected Logo"
              style={{ width: "100%", height: "100%", borderRadius: "2px" }}
            />
          ) : (
            <>
              <HiddenFileInput
                id="fileInput"
                type="file"
                onChange={handleImageChange}
                accept="image/*"
              />
              <ImageInput
                onClick={() => document.getElementById("fileInput").click()}
              >
                <PlusIcon />
                <LogoText>Stylist Image</LogoText>
              </ImageInput>
            </>
          )}
        </UploadImageContainer>
        {fileSizeError && <WarningText>Maximum file size is 10MB.</WarningText>}
        <ButtonContainer>
          <RemoveImage onClick={handleRemoveImage}>Remove Image</RemoveImage>
        </ButtonContainer>
        <ShopNameContainer>
          <ShopNameDescription>Stylist Name</ShopNameDescription>
          <ShopNameInput
            value={shopName}
            onChange={(e) => {
              const input = e.target.value;
              const maxLength = 30;

              if (input.length > maxLength) {
                setShopName(input.slice(0, maxLength));
                setShopNameCountError(true);
                setShopNameValidationError(false);
              } else {
                setShopName(input);
                setShopNameValidationError(false);
              }
            }}
          />
          {shopNameValidationError && (
            <WarningText>Please enter the name.</WarningText>
          )}
          {shopNameCountError && (
            <WarningText>Maximum characters allowed is 30.</WarningText>
          )}
        </ShopNameContainer>

        <ShopNameContainer>
          <ShopNameDescription>Role Name</ShopNameDescription>
          <ShopNameInput
            value={role}
            onChange={(e) => {
              const input = e.target.value;
              const maxLength = 30;

              if (input.length > maxLength) {
                setRole(input.slice(0, maxLength));
                setRoleNameCountError(true);
                setRoleValidationError(false);
              } else {
                setRole(input);
                setRoleValidationError(false);
              }
            }}
          />
          {roleValidationError && (
            <WarningText>Please enter a role of the stylist.</WarningText>
          )}
          {roleNameCountError && (
            <WarningText>Maximum characters allowed is 30.</WarningText>
          )}
        </ShopNameContainer>

        <ShopNameContainer>
          <ShopNameDescription>Stylist Email</ShopNameDescription>
          <ShopNameInput
            value={stylistEmail}
            onChange={(e) => setStylistEmail(e.target.value)}
          />
          {emailValidationError && (
            <WarningText>Please enter the vaild email</WarningText>
          )}
        </ShopNameContainer>

        <ShopNameContainer>
          <ShopNameDescription>Stylist Password</ShopNameDescription>
          <ShopNameInput
            value={stylistPassword}
            onChange={(e) => setStylistPassword(e.target.value)}
          />
          {passwordValidationError && (
            <WarningText>Please enter the vaild email</WarningText>
          )}
        </ShopNameContainer>

        <ShopNameContainer>
          <ShopNameDescription>Description</ShopNameDescription>
          <DescriptionTextarea
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
              setDescriptionValidationError(false);

              const input = e.target.value;
              const maxLength = 100;

              if (input.length > maxLength) {
                setDescription(input.slice(0, maxLength));
                setShopDescriptionCountError(true);
                setDescriptionValidationError(false);
              } else {
                setDescription(input);
                setDescriptionValidationError(false);
              }
            }}
          />
          {descriptionValidationError && (
            <WarningText>Please enter the description.</WarningText>
          )}
          {shopDescriptionCountError && (
            <WarningText>Maximum characters allowed is 100.</WarningText>
          )}
        </ShopNameContainer>
      </Modal>

      <Modal
        title={`Edit stylist`}
        visible={isEditModalVisible}
        onCancel={closeModal}
        footer={[
          <Button key="Cancel" onClick={closeModal}>
            Cancel
          </Button>,
          <Button
            key="Add"
            type="primary"
            style={{ backgroundColor: "rgba(109, 49, 237, 1)" }}
            onClick={handleEditOkButtonClick}
          >
            Ok
          </Button>,
        ]}
      >
        <UploadImageContainer>
          {selectedImage ? (
            <img
              src={selectedImage}
              alt="Selected Logo"
              style={{ width: "100%", height: "100%", borderRadius: "2px" }}
            />
          ) : (
            <>
              <HiddenFileInput
                id="fileInput"
                type="file"
                onChange={handleImageChange}
                accept="image/*"
              />
              <ImageInput
                onClick={() => document.getElementById("fileInput").click()}
              >
                <PlusIcon />
                <LogoText>Stylist Image</LogoText>
              </ImageInput>
            </>
          )}
        </UploadImageContainer>
        {fileSizeError && <WarningText>Maximum file size is 10MB.</WarningText>}
        <ButtonContainer>
          <RemoveImage onClick={handleRemoveImage}>Remove Image</RemoveImage>
        </ButtonContainer>
        <ShopNameContainer>
          <ShopNameDescription>Stylist Name</ShopNameDescription>
          <ShopNameInput
            value={shopName}
            onChange={(e) => {
              const input = e.target.value;
              const maxLength = 30;

              if (input.length > maxLength) {
                setShopName(input.slice(0, maxLength));
                setShopNameCountError(true);
                setShopNameValidationError(false);
              } else {
                setShopName(input);
                setShopNameValidationError(false);
              }
            }}
          />
          {shopNameValidationError && (
            <WarningText>Please enter the name.</WarningText>
          )}
          {shopNameCountError && (
            <WarningText>Maximum characters allowed is 30.</WarningText>
          )}
        </ShopNameContainer>

        <ShopNameContainer>
          <ShopNameDescription>Role Name</ShopNameDescription>
          <ShopNameInput
            value={role}
            onChange={(e) => {
              const input = e.target.value;
              const maxLength = 30;

              if (input.length > maxLength) {
                setRole(input.slice(0, maxLength));
                setRoleNameCountError(true);
                setRoleValidationError(false);
              } else {
                setRole(input);
                setRoleValidationError(false);
              }
            }}
          />
          {roleValidationError && (
            <WarningText>Please enter a valid numeric value.</WarningText>
          )}
          {roleNameCountError && (
            <WarningText>Maximum characters allowed is 30.</WarningText>
          )}
        </ShopNameContainer>

        <ShopNameContainer>
          <ShopNameDescription>Description</ShopNameDescription>
          <DescriptionTextarea
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
              setDescriptionValidationError(false);

              const input = e.target.value;
              const maxLength = 100;

              if (input.length > maxLength) {
                setDescription(input.slice(0, maxLength));
                setShopDescriptionCountError(true);
                setDescriptionValidationError(false);
              } else {
                setDescription(input);
                setDescriptionValidationError(false);
              }
            }}
          />
          {descriptionValidationError && (
            <WarningText>Please enter the description.</WarningText>
          )}
          {shopDescriptionCountError && (
            <WarningText>Maximum characters allowed is 100.</WarningText>
          )}
        </ShopNameContainer>
      </Modal>

      <Modal
        title={`Delete Stylist`}
        visible={isDeleteModalVisible}
        onCancel={() => setDeleteModalVisibility(false)}
        onOk={handleDeleteConfirmation}
        footer={[
          <Button key="Cancel" onClick={() => setDeleteModalVisibility(false)}>
            Cancel
          </Button>,
          <Button
            key="Delete"
            type="primary"
            onClick={handleDeleteConfirmation}
            style={{ backgroundColor: "rgba(109, 49, 237, 1)" }}
          >
            OK
          </Button>,
        ]}
      >
        {stylistToDelete && (
          <p>Are you sure you want to delete {stylistToDelete.name}?</p>
        )}
      </Modal>

      <UnavailabilityModal
        visible={isUnavailabilityModalVisible}
        onClose={handleCloseUnavailabilityModal}
        onOk={handleOkUnavailabilityModal}
        stylistName={selectedStylist?.name}
        stylistEmail={selectedStylist?.stylist_email}
        currentWeekStartDate={currentWeekStartDate}
        setCurrentWeekStartDate={setCurrentWeekStartDate}
      />
    </>
  );
};

export default SalonStylists;
