import React from 'react';
import {
  AddButton,
  ServiceAndProduct,
  ServiceAndProductImage,
  ServiceAndProductInfoContainer,
  ServiceAndProductTitle,
  TimeContainer,
  TitleBar,
  CountContainer,
  CountText,
} from "./ProductCard.styled";
import { useNavigate } from 'react-router-dom';
import defaultImage from "../../Assets/Images/default_image.png";


const ProductCard = ({ data, url, cardType = "demo", buttonType = "text", onClickHandler = (item) => null, onUpdateCount }) => {

  const [cardList, setCardList] = React.useState([])

  React.useEffect(() => {
    if (data) {
      setCardList(data)
    }
  }, [data])

  return (
    <>
      {cardList.map((item) => (
        <ProductItem data={data} item={item} url={url} cardType={cardType} buttonType={buttonType} onClickHandler={onClickHandler} onUpdateCount={onUpdateCount}/>
      ))
      }
    </>
  )
}

const ProductItem = ({ data, item, url, cardType, buttonType, onClickHandler,onUpdateCount }) => {

  const [count, setCount] = React.useState(1)
  const salonName = localStorage.getItem("salonName");
  const navigate = useNavigate();

  const increment = () => {
    setCount(prevCount => {
      onUpdateCount(item, prevCount + 1);
      return prevCount + 1;
    });
  };

  const decrement = () => {
    if (item.itemType === 'product') {
      setCount(prevCount => {
        if (prevCount === 1) {
          if (data.length > 1) {
            onClickHandler(item);
          } else {
            navigate(`/${salonName}/home`);
          }
        } else {
          onUpdateCount(item, prevCount - 1);
        }
        return Math.max(1, prevCount - 1);
      });
    } else {
      if (data.length === 0) {
        navigate(`/${salonName}/home`);
      } else {
        onClickHandler(item);
      }
    }
  };

  return (
    <ServiceAndProduct key={item.name}>
      <ServiceAndProductImage
        src={item.file_path || defaultImage}
        alt={item.name}
      />

      <ServiceAndProductInfoContainer>
        <TitleBar>
          <ServiceAndProductTitle>{item.name}</ServiceAndProductTitle>
        </TitleBar>
        
        {item.required_time && <TimeContainer>{item.required_time}</TimeContainer>}

        <TitleBar>
          <ServiceAndProductTitle>SAR {item.price}</ServiceAndProductTitle>
          {buttonType === 'text' && <AddButton onClick={() => onClickHandler(item) || navigate(url, { state: { cartItem: item } })} >Add</AddButton>}
          {buttonType === 'calculator' &&
            <CountContainer>
              {item.itemType === 'service' ? <AddButton  onClick={decrement} >Remove</AddButton> :
              <>
              <AddButton width={"40px"} color={'black'} background={'#F3F4F6'} onClick={decrement} >-</AddButton>
              <CountText>{count}</CountText>
              <AddButton width={"40px"} onClick={increment} >+</AddButton>
              </>
              }
            </CountContainer>
          }
        </TitleBar>
      </ServiceAndProductInfoContainer>
    </ServiceAndProduct>
  )
}



export default ProductCard;