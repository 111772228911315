import React, { useState, useEffect, useRef } from "react";
import { Form, Input, Button, message, Modal } from "antd";
import { Header, HeaderText } from "./EditSalon.styled";
import { useLocation } from "react-router-dom";
import { updateSalon, fetchSalon } from "../../Api/ApiServerRequests/SalonApiServerRequests";
import { useNavigate } from "react-router-dom";
import defaultImage from "../../Assets/Images/default_image.png";
import { Theme } from "../../Themes/Theme";

const EditSalonComponent = () => {
  const [initialEmail, setInitialEmail] = useState("");
  const [initialSalonName, setInitialSalonName] = useState("");
  const [initialOwner, setInitialOwner] = useState("");
  const [initialDescription, setInitialDescription] = useState("");
  const navigate = useNavigate();
  const [currentImage, setCurrentImage] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);

  const location = useLocation();
  const [fileUrl, setFileUrl] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const emailParam = queryParams.get("email");
    const salonNameParam = queryParams.get("salonName");
    const ownerParam = queryParams.get("owner");
    const descriptionParam = queryParams.get("description");

    const fetchSalonData = async () => {
      try {
        const response = await fetchSalon(emailParam,"email");
        const filePath = response.data.data.file_path;
        setFileUrl(filePath);
        setCurrentImage(filePath);
      } catch (error) {
        console.error("Error fetching salon data:", error);
      }
    };

    fetchSalonData();

    setInitialEmail(emailParam || "");
    setInitialSalonName(salonNameParam || "");
    setInitialOwner(ownerParam || "");
    setInitialDescription(descriptionParam || "");
  }, [location.search]);

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      salonName: initialSalonName,
      salonOwner: initialOwner,
      ownerEmail: initialEmail,
      salonDescription: initialDescription,
    });
  }, [form, initialSalonName, initialOwner, initialEmail, initialDescription]);

  const onFinish = async (values) => {
    try {
      const queryParams = new URLSearchParams({
        owner_email: values.ownerEmail,
        name: values.salonName,
        owner_name: values.salonOwner,
        description: values.salonDescription,
      });

      let formData;

      if(currentImage===fileUrl){

      }else{
        const response = await fetch(currentImage);
      const imageBlob = await response.blob();

      formData = new FormData();
      formData.append("file", imageBlob);
      }

      let uploadResponse;

      if(currentImage===fileUrl){
        uploadResponse = await updateSalon(queryParams);
      }else{
        uploadResponse = await updateSalon(queryParams, formData);
      }

      if (uploadResponse.data.status === 201) {
        message.success("Your salon has been successfully updated!");
        form.resetFields();
        setFileUrl(null);
        setCurrentImage(null);
        navigate("/dashboard/admin");
      } else {
        console.error("Failed to submit data to the server");
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  const handleChangeImage = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setUploadedImage(file);
    const reader = new FileReader();
    reader.onload = (event) => {
      setCurrentImage(event.target.result);
    };
    reader.readAsDataURL(file);
  };

  const handleDeleteImage = () => {
    setCurrentImage(defaultImage);
  };

  return (
    <div>
      <Header>
        <HeaderText>Edit an existing salon detail</HeaderText>
      </Header>
      <Form
        form={form}
        onFinish={onFinish}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        layout="vertical"
      >
        <Form.Item
          label="Salon Name"
          name="salonName"
          rules={[{ required: true, message: "Please enter Salon Name" }]}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          label="Salon Owner"
          name="salonOwner"
          rules={[{ required: true, message: "Please enter Salon owner Name" }]}
        >
          <Input
            onChange={(e) => {
              const sanitizedValue = e.target.value.replace(
                /[^a-zA-Z0-9\s]/g,
                ""
              );
              form.setFieldsValue({ salonOwner: sanitizedValue });
            }}
          />
        </Form.Item>

        <Form.Item
          label="Salon Description"
          name="salonDescription"
          rules={[
            { required: true, message: "Please enter Salon Description" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Salon Owner's Email"
          name="ownerEmail"
          rules={[
            {
              required: true,
              type: "email",
              message: "Please enter a valid email address",
            },
          ]}
        >
          <Input disabled />
        </Form.Item>

        <input
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          ref={fileInputRef}
          onChange={handleFileChange}
        />
        {currentImage && (
          <>
            <Form.Item label="Salon Image">
              <img
                src={currentImage}
                alt="Salon Owner"
                style={{ width: "100px", height: "100px", objectFit: "cover" }}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" onClick={handleChangeImage}>
                Change
              </Button>
              <Button
                type="danger"
                onClick={handleDeleteImage}
                style={{ marginLeft: 8 }}
              >
                Delete
              </Button>
            </Form.Item>
          </>
        )}

        <Form.Item style={{ textAlign: "right" }}>
          <Button style={{ marginRight: 8 }}>Cancel</Button>
          <Button type="primary" htmlType="submit" style={{ backgroundColor: Theme.primaryColor, borderColor: Theme.primaryColor}}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EditSalonComponent;
