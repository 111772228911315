import React from "react";

const CrossIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
<path d="M7.46071 1.10763L6.69951 0.346428C6.64842 0.295209 6.58773 0.254572 6.52091 0.226844C6.45409 0.199117 6.38246 0.184845 6.31011 0.184845C6.23777 0.184845 6.16614 0.199117 6.09932 0.226844C6.0325 0.254572 5.9718 0.295209 5.92071 0.346428L4.00011 2.26703L2.07951 0.346428C2.02842 0.295209 1.96773 0.254572 1.90091 0.226844C1.83409 0.199117 1.76246 0.184845 1.69011 0.184845C1.61777 0.184845 1.54614 0.199117 1.47932 0.226844C1.4125 0.254572 1.3518 0.295209 1.30071 0.346428L0.539513 1.10763C0.488293 1.15872 0.447656 1.21941 0.419929 1.28623C0.392202 1.35305 0.37793 1.42468 0.37793 1.49703C0.37793 1.56937 0.392202 1.64101 0.419929 1.70783C0.447656 1.77465 0.488293 1.83534 0.539513 1.88643L2.46011 3.80703L0.539513 5.72763C0.488293 5.77872 0.447656 5.83941 0.419929 5.90623C0.392202 5.97305 0.37793 6.04468 0.37793 6.11703C0.37793 6.18937 0.392202 6.26101 0.419929 6.32783C0.447656 6.39465 0.488293 6.45534 0.539513 6.50643L1.30071 7.26763C1.3518 7.31885 1.4125 7.35948 1.47932 7.38721C1.54614 7.41494 1.61777 7.42921 1.69011 7.42921C1.76246 7.42921 1.83409 7.41494 1.90091 7.38721C1.96773 7.35948 2.02842 7.31885 2.07951 7.26763L4.00011 5.34703L5.92071 7.26763C5.9718 7.31885 6.0325 7.35948 6.09932 7.38721C6.16614 7.41494 6.23777 7.42921 6.31011 7.42921C6.38246 7.42921 6.45409 7.41494 6.52091 7.38721C6.58773 7.35948 6.64842 7.31885 6.69951 7.26763L7.46071 6.50643C7.51193 6.45534 7.55257 6.39465 7.5803 6.32783C7.60802 6.26101 7.6223 6.18937 7.6223 6.11703C7.6223 6.04468 7.60802 5.97305 7.5803 5.90623C7.55257 5.83941 7.51193 5.77872 7.46071 5.72763L5.54011 3.80703L7.46071 1.88643C7.51193 1.83534 7.55257 1.77465 7.5803 1.70783C7.60802 1.64101 7.6223 1.56937 7.6223 1.49703C7.6223 1.42468 7.60802 1.35305 7.5803 1.28623C7.55257 1.21941 7.51193 1.15872 7.46071 1.10763Z" fill="#424856"/>
</svg>
  );
};

export default CrossIcon;
