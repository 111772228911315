import React, { useEffect, useState } from "react";
import { Table, Modal, message, Typography } from "antd";
import Button from "../../Components/Button/Button";
import { getUserEmails } from "../../Components/UserEmails/userEmails";
import { fetchSalon, subscribeSalon } from "../../Api/ApiServerRequests/SalonApiServerRequests";

const BillingSettings = () => {
  const { Paragraph } = Typography;
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const owner_email = getUserEmails().salonOwnerEmail;
  const[subscribeButton, setSubscribeButton]=useState(false)

  useEffect(() => {
    const fetchBrandingDetails = async () => {
      try {
        const response = await fetchSalon(owner_email, "email");
        console.log(response)
        if(response.data.data.premium_membership_status ==="Inactive" && response.data.data.trial_membership_status ==="Active" ){
          setSubscribeButton(true);
        }
      } catch (error) {
        console.error("Error fetching branding details:", error);
      }
    };

    fetchBrandingDetails();
  }, [owner_email]);

  const handleViewSubscription = (record) => {
    setSelectedSubscription(record);
    setShowSubscriptionModal(true);
  };

  const handleCancelSubscription = (record) => {
    setSelectedSubscription(record);
    setShowCancelModal(true);
  };

  const confirmCancelSubscription = () => {
    message.success("Subscription cancelled successfully!");
    setShowCancelModal(false);
    setSelectedSubscription(null);
  };

  const dataSource = [
    {
      key: "1",
      subscription: "Premium Subscription",
      salonName: "Sample Salon",
      ownerName: "John Doe",
      startDate: "2024-04-01",
      endDate: "2024-04-30",
    },
  ];

  const columns = [
    {
      title: "View Subscription",
      dataIndex: "subscription",
      key: "subscription",
      render: (_, record) => (
        <Button
          text="View"
          type="primary"
          onClick={() => handleViewSubscription(record)}
        />
      ),
    },
    {
      title: "Description",
      key: "description",
      render: () => (
        <Paragraph ellipsis={{ rows: 3, expandable: true }}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt, voluptatibus magnam? Ipsa fugiat non accusamus provident sed quaerat quis eaque.
        </Paragraph>
      ),
    },
    {
      title: "Expiry date",
      key: "expiryDate",
      render: () => (
        <Paragraph ellipsis={{ rows: 3, expandable: true }}>
          2024-06-1
        </Paragraph>
      ),
    },
    // {
    //   title: "Renew",
    //   key: "renew",
    //   render: (_, record) => (
    //     <Button
    //       text="Renew"
    //       type="primary"
    //     />
    //   ),
    // },
    {
      title: "Cancel Subscription",
      key: "cancel",
      render: (_, record) => (
        <Button
          text="Cancel"
          type="default"
          onClick={() => handleCancelSubscription(record)}
        />
      ),
    },
  ];

  const handleSubscribe = async()=>{
    console.log("subscribed");
    
    const queryParams = new URLSearchParams({
      owner_email: owner_email,
    });

    try {
      const response = await subscribeSalon(queryParams);

      if (response.data.status === 201) {
        message.success(response.data.data);
        setSubscribeButton(false)
      } else {
        message.error(response.data.data);
      }
    } catch (error) {
      console.error("Error Subscribing:", error);
      message.error("An error occurred while subscribing the salon.");
    }
  }

  return (
    <>
    <div style={{marginTop:"20px", marginBottom:"20px"}}>
      {subscribeButton && <Button
          text="Subscribe Now"
          type="primary"
          width="100px"
          onClick={handleSubscribe}
        />
     }
      </div>
      <Table dataSource={dataSource} columns={columns} />

      <Modal
        title="Subscription Details"
        visible={showSubscriptionModal}
        onCancel={() => setShowSubscriptionModal(false)}
        footer={[
          <Button
            text="Close"
            type="primary"
            onClick={() => setShowSubscriptionModal(false)}
          />,
        ]}
      >
        {selectedSubscription && (
          <div>
            <p>
              <strong>Salon Name:</strong> {selectedSubscription.salonName}
            </p>
            <p>
              <strong>Owner Name:</strong> {selectedSubscription.ownerName}
            </p>
            <p>
              <strong>Start Date:</strong> {selectedSubscription.startDate}
            </p>
            <p>
              <strong>End Date:</strong> {selectedSubscription.endDate}
            </p>
            <p>
              <strong>Subscription Plan:</strong>{" "}
              {selectedSubscription.subscription}
            </p>
          </div>
        )}
      </Modal>

      <Modal
        title="Cancel Subscription"
        visible={showCancelModal}
        onCancel={() => setShowCancelModal(false)}
        onOk={confirmCancelSubscription}
        okText="OK"
        cancelText="Cancel"
      >
        <p>Are you sure you want to cancel the subscription plan?</p>
      </Modal>
    </>
  );
};

export default BillingSettings;
