import React, { useEffect, useState } from "react";
import {
  DetailsContainer,
  Header,
  MainContainer,
  ProductAmount,
  ProductInformation,
  ProductName,
  SeparateLine,
  SubmitContainer,
} from "./BookingDetails.styled";
import { useNavigate } from "react-router-dom";
import Button from "../../../Components/Button/Button";
import {
  confirmBookingPayment,
  getBookingsInformation,
} from "../../../Api/ApiServerRequests/BookingsApiServerRequests";
import { message } from "antd";
import { Theme } from "../../../Themes/Theme";
import { formatTimeRange } from "../../../Helper/Helper";

const BookingDetails = () => {
  const navigate = useNavigate();
  const salonName = localStorage.getItem("salonName");
  const [bookingInfo, setBookingInfo] = useState(null);
  const bookedId = sessionStorage.getItem("booked_id");

  useEffect(() => {
    const pathArray = window.location.pathname.split("/");
    const potentialSalonName = pathArray[1];
    if (potentialSalonName!==salonName) {
      localStorage.setItem("salonName", potentialSalonName);
        window.location.href = `/${potentialSalonName}`;
    }
  }, []);

  useEffect(() => {
    async function fetchBookingInformation() {
      try {
        const booking_id = bookedId;
        if (booking_id) {
          const response = await getBookingsInformation(
            booking_id,
            "booking id"
          );
          // console.log("booking response", response.data);
          if (
            response.data.status === 200 &&
            Array.isArray(response.data.data)
          ) {
            setBookingInfo(response.data.data[0]);
          } else {
            console.error(
              "Unexpected data format or status code:",
              response.data
            );
          }
        }
      } catch (error) {
        console.error("Error fetching booking information:", error);
      }
    }

    fetchBookingInformation();
  }, [bookedId]);

  const confirmBookingsPayment = async () => {
    try {
      const response = await confirmBookingPayment(bookedId);
      // console.log("Confirmation response", response.data);
      if (response.data.status === 201) {
        message.success(response.data.data);
        navigate(`/${salonName}/booking-confirmation`);
      } else {
        message.error("Something went wrong");
      }
    } catch (error) {
      console.error("Error confirming booking payment:", error);
    }
  };

  return (
    <>
      <MainContainer>
        <div>
          <Header>
            {/* <div
              onClick={() => {
                navigate(-1);
              }}
              style={{ display: "flex" }}
            >
              <LeftChevronIcon />
            </div> */}
            <div>{"Booking details"}</div>
          </Header>
        </div>
        <DetailsContainer>
          {bookingInfo &&
            bookingInfo.services.map((service) => (
              <ProductInformation key={service.name}>
                <ProductName>{service.name}</ProductName>
                <ProductAmount>SAR {service.total_price}</ProductAmount>
              </ProductInformation>
            ))}
          {bookingInfo &&
            bookingInfo.products.map((product) => (
              <ProductInformation key={product.name}>
                <ProductName>{product.name}</ProductName>
                <ProductAmount>SAR {product.total_price}</ProductAmount>
              </ProductInformation>
            ))}

          {bookingInfo && bookingInfo.loyalty_points_used && (
            <ProductInformation>
              <ProductName>Loyalty Points</ProductName>
              <ProductAmount>
                - SAR {bookingInfo.loyalty_points_used}
              </ProductAmount>
            </ProductInformation>
          )}

          {bookingInfo && bookingInfo.offer_discount && (
            <ProductInformation>
              <ProductName>Offers</ProductName>
              <ProductAmount>
                - SAR {bookingInfo.offer_discount}
              </ProductAmount>
            </ProductInformation>
          )}

          {bookingInfo && (
            <>
              <SeparateLine />
              <ProductInformation>
                <ProductName>Total</ProductName>
                <ProductName>{bookingInfo.total_price}</ProductName>
              </ProductInformation>
              <SeparateLine />
              <ProductInformation>
                <ProductName color={Theme.salonColor}>
                  Advance payment
                </ProductName>
                <ProductName color={Theme.salonColor}>
                  {bookingInfo.advance_payment}
                </ProductName>
              </ProductInformation>
            </>
          )}
        </DetailsContainer>

        {bookingInfo && (
          <DetailsContainer>
            <ProductName>Location</ProductName>
            <ProductAmount>{bookingInfo.address.name}</ProductAmount>
            <ProductAmount>{bookingInfo.address.address}</ProductAmount>
            <ProductAmount>{bookingInfo.address.phone}</ProductAmount>
            <img
              src={bookingInfo.address.file_path}
              alt="House"
              style={{ width: "77px", height: "38px" }}
            />
          </DetailsContainer>
        )}

        {bookingInfo && (
          <DetailsContainer>
            <ProductInformation>
              <ProductName>Date</ProductName>
              <ProductAmount>{bookingInfo.date}</ProductAmount>
            </ProductInformation>
            <ProductInformation>
              <ProductName>Time slot</ProductName>
              <ProductAmount>
                {formatTimeRange(
                  bookingInfo.selected_time[0],
                  bookingInfo.selected_time[1]
                )}
              </ProductAmount>
            </ProductInformation>
            <ProductInformation>
              <ProductName>Stylist</ProductName>
              <ProductAmount>{bookingInfo.stylist_name}</ProductAmount>
            </ProductInformation>
          </DetailsContainer>
        )}

        <SubmitContainer>
          <Button
            text={"Pay & Confirm Booking"}
            type={"primary"}
            width={"100%"}
            height={"44px"}
            onClick={confirmBookingsPayment}
            backgroundColor={Theme.salonColor}
          />
        </SubmitContainer>
      </MainContainer>
    </>
  );
};
export default BookingDetails;
