import React, { useEffect, useState } from "react";
import { FormContainer, HelpSetupContainer } from "./HelpSetup.styled";
import { Table, Input, Button as AntdButton, message, Modal } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {
  fetchSalon,
  addHelpSetup,
  removeHelpSetup,
} from "../../Api/ApiServerRequests/SalonApiServerRequests";
import { getUserEmails } from "../../Components/UserEmails/userEmails";
import Button from "../../Components/Button/Button";

const HelpSetup = () => {
  const ownerEmail = getUserEmails().salonOwnerEmail;
  const [formattedData, setFormattedData] = useState([]);
  const [helpDataLoaded, setHelpDataLoaded] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    fetchData();
  }, [ownerEmail]);

  const fetchData = async () => {
    try {
      const response = await fetchSalon(ownerEmail, "email");
      if (response.data.status === 200) {
        const helpData = response.data.data.help;
        const defaultData = [
          { key: "Phone", value: helpData["phone"] || "" },
          { key: "Email", value: helpData["email"] || "" },
          { key: "Facebook", value: helpData["facebook"] || "" },
          { key: "Instagram", value: helpData["instagram"] || "" },
          { key: "Twitter", value: helpData["twitter"] || "" },
        ];
        setFormattedData(defaultData);
        setHelpDataLoaded(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDelete = async (key) => {
    try {
      const response = await removeHelpSetup(ownerEmail, key.toLowerCase());

      if (response.data.status === 201) {
        message.success(response.data.data);
        fetchData();
      } else {
        message.error("Failed to delete help setup.");
      }
    } catch (error) {
      message.error("An error occurred while deleting help setup.");
    }
  };

  const handleAdd = async (key, value) => {
    try {
      let isValid = true;
      let errorMessage = "";

      if(!value){
        message.error("Please enter the value");
        return;
      }
  
      if (key === "Phone") {
        const phoneRegex = /^[0-9+]+$/;
        if (!phoneRegex.test(value)) {
          isValid = false;
          errorMessage = "Invalid phone number format. Please use only numbers and '+' symbol.";
        }
      } else if (key === "Email") {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(value)) {
          isValid = false;
          errorMessage = "Invalid email format. Please provide a valid email address.";
        }
      }
  
      if (!isValid) {
        message.error(errorMessage);
        return;
      }
  
      const queryParams = new URLSearchParams({
        owner_email: ownerEmail,
        [key.toLowerCase()]: value,
      });
  
      const response = await addHelpSetup(queryParams);
  
      if (response.data.status === 201) {
        message.success("Help information updated");
      } else {
        message.error("Update failed");
      }
    } catch (error) {
      console.error("Error adding help setup:", error);
    }
  };
  

  const columns = [
    {
      title: "Name",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      render: (text, record) => (
        <Input
          value={record.value}
          onChange={(e) => {
            const newData = [...formattedData];
            const index = newData.findIndex((item) => record.key === item.key);
            if (index > -1) {
              newData[index].value = e.target.value;
              setFormattedData(newData);
            }
          }}
        />
      ),
    },
    {
      title: "Modify",
      key: "modify",
      render: (text, record) => (
        <span>
          <AntdButton
            icon={<PlusOutlined />}
            style={{ marginRight: 8 }}
            onClick={() => handleAdd(record.key, record.value)}
          />
          <AntdButton
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record.key)}
          />
        </span>
      ),
    },
  ];

  const handleContactAdminClick = () => {
    setIsModalVisible(true);
  };
  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <HelpSetupContainer>
        <FormContainer>
          <Table dataSource={formattedData} columns={columns} />
        </FormContainer>
        <Button
          text={"Contact admin"}
          type={"primary"}
          onClick={handleContactAdminClick}
        />
      </HelpSetupContainer>

      <Modal
        title="Contact Admin"
        visible={isModalVisible}
        onOk={handleCloseModal}
        onCancel={handleCloseModal}
        footer={[
          <Button text="ok" type="primary" onClick={handleCloseModal}/>,
        ]}
      >
        <p>Admin Email: admin@sopss-sa.com</p>
      </Modal>
    </>
  );
};

export default HelpSetup;
