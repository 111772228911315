import styled from "styled-components";
import { Theme } from "../../Themes/Theme";
import { Fonts } from "../../Fonts/Fonts";

export const SidebarContainer = styled.div`
  position: fixed;
  top: 0;
  right: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
  width: 100%;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: right 0.3s ease-in-out;
  z-index: 999;
`;

export const SidebarTitle = styled.h2`
  margin: 20px 0;
  font-family: ${Fonts.primaryFont};
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  color: rgba(144, 149, 161, 1);
`;

export const CancelIcon = styled.span`
  cursor: pointer;
`;

export const TitleBar = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-left: 20px;
  flex-direction: row;
`;

export const ProfileContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: rgba(222, 225, 230, 0);
  border-radius: 8px;
  border-width: 1px;
  border-color: ${Theme.primaryBlack};
  border-style: solid;
  gap: 30px;
  padding: 10px;
`;

export const MobileNumber = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: ${Theme.primaryBlack};
`;

export const ProfileDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
`;

export const ProfileImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const RoyalPoints = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 11px;
  font-weight: 400;
  line-height: 18px;
  color: ${Theme.primaryBlack};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

export const Points = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: ${Theme.primaryBlack};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Sections = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  margin-top: 50px;
  justify-content: center;
`;

export const Section = styled.div`
  background: rgba(222, 225, 230, 0);
  border-radius: 8px;
  border-width: 1px;
  border-color: ${Theme.primaryBlack};
  border-style: solid;
  width: 80%;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
`;

export const SectionName = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  color: ${Theme.primaryDark};;
`;
