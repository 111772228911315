import styled from "styled-components";
import { Fonts } from "../../../Fonts/Fonts";

export const CustomHomePageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const WelcomeContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

export const HelloContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`;

export const HelloText = styled.div`
  font-family: ${Fonts.primaryFont}; 
  font-size: 24px; 
  font-weight: 400; 
  line-height: 36px; 
  color: rgba(23,26,31,1);
`;

export const WelcomeBackText = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 20px; 
  font-weight: 400; 
  line-height: 30px; 
  color: rgba(144,149,161,1);
  margin-top: 10px;
`;

export const YellowContainer = styled.div`
   background: rgba(255,211,23,0.11); 
  border-radius: 4px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
`;

export const PointsText = styled.div`
   font-family: ${Fonts.primaryFont}; 
  font-size: 11px; 
  font-weight: 400; 
  line-height: 18px; 
  color: rgba(23,26,31,1);
`;

export const PointsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Points = styled.div`
   font-family: ${Fonts.primaryFont}; 
  font-size: 20px; 
  font-weight: 400; 
  line-height: 30px; 
  color: rgba(23,26,31,1);
`;

export const AppointmentsContainer = styled.div`
   width:90%;
   display: flex;
   flex-direction: column;
   margin-top: 30px;
`;

export const Appointments = styled.div`
  flex-shrink: 0; 
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(109, 49, 237, 0.11);
  border-radius: 4px;
  padding: 10px;
  margin-right: 10px;
`;


export const AppointmentName = styled.div`
   font-family: ${Fonts.primaryFont}; 
  font-size: 14px; 
  font-weight: 600; 
  line-height: 22px; 
  color: rgba(86,93,109,1);
  width: 100%;
`;

export const AppointmentDetail = styled.div`
  width:100%;
  gap:10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const AppointmentScrolling = styled.div`
  width:100%;
  gap:10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
`;

export const Detail = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 11px; 
  font-weight: 400; 
  line-height: 18px; 
  color: rgba(23,26,31,1);
  width:90%;
`;