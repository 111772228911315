import ApiServer from "../ApiServer";

export const logInUser = (queryParams) => {
  return ApiServer({
    method: "POST",
    url: `/users/login?${queryParams.toString()}`,
  });
};

export const signup = (queryParams) => {
  return ApiServer({
    method: "POST",
    url: `/users/signup?${queryParams.toString()}`,
  });
};

export const revokeAccess = (queryParams) => {
  return ApiServer({
    method: "PUT",
    url: `/users/access/disable?${queryParams.toString()}`,
  });
};

export const grantAccess = (queryParams) => {
  return ApiServer({
    method: "PUT",
    url: `/users/access/enable?${queryParams.toString()}`,
  });
};