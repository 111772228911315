import React, { useState, useRef, useEffect } from "react";
import { Modal, Button, DatePicker, message } from "antd";
import moment from "moment";
import {
  AddButton,
  DescriptionTextarea,
  Heading,
  HeadingRow,
  SearchContainer,
  WarningText,
} from "../SalonStylists/SalonStylists.styled";
import SearchBox from "../../Components/SearchBox/SearchBox";
import PlusIcon from "../../Assets/Svgs/PlusIcon";
import {
  ButtonContainer,
  DeleteButton,
  EditButton,
  SeparateLine,
} from "./SalonOffers.styled";
import {
  Settings,
  SettingsContainer,
  SettingsDescription,
  SettingsNameText,
  OfferNameContainer,
  OfferNameDescription,
  OfferNameInput,
} from "../SalonOffers/SalonOffers.styled";
import {
  createOffer,
  deleteOffer,
  getOffer,
  updateOffer,
} from "../../Api/ApiServerRequests/OffersApiServerRequests";
import { getUserEmails } from "../../Components/UserEmails/userEmails";

const SalonOffers = () => {
  const [isModalVisible, setModalVisibility] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentOffer, setCurrentOffer] = useState(null);
  const [offerName, setOfferName] = useState("");
  const [offerNameValidationError, setOfferNameValidationError] =
    useState(false);
  const [offerNameCountError, setOfferNameCountError] = useState(false);
  const [shopDescriptionCountError, setShopDescriptionCountError] =
    useState(false);
  const [description, setDescription] = useState("");
  const [descriptionValidationError, setDescriptionValidationError] =
    useState(false);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [expiryDate, setExpiryDate] = useState(null);
  const datePickerRef = useRef(null);
  const [offers, setOffers] = useState([]);
  const [filteredOffers, setFilteredOffers] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
    useState(false);
  const [offerToDelete, setOfferToDelete] = useState(null);

  const ownerEmail = getUserEmails().salonOwnerEmail;

  useEffect(() => {
    const fetchOffer = async () => {
      try {
        const queryParams = new URLSearchParams({
          owner_email: ownerEmail,
        });
        const response = await getOffer(queryParams);
        if (response.data.status === 200) {
          setOffers(response.data.data);
          setFilteredOffers(response.data.data);
        } else {
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        closeModal();
      }
    };
    fetchOffer();
  }, [ownerEmail]);

  const handleAddButtonClick = () => {
    setIsEditMode(false);
    setCurrentOffer(null);
    setModalVisibility(true);
  };

  const handleEditButtonClick = (offer) => {
    setIsEditMode(true);
    setCurrentOffer(offer);
    setOfferName(offer.offer_code);
    setDescription(offer.offer_description);
    setDiscountPercentage(offer.discount_percentage);
    setExpiryDate(moment(offer.offer_expiry));
    setModalVisibility(true);
  };

  const closeModal = () => {
    setOfferName("");
    setDescription("");
    setOfferNameValidationError(false);
    setOfferNameCountError(false);
    setShopDescriptionCountError(false);
    setDescriptionValidationError(false);
    setModalVisibility(false);
    setDiscountPercentage(0);
    setExpiryDate(null);
  };

  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = (d.getMonth() + 1).toString().padStart(2, "0");
    const day = d.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleSubmit = async () => {
    if (offerName === "") {
      message.error("Please enter offer name");
      return;
    }

    if (description === "") {
      message.error("Please enter description");
      return;
    }

    if (!expiryDate) {
      message.error("Please select expiry date");
      return;
    }

    if (discountPercentage <= 0 || discountPercentage > 100) {
      message.error("Discount percentage must be between 0 and 100.");
      return;
    }

    const formattedExpiryDate = formatDate(expiryDate);

    if (isEditMode && currentOffer) {
      // Edit offer
      try {
        const queryParams = new URLSearchParams({
          owner_email: ownerEmail,
          offer_id: currentOffer._id,
          offer_code: offerName,
          offer_description: description,
          offer_expiry: formattedExpiryDate,
          discount_percentage: discountPercentage,
        });
        const response = await updateOffer(queryParams);
        if (response.data.status === 201) {
          setOffers((prevOffers) =>
            prevOffers.map((offer) =>
              offer._id === currentOffer._id
                ? {
                    ...offer,
                    offer_code: offerName,
                    offer_description: description,
                    offer_expiry: formattedExpiryDate,
                    discount_percentage: discountPercentage,
                  }
                : offer
            )
          );
          setFilteredOffers((prevOffers) =>
            prevOffers.map((offer) =>
              offer._id === currentOffer._id
                ? {
                    ...offer,
                    offer_code: offerName,
                    offer_description: description,
                    offer_expiry: formattedExpiryDate,
                    discount_percentage: discountPercentage,
                  }
                : offer
            )
          );
          message.success(response.data.data);
        } else {
          message.error(response.data.data);
        }
      } catch (error) {
        console.error("Error updating data:", error);
      } finally {
        closeModal();
      }
    } else {
      // Create new offer
      try {
        const queryParams = new URLSearchParams({
          owner_email: ownerEmail,
          offer_code: offerName,
          offer_description: description,
          offer_expiry: formattedExpiryDate,
          discount_percentage: discountPercentage,
        });
        const response = await createOffer(queryParams);
        if (response.data.status === 201) {
          const newOffer = {
            offer_code: offerName,
            offer_description: description,
            offer_expiry: formattedExpiryDate,
            discount_percentage: discountPercentage,
          };
          setOffers((prevOffers) => [...prevOffers, newOffer]);
          setFilteredOffers((prevOffers) => [...prevOffers, newOffer]);
          message.success(response.data.data);
        } else {
          message.error(response.data.data);
        }
      } catch (error) {
        console.error("Error submitting data:", error);
      } finally {
        closeModal();
      }
    }
  };

  const handleDeleteConfirmation = (offer) => {
    setOfferToDelete(offer);
    setDeleteConfirmationVisible(true);
  };

  const handleDeleteOffer = async () => {
    try {
      const response = await deleteOffer(ownerEmail, offerToDelete._id);
      if (response.data.status === 200) {
        setOffers((prevOffers) =>
          prevOffers.filter((offer) => offer._id !== offerToDelete._id)
        );
        setFilteredOffers((prevOffers) =>
          prevOffers.filter((offer) => offer._id !== offerToDelete._id)
        );
        message.success(response.data.data);
      } else {
        message.error(response.data.data);
      }
    } catch (error) {
      console.error("Error deleting data:", error);
    } finally {
      setDeleteConfirmationVisible(false);
    }
  };

  const handleSearch = (e) => {
    const input = e.target.value;
    setSearchInput(input);
    const filtered = offers.filter((offer) =>
      offer.offer_code.toLowerCase().includes(input.toLowerCase())
    );
    setFilteredOffers(filtered);
  };

  return (
    <>
      <HeadingRow>
        <Heading>Offers</Heading>
        <SearchContainer>
          <SearchBox onChange={handleSearch} />
          <AddButton onClick={handleAddButtonClick}>
            <PlusIcon color="#fff" /> Create offer
          </AddButton>
        </SearchContainer>
      </HeadingRow>
      <SeparateLine />

      <Settings>
        {filteredOffers &&
          filteredOffers.map((offer) => (
            <SettingsContainer key={offer._id}>
              <SettingsNameText>{offer.offer_code}</SettingsNameText>
              <SettingsDescription>
                {offer.offer_description}
              </SettingsDescription>
              <ButtonContainer>
                <EditButton onClick={() => handleEditButtonClick(offer)}>
                  Edit
                </EditButton>
                <DeleteButton onClick={() => handleDeleteConfirmation(offer)}>
                  Delete
                </DeleteButton>
                <SettingsDescription>
                  Expires on: {offer.offer_expiry}
                </SettingsDescription>
              </ButtonContainer>
            </SettingsContainer>
          ))}
      </Settings>

      <Modal
        title={isEditMode ? `Edit offer` : `Add offer`}
        visible={isModalVisible}
        onCancel={closeModal}
        footer={[
          <Button key="Cancel" onClick={closeModal}>
            Cancel
          </Button>,
          <Button
            key={isEditMode ? "Edit" : "Add"}
            type="primary"
            style={{ backgroundColor: "rgba(109, 49, 237, 1)" }}
            onClick={handleSubmit}
          >
            {isEditMode ? "Edit Offer" : "Add Offer"}
          </Button>,
        ]}
      >
        <OfferNameContainer>
          <OfferNameDescription>Offer code</OfferNameDescription>
          <OfferNameInput
            value={offerName}
            onChange={(e) => {
              const input = e.target.value;
              const maxLength = 30;

              if (input.length > maxLength) {
                setOfferName(input.slice(0, maxLength));
                setOfferNameCountError(true);
                setOfferNameValidationError(false);
              } else {
                setOfferName(input);
                setOfferNameValidationError(false);
              }
            }}
          />
          {offerNameValidationError && (
            <WarningText>Please enter the name.</WarningText>
          )}
          {offerNameCountError && (
            <WarningText>Maximum characters allowed is 30.</WarningText>
          )}
        </OfferNameContainer>

        <OfferNameContainer>
          <OfferNameDescription>Description</OfferNameDescription>
          <DescriptionTextarea
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
              setDescriptionValidationError(false);

              const input = e.target.value;
              const maxLength = 100;

              if (input.length > maxLength) {
                setDescription(input.slice(0, maxLength));
                setShopDescriptionCountError(true);
                setDescriptionValidationError(false);
              } else {
                setDescription(input);
                setDescriptionValidationError(false);
              }
            }}
          />
          {descriptionValidationError && (
            <WarningText>Please enter the description.</WarningText>
          )}
          {shopDescriptionCountError && (
            <WarningText>Maximum characters allowed is 100.</WarningText>
          )}
        </OfferNameContainer>

        <OfferNameContainer>
          <OfferNameDescription>Discount Percentage</OfferNameDescription>
          <OfferNameInput
            value={discountPercentage}
            onChange={(e) => {
              const input = e.target.value;
              const numericValue = input.replace(/[^0-9]/g, "");

              if (numericValue !== input) {
                message.error("Please enter only numeric values.");
              }

              setDiscountPercentage(numericValue);
            }}
          />
        </OfferNameContainer>

        <OfferNameContainer>
          <OfferNameDescription>Expires on</OfferNameDescription>
          <DatePicker
            ref={datePickerRef}
            style={{ width: "100%" }}
            value={expiryDate}
            onChange={(date) => {
              setExpiryDate(date);
            }}
          />
        </OfferNameContainer>
      </Modal>

      <Modal
        title={`Delete Offer`}
        visible={deleteConfirmationVisible}
        onCancel={() => setDeleteConfirmationVisible(false)}
        footer={[
          <Button
            key="Cancel"
            onClick={() => setDeleteConfirmationVisible(false)}
          >
            Cancel
          </Button>,
          <Button
            key="Delete"
            type="primary"
            style={{ backgroundColor: "rgba(109, 49, 237, 1)" }}
            onClick={handleDeleteOffer}
          >
            Delete
          </Button>,
        ]}
      >
        {`Would you like to delete the offer "${offerToDelete?.offer_code}"?`}
      </Modal>
    </>
  );
};

export default SalonOffers;
