import styled from "styled-components";
import { Theme } from "../../Themes/Theme";
import { Fonts } from "../../Fonts/Fonts";

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const LoyalPoints = styled.div`
  width: 80%;
  height: 63px;
  background: ${Theme.primaryYellow};
  border-radius: 4px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;

export const LoyalText = styled.div`
   font-family: ${Fonts.primaryFont};
  font-size: 16px; 
  line-height: 26px; 
  font-weight: 400; 
  color: ${Theme.primaryDark};
`;

export const LoyalPoint = styled.div`
 font-family: ${Fonts.primaryFont};
  font-size: 24px; 
  line-height: 36px; 
  font-weight: 400; 
  color: ${Theme.primaryYellow};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Info = styled.div`
  width: 90%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const InfoHeading = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 14px; 
  line-height: 22px; 
  font-weight: 400; 
  color: ${Theme.primaryDark};
  margin-top: 20px;
`;

export const InfoDetail = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const InfoDescription = styled.div`
   font-family: ${Fonts.primaryFont};
  font-size: 16px; 
  line-height: 26px; 
  font-weight: 400; 
  color: rgba(23,26,31,1);
`;

export const EditButton = styled.button`
   display: flex; 
  align-items: center; 
  justify-content: center; 
  font-family: ${Fonts.primaryFont}; 
  font-size: 14px; 
  line-height: 22px; 
  font-weight: 400; 
  color: ${Theme.primaryColor};
  background: rgba(0,0,0,0);
  opacity: 1; 
  border: none; 
  border-radius: 4px;
`;