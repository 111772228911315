import React, { useEffect, useState } from "react";
import {
  SidebarContainer,
  SidebarTitle,
  CancelIcon,
  TitleBar,
  ProfileContainer,
  MobileNumber,
  ProfileDetails,
  ProfileImage,
  Profile,
  RoyalPoints,
  Points,
  Sections,
  Section,
  SectionName,
} from "./Sidebar.styled";
import LeftChevronIcon from "../../Assets/Svgs/LeftChevronIcon";
import defaultImage from "../../Assets/Images/default_image.png";
import CrownIcon from "../../Assets/Svgs/CrownIcon";
import RightChevronIcon from "../../Assets/Svgs/RightChevronIcon";
import UpcomingActivites from "../UpcomingActivites/UpcomingActivites";
import ReferAndEarn from "../ReferAndEarn/ReferAndEarn";
import CustomerSettings from "../CustomerSettings/CustomerSettings";
import BookingHistory from "../BookingHistory/BookingHistory";
import CustomerAccountSettings from "../CustomerAccountSettings/CustomerAccountSettings";
import CustomerHelp from "../CustomerHelp/CustomerHelp";
import { getCustomerAddress } from "../../Api/ApiServerRequests/CustomersApiServerRequests";

const Sidebar = ({ isOpen, onClose, showHelp }) => {
  const [selectedSection, setSelectedSection] = useState(showHelp);
  const salonName=localStorage.getItem("salonName")
  const customerNumber = localStorage.getItem("customerPhoneNumber");
  const [loyaltyPoints, setLoyaltyPoints] = useState(null);

  useEffect(() => {
    const pathArray = window.location.pathname.split("/");
    const potentialSalonName = pathArray[1];
    if (potentialSalonName!==salonName) {
      localStorage.setItem("salonName", potentialSalonName);
        window.location.href = `/${potentialSalonName}`;
    }
  }, []);

  useEffect(()=>{
    const fetchCustomerInformation = async () => {
      try {
        const queryParams = new URLSearchParams({
          phone: customerNumber,
          salon_url_name: salonName
        });
        const response = await getCustomerAddress(queryParams);
        if (response.data.status===200) {
          setLoyaltyPoints(response.data.data[0].loyalty_points);
          console.log("loyalty", response.data.data[0].loyalty_points)
        } else {
          console.error("Invalid data format: expected array");
        }
      } catch (error) {
        console.error("Error fetching booking information:", error);
      }
    };

    fetchCustomerInformation();
  }, [customerNumber])

  const handleSectionClick = (sectionName) => {
    setSelectedSection(sectionName);
  };

  const handleCancel = () => {
    setSelectedSection("");
  };

  const renderSelectedComponent = () => {
    switch (selectedSection) {
      case "Upcoming Bookings":
        return <UpcomingActivites onCancel={handleCancel} />;
      case "Refer":
        return <ReferAndEarn onCancel={handleCancel} />;
      case "Settings":
        return <CustomerSettings onCancel={handleCancel} />;
      case "Booking History":
        return <BookingHistory onCancel={handleCancel} />;
      // case "Accounts Settings":
      //   return <CustomerAccountSettings onCancel={handleCancel} />;
      case "Help":
        return <CustomerHelp onCancel={handleCancel} />;
      default:
        return (
          <>
            <TitleBar>
              <CancelIcon onClick={onClose}>
                <LeftChevronIcon />
              </CancelIcon>
              <SidebarTitle>My Account</SidebarTitle>
            </TitleBar>

            <Profile>
              <ProfileContainer>
                {/* <ProfileImage src={defaultImage} alt="Profile Image" /> */}
                <ProfileDetails>
                  <MobileNumber>{customerNumber}</MobileNumber>
                  {loyaltyPoints !== null && (
                  <RoyalPoints>
                    Your Loyal Points
                    <Points>
                      <CrownIcon /> {loyaltyPoints}
                    </Points>
                  </RoyalPoints>
                  )}
                </ProfileDetails>
                {/* <RightChevronIcon /> */}
              </ProfileContainer>
            </Profile>

            <Sections>
              <Section onClick={() => handleSectionClick("Upcoming Bookings")}>
                <SectionName>Upcoming/Missed Bookings</SectionName>
                <RightChevronIcon />
              </Section>

              <Section onClick={() => handleSectionClick("Booking History")}>
                <SectionName>Booking History</SectionName>
                <RightChevronIcon />
              </Section>

              <Section onClick={() => handleSectionClick("Help")}>
                <SectionName>Help</SectionName>
                <RightChevronIcon />
              </Section>

              {/* <Section onClick={() => handleSectionClick("Refer")}>
                <SectionName>Refer & Earn</SectionName>
                <RightChevronIcon />
              </Section> */}

              <Section onClick={() => handleSectionClick("Settings")}>
                <SectionName>Settings</SectionName>
                <RightChevronIcon />
              </Section>
            </Sections>
          </>
        );
    }
  };

  return (
    <SidebarContainer isOpen={isOpen}>
      {renderSelectedComponent()}
    </SidebarContainer>
  );
};

export default Sidebar;
