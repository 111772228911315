import React, { useEffect, useState } from "react";
import { CancelIcon, SidebarTitle, TitleBar } from "../Sidebar/Sidebar.styled";
import LeftChevronIcon from "../../Assets/Svgs/LeftChevronIcon";
import {
  Activites,
  ActivitesContainer,
  DateContainer,
  DetailsContainer,
  ImageContainer,
  ProfileImage,
  Services,
  StarContainer,
} from "./BookingHistory.styled";
import defaultImage from "../../Assets/Images/default_image.png";
import { Rate } from "antd";
import BookingHistoryDetails from "../BookingHistoryDetails/BookingHistoryDetails";
import { getUpcomingActivites } from "../../Api/ApiServerRequests/BookingsApiServerRequests";

const BookingHistory = ({ onCancel }) => {
  const [showDetails, setShowDetails] = useState(false);
  const salonName = localStorage.getItem("salonName");
  const customerNumber = localStorage.getItem("customerPhoneNumber");
  const [upcomingActivities, setUpcomingActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedActivityId, setSelectedActivityId] = useState(null);

  useEffect(() => {
    const pathArray = window.location.pathname.split("/");
    const potentialSalonName = pathArray[1];
    if (potentialSalonName!==salonName) {
      localStorage.setItem("salonName", potentialSalonName);
        window.location.href = `/${potentialSalonName}`;
    }
  }, []);

  useEffect(() => {
    const fetchBookingInformation = async () => {
      try {
        const queryParams = new URLSearchParams({
          phone: customerNumber,
          processed: true,
          salon_url_name: salonName,
        });
        const response = await getUpcomingActivites(queryParams);
        // console.log("activities response", response.data.data);
        // Ensure response.data.data is an array before setting state
        if (Array.isArray(response.data.data)) {
          setUpcomingActivities(response.data.data);
        } else {
          console.error("Invalid data format: expected array");
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching booking information:", error);
        setLoading(false);
      }
    };

    fetchBookingInformation();
  }, [customerNumber]);

  const handleActivityClick = (activityId) => {
    setSelectedActivityId(activityId);
    setShowDetails(true);
  };

  const handleCancel = () => {
    setShowDetails(false);
  };

  if (showDetails) {
    return (
      <BookingHistoryDetails
        onCancel={handleCancel}
        activityId={selectedActivityId}
      />
    );
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "2-digit",
    });
  };

  const formatTime = (timeArray) => {
    if (timeArray.length !== 2) return "";

    const [startHour, endHour] = timeArray;
    const startTimePeriod = startHour >= 12 ? "PM" : "AM";
    const endTimePeriod = endHour >= 12 ? "PM" : "AM";

    const formatHour = (hour) => {
      if (hour === 0) return 12;
      if (hour > 12) return hour - 12;
      return hour;
    };

    const formattedStartHour = formatHour(startHour % 12);
    const formattedEndHour = formatHour(endHour % 12);

    const startTime = `${formattedStartHour
      .toString()
      .padStart(2, "0")}:00 ${startTimePeriod}`;
    const endTime = `${formattedEndHour
      .toString()
      .padStart(2, "0")}:00 ${endTimePeriod}`;

    return `${startTime} - ${endTime}`;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <TitleBar>
        <CancelIcon onClick={onCancel}>
          <LeftChevronIcon />
        </CancelIcon>
        <SidebarTitle>Booking History</SidebarTitle>
      </TitleBar>

      <ActivitesContainer>
        {upcomingActivities.map((activity, index) => (
          <Activites
            key={index}
            onClick={() => handleActivityClick(activity._id)}
          >
            <DetailsContainer>
              <DateContainer>
                {formatDate(activity.date)},{" "}
                {formatTime(activity.selected_time)}
              </DateContainer>
              <Services>SAR {activity.total_price}</Services>
            </DetailsContainer>
            <DetailsContainer>
              <Services>
                {activity.services.map((service, index) => (
                  <React.Fragment key={index}>
                    <span>{service.name}</span>
                    {index !== activity.services.length - 1 && <span>, </span>}
                  </React.Fragment>
                ))}
                {activity.services.length > 0 &&
                  activity.products.length > 0 && <span>, </span>}
                {activity.products.map((product, index) => (
                  <React.Fragment key={index}>
                    <span>{product.name}</span>
                    {index !== activity.products.length - 1 && <span>, </span>}
                  </React.Fragment>
                ))}
              </Services>{" "}
              <ImageContainer>
             
              {activity.rated&&
                <StarContainer>
                  <Rate
                    allowHalf
                    value={activity.ratings_received} 
                    disabled
                    style={{ fontSize: 12, gap: 0 }}
                  />
                </StarContainer>
}
                <ProfileImage
                  src={activity.address.file_path || defaultImage}
                  alt="Profile Image"
                />
              </ImageContainer>
            </DetailsContainer>
          </Activites>
        ))}
      </ActivitesContainer>
    </>
  );
};

export default BookingHistory;
