import React, { useState } from "react";
import {
  Heading,
  HeadingRow,
  SearchContainer,
} from "../SalonStylists/SalonStylists.styled";
import SearchBox from "../../Components/SearchBox/SearchBox";
import { Table } from "antd";
import { getUserEmails } from "../../Components/UserEmails/userEmails";
import { SeparateLine } from "../SalonInventory/SalonInventory.styled";

const SalonTips = () => {
  const ownerEmail = getUserEmails().salonOwnerEmail;
  const [searchQuery, setSearchQuery] = useState("");

  const columns = [
    { title: "Customer Name", dataIndex: "customerName", key: "customerName" },
    { title: "Date", dataIndex: "date", key: "date" },
    { title: "Receiver", dataIndex: "receiver", key: "receiver" },
    { title: "Amount", dataIndex: "amount", key: "amount" },
  ];

  const data = [
    {
      key: "1",
      customerName: "John Doe",
      date: "12 Mar 24, 02:23 pm",
      receiver: "Alice Smith",
      amount: "SAR 30",
    },
    {
      key: "2",
      customerName: "Jane Roe",
      date: "13 Mar 24, 01:15 pm",
      receiver: "Bob Johnson",
      amount: "SAR 50",
    },
    {
      key: "3",
      customerName: "Mary Major",
      date: "14 Mar 24, 11:00 am",
      receiver: "Chris Lee",
      amount: "SAR 40",
    },
    {
      key: "4",
      customerName: "Peter Parker",
      date: "15 Mar 24, 03:45 pm",
      receiver: "Diana Prince",
      amount: "SAR 60",
    },
  ];

  return (
    <>
      <HeadingRow>
        <Heading>Tips</Heading>
        <SearchContainer>
          <SearchBox onChange={(e) => setSearchQuery(e.target.value)} />
        </SearchContainer>
      </HeadingRow>
      <SeparateLine />
      <Table dataSource={data} columns={columns} />
    </>
  );
};

export default SalonTips;
