import styled from "styled-components";
import { Theme } from "../../Themes/Theme";

export const NoDataContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%; 
  width:100%;
`;

export const NoDataText = styled.div `
  color: ${Theme.primaryBlack};
`;