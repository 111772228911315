import React, { useEffect, useState } from "react";
import LeftChevronIcon from "../../../Assets/Svgs/LeftChevronIcon";
import { useNavigate } from "react-router-dom";
import {
  Header,
  MainContainer,
  MonthContainer,
  ChevronContainer,
  DateContainer,
  CalendarContainer,
  DateWrapper,
  CalendarWrapper,
  DateText,
  WeekText,
  TitleText,
  TimeSlotContainer,
  TimeBadgeContainer,
  StylistContainer,
  NoteText,
  SubmitContainer,
  ProfileContainer,
  ProfileWrapper,
  RadioWrapper,
  StylistTitle,
  StylistName,
} from "./SchedulePage.styled";
import RightChevronIcon from "../../../Assets/Svgs/RightChevronIcon";
import moment from "moment";
import Button from "../../../Components/Button/Button";
import { Switch, Card, Radio, Avatar, message, Rate } from "antd";
import SwitchButton from "../../../Components/SwitchButton/SwitchButton";
import {
  rescheduleBookings,
} from "../../../Api/ApiServerRequests/BookingsApiServerRequests";
import {
  getAvailableStylist,
} from "../../../Api/ApiServerRequests/StylistsApiServerRequests";
import {
  getSalonWorkingHours,
} from "../../../Api/ApiServerRequests/SalonApiServerRequests";
import {
  CancelIcon,
  SidebarTitle,
} from "../../../Modules/Sidebar/Sidebar.styled";
import { StarContainer } from "../../../Modules/BookingHistory/BookingHistory.styled";
import { Theme } from "../../../Themes/Theme";

const SchedulePage = ({ reschedule, onCancel, bookingID }) => {
  const navigate = useNavigate();
  const salonName = localStorage.getItem("salonName");
  const [weeksOffset, setWeeksOffset] = useState(0);
  const [currentWeekDate, setCurrentWeekDate] = useState([]);
  const [selectedStylist, setSelectedStylist] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    moment().format("DD/MM/YYYY")
  );
  const [selectedTime, setSelectedTime] = useState();
  const [workingHours, setWorkingHours] = useState([]);
  const [selectedTimeIndex, setSelectedTimeIndex] = useState(null);
  const [stylists, setStylists] = useState([]);
  const [selectedStylistIndex, setSelectedStylistIndex] = useState(null);

  useEffect(() => {
    const pathArray = window.location.pathname.split("/");
    const potentialSalonName = pathArray[1];
    if (potentialSalonName!==salonName) {
      localStorage.setItem("salonName", potentialSalonName);
        window.location.href = `/${potentialSalonName}`;
    }
  }, []);

  useEffect(() => {
    const fetchWorkingHours = async (date) => {
      const selectedDate = moment(date, "DD/MM/YYYY");
      const formattedDate = selectedDate.format("YYYY-MM-DD");
      const today = moment().startOf("day");

      if (selectedDate.isSameOrAfter(today, "day")) {
        try {
          const response = await getSalonWorkingHours(
            formattedDate,
            "salon_url_name",
            salonName
          );
          if (response.data.status === 200) {
            setWorkingHours(response.data.data.working_hours);
          } else {
            setWorkingHours([]);
          }
        } catch (error) {
          console.error("Error fetching salon working hours:", error);
        } finally {
          setStylists([]);
        }
      }
    };

    fetchWorkingHours(selectedDate);
  }, [selectedDate, salonName]);

  useEffect(() => {
    setCurrentWeekDate(getDatesForCurrentWeek());
  }, [weeksOffset]);

  function extractDayFromDate(dateString) {
    const parts = dateString.split("/");
    const day = parts[0];
    return day;
  }

  function getDayOfWeek(dateString) {
    const formattedDate = moment(dateString, "DD/MM/YYYY");
    return formattedDate.format("ddd");
  }

  function getDatesForCurrentWeek() {
    const today = moment();
    const startOfWeek = today.startOf("week").add(weeksOffset, "weeks");
    const dates = [];

    for (let i = 0; i < 7; i++) {
      const date = startOfWeek.clone().add(i, "days");
      dates.push(date.format("DD/MM/YYYY"));
    }
    return dates;
  }

  const selectedDateHandler = async (date) => {
    const selectedDate = moment(date, "DD/MM/YYYY");
    const formattedDate = selectedDate.format("YYYY-MM-DD");
    // console.log(formattedDate);
    const today = moment().startOf("day");
    if (selectedDate.isSameOrAfter(today, "day")) {
      setSelectedDate(date);
      try {
        const response = await getSalonWorkingHours(
          formattedDate,
          "salon_url_name",
          salonName
        );
        // console.log(response);
        if (response.data.status === 200) {
          setWorkingHours(response.data.data.working_hours);
        } else {
          setWorkingHours([]);
        }
      } catch (error) {
        console.error("Error fetching salon working hours:", error);
      } finally {
        setStylists([]);
      }
    }
  };

  const handleTimeSelect = (startTime, endTime) => {
    const formatTime = (time) => {
      const hour = parseInt(time);
      const minute = 0;
      const isAM = hour < 12;
      const formattedHour = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour;
      return `${formattedHour}:${minute < 10 ? "0" + minute : minute} ${
        isAM ? "AM" : "PM"
      }`;
    };

    const formattedStartTime = formatTime(startTime);
    const formattedEndTime = formatTime(endTime);

    return `${formattedStartTime} - ${formattedEndTime}`;
  };

  const getStylist = async (index, startTime, endTime) => {
    // console.log(index, startTime, endTime);
    if (selectedTimeIndex === index) {
      setSelectedTimeIndex(null);
      setStylists([]);
    } else {
      setSelectedTimeIndex(index);
      const currentDate = moment(selectedDate, "DD/MM/YYYY");
      const formattedDate = currentDate.format("YYYY-MM-DD");
      const queryParams = new URLSearchParams({
        date: formattedDate,
        start_time: startTime,
        end_time: endTime,
        salon_url_name: salonName,
      });

      try {
        const response = await getAvailableStylist(queryParams);
        // console.log(response);
        if (response.data.status === 200) {
          setStylists(response.data.data);
        } else {
          setStylists([]);
        }
      } catch (error) {
        console.error("Error fetching available stylists:", error);
      }
    }
  };

  const incrementWeekOffset = () => {
    setWeeksOffset((previous) => previous + 1);
  };

  const decrementWeekOffset = () => {
    if (!currentWeekDate.includes(moment().format("DD/MM/YYYY")))
      setWeeksOffset((previous) => previous - 1);
  };

  const handleContinue = async () => {
    if (reschedule === "reschedule") {
      // console.log("bookingid", bookingID);
      if (selectedTimeIndex === null) {
        message.error("Please select a time slot");
      } else {
        const selectedSlot = workingHours[selectedTimeIndex];
        if (!selectedSlot) {
          message.error("Invalid time slot selected");
          return;
        }

        const startTime = selectedSlot[0];
        const endTime = selectedSlot[1];

        const stylistEmail =
          selectedStylistIndex !== null
            ? stylists[selectedStylistIndex].stylist_email
            : "";
        // console.log("stylists", stylists[selectedStylistIndex]);

        const currentDate = moment(selectedDate, "DD/MM/YYYY");
        const formattedDate = currentDate.format("YYYY-MM-DD");

        const queryParams = new URLSearchParams({
          booking_id: bookingID,
          date: formattedDate,
          start_time: startTime,
          end_time: endTime,
        });
        if (stylistEmail) {
          queryParams.append("stylist_email", stylistEmail);
        }
        try {
          const response = await rescheduleBookings(queryParams);
          // console.log("rescheduleBookings response:", response);
          if (response.data.status === 201) {
            message.success(response.data.data);
            onCancel();
          } else {
            message.error(response.data.data);
          }
        } catch (error) {
          console.error("Error rescheduling booking:", error);
        }
      }
    } else {
      if (selectedTimeIndex === null) {
        message.error("Please select a time slot");
      } else {
        // Get the selected time slot
        const selectedSlot = workingHours[selectedTimeIndex];
        if (!selectedSlot) {
          message.error("Invalid time slot selected");
          return;
        }

        // Extract start time and end time
        const startTime = selectedSlot[0];
        const endTime = selectedSlot[1];

        // Get selected stylist email if available
        const stylistEmail =
          selectedStylistIndex !== null
            ? stylists[selectedStylistIndex].stylist_email
            : "";
        console.log("stylists", stylists[selectedStylistIndex]);

        // Format date
        const currentDate = moment(selectedDate, "DD/MM/YYYY");
        const formattedDate = currentDate.format("YYYY-MM-DD");

        // Store values in sessionStorage
        sessionStorage.setItem("stylist_email", stylistEmail);
        sessionStorage.setItem("date", formattedDate);
        sessionStorage.setItem("start_time", JSON.stringify(startTime));
        sessionStorage.setItem("end_time", JSON.stringify(endTime));

        // Navigate to the next page
        navigate(`/${salonName}/address`);
      }
    }
  };

  const handleClearSelectedStylist = () => {
    setSelectedStylistIndex(null);
  };

  return (
    <>
      <MainContainer>
        {reschedule === "reschedule" ? (
          <>
            <Header>
              <div style={{ display: "flex" }}>
                <CancelIcon onClick={onCancel}>
                  <LeftChevronIcon />
                </CancelIcon>
              </div>
              <SidebarTitle>Reschedule booking</SidebarTitle>
            </Header>
          </>
        ) : (
          <>
            <Header>
              <div onClick={() => navigate(-1)} style={{ display: "flex" }}>
                <LeftChevronIcon />
              </div>
              <div>{salonName}</div>
            </Header>
          </>
        )}
        <MonthContainer>
          <TitleText>
            {moment(currentWeekDate[0], "DD/MM/YYYY").format("MMMM YYYY")}
          </TitleText>
          <ChevronContainer>
            <div onClick={decrementWeekOffset}>
              <LeftChevronIcon />
            </div>
            <div onClick={incrementWeekOffset}>
              <RightChevronIcon />
            </div>
          </ChevronContainer>
        </MonthContainer>

        <CalendarWrapper>
          <CalendarContainer>
            <DateContainer>
              {currentWeekDate.map((item, index) => (
                <DateWrapper
                  key={index}
                  isSelected={selectedDate === item}
                  onClick={() => selectedDateHandler(item)}
                >
                  <DateText>{extractDayFromDate(item)}</DateText>
                  <WeekText>{getDayOfWeek(item)}</WeekText>
                </DateWrapper>
              ))}
            </DateContainer>
          </CalendarContainer>
        </CalendarWrapper>
        <TimeSlotContainer>
          <TitleText>Select Time Slot</TitleText>
          <TimeBadgeContainer hasWorkingHours={workingHours.length > 0}>
            {workingHours.length > 0 ? (
              workingHours.map((slot, index) => (
                <Button
                  key={index}
                  text={handleTimeSelect(slot[0], slot[1])}
                  type={selectedTimeIndex === index ? "primary" : "default"}
                  width={"45%"}
                  onClick={() => {
                    getStylist(index, slot[0], slot[1]);
                  }}
                  color={Theme.salonColor}
                />
              ))
            ) : (
              <div>No available time slots</div>
            )}
          </TimeBadgeContainer>
        </TimeSlotContainer>
        <StylistContainer>
          <TitleText>Do you want to choose your stylist?</TitleText>
          <SwitchButton
            checked={selectedStylist}
            onChange={() => setSelectedStylist((previous) => !previous)}
          />
        </StylistContainer>
        {selectedStylist && (
          <>
            <NoteText>
              Note: Selecting a specific stylist may be subject to availability
            </NoteText>
            {stylists.length > 0 ? (
              <>
                <ProfileContainer>
                  <Radio.Group
                    onChange={(e) => {
                      setSelectedStylistIndex(e.target.value);
                    }}
                    value={selectedStylistIndex}
                    style={{
                      marginTop: "12px",
                      gap: "10px",
                      display: "flex",
                      flexDirection: "row",
                      overflowX: "auto",
                    }}
                  >
                    {stylists.map((stylist, index) => (
                      <Card key={index}>
                        <RadioWrapper>
                          <Radio value={index} />
                        </RadioWrapper>
                        <ProfileWrapper>
                          <Avatar src={stylist.file_path} size={"large"} />
                          <StylistName>{stylist.name}</StylistName>
                          <StylistTitle>{stylist.role}</StylistTitle>
                          {stylist.ratings && (
                            <StarContainer>
                              <Rate
                                allowHalf
                                value={stylist.ratings}
                                disabled
                                style={{ fontSize: 12, gap: 0 }}
                              />
                            </StarContainer>
                          )}
                        </ProfileWrapper>
                      </Card>
                    ))}
                  </Radio.Group>
                </ProfileContainer>
                <Button
                  text={"Clear Selected Stylist"}
                  type={"primary"}
                  width={"50%"}
                  height={"44px"}
                  onClick={handleClearSelectedStylist}
                  backgroundColor={Theme.salonColor}

                />
              </>
            ) : (
              <div style={{ marginTop: "10px" }}>Stylist not available</div>
            )}
          </>
        )}
      </MainContainer>
      <SubmitContainer>
        <Button
          text={reschedule === "reschedule" ? "Reschedule" : "Continue"}
          type={"primary"}
          width={"90%"}
          height={"44px"}
          onClick={handleContinue}
          backgroundColor={Theme.salonColor}
        />
      </SubmitContainer>
    </>
  );
};

export default SchedulePage;
