import React from "react";

const PlusIcon = ({ color="#fff" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
      <path d="M10 4.44962L10 17.4496" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M3.5 10.9496L16.5 10.9496" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
};

export default PlusIcon;
