import React from "react";
import { Modal, Button } from "antd";

const DeleteConfirmationModal = ({
  visible,
  itemName,
  isService,
  onCancel,
  onConfirm,
}) => {
  return (
    <Modal
      title={`Delete ${isService ? "Service" : "Product"}`}
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="Cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          key="Delete"
          type="primary"
          style={{ backgroundColor: "rgba(109, 49, 237, 1)" }}
          onClick={onConfirm}
        >
          Delete
        </Button>,
      ]}
    >
      {`Would you like to delete the ${
        isService ? "service" : "product"
      } "${itemName}"?`}
    </Modal>
  );
};

export default DeleteConfirmationModal;
