import React, { useEffect, useState } from "react";
import {
  DetailsContainer,
  FinalAmount,
  Header,
  MainContainer,
  SeparateLine,
  SubmitContainer,
  Success,
} from "../BookingDetails/BookingDetails.styled";
import { useNavigate } from "react-router-dom";
import Button from "../../../Components/Button/Button";
import {
  BookingConfirmationText,
  ConfirmationContainer,
  ProductAmount,
  ProductInformation,
  ProductName,
} from "./TippingConfimation.styled";
import GreenTick from "../../../Assets/Svgs/GreenTick";
import TestingPDF from "../../../Assets/PDF/Receipt.pdf";
import Sidebar from "../../../Modules/Sidebar/Sidebar";
import { getBookingsConfirmationInformation } from "../../../Api/ApiServerRequests/BookingsApiServerRequests";
import { Theme } from "../../../Themes/Theme";
import {
  AdvancedPayment,
  CostContainer,
  DetailContainer,
  DownloadButton,
  SeperateLine,
  ServiceType,
} from "../../../Modules/UpcomingBookingDetails/UpcomingBookingDetails.styled";
import { Name } from "../../../Modules/BookingHistoryDetails/BookingHistoryDetails.styled";
import { handleDownloadReceipt } from "../../../Helper/Helper";

const TippingConfimation = () => {
  const navigate = useNavigate();
  const salonName = localStorage.getItem("salonName");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [showHelp] = useState("Help");

  useEffect(() => {
    const pathArray = window.location.pathname.split("/");
    const potentialSalonName = pathArray[1];
    if (potentialSalonName!==salonName) {
      localStorage.setItem("salonName", potentialSalonName);
        window.location.href = `/${potentialSalonName}`;
    }
  }, []);

  return (
    <>
      <MainContainer>
        <div>
          <Header>
            {/* <div
              onClick={() => {
                navigate(`/${salonName}/home`);
              }}
              style={{ display: "flex" }}
            >
              <LeftChevronIcon />
            </div> */}
            <div>{"Tipping confirmation"}</div>
          </Header>
        </div>

        <ConfirmationContainer>
          <GreenTick />
          <BookingConfirmationText>
            Your Tipping Confirmed
          </BookingConfirmationText>
          {/* {bookingInfo && (
            <LoyalPoints>
              Received <CrownIcon /> {bookingInfo.loyalty_points} Loyal points on
              this purchase
            </LoyalPoints>
          )} */}
        </ConfirmationContainer>

        <DetailContainer>
          <CostContainer>
            <ServiceType>
              <Name>Tipping amount</Name>
              <AdvancedPayment color="rgba(76,161,16,1)">
                SAR 100
              </AdvancedPayment>
            </ServiceType>
          </CostContainer>
        </DetailContainer>

        <SubmitContainer>
          <DownloadButton onClick={handleDownloadReceipt}>
            Download Receipt
          </DownloadButton>
          <Button
            text={"Back to home"}
            type={"primary"}
            width={"100%"}
            height={"44px"}
            onClick={() => {
              navigate(`/${salonName}/home`);
            }}
            backgroundColor={Theme.salonColor}
          />
        </SubmitContainer>
      </MainContainer>
    </>
  );
};
export default TippingConfimation;
