import styled from "styled-components";
import { Fonts } from "../../Fonts/Fonts";
import { Theme } from "../../Themes/Theme";

export const StylistNameContainer = styled.div`
  margin: 20px 0;

`;

export const StylistHeader = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: rgba(50, 55, 67, 1);
`;

export const StylistName = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  color: ${Theme.primaryBlack};
`;

export const Leave = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`;