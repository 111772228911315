import React from "react";

const EyeVisibleIcon = ({ onClick }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"  onClick={onClick} style={{ cursor: "pointer" }}>
<path d="M2.97681 13.1091C2.43595 12.4689 2.43595 11.5311 2.97681 10.8909C4.44252 9.15686 7.70138 6 12 6C16.2985 6 19.5574 9.15686 21.0231 10.8909C21.564 11.5311 21.564 12.4689 21.0231 13.1091C19.5574 14.8431 16.2985 18 12 18C7.70138 18 4.44252 14.8431 2.97681 13.1091Z" stroke="#171A1F" stroke-width="2.05714" stroke-miterlimit="10" stroke-linecap="square"/>
<path d="M11.9999 14.5714C14.3669 14.5714 16.2857 12.6526 16.2857 10.2857C16.2857 7.91878 14.3669 6 11.9999 6C9.63301 6 7.71423 7.91878 7.71423 10.2857C7.71423 12.6526 9.63301 14.5714 11.9999 14.5714Z" stroke="#171A1F" stroke-width="2.05714" stroke-miterlimit="10" stroke-linecap="square"/>
</svg>
  );
};

export default EyeVisibleIcon;