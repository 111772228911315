import React, { useState, useEffect } from "react";
import { Modal, Button, Checkbox, Select, message } from "antd";
import moment from "moment";
import {
  CheckboxContainer,
  Leave,
  StylistHeader,
  StylistName,
  StylistNameContainer,
  AddButton,
} from "./UnAvailabilityModal.styled";
import {
  CancelIconContainer,
  DateSelector,
  SchdeuleContainer,
  SchdeuleHeader,
} from "../SalonSchdeule/SalonSchdeule.styled";
import CancelIcon from "../../Assets/Svgs/CancelIcon";
import {
  addStylistWorkingHours,
  getStylistWorkingHours,
  deleteStylistWorkingHours,
} from "../../Api/ApiServerRequests/StylistsApiServerRequests";
import DefaultScheduleModal from "../DefaultScheduleModal/DefaultScheduleModal";

const UnavailabilityModal = ({
  visible,
  onClose,
  onOk,
  stylistName,
  stylistEmail,
  currentWeekStartDate,
  setCurrentWeekStartDate,
}) => {
  //console.log("stylistEmail", stylistEmail);
  const [selectedDays, setSelectedDays] = useState([]);
  const [availabilityTimes, setAvailabilityTimes] = useState({});

  const rangePickerStyle = { width: "90%" };
  const [leaveFrom, setLeaveFrom] = useState(null);
  const [leaveTo, setLeaveTo] = useState(null);
  const { Option } = Select;
  const [workingHoursData, setWorkingHoursData] = useState({});
  const [initialRender, setInitialRender] = useState(true);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteDate, setDeleteDate] = useState(null);
  const [defaultScheduleModalVisible, setDefaultScheduleModalVisible] = useState(false);

  useEffect(() => {
    if (visible && !initialRender) {
      fetchWorkingHours(currentWeekStartDate);
    } else {
      setInitialRender(false);
    }
  }, [visible]);

  const fetchWorkingHours = (weekStartDate) => {
    setSelectedDays([]);
    //console.log("called");
    const promises = [];
    const dates = getCurrentWeekDates(weekStartDate);

    for (const date of dates) {
      const formattedDate = moment(date).format("YYYY-MM-DD");
      promises.push(getStylistWorkingHours(formattedDate, stylistEmail));
    }

    Promise.all(promises)
      .then((responses) => {
        const data = {};
        responses.forEach((response, index) => {
          const date = moment(dates[index]).format("DD/MM/YYYY");
          const formattedDate = moment(dates[index]).format("YYYY/MM/DD");
          const workingHours = response.data.data.working_hours;
          if (workingHours) {
            data[date] = {
              from: moment(workingHours[0], "H").format("h:mm A"),
              to: moment(workingHours[1], "H").format("h:mm A"),
            };
            const dayName = moment(formattedDate).format("ddd").toUpperCase();
            //console.log("dayName", dayName);
            handleCheckboxChange(dayName);
          } else {
            data[date] = false;
          }
        });
        setWorkingHoursData(data);
      })
      .catch((error) => {
        console.error("Error fetching working hours:", error);
      });
  };

  const getCurrentWeekDates = (startDate) => {
    const currentDay = startDate.getDay();
    const clonedStartDate = new Date(startDate);
    clonedStartDate.setDate(clonedStartDate.getDate() - currentDay);

    const dates = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(clonedStartDate);
      date.setDate(date.getDate() + i);
      dates.push(date);
    }
    return dates;
  };

  const handleCheckboxChange = (day) => {
    setSelectedDays((prevSelectedDays) => {
      const updatedSelectedDays = [...prevSelectedDays];
      if (updatedSelectedDays.includes(day)) {
        updatedSelectedDays.splice(updatedSelectedDays.indexOf(day), 1);
        setAvailabilityTimes((prevAvailabilityTimes) => {
          const updatedAvailabilityTimes = { ...prevAvailabilityTimes };
          delete updatedAvailabilityTimes[day];
          return updatedAvailabilityTimes;
        });
      } else {
        updatedSelectedDays.push(day);
      }
      return updatedSelectedDays;
    });
  };

  const handleFromTimeChange = (day, value) => {
    setAvailabilityTimes((prevAvailabilityTimes) => ({
      ...prevAvailabilityTimes,
      [day]: { ...prevAvailabilityTimes[day], from: value },
    }));
  };

  const handleToTimeChange = (day, value) => {
    setAvailabilityTimes((prevAvailabilityTimes) => ({
      ...prevAvailabilityTimes,
      [day]: { ...prevAvailabilityTimes[day], to: value },
    }));
  };

  const getTimeOptions = () => {
    const timeOptions = [];
    for (let i = 0; i < 12; i++) {
      const hour = i === 0 ? 12 : i;
      timeOptions.push(
        <Option
          key={`${hour}:00 AM`}
          value={`${hour}:00 AM`}
        >{`${hour}:00 AM`}</Option>
      );
    }
    timeOptions.push(
      <Option key={`12:00 PM`} value={`12:00 PM`}>{`12:00 PM`}</Option>
    );
    for (let i = 1; i < 12; i++) {
      const hour = i === 0 ? 12 : i;
      timeOptions.push(
        <Option
          key={`${hour}:00 PM`}
          value={`${hour}:00 PM`}
        >{`${hour}:00 PM`}</Option>
      );
    }
    return timeOptions;
  };

  const goToPreviousWeek = () => {
    setSelectedDays([]);
    const prevWeekStartDate = new Date(currentWeekStartDate);
    prevWeekStartDate.setDate(prevWeekStartDate.getDate() - 7);
    prevWeekStartDate.setDate(
      prevWeekStartDate.getDate() - prevWeekStartDate.getDay()
    );
    setCurrentWeekStartDate(prevWeekStartDate);
    fetchWorkingHours(prevWeekStartDate);
  };

  const goToNextWeek = () => {
    setSelectedDays([]);
    const nextWeekStartDate = new Date(currentWeekStartDate);
    nextWeekStartDate.setDate(nextWeekStartDate.getDate() + 7);
    setCurrentWeekStartDate(nextWeekStartDate);
    fetchWorkingHours(nextWeekStartDate);
  };

  const weekDates = getCurrentWeekDates(currentWeekStartDate);

  const handleAddSchedule = () => {
    const scheduleData = {};
    const missingFromTimingDates = [];
    const missingToTimingDates = [];
    const invalidTimingsDates = [];

    for (const day of selectedDays) {
      if (availabilityTimes[day]) {
        const from = availabilityTimes[day].from;
        const to = availabilityTimes[day].to;
        if (!from) {
          missingFromTimingDates.push(day);
        }
        if (!to) {
          missingToTimingDates.push(day);
        }
        if (from && to) {
          const fromHour = moment(from, "h:mm A").format("H");
          const toHour = moment(to, "h:mm A").format("H");
          if (parseInt(fromHour) >= parseInt(toHour)) {
            const dateIndex = [
              "SUN",
              "MON",
              "TUE",
              "WED",
              "THU",
              "FRI",
              "SAT",
            ].indexOf(day);
            const selectedDate =
              getCurrentWeekDates(currentWeekStartDate)[dateIndex];
            const formattedDate = moment(selectedDate).format("DD/MM/YYYY");
            invalidTimingsDates.push(formattedDate);
          } else {
            const dateIndex = [
              "SUN",
              "MON",
              "TUE",
              "WED",
              "THU",
              "FRI",
              "SAT",
            ].indexOf(day);
            const selectedDate =
              getCurrentWeekDates(currentWeekStartDate)[dateIndex];
            const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
            if (!scheduleData[formattedDate]) {
              scheduleData[formattedDate] = [];
            }
            scheduleData[formattedDate].push(fromHour, toHour);
          }
        }
      }
    }

    if (missingFromTimingDates.length > 0 || missingToTimingDates.length > 0) {
      let errorMessage = "";
      if (missingFromTimingDates.length > 0) {
        const formattedFromTimingDates = missingFromTimingDates.map((date) =>
          moment(date, "ddd").format("DD/MM/YYYY")
        );
        errorMessage += `Missing From timing for ${formattedFromTimingDates.join(
          ", "
        )}. `;
      }
      if (missingToTimingDates.length > 0) {
        const formattedToTimingDates = missingToTimingDates.map((date) =>
          moment(date, "ddd").format("DD/MM/YYYY")
        );
        errorMessage += `Missing To timing for ${formattedToTimingDates.join(
          ", "
        )}.`;
      }
      message.error(errorMessage);
    } else if (invalidTimingsDates.length > 0) {
      const errorMessage =
        invalidTimingsDates.length === 1
          ? `Invalid timing for ${invalidTimingsDates[0]}: 'From' time must be earlier than 'To' time`
          : `Invalid timings for ${invalidTimingsDates.join(
              ", "
            )}: 'From' time must be earlier than 'To' time`;
      message.error(errorMessage);
    } else {
      //console.log("scheduleData", scheduleData);
      addStylistWorkingHours(stylistEmail, scheduleData)
        .then((response) => {
          //console.log("Response:", response);
          if (response.data.status === 201) {
            message.success(response.data.data);
          } else {
            message.error("Setting of salon working hours was unsuccessful");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const showDeleteModal = (date) => {
    //console.log("showDeleteModal date", date);
    setDeleteDate(date);
    setDeleteModalVisible(true);
  };

  const handleDeleteConfirm = () => {
    //console.log("deleteDate", deleteDate);
    const formattedDate = moment(deleteDate, "DD/MM/YYYY").format("YYYY-MM-DD");
    deleteStylistWorkingHours(
      `date=${formattedDate}&stylist_email=${stylistEmail}`
    )
      .then((response) => {
        //console.log("Deletion response:", response);
        if (response.data.status === 201) {
          message.success("Schedule time deleted successfully.");
          fetchWorkingHours(currentWeekStartDate);
        } else {
          message.error("Failed to delete schedule time.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        message.error("Failed to delete schedule time.");
      })
      .finally(() => {
        setDeleteModalVisible(false);
      });
  };

  const deleteModal = (
    <Modal
      title={`Are you sure you want to delete schedule time of ${deleteDate}?`}
      visible={deleteModalVisible}
      onOk={handleDeleteConfirm}
      onCancel={() => setDeleteModalVisible(false)}
    >
      {/* <p>This action cannot be undone.</p> */}
    </Modal>
  );

  const openDefaultScheduleModal = () => {
    setDefaultScheduleModalVisible(true);
  };

  const closeDefaultScheduleModal = () => {
    fetchWorkingHours(currentWeekStartDate);
    setDefaultScheduleModalVisible(false);
  };


  return (
    <Modal
      title={`Set availability`}
      visible={visible}
      onCancel={onClose}
      onOk={onOk}
      width={900}
      footer={[
        // <Button key="Cancel" onClick={onClose}>
        //   Cancel
        // </Button>,
        <Button key="Add" type="primary" onClick={onOk} style={{ backgroundColor: 'rgba(109, 49, 237, 1)' }}>
          Close
        </Button>,
      ]}
    >
      <StylistNameContainer>
        <StylistHeader>Stylist</StylistHeader>
        <StylistName> {stylistName}</StylistName>
      </StylistNameContainer>
      <StylistHeader>Regular Availability</StylistHeader>
      <SchdeuleContainer>
        <SchdeuleHeader>
          <Button onClick={goToPreviousWeek}>&lt;</Button>
          <StylistHeader style={{ margin: "0 10px" }}>
            Weekly Schedule
          </StylistHeader>
          <Button onClick={goToNextWeek}>&gt;</Button>
          <AddButton onClick={openDefaultScheduleModal}> Set Default Timing</AddButton>
        </SchdeuleHeader>
        {weekDates.map((date, index) => {
          const formattedDate = moment(date).format("DD/MM/YYYY");
          const dayName = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"][
            index
          ];

          const workingHours = workingHoursData[formattedDate];

          return (
            <DateSelector key={index}>
              <div style={{ width: "80px", marginRight: "10px" }}>
                {formattedDate}
              </div>
              <Checkbox
                style={{ width: "20%" }}
                onChange={() => handleCheckboxChange(dayName)}
                checked={
                  selectedDays.includes(dayName) ||
                  (workingHoursData[formattedDate] &&
                    selectedDays.includes(dayName))
                }
                disabled={!!workingHoursData[formattedDate]}
              >
                {dayName}
              </Checkbox>

              {selectedDays.includes(dayName) ? (
                <CheckboxContainer>
                  {workingHours ? (
                    <>
                      <Select
                        style={{ width: "100px" }}
                        placeholder="From"
                        defaultValue={workingHours.from}
                        onChange={(value) =>
                          handleFromTimeChange(dayName, value)
                        }
                      >
                        {getTimeOptions()}
                      </Select>
                      <span style={{ margin: "0 5px" }}>to</span>
                      <Select
                        style={{ width: "100px" }}
                        placeholder="To"
                        defaultValue={workingHours.to}
                        onChange={(value) => handleToTimeChange(dayName, value)}
                      >
                        {getTimeOptions()}
                      </Select>
                      <CancelIconContainer
                        onClick={() => showDeleteModal(formattedDate)}
                      >
                        <CancelIcon />
                      </CancelIconContainer>
                    </>
                  ) : (
                    <>
                      <Select
                        style={{ width: "100px" }}
                        placeholder="From"
                        onChange={(value) =>
                          handleFromTimeChange(dayName, value)
                        }
                      >
                        {getTimeOptions()}
                      </Select>
                      <span style={{ margin: "0 5px" }}>to</span>
                      <Select
                        style={{ width: "100px" }}
                        placeholder="To"
                        onChange={(value) => handleToTimeChange(dayName, value)}
                      >
                        {getTimeOptions()}
                      </Select>
                      <CancelIconContainer
                        onClick={() => showDeleteModal(formattedDate)}
                      >
                        <CancelIcon />
                      </CancelIconContainer>
                    </>
                  )}
                </CheckboxContainer>
              ) : (
                <CheckboxContainer>Unavailable</CheckboxContainer>
              )}
            </DateSelector>
          );
        })}

        <AddButton onClick={handleAddSchedule}>Add Schedule</AddButton>
      </SchdeuleContainer>
      {deleteModal}
      <DefaultScheduleModal
        visible={defaultScheduleModalVisible}
        onClose={closeDefaultScheduleModal}
        isStylist={true}
        stylistEmail={stylistEmail}
      />
    </Modal>
  );
};

export default UnavailabilityModal;
