import React from "react";
import { Select, Button } from "antd";
import {
  Description,
  Heading,
  OptionsContainer,
} from "../PaymentSettings/PaymentSettings.styled";
import { Container } from "./AdvancePaymentSettings.styled";

const { Option } = Select;

const AdvancePaymentSettings = () => {
  const handleChange = (value) => {
    // console.log(`Selected: ${value}`);
  };

  const handleSave = () => {
    // Handle save functionality here
    // console.log("Save button clicked");
  };

  return (
    <>
      <Container>
        <Heading>Advance Payment</Heading>
        <Description>
          Configure advance payment options for your pre-bookings.
        </Description>
        <OptionsContainer>
        <Select defaultValue="0%" style={{ width: 120 }} onChange={handleChange}>
          {[...Array(11).keys()].map((value) => (
            <Option key={value * 10} value={`${value * 10}%`}>
              {`${value * 10}%`}
            </Option>
          ))}
        </Select>
        <Button
          type="primary"
          style={{ background: "rgba(81,19,215,1)", marginLeft: 10 }}
          onClick={handleSave}
        >
          Save
        </Button>
        </OptionsContainer>
      </Container>
    </>
  );
};

export default AdvancePaymentSettings;
