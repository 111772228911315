import React from "react";

const DashboardHomeIcon = ({ isActive }) => {
  const strokeColor = isActive ? "#6D31ED" : "#000";

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M2.57141 11.1428L12 3.42856L21.4286 11.1428" stroke={strokeColor} strokeWidth="2.05714" strokeLinecap="round" strokeMiterlimit="10" />
      <path d="M10.2857 21.4286L10.2857 16.2857H13.7143L13.7143 21.4286" stroke={strokeColor} strokeWidth="2.05714" strokeLinecap="round" strokeMiterlimit="10" />
      <path d="M5.14288 12.8571L5.14288 19.7143C5.14288 20.6614 5.91003 21.4286 6.85717 21.4286L17.1429 21.4286C18.09 21.4286 18.8572 20.6614 18.8572 19.7143V12.8571" stroke={strokeColor} strokeWidth="2.05714" strokeLinecap="square" strokeMiterlimit="10" />
    </svg>
  );
};

export default DashboardHomeIcon;