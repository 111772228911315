import React from "react";

const MoneyBagIcon = ({ isActive }) => {
  const strokeColor = isActive ? "#6D31ED" : "#171A1F";

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M9.42855 6.85716L6.85712 2.57144L17.1428 2.57144L14.5714 6.85716" stroke={strokeColor} strokeWidth="2.05714" strokeLinecap="square" strokeMiterlimit="10" />
      <path d="M14.5714 6.85715L9.42856 6.85715C9.42856 6.85715 4.28571 12.312 4.28571 16.2857C4.28571 18.9351 6.21513 21.4286 12 21.4286C17.7848 21.4286 19.7143 18.9351 19.7143 16.2857C19.7143 12.312 14.5714 6.85715 14.5714 6.85715Z" stroke={strokeColor} strokeWidth="2.05714" strokeLinecap="square" strokeMiterlimit="10" />
    </svg>
  );
};

export default MoneyBagIcon;
