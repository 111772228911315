import React from "react";
import { StyledRadioButton } from "./RadioButton.styled";


const RadioButton = ({checked, onChange, value, text}) => {
  return (
    <StyledRadioButton
      checked={checked} 
      onChange={onChange}
      value={value}
    >
        {text}
    </StyledRadioButton>
  );
};

export default RadioButton;
