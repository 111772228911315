import ApiServer from "../ApiServer";


export const getStylists = (parameter = "", value = "") => {
  let url = "/stylists";

  if (value && parameter === "owner email") {
    url += `?owner_email=${value}`;
  } else if (value && parameter === "name") {
    url += `?salon_url_name=${value}`;
  } else if (value && parameter === "stylist email") {
    url += `?stylist_email=${value}`;
  }

  return ApiServer({
    method: "GET",
    url: url,
  });
};


export const createStylist = (queryParams, formData) => {
  return ApiServer({
    method: "POST",
    url: `/stylists?${queryParams.toString()}`,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteStylists = (queryParams) => {
  return ApiServer({
    method: "DELETE",
    url: `/stylists?${queryParams.toString()}`,
  });
};

export const updateStylist = (queryParams, formData) => {
  return ApiServer({
    method: "PUT",
    url: `/stylists?${queryParams.toString()}`,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const addStylistWorkingHours = (email, data) => {
  return ApiServer({
    method: "POST",
    url: `/stylists/schedule?stylist_email=${email}`,
    data: data,
  });
};

export const getStylistWorkingHours = (date, value) => {
  let url;
  url = `/stylists/schedule?date=${date}&stylist_email=${value}`;
  return ApiServer({
    method: "GET",
    url: url,
  });
};

export const deleteStylistWorkingHours = (queryParams) => {
  return ApiServer({
    method: "PUT",
    url: `/stylists/schedule?${queryParams.toString()}`,
  });
};

export const requestLeave = (email, data, reason, start_date, end_date) => {
  return ApiServer({
    method: "POST",
    url: `/stylists/timeoff/request?stylist_email=${email}&reason=${reason}&start_date=${start_date}&end_date=${end_date}`,
    data: data,
  });
};

export const getRequestLeave = (email, type, status) => {
  let url;
  if (type === "owner") {
    url = `/stylists/timeoff?owner_email=${email}`;
  } else if (type === "stylist") {
    url = `/stylists/timeoff?stylist_email=${email}`;
  }

  if (status) {
    url += `&status=${status}`;
  }

  return ApiServer({
    method: "GET",
    url: url,
  });
};

export const respondLeaveRequest = (stylist_email, start_date, end_date, status) => {
  return ApiServer({
    method: "POST",
    url: `/stylists/timeoff/respond?stylist_email=${stylist_email}&start_date=${start_date}&end_date=${end_date}&status=${status}`,
  });
};

export const deleteLeaveRequest = (stylist_email, start_date, end_date) => {
  return ApiServer({
    method: "DELETE",
    url: `/stylists/timeoff?stylist_email=${stylist_email}&start_date=${start_date}&end_date=${end_date}`,
  });
};

export const getAvailableStylist = (queryParams) => {
  let url;
  url = `/stylists/available?${queryParams.toString()}`;
  return ApiServer({
    method: "GET",
    url: url,
  });
};

export const rateStylist = (queryParams) => {
  return ApiServer({
    method: "POST",
    url: `/stylists/ratings?${queryParams.toString()}`
  });
};

export const setSalonStylistDefaultTiming = (queryParams) => {
  return ApiServer({
    method: "POST",
    url: `/stylists/schedule/default?${queryParams.toString()}`
  });
};