import React from "react";

const CancelIcon = () => {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
<path d="M15.5 4.56772L4.5 15.5677" stroke="#171A1F" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.5 4.56772L15.5 15.5677" stroke="#171A1F" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  );
};

export default CancelIcon;
