import React from "react";
import { NoDataContainer, NoDataText } from "./NoData.styled";
import NoDataIcon from "../../Assets/Svgs/NoDataIcon";

const NoData = () => {
  return (
    <NoDataContainer>
        <NoDataIcon/>
        <NoDataText>No Data</NoDataText>
    </NoDataContainer>
  );
};

export default NoData;
