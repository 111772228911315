import styled from "styled-components";
import { Fonts } from "../../Fonts/Fonts";

export const CustomerHeader = styled.div`
 height: auto; 
  background: rgba(243,244,246,1); 
  border-radius: 0px; 
  width:100%;
`;

export const SearchContainer = styled.div`
 padding: 20px;
 display: flex;
 flex-direction: row;
 gap:20px;
 align-items: center;
 justify-content: space-between;
`;


export const HelloContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const HelloText = styled.div`
  font-family: ${Fonts.primaryFont}; 
  font-size: 24px; 
  font-weight: 400; 
  line-height: 36px; 
  color: rgba(23,26,31,1);
`;

export const WelcomeBackText = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 20px; 
  font-weight: 400; 
  line-height: 30px; 
  color: rgba(144,149,161,1);
  margin-top: 10px;
`;