import React, { useEffect, useState } from "react";
import {
  CouponCode,
  CouponCodeInput,
  CouponContainer,
  DetailsContainer,
  Header,
  LineSeparator,
  LoyalpointContainer,
  MainContainer,
  MandatoryContainer,
  Points,
  PointsContainer,
  PointsText,
  ProductAmount,
  ProductInformation,
  ProductName,
  SeparateLine,
  SubmitContainer,
  UseLoyal,
  YellowContainer,
} from "./PaymentDiscounts.styled";
import LeftChevronIcon from "../../../Assets/Svgs/LeftChevronIcon";
import { useNavigate } from "react-router-dom";
import Button from "../../../Components/Button/Button";
import { Radio, Checkbox } from "antd"; // Import Checkbox from Ant Design
import CrownIcon from "../../../Assets/Svgs/CrownIcon";
import { createBookings } from "../../../Api/ApiServerRequests/BookingsApiServerRequests";
import { getCustomerAddress } from "../../../Api/ApiServerRequests/CustomersApiServerRequests";
import { fetchSalon } from "../../../Api/ApiServerRequests/SalonApiServerRequests";
import {message} from "antd";
import { Theme } from "../../../Themes/Theme";

const PaymentDiscounts = () => {
  const navigate = useNavigate();
  const salonName = localStorage.getItem("salonName");
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [useLoyalPoints, setUseLoyalPoints] = useState(false);
  const customerNumber = localStorage.getItem("customerPhoneNumber");
  const [loyaltyPoints, setLoyaltyPoints] = useState(null);
  const [loyaltySettings, setLoyaltySettings] = useState(null); 
  const [couponCode, setCouponCode] = useState("");

  useEffect(() => {
    const pathArray = window.location.pathname.split("/");
    const potentialSalonName = pathArray[1];
    if (potentialSalonName!==salonName) {
      localStorage.setItem("salonName", potentialSalonName);
        window.location.href = `/${potentialSalonName}`;
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchSalon(salonName, "salon_url_name");
        // console.log("response", response);

        if (response.data.status === 200) {
        setLoyaltySettings(response.data.data.loyalty_settings);        }
        // console.log("response", response.data.data.help);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [salonName]);

  useEffect(()=>{
    const fetchCustomerInformation = async () => {
      try {
        const queryParams = new URLSearchParams({
          phone: customerNumber,
          salon_url_name: salonName
        });
        const response = await getCustomerAddress(queryParams);
        if (response.data.status===200) {
          setLoyaltyPoints(response.data.data[0].loyalty_points);
          // console.log("loyalty", response.data.data[0].loyalty_points)
        } else {
          console.error("Invalid data format: expected array");
        }
      } catch (error) {
        console.error("Error fetching booking information:", error);
      }
    };

    fetchCustomerInformation();
  }, [customerNumber])

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  const handleUseLoyalPointsChange = (e) => {
    setUseLoyalPoints(e.target.checked);
    // console.log(e.target.checked)
  };

  const handleBookings = async()=>{
      const queryParams = new URLSearchParams({
        phone: customerNumber,
        salon_url_name: salonName,
      });

      const data = {
        services: JSON.parse(sessionStorage.getItem("services")),
        products: JSON.parse(sessionStorage.getItem("products")),
        stylist_email: sessionStorage.getItem("stylist_email"),
        address: JSON.parse(sessionStorage.getItem("selectedAddress")).address,
        date: sessionStorage.getItem("date"),
        start_time: sessionStorage.getItem("start_time"),
        end_time: sessionStorage.getItem("end_time"),
        loyalty_points: useLoyalPoints,
        offer_code: couponCode || "",
      };
      const response = await createBookings(queryParams, data);

      if (response.data.status === 201) {
        message.success("Booked successfully");
        sessionStorage.removeItem("services");
        sessionStorage.removeItem("products");
        sessionStorage.removeItem("date");
        sessionStorage.removeItem("start_time");
        sessionStorage.removeItem("end_time");
        sessionStorage.removeItem("selectedAddress");
        sessionStorage.removeItem("stylist_email");
        sessionStorage.setItem("booked_id", response.data.data);
        navigate(`/${salonName}/booking-details`);
      } else {
        message.error(response.data.data);
      }
  }

  return (
    <>
      <MainContainer>
        <div>
          <Header>
            <div
              onClick={() => {
                navigate(-1);
              }}
              style={{ display: "flex" }}
            >
              <LeftChevronIcon />
            </div>
            <div>{"Payment Discounts"}</div>
          </Header>
        </div>

        <DetailsContainer>
          <Radio.Group
            onChange={handlePaymentMethodChange}
            value={paymentMethod}
          >
            <Radio value={"applePay"}>Apple Pay</Radio>
          </Radio.Group>
        </DetailsContainer>

        <DetailsContainer>
          <ProductName>Offer code</ProductName>
          <CouponContainer>
            <CouponCode>
            <CouponCodeInput
                placeholder="Enter Code"
                value={couponCode}
                onChange={(e) => setCouponCode(e.target.value)}
              />
              {/* <Button
                text={"Add"}
                type={"primary"}
                width={"50%"}
                height={"34px"}
                onClick={() => {
                  navigate(`/${salonName}/booking-details`);
                }}
              /> */}
            </CouponCode>
          </CouponContainer>
        </DetailsContainer>

        <YellowContainer>
          <LoyalpointContainer>
            <Checkbox
              checked={useLoyalPoints}
              onChange={handleUseLoyalPointsChange}
            >
              Use Loyal Points
            </Checkbox>
            {loyaltySettings &&(<PointsText>
              <CrownIcon />{loyaltySettings.points_earned} Loyal point = {loyaltySettings.amount_spent} SAR
            </PointsText>)}
          </LoyalpointContainer>
          <LineSeparator />
          <PointsContainer>
            <PointsText>Your Loyal Points</PointsText>
            <Points>
              <CrownIcon />
              {loyaltyPoints || 0}
            </Points>
          </PointsContainer>
        </YellowContainer>

        {/* <MandatoryContainer>
          <DisclaimerIcon />
          50% Advance payment is mandatory to book blaze saloon
        </MandatoryContainer> */}

        <SubmitContainer>
          <Button
            text={"Continue"}
            type={"primary"}
            width={"100%"}
            height={"44px"}
            onClick={handleBookings}
            backgroundColor={Theme.salonColor}
          />
        </SubmitContainer>
      </MainContainer>
    </>
  );
};
export default PaymentDiscounts;
