import React from "react";

const HeadSetIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
    <path d="M13.7148 22.0899H18.0006C19.4208 22.0899 20.572 20.9388 20.572 19.5185V16.9471" stroke="#6D31ED" stroke-width="2.05714" stroke-miterlimit="10" stroke-linecap="square"/>
    <path d="M7.71345 12.6614L3.42773 12.6614L3.42773 16.9471C3.42773 17.8942 4.19488 18.6614 5.14202 18.6614H7.71345L7.71345 12.6614Z" stroke="#6D31ED" stroke-width="2.05714" stroke-miterlimit="10" stroke-linecap="square"/>
    <path d="M20.5709 12.6614L16.2852 12.6614V18.6614H18.8566C19.8037 18.6614 20.5709 17.8942 20.5709 16.9471V12.6614Z" stroke="#6D31ED" stroke-width="2.05714" stroke-miterlimit="10" stroke-linecap="square"/>
    <path d="M20.5706 12.6614V11.8042C20.5706 7.07025 16.7332 3.23282 11.9992 3.23282C7.26516 3.23282 3.42773 7.07025 3.42773 11.8042V12.6614" stroke="#6D31ED" stroke-width="2.05714" stroke-miterlimit="10" stroke-linecap="square"/>
    </svg>
  );
};

export default HeadSetIcon;
