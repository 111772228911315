import React from "react";

const ScissorsIcon = ({ isActive }) => {
  const strokeColor = isActive ? "#6D31ED" : "#171A1F";

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M9.42859 18.8571L16.0089 2.82857C16.8499 3.17394 17.5194 3.83925 17.87 4.67816C18.2205 5.51707 18.2235 6.46087 17.8783 7.302L16.0372 11.79C15.8192 12.3208 15.43 12.7635 14.9314 13.0476C14.4328 13.3317 13.8535 13.4409 13.2857 13.3577L11.7754 13.1383" stroke={strokeColor} strokeWidth="2.05714" strokeLinecap="square" strokeMiterlimit="10" />
      <path d="M14.5714 18.8571L7.99112 2.82857C7.57446 2.99957 7.19558 3.25099 6.87611 3.56847C6.55665 3.88594 6.30286 4.26325 6.12926 4.67883C5.95565 5.09442 5.86563 5.54013 5.86433 5.99052C5.86304 6.44091 5.95049 6.88713 6.1217 7.30371L7.96284 11.79C8.18077 12.3208 8.57001 12.7635 9.0686 13.0476C9.56719 13.3317 10.1465 13.4409 10.7143 13.3577L12.2246 13.1383" stroke={strokeColor} strokeWidth="2.05714" strokeLinecap="square" strokeMiterlimit="10" />
      <path d="M17.1428 21.4286C18.563 21.4286 19.7143 20.2773 19.7143 18.8571C19.7143 17.437 18.563 16.2857 17.1428 16.2857C15.7227 16.2857 14.5714 17.437 14.5714 18.8571C14.5714 20.2773 15.7227 21.4286 17.1428 21.4286Z" stroke={strokeColor} strokeWidth="2.05714" strokeLinecap="square" strokeMiterlimit="10" />
      <path d="M6.85713 21.4286C8.27729 21.4286 9.42856 20.2773 9.42856 18.8571C9.42856 17.437 8.27729 16.2857 6.85713 16.2857C5.43697 16.2857 4.28571 17.437 4.28571 18.8571C4.28571 20.2773 5.43697 21.4286 6.85713 21.4286Z" stroke={strokeColor} strokeWidth="2.05714" strokeLinecap="square" strokeMiterlimit="10" />
    </svg>
  );
};

export default ScissorsIcon;