import React, { useEffect, useState } from "react";
import { CancelIcon } from "../Sidebar/Sidebar.styled";
import LeftChevronIcon from "../../Assets/Svgs/LeftChevronIcon";
import {
  UpcomingBookingTitle,
  TitleBar,
  BookingId,
  Titles,
  SeperateLine,
  DetailsContainer,
  StylistDetails,
  StylistContainer,
  ButtonContainer,
  ChangeDateButton,
  ImageContainer,
  ProfileImage,
  StylistInfo,
  Name,
  DescriptionText,
  DetailContainer,
  CostContainer,
  ServiceType,
  AdvancedPayment,
  PaymentContainer,
  DateContainer,
  DownloadButton,
  Footer,
  UpcomingBooking,
} from "./BookingHistoryDetails.styled";
import {Rate, message } from "antd";
import TestingPDF from "../../Assets/PDF/Receipt.pdf";
import {
  getBookingsInformation,
} from "../../Api/ApiServerRequests/BookingsApiServerRequests";
import {
  getStylists,
  rateStylist,
} from "../../Api/ApiServerRequests/StylistsApiServerRequests";
import { useNavigate } from "react-router-dom";
import { handleDownloadReceipt } from "../../Helper/Helper";

const BookingHistoryDetails = ({ onCancel, activityId }) => {
  const [bookingInfo, setBookingInfo] = useState(null);
  const [stylistInfo, setStylistInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [ratingValue, setRatingValue] = useState(0);
  const navigate = useNavigate();
  const salonName = localStorage.getItem("salonName");

  useEffect(() => {
    const fetchBookingInformation = async () => {
      try {
        const booking_id = activityId;
        if (booking_id) {
          const response = await getBookingsInformation(
            booking_id,
            "booking id"
          );

          if (response.data.data && response.data.data.length > 0) {
            const bookingData = response.data.data[0];
            setBookingInfo(bookingData);
            if (bookingData.stylist_email) {
              const stylistResponse = await getStylists(
                "stylist email",
                bookingData.stylist_email
              );
              setStylistInfo(stylistResponse.data.data);
            }
          }
        }
      } catch (error) {
        console.error("Error fetching booking information:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBookingInformation();
  }, [activityId]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "2-digit",
    });
  };

  const formatUnixDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  const formatTime = (timeArray) => {
    if (timeArray.length !== 2) return "";

    const [startHour, endHour] = timeArray;
    const startTimePeriod = startHour >= 12 ? "PM" : "AM";
    const endTimePeriod = endHour >= 12 ? "PM" : "AM";

    const formatHour = (hour) => {
      if (hour === 0) return 12;
      if (hour > 12) return hour - 12;
      return hour;
    };

    const formattedStartHour = formatHour(startHour % 12);
    const formattedEndHour = formatHour(endHour % 12);

    const startTime = `${formattedStartHour
      .toString()
      .padStart(2, "0")}:00 ${startTimePeriod}`;
    const endTime = `${formattedEndHour
      .toString()
      .padStart(2, "0")}:00 ${endTimePeriod}`;

    return `${startTime} - ${endTime}`;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleRateChange = (value) => {
    setRatingValue(value);
  };

  const handleRateButtonClick = async() => {
    if (ratingValue === 0) {
      message.error("Please select a rating");
    } else {
      try {
        const queryParams = new URLSearchParams({
          stylist_email: stylistInfo.stylist_email,
          booking_id: bookingInfo._id,
          ratings: ratingValue,
        });
        const response = await rateStylist(queryParams);
        if(response.data.status===201){
          setBookingInfo(prevBookingInfo => ({
            ...prevBookingInfo,
            rated: true,
            ratings_received: ratingValue
          }));
          message.success(response.data.data);
        }else{
          message.error(response.data.data);
        }
      }catch (error) {
        console.error("Error submitting data:", error);
      }
      // console.log("Rating submitted:", ratingValue);
    }
  };

  const handleTipClick =()=>{
    navigate(`/${salonName}/tipping`);
  }

  return (
    <>
      <UpcomingBooking>
        <TitleBar>
          <CancelIcon onClick={onCancel}>
            <LeftChevronIcon />
          </CancelIcon>
          <Titles>
            <UpcomingBookingTitle>
              {formatDate(bookingInfo.date)},
              {formatTime(bookingInfo.selected_time)}
            </UpcomingBookingTitle>
            <BookingId>Booking Id: {bookingInfo._id}</BookingId>
          </Titles>
        </TitleBar>

        <DetailsContainer>
          <SeperateLine></SeperateLine>
          <StylistDetails>
            {bookingInfo && bookingInfo.stylist_email && stylistInfo && (
              <StylistContainer>
                <ImageContainer>
                  <ProfileImage
                    src={stylistInfo.file_path}
                    alt="Profile Image"
                  />
                </ImageContainer>
                <StylistInfo>
                  <Name>{stylistInfo.name}</Name>
                  <DescriptionText>{stylistInfo.role}</DescriptionText>
                  <ChangeDateButton onClick={handleTipClick}>Tip</ChangeDateButton>
                </StylistInfo>
              </StylistContainer>
            )}
            <ButtonContainer>
              {bookingInfo && bookingInfo.rated ? (

                <ButtonContainer>
                  <Name>Rated</Name>
                  <Rate allowHalf value={bookingInfo.ratings_received} />
                </ButtonContainer>
              ) : (
                <ButtonContainer>
                  <Name>Rate work</Name>
                  <Rate
                    allowHalf
                    defaultValue={0}
                    onChange={handleRateChange}
                  />
                  <ChangeDateButton onClick={handleRateButtonClick}>
                    Rate it
                  </ChangeDateButton>
                </ButtonContainer>
              )}
            </ButtonContainer>
          </StylistDetails>
          <SeperateLine></SeperateLine>

          <DetailContainer>
            <Name>Location</Name>
            <DescriptionText>{bookingInfo.address.address}</DescriptionText>
          </DetailContainer>
          <SeperateLine></SeperateLine>

          <DetailContainer>
            <Name>Bill Details</Name>
            <CostContainer>
              {bookingInfo &&
                bookingInfo.services.map((service) => (
                  <ServiceType key={service.name}>
                    <DescriptionText>{service.name}</DescriptionText>
                    <DescriptionText>SAR {service.total_price}</DescriptionText>
                  </ServiceType>
                ))}

              {bookingInfo &&
                bookingInfo.products.map((products) => (
                  <ServiceType key={products.name}>
                    <DescriptionText>{products.name}</DescriptionText>
                    <DescriptionText>
                      SAR {products.total_price}
                    </DescriptionText>
                  </ServiceType>
                ))}
            </CostContainer>
          </DetailContainer>
          <SeperateLine></SeperateLine>

          <DetailContainer>
            <CostContainer>
              <ServiceType>
                <Name>Total</Name>
                <Name>SAR {bookingInfo.total_price}</Name>
              </ServiceType>
            </CostContainer>
          </DetailContainer>
          <SeperateLine></SeperateLine>

          <DetailContainer>
            <CostContainer>
              <ServiceType>
                <Name>Total payable</Name>
                <AdvancedPayment color="rgba(131,83,226,1)">
                  SAR {bookingInfo.total_price}
                </AdvancedPayment>
              </ServiceType>
            </CostContainer>
          </DetailContainer>
          <SeperateLine></SeperateLine>

          <DetailContainer>
            <Name>Payment</Name>
            <CostContainer>
              <ServiceType>
                <PaymentContainer>
                  <DescriptionText>
                    {bookingInfo.payment_data.source}
                  </DescriptionText>
                  <DateContainer>
                    {formatUnixDate(bookingInfo.payment_data.created_at)}
                  </DateContainer>
                </PaymentContainer>
                <DescriptionText>SAR {bookingInfo.total_price}</DescriptionText>
              </ServiceType>
            </CostContainer>
          </DetailContainer>

          <Footer>
            <DownloadButton onClick={handleDownloadReceipt}>
              Download invoice
            </DownloadButton>
          </Footer>
        </DetailsContainer>
      </UpcomingBooking>
    </>
  );
};

export default BookingHistoryDetails;
