import React from "react";

const BrandingIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
    <path d="M18.8569 6.66138L5.14258 6.66138" stroke="#6D31ED" stroke-width="2.05714" stroke-miterlimit="10" stroke-linecap="square"/>
    <path d="M4.28516 11.8042L4.28516 22.0899H11.9994L11.9994 16.9471L16.2852 16.9471V22.0899H19.7137V11.8042" stroke="#6D31ED" stroke-width="2.05714" stroke-miterlimit="10" stroke-linecap="square"/>
    <path d="M18.858 6.66139L21.4294 10.09L21.4294 11.8042L2.57227 11.8042L2.57227 10.09L5.14369 6.66139L5.14369 3.23282L18.858 3.23282V6.66139Z" stroke="#6D31ED" stroke-width="2.05714" stroke-miterlimit="10" stroke-linecap="square"/>
    <path d="M9.42885 14.3757H6.85742L6.85742 16.9471H9.42885V14.3757Z" fill="#6D31ED"/>
    </svg>
  );
};

export default BrandingIcon;
