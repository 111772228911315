import React, { useState, useEffect } from "react";
import { Modal, Button, message } from "antd";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { Theme } from "../../Themes/Theme";

const MapPopup = ({ visible, onClose, onLocationSelect }) => {
  const [currentLocation, setCurrentLocation] = useState(null);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY,
  });

  const handleMarkerDragEnd = (e) => {
    const { latLng } = e;
    const latitude = latLng.lat();
    const longitude = latLng.lng();
    setCurrentLocation({ lat: latitude, lng: longitude });
    onLocationSelect(latitude, longitude);
  };

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const currentLat = position.coords.latitude;
          const currentLng = position.coords.longitude;
          setCurrentLocation({ lat: currentLat, lng: currentLng });
          onLocationSelect(currentLat, currentLng);
        },
        (error) => {
          console.error("Error getting current location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    if (visible) {
      getCurrentLocation();
    }
  }, [visible]);

  const handleOk = () => {
    if (currentLocation) {
      onClose();
    } else {
      message.error("No location selected.");
    }
  };

  const handleClose = () => {
    setCurrentLocation(null);
    onLocationSelect(null,null);
      onClose();
  };

  return (
    <Modal
      visible={visible}
      title="Select Location"
      onCancel={handleClose}
      footer={[
        <Button key="cancel" onClick={handleClose}>
          Cancel
        </Button>,
        // <Button key="current" onClick={getCurrentLocation}>
        //   Get Current Location
        // </Button>,
        <Button key="ok" type="primary" onClick={handleOk} style={{ marginTop: '10px', backgroundColor:`${Theme.salonColor}` }}>
          OK
        </Button>,
      ]}
    >
      {isLoaded && !loadError ? (
        <GoogleMap
          mapContainerStyle={{ width: "100%", height: "300px" }}
          center={currentLocation || { lat: 40.7128, lng: -74.006 }}
          zoom={currentLocation ? 14 : 4}
        >
          {currentLocation && (
            <Marker
              position={currentLocation}
              draggable={true}
              onDragEnd={handleMarkerDragEnd}
            />
          )}
        </GoogleMap>
      ) : (
        <p>Error loading Google Maps</p>
      )}
    </Modal>
  );
};

export default MapPopup;
