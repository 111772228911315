import React, { useEffect, useState } from "react";
import { DetailsContainer, Header, MainContainer, SubmitContainer } from "../BookingDetails/BookingDetails.styled";
import { useNavigate } from "react-router-dom";
import Button from "../../../Components/Button/Button";
import { BookingConfirmationText, ConfirmationContainer } from "./Tipping.styled";
import { Theme } from "../../../Themes/Theme";
import { Slider, Input, message } from "antd";
import { DescriptionText, ImageContainer, Name, ProfileImage, SeperateLine, StylistContainer, StylistDetails, StylistInfo } from "../../../Modules/BookingHistoryDetails/BookingHistoryDetails.styled";
import defaultImage from "../../../Assets/Images/default_image.png";

const Tipping = () => {
  const navigate = useNavigate();
  const salonName = localStorage.getItem("salonName");
  const [tipValue, setTipValue] = useState(1);

  useEffect(() => {
    const pathArray = window.location.pathname.split("/");
    const potentialSalonName = pathArray[1];
    if (potentialSalonName!==salonName) {
      localStorage.setItem("salonName", potentialSalonName);
        window.location.href = `/${potentialSalonName}`;
    }
  }, []);

  const handleSliderChange = (value) => {
    setTipValue(value);
  };

  const handleInputChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setTipValue(value);
  };

  const handleTipClick=()=>{
    if(tipValue<1 || tipValue>50){
      message.error("Tip range is 1 to 50");
      return;
    }
    navigate(`/${salonName}/tipping-confirmation`);
  }

  return (
    <MainContainer>
      <div>
        <Header>
          <div>{"Tipping"}</div>
        </Header>
      </div>

      <ConfirmationContainer>
        <BookingConfirmationText>Select your tipping amount</BookingConfirmationText>
      </ConfirmationContainer>

      <div style={{ margin: "20px 0", width: "80%", marginLeft: "10%" }}>
        <Slider
          min={1}
          max={50}
          onChange={handleSliderChange}
          value={tipValue}
          trackStyle={{ backgroundColor: "black" }}
          handleStyle={{ borderColor: "black" }}
        />
        <Input
          type="number"
          value={tipValue}
          onChange={handleInputChange}
          style={{ marginTop: "10px", width: "100px" }}
        />
      </div>

      <DetailsContainer>
          <StylistDetails>
              <StylistContainer>
                <ImageContainer>
                  <ProfileImage
                    src={defaultImage}
                    alt="Profile Image"
                  />
                </ImageContainer>
                <StylistInfo>
                  <Name>{"Mani"}</Name>
                  <DescriptionText>{"SR stylist"}</DescriptionText>
                </StylistInfo>
              </StylistContainer>
          </StylistDetails>
          </DetailsContainer>

      <SubmitContainer>
        <Button
          text={"Tip it"}
          type={"primary"}
          width={"100%"}
          height={"44px"}
          onClick={handleTipClick}
          backgroundColor={Theme.salonColor}
        />
      </SubmitContainer>
    </MainContainer>
  );
};

export default Tipping;
