import React, { useEffect, useState } from "react";
import { CancelIcon, SidebarTitle, TitleBar } from "../Sidebar/Sidebar.styled";
import LeftChevronIcon from "../../Assets/Svgs/LeftChevronIcon";
import UpcomingBookingDetails from "../UpcomingBookingDetails/UpcomingBookingDetails";
import {
  ButtonContainer,
  CouponCodeContainer,
  CouponContainer,
  CouponDescription,
  CouponName,
  Coupons,
  ReferContainer,
} from "./ReferAndEarn.styled";
import CopyIcon from "../../Assets/Svgs/CopyIcon";
import ReferIcon from "../../Assets/Svgs/ReferIcon";

const ReferAndEarn = ({ onCancel }) => {
  const [showDetails, setShowDetails] = useState(false);
  const salonName=localStorage.getItem("salonName")

  const handleCancel = () => {
    setShowDetails(false);
  };

  useEffect(() => {
    const pathArray = window.location.pathname.split("/");
    const potentialSalonName = pathArray[1];
    if (potentialSalonName!==salonName) {
      localStorage.setItem("salonName", potentialSalonName);
        window.location.href = `/${potentialSalonName}`;
    }
  }, []);

  if (showDetails) {
    return <UpcomingBookingDetails onCancel={handleCancel} />;
  }

  return (
    <>
      <TitleBar>
        <CancelIcon onClick={onCancel}>
          <LeftChevronIcon />
        </CancelIcon>
        <SidebarTitle>Refer & Earn</SidebarTitle>
      </TitleBar>
      <Coupons>
        <CouponContainer>
          <CouponName>Get 100 Loyal points = 100 SAR</CouponName>
          <CouponDescription>
            When your friend completes his/her first booking with us
          </CouponDescription>
          <ReferContainer>
            <CouponCodeContainer>
              WELCOME60
              <CopyIcon textToCopy="WELCOME60" color="rgba(109,49,237,1)" />
            </CouponCodeContainer>
            <CouponCodeContainer>
              Expires on: 30-5-2024
            </CouponCodeContainer>
            <ButtonContainer>
              <ReferIcon />
              Refer now
            </ButtonContainer>
          </ReferContainer>
        </CouponContainer>
      </Coupons>
    </>
  );
};

export default ReferAndEarn;
