import React from 'react';
import LoginForm from '../../../src/Modules/LoginForm/LoginForm';
import {LoginFormContainer,Header} from './LoginPage.styled';


const LoginPage = () => {

  return (
    <LoginFormContainer>
       <Header> 
        Login
      </Header>     
      <LoginForm mode="Login" />
    </LoginFormContainer>
  );
};

export default LoginPage;
