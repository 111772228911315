import React from "react";

const LeftChevronIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
<path d="M14.167 4.58992L12.8261 3.249L5.32611 10.749L12.8261 18.249L14.167 16.9081L8.00794 10.749L14.167 4.58992Z" fill="#323743"/>
</svg>
  );
};

export default LeftChevronIcon;
