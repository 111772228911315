import styled from "styled-components";
import { Theme } from "../../Themes/Theme";
import { Fonts } from "../../Fonts/Fonts";

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${Fonts.primaryFont};
  font-size: 32px;
  font-weight: 400;
  line-height: 48px;
  color: ${Theme.primaryBlack};
`;

export const ForgotPasswordContainer = styled.div`
  display: flex;
  width:100%;
  padding: 20px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
`;

export const StyledButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: ${Theme.primaryColor};
  color: #fff;
  border: none;
  cursor: pointer;
`;