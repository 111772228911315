import React from "react";

const ProfileIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
<path d="M17.9907 20.108C17.8749 18.0611 16.7075 17.4114 15.0001 16.8423C13.6955 16.4077 13.3364 15.038 13.2378 14.3111" stroke="#6D31ED" stroke-width="2.05714" stroke-miterlimit="10"/>
<path d="M10.7615 14.3103C10.6647 15.0337 10.3124 16.4051 9.0001 16.8423C7.29267 17.4114 6.12353 18.0594 6.00781 20.1063" stroke="#6D31ED" stroke-width="2.05714" stroke-miterlimit="10"/>
<path d="M11.9999 14.5409C10.1064 14.5409 8.57129 13.0057 8.57129 11.1123L8.57129 10.2551C8.57129 8.36171 10.1064 6.82657 11.9999 6.82657C13.8933 6.82657 15.4284 8.36171 15.4284 10.2551V11.1123C15.4284 13.0057 13.8933 14.5409 11.9999 14.5409Z" stroke="#6D31ED" stroke-width="2.05714" stroke-miterlimit="10" stroke-linecap="square"/>
<path d="M11.9999 22.2552C17.2071 22.2552 21.4284 18.0338 21.4284 12.8266C21.4284 7.61933 17.2071 3.39801 11.9999 3.39801C6.7926 3.39801 2.57129 7.61933 2.57129 12.8266C2.57129 18.0338 6.7926 22.2552 11.9999 22.2552Z" stroke="#6D31ED" stroke-width="2.05714" stroke-miterlimit="10" stroke-linecap="square"/>
</svg>
  );
};

export default ProfileIcon;
