import React from "react";

const MenuIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
    <path d="M2.57129 12.8645L21.4284 12.8645" stroke="#171A1F" stroke-width="2.05714" stroke-miterlimit="10" stroke-linecap="square"/>
    <path d="M2.57129 6.86455L21.4284 6.86455" stroke="#171A1F" stroke-width="2.05714" stroke-miterlimit="10" stroke-linecap="square"/>
    <path d="M2.57129 18.8645L21.4284 18.8645" stroke="#171A1F" stroke-width="2.05714" stroke-miterlimit="10" stroke-linecap="square"/>
    </svg>
  );
};

export default MenuIcon;
