import styled from "styled-components";
import { Fonts } from "../../../Fonts/Fonts";
import { Theme } from "../../../Themes/Theme";

export const GettingStartedContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StylistContainer = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

export const StylistScroller = styled.div`
  height: 100px;
  display: flex;
  flex-direction: row;
  gap: 30px;
  overflow-x: auto;
  padding: 30px;
`;

export const StylistTitle = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: ${Theme.primaryBlack};
  margin-left: 20px;
`;

export const StylistName = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 9px;
  font-weight: 400;
  line-height: 14px;
  color: rgba(144, 149, 161, 1);
`;

export const StylistWrapper = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

export const StylistImage = styled.img`
  width: 100%;
  height: 100%;
  /* border-radius: 50%; */
  object-fit: cover;
  border: 2px solid #fff;
`;


export const Stylist = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
