import React, { useState } from "react";
import { UserPermissionContainer } from "./UserPermissions.styled";
import Button from "../../Components/Button/Button";
import { Modal, Table, Popconfirm } from "antd";
import {
  DescriptionTextarea,
  WarningText,
} from "../SalonStylists/SalonStylists.styled";
import {
  ShopNameContainer,
  ShopNameDescription,
  ShopNameInput,
} from "../SalonServices/SalonServices.styled";

const Error = ({ message }) => <WarningText>{message}</WarningText>;

const UserPermissions = () => {
  const [isModalVisible, setModalVisibility] = useState(false);
  const [userName, setUserName] = useState("");
  const [userNameValidationError, setUserNameValidationError] = useState(false);
  const [userNameCountError, setUserNameCountError] = useState(false);
  const [description, setDescription] = useState("");
  const [descriptionValidationError, setDescriptionValidationError] =
    useState(false);
  const [roleValidationError, setRoleValidationError] = useState(false);
  const [role, setRole] = useState("");
  const [roleNameCountError, setRoleNameCountError] = useState(false);
  const [userDescriptionCountError, setUserDescriptionCountError] =
    useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [emailValidationError, setEmailValidationError] = useState(false);
  const [userPassword, setUserPassword] = useState("");
  const [passwordValidationError, setPasswordValidationError] = useState(false);
  
  const [users, setUsers] = useState([
    {
      key: "1",
      name: "John Doe",
      email: "user1@example.com",
      status: "Active",
    },
    {
      key: "2",
      name: "Jane Smith",
      email: "user2@example.com",
      status: "Inactive",
    },
  ]);

  const handleContactAdminClick = () => {
    setModalVisibility(true);
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const closeModal = () => {
    setUserName("");
    setDescription("");
    setRole("");
    setUserNameValidationError(false);
    setUserNameCountError(false);
    setUserDescriptionCountError(false);
    setDescriptionValidationError(false);
    setRoleValidationError(false);
    setModalVisibility(false);
    setRoleNameCountError(false);
    setEmailValidationError(false);
    setPasswordValidationError(false);
    setUserEmail("");
    setUserPassword("");
  };

  const handleOkButtonClick = async () => {
    if (!userName) {
      setUserNameValidationError(true);
      return;
    }

    if (!role) {
      setRoleValidationError(true);
      return;
    }

    if (!validateEmail(userEmail)) {
      setEmailValidationError(true);
      return;
    } else {
      setEmailValidationError(false);
    }

    if (!userPassword) {
      setPasswordValidationError(true);
      return;
    } else {
      setPasswordValidationError(false);
    }

    if (!description) {
      setDescriptionValidationError(true);
      return;
    }

    const userStatus = role === "Admin" ? "Active" : role === "Editor" ? "Inactive" : role;

    const newUser = {
      key: (users.length + 1).toString(),
      email: userEmail,
      status: userStatus,
    };

    setUsers([...users, newUser]);
    closeModal();
  };

  const handleRemoveUser = (key) => {
    setUsers(users.filter(user => user.key !== key));
  };

  const handleRemoveAccess = (key) => {
    const updatedUsers = users.map(user => {
      if (user.key === key) {
        return { ...user, status: "Inactive" };
      }
      return user;
    });

    setUsers(updatedUsers);
  };

  const columns = [
    {
      title: "Name",  
      dataIndex: "name", 
      key: "name",
    },{
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Remove Access",
      key: "removeAccess",
      render: (text, record) => (
        <Popconfirm
          title="Are you sure you want to remove access?"
          onConfirm={() => handleRemoveAccess(record.key)}
        >
          <Button text={"Remove Access"} type="primary"></Button>
        </Popconfirm>
      ),
    },
    {
      title: "Remove User",
      key: "removeUser",
      render: (text, record) => (
        <Popconfirm
          title="Are you sure you want to remove this user?"
          onConfirm={() => handleRemoveUser(record.key)}
        >
          <Button text={"Remove User"} type="default"/>
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <UserPermissionContainer>
        <Button
          text={"Add User"}
          type={"primary"}
          onClick={handleContactAdminClick}
        />
      </UserPermissionContainer>
      <Modal
        title="Add User"
        visible={isModalVisible}
        onOk={handleOkButtonClick}
        onCancel={closeModal}
      >
        <ShopNameContainer>
          <ShopNameDescription>User Name</ShopNameDescription>
          <ShopNameInput
            value={userName}
            onChange={(e) => {
              const input = e.target.value;
              const maxLength = 30;

              if (input.length > maxLength) {
                setUserName(input.slice(0, maxLength));
                setUserNameCountError(true);
                setUserNameValidationError(false);
              } else {
                setUserName(input);
                setUserNameValidationError(false);
              }
            }}
          />
          {userNameValidationError && <Error message="Please enter the user name." />}
          {userNameCountError && (
            <Error message="Maximum characters allowed is 30." />
          )}
        </ShopNameContainer>

        <ShopNameContainer>
          <ShopNameDescription>Role</ShopNameDescription>
          <ShopNameInput
            value={role}
            onChange={(e) => {
              const input = e.target.value;
              const maxLength = 30;

              if (input.length > maxLength) {
                setRole(input.slice(0, maxLength));
                setRoleNameCountError(true);
                setRoleValidationError(false);
              } else {
                setRole(input);
                setRoleValidationError(false);
              }
            }}
          />
          {roleValidationError && (
            <Error message="Please enter a role of the user." />
          )}
          {roleNameCountError && (
            <Error message="Maximum characters allowed is 30." />
          )}
        </ShopNameContainer>

        <ShopNameContainer>
          <ShopNameDescription>User Email</ShopNameDescription>
          <ShopNameInput
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
          />
          {emailValidationError && <Error message="Please enter a valid email." />}
        </ShopNameContainer>

        <ShopNameContainer>
          <ShopNameDescription>User Password</ShopNameDescription>
          <ShopNameInput
            value={userPassword}
            onChange={(e) => setUserPassword(e.target.value)}
          />
          {passwordValidationError && (
            <Error message="Please enter a valid password." />
          )}
        </ShopNameContainer>

        <ShopNameContainer>
          <ShopNameDescription>Description</ShopNameDescription>
          <DescriptionTextarea
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
              setDescriptionValidationError(false);

              const input = e.target.value;
              const maxLength = 100;

              if (input.length > maxLength) {
                setDescription(input.slice(0, maxLength));
                setUserDescriptionCountError(true);
                setDescriptionValidationError(false);
              } else {
                setDescription(input);
                setDescriptionValidationError(false);
              }
            }}
          />
          {descriptionValidationError && (
            <Error message="Please enter a description." />
          )}
          {userDescriptionCountError && (
            <Error message="Maximum characters allowed is 100." />
          )}
        </ShopNameContainer>
      </Modal>

      <Table columns={columns} dataSource={users} />
    </>
  );
};

export default UserPermissions;
