import React from "react";

const UserPermissionIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
<path d="M11.142 13.5185C8.72145 13.5185 6.57431 14.0654 5.16345 14.5411C4.12202 14.8934 3.42773 15.8748 3.42773 16.9745L3.42773 20.3757H10.2849" stroke="#6D31ED" stroke-width="2.05714" stroke-miterlimit="10"/>
<path d="M11.1431 13.5185C8.77656 13.5185 6.85742 10.7422 6.85742 8.37568V7.51853C6.85742 5.15196 8.77656 3.23282 11.1431 3.23282C13.5097 3.23282 15.4288 5.15196 15.4288 7.51853V8.37568C15.4288 10.7422 13.5097 13.5185 11.1431 13.5185Z" stroke="#6D31ED" stroke-width="2.05714" stroke-miterlimit="10" stroke-linecap="square"/>
<path d="M14.5723 19.5185L16.2866 21.2328L20.5723 16.9471" stroke="#6D31ED" stroke-width="2.05714" stroke-miterlimit="10" stroke-linecap="square"/>
</svg>
  );
};

export default UserPermissionIcon;
