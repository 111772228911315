import React from "react";
import { StyledSwitchButton } from "./SwitchButton.styled";


const SwitchButton = ({checked, onChange}) => {
  return (
    <StyledSwitchButton
      checked={checked} 
      onChange={onChange}
    >
    </StyledSwitchButton>
  );
};

export default SwitchButton;
