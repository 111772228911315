import React, { useEffect, useState } from "react";
import {
  GettingStartedContainer,
  Stylist,
  StylistContainer,
  StylistImage,
  StylistName,
  StylistScroller,
  StylistTitle,
  StylistWrapper,
} from "./GettingStartedScreen.styled";
import OfferContainer from "../../../Components/OfferContainer/OfferContainer";
import ServiceAndProductCustomerDisplay from "../../../Modules/ServiceAndProductCustomerDisplay/ServiceAndProductCustomerDisplay";
import { getStylists } from "../../../Api/ApiServerRequests/StylistsApiServerRequests";
import { message } from "antd";
import { WelcomeBackText } from "../CustomerHomePage/CustomerHomePage.styled";

const GettingStartedScreen = () => {
  // localStorage.removeItem("role");
  // localStorage.removeItem("email");
  // localStorage.removeItem("customerPhoneNumber");
  const salonName = localStorage.getItem("salonName");
  const [stylistData, setStylistData] = useState([]);

  useEffect(() => {
    const pathArray = window.location.pathname.split("/");
    const potentialSalonName = pathArray[1];
    if (potentialSalonName!==salonName) {
      localStorage.setItem("salonName", potentialSalonName);
        window.location.href = `/${potentialSalonName}`;
    }
  }, []);

  useEffect(() => {
    async function fetchStylists() {
      try {
        const response = await getStylists("name", salonName);
        //console.log(response.status);
        //console.log("Stylists Response:", response);
        if (response.status === 200) {
          setStylistData(response.data.data);
        } else {
          message.error("Failed to fetch stylists");
        }
      } catch (error) {
        console.error("Error fetching stylists:", error);
        message.error("Failed to fetch stylists");
      }
    }

    fetchStylists();
  }, []);

  return (
    <GettingStartedContainer>
      {/* <CustomHeader menuIcon={false} /> */}

      <WelcomeBackText>Welcome to {salonName} salon</WelcomeBackText>
      <OfferContainer />
      <StylistContainer>
        <StylistTitle>Top Stylist</StylistTitle>
        <StylistScroller>
          {stylistData.map((stylist) => (
            <Stylist>
              <StylistWrapper>
              <StylistImage src={stylist.file_path} alt="Stylist" /></StylistWrapper>
              <StylistName>{stylist.name}</StylistName>
            </Stylist>
          ))}
        </StylistScroller>
      </StylistContainer>

      <ServiceAndProductCustomerDisplay type={"Getting Started"} />
    </GettingStartedContainer>
  );
};

export default GettingStartedScreen;
