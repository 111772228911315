import React, { useEffect, useState } from "react";
import {
  AllOfferContainer,
  OfferCodeContainer,
  OffersContainer,
  OfferInformtion,
} from "./OfferContainer.styled";
import CopyIcon from "../../Assets/Svgs/CopyIcon";
import { getOffer } from "../../Api/ApiServerRequests/OffersApiServerRequests";

const OfferContainer = () => {
  const [offers, setOffers] = useState([]);
  const salonName=localStorage.getItem("salonName");

  useEffect(() => {
    const fetchOffer = async () => {
      try {
        const queryParams = new URLSearchParams({
          salon_url_name: salonName,
        });
        const response = await getOffer(queryParams);
        if (response.data.status === 200) {
          setOffers(response.data.data);
        } else {
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } 
    };
    fetchOffer();
  }, [salonName]);

  return (
    <AllOfferContainer>

{offers &&
          offers.map((offer) => (
      <OffersContainer key={offer._id}>
        <OfferInformtion>{offer.offer_description}</OfferInformtion>
        <OfferCodeContainer>
          {offer.offer_code}
          <CopyIcon textToCopy={offer.offer_code} />
        </OfferCodeContainer>
      </OffersContainer>
          ))}
    </AllOfferContainer>
  );
};

export default OfferContainer;
