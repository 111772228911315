import styled, { css } from "styled-components";
import { Fonts } from "../../../Fonts/Fonts";
import { Theme } from "../../../Themes/Theme";
export const Header = styled.div`
  display: flex;
  align-items: center;
  font-family: ${Fonts.primaryFont};
  font-size: 20px;
  font-weight: 600;
  line-height: 48px;
  color: ${Theme.primaryBlack};
`;

export const AddressCard = styled.div`
  display: flex;
  margin-bottom: 20px;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  gap:10px;
`;


export const CardContainer = styled.div`
  display: flex;
  padding: 6px 22px;
  flex-direction: column;
`;

export const ImageContainer = styled.div`
  padding: 6px 20px;
  width: 120px;
  height: auto;
`;

export const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
`;

export const MainContainer = styled.div`
  margin-top: 10px;
  padding: 0px 20px;
  min-height: calc(100vh - 10vh);
`;

export const AddressFormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormInputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormTitle = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  color: #424856;
  margin-top: 14px;
`;

export const FormInput = styled.input`
  font-family: ${Fonts.primaryFont};
  font-size: 12px;
  font-weight: 400;
  background-color: #f3f4f6;
  border: 0px;
  border-radius: 4px;
  height: 30px;

  &::placeholder {
    font-family: ${Fonts.primaryFont};
    font-size: 12px;
    font-weight: 400;
    color: #bdc1ca;
    text-indent: 8px;
  }
`;

export const FormInputTextArea = styled.textarea`
  font-family: ${Fonts.primaryFont};
  font-size: 12px;
  font-weight: 400;
  background-color: #f3f4f6;
  border: 0px;
  border-radius: 4px;
  height: 80px;
  padding-top: 10px;
  resize: none;

  &::placeholder {
    font-family: ${Fonts.primaryFont};
    font-size: 12px;
    font-weight: 400;
    color: #bdc1ca;
    text-indent: 8px;
  }
`;

export const SubmitContainer = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
`;

export const AddAddressButton = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;
`;

export const UploadImageContainer = styled.div`
  width: 191px;
  height: 73px;
  background: rgba(243, 244, 246, 1);
  border-radius: 2px;
  border-width: 1px;
  border-color: ${Theme.primaryBlack};
  border-style: dashed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  img {
    border-radius: 2px;
  }
`;

export const HiddenFileInput = styled.input`
  display: none;
`;

export const ImageInput = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
`;

export const AddressLocation = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  color: ${Theme.salonColor};
  margin-top: 10px;
  /* margin-bottom: 10px; */
`;

export const LocationContainer = styled.div`
  display: flex;
  flex-direction: row;
  color: ${Theme.salonColor};

`;
