import React, { useState } from "react";
import { Checkbox, Button } from "antd";
import {
  Description,
  Heading,
  OptionsContainer,
} from "../PaymentSettings/PaymentSettings.styled";
import { Container } from "./PaymentMethod.styled";

const PaymentMethod = () => {
  const [checkedValues, setCheckedValues] = useState([]);

  const handleCheckboxChange = (checkedValues) => {
    setCheckedValues(checkedValues);
  };

  const handleSave = () => {
    // console.log("Selected values:", checkedValues);
  };

  return (
    <>
      <Container>
        <Heading>Payment Method</Heading>
        <Description>Select your payment methods.</Description>
        <OptionsContainer>
          <Checkbox.Group onChange={handleCheckboxChange}>
            <Checkbox value="Apple Pay">Apple Pay</Checkbox>
            <Checkbox value="Hyperpay">Hyperpay</Checkbox>
          </Checkbox.Group>
          <Button
            type="primary"
            style={{ background: "rgba(81,19,215,1)", marginLeft: 10 }}
            onClick={handleSave}
          >
            Save
          </Button>
        </OptionsContainer>
      </Container>
    </>
  );
};

export default PaymentMethod;
