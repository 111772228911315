import styled from "styled-components";
import { Theme } from "../../../Themes/Theme";
import { Fonts } from "../../../Fonts/Fonts";

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 35px;
`;

export const HeaderText = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 31px;
  font-weight: 400;
  line-height: 46px;
  color: ${Theme.primaryBlack};
`;

export const CancelIcon = styled.div`
  cursor: pointer;
`;

export const OTPContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
  gap: 30px;
`;

export const MobileNumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const MobileNumberText = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: rgba(144, 149, 161, 1);
`;

export const OTPBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* margin: 20px; */
  gap: 10px;
  margin-top: 40px;
`;

export const SubmitButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${Fonts.primaryFont};
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  color: rgba(255, 255, 255, 1);
  background: ${Theme.salonColor};
  opacity: 1;
  border: none;
  border-radius: 24px;
  height: 40px;
`;
