import React, { useEffect, useState } from "react";
import {
  ServiceAndProductContainer,
  ServiceAndProductScroller,
  ServiceAndProductTitle,
} from "./ServiceAndProductCustomerDisplay.styled";
import { getServices } from "../../Api/ApiServerRequests/ServicesApiServerRequests";
import { getProducts } from "../../Api/ApiServerRequests/ProductsApiServerRequests";
import ProductCard from "../../Components/ProductCard/ProductCard";
import SearchBox from "../../Components/SearchBox/SearchBox";

const ServiceAndProductCustomerDisplay = ({ type }) => {
  const [products, setProducts] = useState([]);
  const [services, setServices] = useState([]);
  const salonName = localStorage.getItem("salonName");
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (!salonName) return;

    getProducts("name", salonName)
      .then((response) => {
        setProducts(
          response.data.data.map((item) => ({
            ...item,
            itemType: "product",
          }))
        );
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
      });

    getServices("name", salonName)
      .then((response) => {
        setServices(
          response.data.data.map((item) => ({
            ...item,
            itemType: "service",
          }))
        );
      })
      .catch((error) => {
        console.error("Error fetching services:", error);
      });
  }, [salonName]);

  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredServices = services.filter((service) =>
    service.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <ServiceAndProductContainer>
      <SearchBox onChange={(e) => setSearchQuery(e.target.value)} />

      <ServiceAndProductTitle>Service & Products Offered</ServiceAndProductTitle>

      <ServiceAndProductScroller>
        {type === "Home" ? (
          <>
            <ProductCard
              data={filteredServices}
              cardType={"service"}
              url={`/${salonName}/cart`}
            />
            <ProductCard
              data={filteredProducts}
              cardType={"product"}
              url={`/${salonName}/cart`}
            />
          </>
        ) : (
          <>
            <ProductCard
              data={filteredServices}
              cardType={"service"}
              url={`/${salonName}/login`}
            />
            <ProductCard
              data={filteredProducts}
              cardType={"product"}
              url={`/${salonName}/login`}
            />
          </>
        )}
      </ServiceAndProductScroller>
    </ServiceAndProductContainer>
  );
};

export default ServiceAndProductCustomerDisplay;
