import React, { useState } from "react";
import { Modal, Button, Select, DatePicker, message } from "antd";
import moment from "moment";
import { CheckboxContainer, CancelIconContainer } from "../SalonSchdeule/SalonSchdeule.styled";
import CancelIcon from "../../Assets/Svgs/CancelIcon";
import { setSalonDefaultTiming } from "../../Api/ApiServerRequests/SalonApiServerRequests";
import { setSalonStylistDefaultTiming } from "../../Api/ApiServerRequests/StylistsApiServerRequests";
import { getUserEmails } from "../../Components/UserEmails/userEmails";
import { DefaultScheduleConatiner } from "./DefaultScheduleModal.styled";
import { Theme } from "../../Themes/Theme";

const DefaultScheduleModal = ({ visible, onClose, isStylist, stylistEmail }) => {
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const [dateRange, setDateRange] = useState([]);
  const [fromTime, setFromTime] = useState(null);
  const [toTime, setToTime] = useState(null);
  const owner_email = getUserEmails().salonOwnerEmail;

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
  };

  const handleFromTimeChange = (value) => {
    setFromTime(value);
    console.log("From time selected:", value);
  };

  const handleToTimeChange = (value) => {
    setToTime(value);
    console.log("To time selected:", value);
  };

  const convertTimeToNumber = (time) => {
    const [hour, period] = time.split(" ");
    let [hours, minutes] = hour.split(":").map(Number);
    if (period === "PM" && hours !== 12) {
      hours += 12;
    } else if (period === "AM" && hours === 12) {
      hours = 0;
    }
    return hours;
  };

  const handleOk = async () => {
    if (dateRange.length === 2) {
      const [start, end] = dateRange;

      if(!fromTime || !toTime){
        message.error("Please select valid time format");
        return;
      }
    
      if (start.isBefore(moment(), 'day') || end.isBefore(moment(), 'day')) {
        message.error("The selected date range includes past dates. Please choose a date range that starts from today or a future date.");
        return;
      }

      const startTimeNumber = convertTimeToNumber(fromTime);
      const endTimeNumber = convertTimeToNumber(toTime);

      if(startTimeNumber>endTimeNumber){
        message.error("Please select valid time format");
        return;
      }

      let response;

      if(isStylist){
        const queryParams = new URLSearchParams({
          stylist_email: stylistEmail,
          start_date: start.format("YYYY-MM-DD"),
          end_date: end.format("YYYY-MM-DD"),
          start_time: startTimeNumber,
          end_time: endTimeNumber,
        });
  
        response = await setSalonStylistDefaultTiming(queryParams);
      }else{
        const queryParams = new URLSearchParams({
          owner_email: owner_email,
          start_date: start.format("YYYY-MM-DD"),
          end_date: end.format("YYYY-MM-DD"),
          start_time: startTimeNumber,
          end_time: endTimeNumber,
        });
  
        response = await setSalonDefaultTiming(queryParams);
      }
      if(response.data.status===201){
        message.success(response.data.data)
      }else{
        message.error(response.data.data)
      }
      console.log(response);

      onClose();
      setDateRange([]);
      setFromTime(null);
      setToTime(null);
    } else {
      message.error("Please select a valid date range and time.");
    }
  };

  const handleCancel = () => {
    onClose();
    setDateRange([]);
    setFromTime(null);
    setToTime(null);
  };

  const getTimeOptions = () => {
    const timeOptions = [];
    for (let i = 0; i < 12; i++) {
      const hour = i === 0 ? 12 : i;
      timeOptions.push(
        <Option key={`${hour}:00 AM`} value={`${hour}:00 AM`}>{`${hour}:00 AM`}</Option>
      );
    }
    timeOptions.push(
      <Option key={`12:00 PM`} value={`12:00 PM`}>{`12:00 PM`}</Option>
    );
    for (let i = 1; i < 12; i++) {
      const hour = i === 0 ? 12 : i;
      timeOptions.push(
        <Option key={`${hour}:00 PM`} value={`${hour}:00 PM`}>{`${hour}:00 PM`}</Option>
      );
    }
    return timeOptions;
  };

  return (
    <Modal
      title="Set default timing"
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="OK"
      cancelText="Cancel"
      okButtonProps={{ style: { backgroundColor: Theme.primaryColor, borderColor: Theme.primaryColor } }}
    >
      <DefaultScheduleConatiner>
      <RangePicker
        style={{ marginRight: 16 }}
        onChange={handleDateRangeChange}
        value={dateRange}
      />
      <CheckboxContainer>
        <>
          <Select
            style={{ width: "100px" }}
            placeholder="From"
            value={fromTime}
            onChange={handleFromTimeChange}
          >
            {getTimeOptions()}
          </Select>
          <span style={{ margin: "0 5px" }}>to</span>
          <Select
            style={{ width: "100px" }}
            placeholder="To"
            value={toTime}
            onChange={handleToTimeChange}
          >
            {getTimeOptions()}
          </Select>
          <CancelIconContainer
            onClick={() => {
              setFromTime(null);
              setToTime(null);
            }}
          >
            <CancelIcon />
          </CancelIconContainer>
        </>
      </CheckboxContainer>
      </DefaultScheduleConatiner>
    </Modal>
  );
};

export default DefaultScheduleModal;
