import React from "react";

const CopyIcon = ({ textToCopy, color = "#15ABFF" }) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        // console.log("Text copied to clipboard:", textToCopy);
      })
      .catch((error) => {
        // console.error("Error copying text to clipboard:", error);
      });
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      onClick={copyToClipboard}
      style={{ cursor: "pointer" }}
    >
      <path
        d="M10.8 2.56277L2 2.56277L2 11.3628L10.8 11.3628L10.8 2.56277Z"
        stroke={color}
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4002 5.76279H14.0002L14.0002 14.5628L5.2002 14.5628V12.9628"
        stroke={color}
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CopyIcon;
