import styled from "styled-components";
import { Theme } from "../../Themes/Theme";
import { Fonts } from "../../Fonts/Fonts";

export const AddButton = styled.button`
  color: rgba(255, 255, 255, 1);
  background: ${Theme.salonColor};
  opacity: 1;
  border: none;
  border-radius: 4px;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 67px;
  height: 33px;
`;

export const ServiceAndProductScroller = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow-y: auto;
  height: 60vh;
  flex-shrink: 0;
`;

export const ServiceAndProductContainer = styled.div`
  width: 90%;
  margin-top: 30px;
`;

export const ServiceAndProductTitle = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: ${Theme.primaryBlack};
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  white-space: normal; 
  word-wrap: break-word; 
`;


export const ServiceAndProduct = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export const ServiceAndProductImage = styled.img`
  width: 40%;
  height: 100px;
`;

export const ServiceAndProductInfoContainer = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TitleBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const StarContainer = styled.div``;

export const TimeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: ${Fonts.primaryFont};
  font-size: 11px;
  font-weight: 400;
  opacity: 1;
  background: rgba(243, 244, 246, 1);
  width: 20%;
  padding: 5px;
  border-radius: 30px;
`;
