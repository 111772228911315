import styled from "styled-components";

export const Container = styled.div`
  display: flex;
`;

export const Sidebar = styled.div`
  padding: 20px;
`;

export const SidebarItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 35px;
  flex-direction: column;
  cursor: pointer;
  
  a {
    text-decoration: none;
  }
`;