import styled from "styled-components";
import { Fonts } from "../../../Fonts/Fonts";
import { Theme } from "../../../Themes/Theme";

export const Header = styled.div`
  display: flex;
  align-items: center;
  font-family: ${Fonts.primaryFont};
  font-size: 22px;
  font-weight: 400;
  line-height: 48px;
  color: ${Theme.primaryBlack};
`;


export const TotalItemContainer = styled.div`
    padding: 8px 20px;
`;

export const MainWrapper = styled.div`
    display: flex;
    background-color: ${Theme.salonColor};
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 4px;
`;

export const ErrorText = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${Theme.primaryRed};
`;

export const ItemContainer = styled.div`
  padding: 20px;
  gap: 10px;
  display: flex;
  flex-direction: column;
`;

export const ErrorContainer = styled.div`
  display: flex;
  justify-content: center; 
  padding: 0px 20px; 
  color: ${Theme.primaryRed}
`;

export const ServiceAndProductTitle = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: ${Theme.primaryBlack};
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  white-space: normal; 
  word-wrap: break-word; 
`;

export const SearchBarContainer = styled.div`
display: flex;
width:100%;
margin-bottom: 10px;
`;