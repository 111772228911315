import React from "react";

const DeleteIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="37"
      viewBox="0 0 36 37"
      fill="none"
    >
      <rect y="0.768921" width="36" height="36" rx="18" fill="#DE3B40" />
      <path
        d="M11.9502 16.0189L11.9502 25.3689C11.9502 26.2797 12.6894 27.0189 13.6002 27.0189L22.4002 27.0189C23.311 27.0189 24.0502 26.2797 24.0502 25.3689L24.0502 16.0189"
        stroke="white"
        stroke-width="1.1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.75 13.8189L26.25 13.8189"
        stroke="white"
        stroke-width="1.1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.25 13.8189L15.25 10.5189L20.75 10.5189V13.8189"
        stroke="white"
        stroke-width="1.1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18 18.2189L18 23.7189"
        stroke="white"
        stroke-width="1.1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.75 18.2189L20.75 23.7189"
        stroke="white"
        stroke-width="1.1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.25 18.2189L15.25 23.7189"
        stroke="white"
        stroke-width="1.1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default DeleteIcon;
