import React, { useEffect, useState } from "react";
import {
  ButtonContainer,
  CancelIcon,
  HeaderContainer,
  HeaderText,
  MobileNumberContainer,
  MobileNumberText,
  OTPBox,
  OTPContainer,
  SubmitButton,
} from "./CustomerLoginPage.styled";
import CrossIcon from "../../../Assets/Svgs/CrossIcon";
import { Input, Select, message } from "antd";
import { useNavigate } from "react-router-dom";
import {
  getCustomerOTP,
  verifyCustomerOTP,
} from "../../../Api/ApiServerRequests/CustomersApiServerRequests";

const { Option } = Select;

const CustomerLoginPage = () => {
//   localStorage.removeItem("role");
//   localStorage.removeItem("email");
// localStorage.removeItem("customerPhoneNumber");
  const [otpValues, setOtpValues] = useState(["", "", "", "", ""]);
  const otpInputs = Array.from({ length: 5 });

  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileNumberError, setMobileNumberError] = useState("");
  const [selectedCountryCode, setSelectedCountryCode] = useState("+1");
  const navigate = useNavigate();
  const salonName = localStorage.getItem("salonName");
  const [showOTP, setShowOTP] = useState(false);

  useEffect(() => {
    const pathArray = window.location.pathname.split("/");
    const potentialSalonName = pathArray[1];
    if (potentialSalonName!==salonName) {
      localStorage.setItem("salonName", potentialSalonName);
        window.location.href = `/${potentialSalonName}`;
    }
  }, []);

  const handleMobileNumberChange = (e) => {
    let value = e.target.value;
    if (!/^\d*$/.test(value)) {
      setMobileNumberError("Only numeric characters are allowed");
      return;
    } else if (value.length > 10) {
      value = value.slice(0, 10);
      setMobileNumberError("Only 10 digits are allowed");
    } else {
      setMobileNumberError("");
    }
    setMobileNumber(value);
  };

  const handleCountryCodeChange = (value) => {
    setSelectedCountryCode(value);
  };

  const handleOtpChange = (index, value) => {
    if (value === "" && index > 0) {
      const newOtpValues = [...otpValues];
      newOtpValues[index] = "";
      setOtpValues(newOtpValues);
      otpInputs[index - 1].focus();
      return;
    }

    const newOtpValues = [...otpValues];
    newOtpValues[index] = value;
    setOtpValues(newOtpValues);

    if (index < otpInputs.length - 1 && value !== "") {
      otpInputs[index + 1].focus();
    }
  };

  const handleCancel = () => {
    navigate(`/${salonName}/customer`);
  };

  const handleSubmit = async () => {
    const isAllDigitsEntered = otpValues.every((value) => value !== "");

    if (!isAllDigitsEntered) {
      message.error("Please enter the complete OTP");
      return;
    }
    const otp = otpValues.join("");
    const fullPhoneNumber = selectedCountryCode + mobileNumber;
    const queryParams = new URLSearchParams({
      phone: fullPhoneNumber,
      otp: otp,
      salon_url_name: salonName,
    });
    const result = await verifyCustomerOTP(queryParams);

    //console.log("verify Response:", result.data.status);
    if (result.data.status === 200) {
      message.success(result.data.data);
      localStorage.setItem("role","Salon Customer")
      localStorage.setItem("customerPhoneNumber",fullPhoneNumber)
      navigate(`/${salonName}/home`);
    } else if (result.data.status === 400) {
      message.error(result.data.data);
    }
  };

  const handleSendOTP = async () => {
    if (mobileNumber.length === 10) {
      const fullPhoneNumber = selectedCountryCode + mobileNumber;
      const queryParams = new URLSearchParams({
        phone: fullPhoneNumber,
        salon_url_name: salonName,
      });
      const result = await getCustomerOTP(queryParams);

      //console.log("login Response:", result);
      if (result.data.status === 200) {
        setShowOTP(true);
      } else {
        message.error("Can't able to send an OTP");
      }
    } else {
      setMobileNumberError("Please enter a 10-digit number");
      setShowOTP(false);
    }
  };

  return (
    <>
      <HeaderContainer>
        <HeaderText>Login/Signup</HeaderText>
        <CancelIcon onClick={handleCancel}>
          <CrossIcon />
        </CancelIcon>
      </HeaderContainer>

      <OTPContainer>
        <MobileNumberContainer>
          <MobileNumberText>Mobile No</MobileNumberText>
          <div style={{ display: "flex" }}>
            <Select
              defaultValue={selectedCountryCode}
              style={{ width: "21%", marginRight: "2px" }}
              dropdownStyle={{ minWidth: "auto" }}
              onChange={handleCountryCodeChange}
            >
              <Option value="+1">+1</Option>
              <Option value="+91">+91</Option>
              <Option value="+44">+44</Option>
            </Select>
            <Input
              style={{ flex: 1 }}
              placeholder="Enter your mobile number"
              value={mobileNumber}
              onChange={handleMobileNumberChange}
            />
          </div>
          {mobileNumberError && (
            <div style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
              {mobileNumberError}
            </div>
          )}
        </MobileNumberContainer>

        <SubmitButton onClick={handleSendOTP}>Send Otp</SubmitButton>

        {showOTP && (
          <>
            <MobileNumberContainer>
              <MobileNumberText>Enter OTP</MobileNumberText>
              <OTPBox>
                {otpInputs.map((_, index) => (
                  <Input
                    key={index}
                    style={{ width: "18%" }}
                    maxLength={1}
                    value={otpValues[index]}
                    onChange={(e) => handleOtpChange(index, e.target.value)}
                    ref={(input) => (otpInputs[index] = input)}
                  />
                ))}
              </OTPBox>
            </MobileNumberContainer>
            <ButtonContainer>
              {/*<ResendOTP>Resend OTP</ResendOTP>*/}
              <SubmitButton onClick={handleSubmit}>Submit</SubmitButton>
            </ButtonContainer>
          </>
        )}
      </OTPContainer>
    </>
  );
};

export default CustomerLoginPage;
