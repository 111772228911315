import React, { useEffect, useState } from "react";
import {
  Header,
  MainContainer,
  AddressFormContainer,
  FormInputContainer,
  FormTitle,
  FormInput,
  FormInputTextArea,
  SubmitContainer,
  AddAddressButton,
  AddressCard,
  CardContainer,
  ImageContainer,
  ActionButtonContainer,
  UploadImageContainer,
  HiddenFileInput,
  ImageInput,
  AddressLocation,
  LocationContainer,
} from "./AddressPage.styled";
import { useNavigate } from "react-router-dom";
import LeftChevronIcon from "../../../Assets/Svgs/LeftChevronIcon";
import Button from "../../../Components/Button/Button";
import { Card, Radio } from "antd";
import RadioButton from "../../../Components/RadioButton/RadioButton";
import PlusIcon from "../../../Assets/Svgs/PlusIcon";
import {
  ButtonContainer,
  LogoText,
  WarningText,
} from "../../../Modules/SalonStylists/SalonStylists.styled";
import { RemoveImage } from "../../../Modules/SalonServices/SalonServices.styled";
import { Modal, message } from "antd";
import {
  addCustomerAddress,
  deleteCustomerAddress,
  editCustomerAddress,
  getCustomerAddress,
} from "../../../Api/ApiServerRequests/CustomersApiServerRequests";
import { Button as AntButton } from "antd";
import LocationIcon from "../../../Assets/Svgs/LocationIcon";
import MapPopup from "../../../Components/MapPopup/MapPopup";
import { Theme } from "../../../Themes/Theme";

const RadioGroup = Radio.Group;

const AddressPage = ({ address }) => {
  const navigate = useNavigate();
  const salonName = localStorage.getItem("salonName");
  const [hasAddress, setHasAddress] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const customerNumber = localStorage.getItem("customerPhoneNumber");
  const [customerAddresses, setCustomerAddresses] = useState([]);
  const [selectedAddressID, setSelectedAddressID] = useState(null);
  const [name, setName] = useState("");
  const [addressText, setAddressText] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editingAddress, setEditingAddress] = useState(null);
  const [nameError, setNameError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [imageError, setImageError] = useState("");
  const [showMapPopup, setShowMapPopup] = useState(false);
  const [currentLocation, setCurrentLocation] = useState({
    latitude: null,
    longitude: null,
  });

  useEffect(() => {
    const pathArray = window.location.pathname.split("/");
    const potentialSalonName = pathArray[1];
    if (potentialSalonName!==salonName) {
      localStorage.setItem("salonName", potentialSalonName);
        window.location.href = `/${potentialSalonName}`;
    }
  }, []);

  useEffect(() => {
    async function fetchCustomerAddress() {
      const queryParams = new URLSearchParams({
        phone: customerNumber,
        salon_url_name: salonName,
      });
      try {
        const response = await getCustomerAddress(queryParams);
        setCustomerAddresses(response.data.data[0].address);
      } catch (error) {
        console.error("Error fetching customer address:", error);
      }
    }

    fetchCustomerAddress();
  }, []);

  const handleDelete = (addressId) => {
    setIsModalVisible(true);
    setSelectedAddressID(addressId);
  };

  const handleModalOk = async () => {
    const queryParams = new URLSearchParams({
      customer_phone: customerNumber,
      address_id: selectedAddressID,
    });
    try {
      const response = await deleteCustomerAddress(queryParams);
      if (response.data.status === 422) {
        message.error(response.data.data);
      } else if (response.data.status === 201) {
        message.success(response.data.data);
      }
    } catch (error) {
      message.error("Error deleting address. Please try again later.");
    }
    setIsModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleRadioChange = (e) => {
    setSelectedAddress(e.target.value);
    setHasAddress(true);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const maxSizeInBytes = 10 * 1024 * 1024;
      if (file.size > maxSizeInBytes) {
        setFileSizeError(true);
        return;
      } else {
        setFileSizeError(false);
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    setSelectedImage(null);
    setEditingAddress({ ...editingAddress, file_path: null });
  };

  const handleSubmit = async () => {
    if (hasAddress) {
      // console.log("setSelectedAddress", customerAddresses[selectedAddress]);
      // const queryParams = new URLSearchParams({
      //   phone: customerNumber,
      //   salon_url_name: salonName,
      // });

      // const data = {
      //   services: JSON.parse(sessionStorage.getItem("services")),
      //   products: JSON.parse(sessionStorage.getItem("products")),
      //   stylist_email: sessionStorage.getItem("stylist_email"),
      //   address: {
      //     name: customerAddresses[selectedAddress].name,
      //     address: customerAddresses[selectedAddress].address,
      //     phone: customerAddresses[selectedAddress].phone,
      //     file_path: customerAddresses[selectedAddress].file_path,
      //   },
      //   date: sessionStorage.getItem("date"),
      //   start_time: sessionStorage.getItem("start_time"),
      //   end_time: sessionStorage.getItem("end_time"),
      // };
      // const response = await createBookings(queryParams, data);

      // if (response.data.status === 201) {
      //   message.success("Booked successfully");
      //   sessionStorage.removeItem("services");
      //   sessionStorage.removeItem("products");
      //   sessionStorage.removeItem("date");
      //   sessionStorage.removeItem("start_time");
      //   sessionStorage.removeItem("end_time");
      //   sessionStorage.setItem("booked_id", response.data.data);
      //   navigate(`/${salonName}/booking-details`);
      // } else {
      //   message.error("Booking unsuccessful");
      // }

      const selectedAddressData = customerAddresses.find(
        (addr) => addr.id === selectedAddress
      );
      const addressObject = {
        name: selectedAddressData.name,
        address: selectedAddressData.address,
        phone: selectedAddressData.phone,
        file_path: selectedAddressData.file_path,
      };
      sessionStorage.setItem(
        "selectedAddress",
        JSON.stringify({ address: addressObject })
      );
      navigate(`/${salonName}/offers`);
    } 
    
    else {
      const phoneRegex = /^\+[0-9]+$/;
      if (!phoneRegex.test(phoneNumber)) {
        message.error(
          "Please provide a valid phone number with country code (e.g., +1234567890)."
        );
        return;
      }

      if (!address) {
        message.error("Please select one address.");
        return;
      }

      if (currentLocation.latitude && currentLocation.longitude) {
        if (!name || !phoneNumber || !selectedImage) {
          message.error("Please fill in all required fields.");
          return;
        }
      } else {
        if (!name || !addressText || !phoneNumber || !selectedImage) {
          message.error("Please fill in all required fields.");
          return;
        }
      }

      let queryParams;
      if (currentLocation.latitude && currentLocation.longitude) {
        queryParams = new URLSearchParams({
          customer_phone: customerNumber,
          salon_url_name: salonName,
          name: name,
          phone: phoneNumber,
          latitude: currentLocation.latitude,
          longitude: currentLocation.longitude,
        });
      } else {
        queryParams = new URLSearchParams({
          customer_phone: customerNumber,
          salon_url_name: salonName,
          name: name,
          address: addressText,
          phone: phoneNumber,
        });
      }

      const response = await fetch(selectedImage);
      const imageBlob = await response.blob();

      const formData = new FormData();
      formData.append("file", imageBlob);

      try {
        const response = await addCustomerAddress(queryParams, formData);
        if (response.data.status === 422) {
          message.error(response.data.data);
        } else if (response.data.status === 201) {
          message.success(response.data.data);
          navigate(`/${salonName}/address`);
        }
      } catch (error) {
        message.error("Error adding address. Please try again later.");
      }
    }
  };

  const handleEdit = (address) => {
    setEditingAddress(address);
    setIsEditModalVisible(true);
  };

  const handleEditModalCancel = () => {
    setIsEditModalVisible(false);
    setNameError("");
    setAddressError("");
    setPhoneNumberError("");
    setImageError("");
  };

  const handleEditSubmit = async () => {
    let isValid = true;
    let editedImage;
    if (!editingAddress.name) {
      setNameError("Please provide a name.");
      isValid = false;
    } else {
      setNameError("");
    }
    if (!currentLocation.latitude && !currentLocation.longitude) {
      if (!editingAddress.address) {
        setAddressError("Please provide an address.");
        isValid = false;
      } else {
        setAddressError("");
      }
    }
    if (!editingAddress.phone) {
      setPhoneNumberError("Please provide a phone number.");
      isValid = false;
    } else {
      setPhoneNumberError("");
    }
    if (!selectedImage && !editingAddress.file_path) {
      setImageError("Please add a photo landmark.");
      isValid = false;
    } else {
      setImageError("");
    }

    if (isValid) {
      // const queryParams = new URLSearchParams({
      //   customer_phone: customerNumber,
      //   address_id: editingAddress.id,
      //   name: editingAddress.name,
      //   address: editingAddress.address,
      //   phone: editingAddress.phone,
      // });

      let queryParams;
      if (currentLocation.latitude && currentLocation.longitude) {
        queryParams = new URLSearchParams({
          customer_phone: customerNumber,
          address_id: editingAddress.id,
          salon_url_name: salonName,
          name: editingAddress.name,
          phone: editingAddress.phone,
          latitude: currentLocation.latitude,
          longitude: currentLocation.longitude,
        });
      } else {
        queryParams = new URLSearchParams({
          customer_phone: customerNumber,
          address_id: editingAddress.id,
          salon_url_name: salonName,
          name: editingAddress.name,
          address: editingAddress.address,
          phone: editingAddress.phone,
        });
      }

      if (!selectedImage) {
        editedImage = await fetch(editingAddress.file_path);
      } else {
        editedImage = await fetch(selectedImage);
      }
      const imageBlob = await editedImage.blob();

      const formData = new FormData();
      formData.append("file", imageBlob);

      try {
        const response = await editCustomerAddress(queryParams, formData);
        // console.log("response", response.data.status);
        if (response.data.status === 201) {
          message.success("Changes updated successfully");
        } else {
          message.error("Changes not updated");
        }
      } catch (error) {
        message.error("Error adding address. Please try again later.");
      }
      setIsEditModalVisible(false);
    }
  };

  const handleNameChange = (e) => {
    const value = e.target.value;
    if (value.length <= 30) {
      setName(value);
      setNameError("");
    } else {
      setNameError(`Maximum limit is 30`);
    }
  };

  const handleAddressChange = (e) => {
    const value = e.target.value;
    if (value.length <= 100) {
      setAddressText(value);
      setAddressError("");
    } else {
      setAddressError(`Maximum limit is 100`);
    }
  };

  const handleLocationSelect = (latitude, longitude) => {
    setCurrentLocation({ latitude, longitude });
    // console.log("Selected Locatio:", { latitude, longitude });
    // setShowMapPopup(false);
  };

  const handleClearLocation = () => {
    setCurrentLocation({ latitude: null, longitude: null });
  };

  return (
    <>
      <MainContainer>
        {!address ? (
          <>
            <Header>
              <div
                onClick={() => {
                  navigate(-1);
                }}
                style={{ display: "flex" }}
              >
                <LeftChevronIcon />
              </div>
              <div>{"Select Location"}</div>
            </Header>
            <AddressCard>
              <RadioGroup
                onChange={(e) => handleRadioChange(e)}
                value={selectedAddress}
              >
                {customerAddresses &&
                  customerAddresses.map((addr) => (
                    <Card
                      key={addr.id}
                      style={{ width: "90vw", marginBottom: "10px" }}
                    >
                      <RadioButton value={addr.id} text={addr.name} />
                      <CardContainer>
                        <p>{`Address: ${addr.address}`}</p>
                        <p>{`Phone number: ${addr.phone}`}</p>
                      </CardContainer>
                      <ImageContainer>
                        <img
                          src={addr.file_path}
                          width="120px"
                          height="auto"
                          alt="Landmark image"
                        />
                      </ImageContainer>
                      <ActionButtonContainer>
                        <Button
                          text={"Edit"}
                          width={"100px"}
                          borderColor={"#F5F1FE"}
                          backgroundColor={"#F5F1FE"}
                          onClick={() => handleEdit(addr)}
                        />
                        <Button
                          text={"Delete"}
                          width={"100px"}
                          borderColor={"#FDF2F2"}
                          color={"#DE3B40"}
                          backgroundColor={"#FDF2F2"}
                          onClick={() => handleDelete(addr.id)}
                        />
                      </ActionButtonContainer>
                    </Card>
                  ))}
              </RadioGroup>
            </AddressCard>
            <AddAddressButton>
              <Button
                text={"Add new Address"}
                type={"default"}
                width={"100%"}
                borderColor={"#DEE1E6"}
                color={"#565D6D"}
                onClick={() => navigate(`/${salonName}/address/add`)}
              />
            </AddAddressButton>
          </>
        ) : (
          <>
            <Header>
              <div
                onClick={() => {
                  navigate(-1);
                }}
                style={{ display: "flex" }}
              >
                <LeftChevronIcon />
              </div>
              <div>{"Add New Address"}</div>
            </Header>
            <AddressFormContainer>
              <FormInputContainer>
                <FormTitle>Name</FormTitle>
                <FormInput
                  type="text"
                  placeholder="Enter your Name"
                  value={name}
                  onChange={handleNameChange}
                />
                {nameError && <WarningText>{nameError}</WarningText>}
                {!name && <WarningText>Please provide a name.</WarningText>}
              </FormInputContainer>
              {!currentLocation.latitude && !currentLocation.longitude && (
                <FormInputContainer>
                  <FormTitle>Address</FormTitle>
                  <FormInputTextArea
                    type="text"
                    placeholder="Enter Address"
                    value={addressText}
                    onChange={handleAddressChange}
                  />
                  {addressError && <WarningText>{addressError}</WarningText>}
                  {!addressText && (
                    <WarningText>Please provide an address.</WarningText>
                  )}
                </FormInputContainer>
              )}
              <AddressLocation onClick={() => setShowMapPopup(true)}>
                <LocationContainer>
                  <LocationIcon color={Theme.salonColor}/> Get my current location
                </LocationContainer>
              </AddressLocation>
              {currentLocation.latitude && currentLocation.longitude && (
                <>
                  <FormTitle>
                    Latitude: {currentLocation.latitude}, Longitude:
                    {currentLocation.longitude}
                  </FormTitle>
                  <Button
                    text={"Clear selection"}
                    type={"primary"}
                    width={"30%"}
                    height={"30px"}
                    onClick={handleClearLocation}
                  />
                </>
              )}
              <FormInputContainer>
                <FormTitle>Phone number</FormTitle>
                <FormInput
                  type="text"
                  placeholder="Enter your phone number with country code"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
                {!phoneNumber && (
                  <WarningText>Please provide a phone number.</WarningText>
                )}
              </FormInputContainer>
              <FormInputContainer>
                <FormTitle>Add photo Land mark</FormTitle>
                <UploadImageContainer>
                  {selectedImage ? (
                    <img
                      src={selectedImage}
                      alt="Selected Logo"
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "2px",
                      }}
                    />
                  ) : (
                    <>
                      <HiddenFileInput
                        id="fileInput"
                        type="file"
                        onChange={handleImageChange}
                        accept="image/*"
                      />
                      <ImageInput
                        onClick={() =>
                          document.getElementById("fileInput").click()
                        }
                      >
                        <PlusIcon />
                        <LogoText>Add photo</LogoText>
                      </ImageInput>
                    </>
                  )}
                </UploadImageContainer>
                {fileSizeError && (
                  <WarningText>Maximum file size is 10MB.</WarningText>
                )}
                <ButtonContainer>
                  <RemoveImage onClick={handleRemoveImage}>
                    Remove Image
                  </RemoveImage>
                </ButtonContainer>
              </FormInputContainer>
            </AddressFormContainer>
          </>
        )}
      </MainContainer>
      <SubmitContainer>
        <Button
          text={hasAddress ? "Continue" : "Save"}
          type={"primary"}
          width={"90%"}
          height={"44px"}
          onClick={handleSubmit}
          backgroundColor={Theme.salonColor}
        />
      </SubmitContainer>
      <Modal
        title="Confirm Delete"
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <p>Do you want to delete this address?</p>
      </Modal>
      <Modal
        title="Edit Address"
        visible={isEditModalVisible}
        onCancel={handleEditModalCancel}
        footer={[
          <AntButton key="cancel" onClick={handleEditModalCancel}>
            Cancel
          </AntButton>,
          <AntButton key="submit" type="primary" onClick={handleEditSubmit}>
            Save
          </AntButton>,
        ]}
      >
        <AddressFormContainer>
          <FormInputContainer>
            <FormTitle>Name</FormTitle>
            <FormInput
              type="text"
              placeholder="Enter your Name"
              value={editingAddress?.name || ""}
              onChange={(e) =>
                setEditingAddress({
                  ...editingAddress,
                  name: e.target.value.slice(0, 30),
                })
              }
            />
          </FormInputContainer>
          {nameError && <WarningText>{nameError}</WarningText>}
          {!currentLocation.latitude && !currentLocation.longitude && (
            <FormInputContainer>
              <FormTitle>Address</FormTitle>
              <FormInputTextArea
                type="text"
                placeholder="Enter Address"
                value={editingAddress?.address || ""}
                onChange={(e) =>
                  setEditingAddress({
                    ...editingAddress,
                    address: e.target.value.slice(0, 100),
                  })
                }
              />
            </FormInputContainer>
          )}
          {addressError && <WarningText>{addressError}</WarningText>}
          <AddressLocation onClick={() => setShowMapPopup(true)}>
            <LocationContainer>
              <LocationIcon color={Theme.salonColor} /> Get my current location
            </LocationContainer>
          </AddressLocation>
          {currentLocation.latitude && currentLocation.longitude && (
            <>
              <FormTitle>
                Latitude: {currentLocation.latitude}, Longitude:
                {currentLocation.longitude}
              </FormTitle>
              <Button
                text={"Clear selection"}
                type={"primary"}
                width={"30%"}
                height={"30px"}
                onClick={handleClearLocation}
              />
            </>
          )}
          <FormInputContainer>
            <FormTitle>Phone number</FormTitle>
            <FormInput
              type="text"
              placeholder="Enter your phone number with country code"
              value={editingAddress?.phone || ""}
              onChange={(e) => {
                const input = e.target.value;
                const sanitizedInput = input.replace(/[^0-9+]/g, "");
                const truncatedInput = sanitizedInput.slice(0, 15);
                setEditingAddress({ ...editingAddress, phone: truncatedInput });
              }}
            />
          </FormInputContainer>
          {phoneNumberError && <WarningText>{phoneNumberError}</WarningText>}
          <FormInputContainer>
            <FormTitle>Add photo Land mark</FormTitle>
            <UploadImageContainer>
              {selectedImage || editingAddress?.file_path ? (
                <img
                  src={selectedImage || editingAddress?.file_path}
                  alt="Selected Logo"
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "2px",
                  }}
                />
              ) : (
                <>
                  <HiddenFileInput
                    id="fileInput"
                    type="file"
                    onChange={handleImageChange}
                    accept="image/*"
                  />
                  <ImageInput
                    onClick={() => document.getElementById("fileInput").click()}
                  >
                    <PlusIcon />
                    <LogoText>Add photo</LogoText>
                  </ImageInput>
                </>
              )}
            </UploadImageContainer>

            {fileSizeError && (
              <WarningText>Maximum file size is 10MB.</WarningText>
            )}
            <ButtonContainer>
              <RemoveImage onClick={handleRemoveImage}>
                Remove Image
              </RemoveImage>
            </ButtonContainer>
          </FormInputContainer>
        </AddressFormContainer>
      </Modal>

      {showMapPopup && (
        <MapPopup
          visible={true}
          onClose={() => setShowMapPopup(false)}
          onLocationSelect={handleLocationSelect}
        />
      )}
    </>
  );
};

export default AddressPage;
