import React, { useState, useEffect } from "react";
import { Table, Modal, message } from "antd";
import moment from "moment";
import RequestTimeOff from "../RequestTimeOff/RequestTimeOff";
import {
  ButtonContainer,
  DeleteButton,
  HeadingRow,
  Name,
  NameRow,
  TimeOffRequest,
  TimeOffRequestButton,
} from "./EmployeeLeaveSchdeule.styled";
import {
  deleteLeaveRequest,
  getRequestLeave,
} from "../../Api/ApiServerRequests/StylistsApiServerRequests";
import { getUserEmails } from "../../Components/UserEmails/userEmails";

const EmployeeLeaveSchdeule = () => {
  const [activeTab, setActiveTab] = useState("Submitted");
  const [visible, setVisible] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState(null);

  const fetchData = async () => {
    const email = getUserEmails().salonEmployeeEmail;
    let status = "";

    switch (activeTab) {
      case "Submitted":
        status = "pending";
        break;
      case "Approved":
        status = "approved";
        break;
      case "Denied":
        status = "denied";
        break;
      default:
        status = "";
    }

    try {
      const response = await getRequestLeave(email, "stylist", status);
      // console.log("Response from GetRequestLeave:", response);
      if (response && response.data && response.data.data) {
        setDataSource(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [activeTab]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const onClose = () => {
    setVisible(false);
  };

  const showDeleteModal = (record) => {
    setDeleteModalVisible(true);
    setDeleteRecord(record);
  };

  const handleDeleteCancel = () => {
    setDeleteModalVisible(false);
  };

  const handleDeleteConfirm = async () => {
    try {
      const { stylist_email, start_date, end_date } = deleteRecord;
      const response = await deleteLeaveRequest(
        stylist_email,
        start_date,
        end_date
      );
      // console.log("Response from DeleteLeaveRequest:", response);
      if ((response.data.status = 201)) {
        message.success(response.data.data);
      } else {
        message.success(response.data.data);
      }
      fetchData();
    } catch (error) {
      console.error("Error deleting leave request:", error);
    }
    setDeleteModalVisible(false);
  };

  let columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
    },
    {
      title: "Applied On",
      dataIndex: "created_at",
      key: "created_at",
      render: (text, record) => moment(text).format("YYYY-MM-DD"),
    },
  ];

  if (activeTab === "Submitted") {
    columns.push({
      title: "Action",
      key: "action",
      render: (text, record) => (
        <ButtonContainer>
          <DeleteButton onClick={() => showDeleteModal(record)}>
            Delete
          </DeleteButton>
        </ButtonContainer>
      ),
    });
  }

  return (
    <>
      <HeadingRow>
        <NameRow>
          <Name
            active={activeTab === "Submitted"}
            onClick={() => handleTabClick("Submitted")}
          >
            Submitted
          </Name>
          <Name
            active={activeTab === "Approved"}
            onClick={() => handleTabClick("Approved")}
          >
            Approved
          </Name>
          <Name
            active={activeTab === "Denied"}
            onClick={() => handleTabClick("Denied")}
          >
            Denied
          </Name>
          <Name
            active={activeTab === "All"}
            onClick={() => handleTabClick("All")}
          >
            All
          </Name>
        </NameRow>

        <TimeOffRequest>
          <TimeOffRequestButton onClick={() => setVisible(true)}>
            Request time off
          </TimeOffRequestButton>
        </TimeOffRequest>
      </HeadingRow>

      <Table dataSource={dataSource} columns={columns} />

      <RequestTimeOff visible={visible} onClose={onClose} />

      <Modal
        title="Confirm Delete"
        visible={deleteModalVisible}
        onOk={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
      >
        <p>Are you sure you want to delete this leave request?</p>
      </Modal>
    </>
  );
};

export default EmployeeLeaveSchdeule;
