import TestingPDF from "../Assets/PDF/Receipt.pdf";

export const handleDownloadReceipt = () => {
    const link = document.createElement("a");
    link.href = TestingPDF;
    link.download = "Receipt.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const formatTimeRange = (startTime, endTime) => {
    const startHour = parseInt(startTime);
    const endHour = parseInt(endTime);
    const startPeriod = startHour < 12 ? "AM" : "PM";
    const endPeriod = endHour < 12 ? "AM" : "PM";

    const formattedStartHour = startHour <= 12 ? startHour : startHour - 12;
    const formattedEndHour = endHour <= 12 ? endHour : endHour - 12;

    return `${formattedStartHour} ${startPeriod} - ${formattedEndHour} ${endPeriod}`;
  };