import React, { useState } from "react";
import { Form, Input, Button, Upload, message, Modal } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Header, HeaderText } from "./AddSalon.styled";
import { createSalon } from "../../Api/ApiServerRequests/SalonApiServerRequests";
import { Theme } from "../../Themes/Theme";

const AddSalon = () => {
  const [form] = Form.useForm();
  const [previewImage, setPreviewImage] = useState(null);

  const onFinish = async (values) => {
    const password=values.password
    if (password.length < 12) {
      message.error({
        title: "Weak Password",
        content: "Password must be at least 12 characters long.",
      });
      return;
    }

    if (!/[a-z]/.test(password)) {
      message.error({
        title: "Weak Password",
        content: "Password must contain at least one lowercase letter.",
      });
      return;
    }

    if (!/[A-Z]/.test(password)) {
      message.error({
        title: "Weak Password",
        content: "Password must contain at least one uppercase letter.",
      });
      return;
    }

    if (!/\d/.test(password)) {
      message.error({
        title: "Weak Password",
        content: "Password must contain at least one number.",
      });
      return;
    }

    if (!/[@$!%*?&]/.test(password)) {
      message.error({
        title: "Weak Password",
        content: "Password must contain at least one special character.",
      });
      return;
    }

    try {
      const queryParams = new URLSearchParams({
        name: values.salonName,
        owner_name: values.salonOwner,
        owner_email: values.ownerEmail,
        description: values.description,
        password: password
      });
      const formData = new FormData();
      formData.append("file", values.image.fileList[0]?.originFileObj || null);

      const response = await createSalon(queryParams, formData);
      if (response.data.status === 200) {
        message.success("Your salon has been successfully added!");
        form.resetFields();
        setPreviewImage(null);
      } else {
        message.error("Failed to submit data to the server");
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  const beforeUpload = (file) => {
    const isImage = file.type.startsWith("image/");
    if (!isImage) {
      message.error("You can only upload image files!");
    } else {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
    return false;
  };

  return (
    <div>
      <Header>
        <HeaderText>Add a New Salon</HeaderText>
      </Header>
      <Form
        form={form}
        onFinish={onFinish}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        layout="vertical"
      >
        <Form.Item
          label="Salon Name"
          name="salonName"
          rules={[{ required: true, message: "Please enter Salon Name" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Salon Description"
          name="description"
          rules={[
            { required: true, message: "Please enter Salon description" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Salon Owner"
          name="salonOwner"
          rules={[{ required: true, message: "Please enter Salon owner Name" }]}
        >
          <Input
            onChange={(e) => {
              const sanitizedValue = e.target.value.replace(
                /[^a-zA-Z0-9\s]/g,
                ""
              );
              form.setFieldsValue({ salonOwner: sanitizedValue });
            }}
          />
        </Form.Item>

        <Form.Item
          label="Salon Owner's Email"
          name="ownerEmail"
          rules={[
            {
              required: true,
              type: "email",
              message: "Please enter a valid email address",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            { required: true, message: "Please enter password" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Upload Image"
          name="image"
          rules={[{ required: true, message: "Please upload an image" }]}
        >
          {previewImage && (
            <img
              src={previewImage}
              alt="Preview"
              style={{
                width: "288px",
                height: "239px",
                objectFit: "cover",
                marginRight: "16px",
                display: "flex",
                alignItems: "center",
                paddingBottom: "10px",
              }}
            />
          )}
          <Upload
            beforeUpload={beforeUpload}
            showUploadList={false}
            onRemove={() => {
              setPreviewImage(null);
            }}
          >
            <Button icon={<UploadOutlined />}>Upload Image</Button>
          </Upload>
        </Form.Item>

        <Form.Item style={{ textAlign: "right" }}>
          <Button style={{ marginRight: 8 }}>Cancel</Button>
          <Button type="primary" htmlType="submit" style={{ backgroundColor: Theme.primaryColor, borderColor: Theme.primaryColor}}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddSalon;
