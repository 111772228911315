import styled from "styled-components";
import { Fonts } from "../../Fonts/Fonts";
import { Theme } from "../../Themes/Theme";

export const SettingContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  background: rgba(249, 98, 62, 0.07);
  border-radius: 8px;
  border-width: 1px;
  border-color: rgba(249, 98, 62, 1);
  border-style: solid;
  padding: 10px;
`;

export const Settings = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SettingName = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: ${Theme.primaryRed};
`;

export const SettingDescription = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: ${Theme.primaryBlack};
`;
