import React from "react";

const GreenTick = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="74" height="75" viewBox="0 0 74 75" fill="none">
    <rect y="0.758667" width="74" height="74" rx="37" fill="#17A948"/>
    <path d="M19.7578 37.7586L31.8268 49.8276L54.2406 27.4138" stroke="white" stroke-width="2.06897" stroke-miterlimit="10" stroke-linecap="square"/>
    </svg>
  );
};

export default GreenTick;
