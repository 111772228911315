import React from "react";

const LocationIcon = ({ color = "#171A1F" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path d="M18.8569 9.99136C18.8569 14.1914 11.9997 21.1342 11.9997 21.1342C11.9997 21.1342 5.14258 14.1914 5.14258 9.99136C5.14258 5.61993 8.65686 3.13422 11.9997 3.13422C15.3426 3.13422 18.8569 5.61993 18.8569 9.99136Z" stroke={color} strokeWidth="2.05714" strokeLinecap="square"/>
      <path d="M12.0001 12.5628C13.4203 12.5628 14.5716 11.4115 14.5716 9.99135C14.5716 8.57119 13.4203 7.41992 12.0001 7.41992C10.58 7.41992 9.42871 8.57119 9.42871 9.99135C9.42871 11.4115 10.58 12.5628 12.0001 12.5628Z" stroke={color} strokeWidth="2.05714" strokeLinecap="square"/>
    </svg>
  );
};

export default LocationIcon;
