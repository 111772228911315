import React from "react";
import { Group, TextInput } from "./SearchBox.styled";
import SearchIcon from "../../Assets/Svgs/SearchIcon";

const SearchBox = ({onChange}) => {
  return (
    <>
      <Group>
        <SearchIcon />
        <TextInput type="text" placeholder="Search" onChange={onChange}/>
      </Group>
    </>
  );
};

export default SearchBox;
