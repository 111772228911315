import React from "react";

const BillingIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
    <path d="M4.71512 3.2328C3.53141 3.2328 2.57227 4.19195 2.57227 5.37566L2.57227 6.66137L13.7151 6.66137V5.37566C13.7151 4.19195 14.6743 3.2328 15.858 3.2328L4.71512 3.2328Z" stroke="#6D31ED" stroke-width="2.05714" stroke-miterlimit="10"/>
    <path d="M15.8574 3.2328C17.0411 3.2328 18.0003 4.19195 18.0003 5.37566L18.0003 15.2328" stroke="#6D31ED" stroke-width="2.05714" stroke-miterlimit="10"/>
    <path d="M6 6.66138L6 15.2328" stroke="#6D31ED" stroke-width="2.05714" stroke-miterlimit="10"/>
    <path d="M21.4294 15.2328L2.57227 15.2328L2.57227 22.0899L21.4294 22.0899V15.2328Z" stroke="#6D31ED" stroke-width="2.05714" stroke-miterlimit="10" stroke-linecap="square"/>
    <path d="M6 18.6614H6.85714" stroke="#6D31ED" stroke-width="2.05714" stroke-miterlimit="10" stroke-linecap="square"/>
    <path d="M10.2852 18.6614L17.9994 18.6614" stroke="#6D31ED" stroke-width="2.05714" stroke-miterlimit="10" stroke-linecap="square"/>
    </svg>
  );
};

export default BillingIcon;
