import styled from "styled-components";
import { Fonts } from "../../Fonts/Fonts";
import { Theme } from "../../Themes/Theme";

export const FindContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  width: 100%;
  justify-content: end;
`;

export const Header = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 23px; 
  font-weight: 400; 
  line-height: 34px; 
  color: rgba(23,26,31,1);
`;
