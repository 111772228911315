import React from "react";

const CrownIcon = ({ color }) => {
  const defaultColor = color || "#EFB034";
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
<path d="M11.9996 5.75032C12.7097 5.75032 13.2853 5.17469 13.2853 4.46461C13.2853 3.75453 12.7097 3.17889 11.9996 3.17889C11.2895 3.17889 10.7139 3.75453 10.7139 4.46461C10.7139 5.17469 11.2895 5.75032 11.9996 5.75032Z" fill={defaultColor}/>
<path d="M20.1431 9.17888C20.8532 9.17888 21.4289 8.60325 21.4289 7.89317C21.4289 7.18309 20.8532 6.60745 20.1431 6.60745C19.4331 6.60745 18.8574 7.18309 18.8574 7.89317C18.8574 8.60325 19.4331 9.17888 20.1431 9.17888Z" fill={defaultColor}/>
<path d="M3.857 9.17888C4.56708 9.17888 5.14272 8.60325 5.14272 7.89317C5.14272 7.18309 4.56708 6.60745 3.857 6.60745C3.14692 6.60745 2.57129 7.18309 2.57129 7.89317C2.57129 8.60325 3.14692 9.17888 3.857 9.17888Z" fill={defaultColor}/>
<path d="M11.9996 5.75032C12.7097 5.75032 13.2853 5.17469 13.2853 4.46461C13.2853 3.75453 12.7097 3.17889 11.9996 3.17889C11.2895 3.17889 10.7139 3.75453 10.7139 4.46461C10.7139 5.17469 11.2895 5.75032 11.9996 5.75032Z" stroke={defaultColor}stroke-width="2.05714" stroke-miterlimit="10" stroke-linecap="square"/>
<path d="M11.6486 5.69547L9.4286 13.4646L4.7666 8.80261" stroke={defaultColor}stroke-width="2.05714" stroke-miterlimit="10"/>
<path d="M4.20215 9.12573L6.85672 18.6074" stroke={defaultColor}stroke-width="2.05714" stroke-miterlimit="10"/>
<path d="M19.2336 8.80261L14.5716 13.4646L12.3516 5.69547" stroke={defaultColor}stroke-width="2.05714" stroke-miterlimit="10"/>
<path d="M17.1426 18.6074L19.7971 9.12573" stroke={defaultColor}stroke-width="2.05714" stroke-miterlimit="10"/>
<path d="M20.1431 9.17888C20.8532 9.17888 21.4289 8.60325 21.4289 7.89317C21.4289 7.18309 20.8532 6.60745 20.1431 6.60745C19.4331 6.60745 18.8574 7.18309 18.8574 7.89317C18.8574 8.60325 19.4331 9.17888 20.1431 9.17888Z" stroke={defaultColor}stroke-width="2.05714" stroke-miterlimit="10" stroke-linecap="square"/>
<path d="M3.857 9.17888C4.56708 9.17888 5.14272 8.60325 5.14272 7.89317C5.14272 7.18309 4.56708 6.60745 3.857 6.60745C3.14692 6.60745 2.57129 7.18309 2.57129 7.89317C2.57129 8.60325 3.14692 9.17888 3.857 9.17888Z" stroke={defaultColor}stroke-width="2.05714" stroke-miterlimit="10" stroke-linecap="square"/>
<path d="M19.7147 18.6075L4.28613 18.6075L4.28613 22.036L19.7147 22.036V18.6075Z" stroke={defaultColor}stroke-width="2.05714" stroke-miterlimit="10" stroke-linecap="square"/>
</svg>
  );
};

export default CrownIcon;
