import React from "react";

const WarningIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke="#C12126" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.00001 12C8.44184 12 8.80001 11.6418 8.80001 11.2C8.80001 10.7582 8.44184 10.4 8.00001 10.4C7.55818 10.4 7.20001 10.7582 7.20001 11.2C7.20001 11.6418 7.55818 12 8.00001 12Z" fill="#C12126"/>
        <path d="M8 4.4L8 8.8" stroke="#C12126" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
};

export default WarningIcon;

