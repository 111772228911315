import React, { useState } from "react";
import {
  Link,
  Route,
  Routes,
  useNavigate,
  Navigate,
  useLocation,
} from "react-router-dom";
import { Container, Sidebar, SidebarItem } from "./OwnerDashboardPage.styled";
import DashboardHomeIcon from "../../../Assets/Svgs/DashboardHomeIcon";
import ScissorsIcon from "../../../Assets/Svgs/ScissorsIcon";
import CouponIcon from "../../../Assets/Svgs/CouponIcon";
import MemberShipIcon from "../../../Assets/Svgs/MemberShipIcon";
import CalendarIcon from "../../../Assets/Svgs/CalendarIcon";
import StoreIcon from "../../../Assets/Svgs/StoreIcon";
import MoneyBag from "../../../Assets/Svgs/MoneyBagIcon";
import SettingsGearIcon from "../../../Assets/Svgs/SettingsGearIcon";
import Header from "../../../Modules/Header/Header";
import Bookings from "../../../Modules/SalonBookings/SalonBookings";
import Services from "../../../Modules/SalonServices/SalonServices";
import SalonOffers from "../../../Modules/SalonOffers/SalonOffers";
import SalonStylists from "../../../Modules/SalonStylists/SalonStylists";
import SalonSchdeule from "../../../Modules/SalonSchdeule/SalonSchdeule";
import SalonInventory from "../../../Modules/SalonInventory/SalonInventory";
import SalonTips from "../../../Modules/SalonTips/SalonTips";
import SalonSettings from "../../../Modules/SalonSettings/SalonSettings";
import StylistLeaveManagement from "../../../Modules/StylistLeaveManagement/StylistLeaveManagement";
import WarningPopup from "../../../Components/WarningPopup/WarningPopup";
import Urls from "../../../Urls/Urls";

const OwnerDashboardPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showWarning, setShowWarning] = useState(false);
  const OwnerRoute = ({ element }) => {
    const storedRoles = JSON.parse(localStorage.getItem("roles")) || {};
    const isOwner = storedRoles.hasOwnProperty("Salon Owner");

    return isOwner ? (
      element
    ) : (
      <>
        <Navigate to="/dashboard" />
        {setShowWarning(true)}
      </>
    );
  };

  const sidebarItems = [
    {
      icon: <DashboardHomeIcon isActive={location.pathname === Urls.home} />,
      text: "Home",
      path: Urls.home,
    },
    {
      icon: (
        <OwnerRoute
          element={
            <ScissorsIcon isActive={location.pathname === Urls.services} />
          }
        />
      ),
      text: "Catalog",
      path: Urls.services,
    },
    {
      icon: (
        <OwnerRoute
          element={<CouponIcon isActive={location.pathname === Urls.offers} />}
        />
      ),
      text: "Offers",
      path: Urls.offers,
    },
    {
      icon: (
        <OwnerRoute
          element={
            <MemberShipIcon isActive={location.pathname === Urls.stylists} />
          }
        />
      ),
      text: "Stylists",
      path: Urls.stylists,
    },
    {
      icon: (
        <OwnerRoute
          element={
            <CalendarIcon isActive={location.pathname === Urls.schedule} />
          }
        />
      ),
      text: "Schedule",
      path: Urls.schedule,
    },
    {
      icon: (
        <OwnerRoute
          element={
            <StoreIcon isActive={location.pathname === Urls.inventory} />
          }
        />
      ),
      text: "Inventory",
      path: Urls.inventory,
    },
    {
      icon: (
        <OwnerRoute
          element={<MoneyBag isActive={location.pathname === Urls.tips} />}
        />
      ),
      text: "Tips",
      path: Urls.tips,
    },
    {
      icon: (
        <OwnerRoute
          element={
            <SettingsGearIcon isActive={location.pathname === Urls.settings} />
          }
        />
      ),
      text: "Settings",
      path: Urls.settings,
    },
  ];

  const handleContainerClick = (path) => {
    navigate(path);
  };

  const handleHelpSetupIconClick = () => {
    navigate(Urls.settings + "/help-setup");
  };

  return (
    <>
      <Header
        handleHelpSetupClick={handleHelpSetupIconClick}
        user="Salon Owner"
      />
      <Container>
        <Sidebar>
          {sidebarItems.map((item, index) => (
            <SidebarItem
              key={index}
              isActive={location.pathname === item.path}
              onClick={() => handleContainerClick(item.path)}
            >
              {item.icon}
              <Link
                to={item.path}
                style={{
                  color: location.pathname === item.path ? "#6D31ED" : "black",
                  textDecoration: "none",
                }}
              >
                {item.text}
              </Link>
            </SidebarItem>
          ))}
        </Sidebar>

        <div style={{ flex: "1" }}>
          <Routes>
            <Route path="/" element={<Bookings />} />
            <Route path="/services" element={<Services />} />
            <Route path="/offers" element={<SalonOffers />} />
            <Route path="/stylists" element={<SalonStylists />} />
            <Route path="/schedule" element={<SalonSchdeule />} />
            <Route path="/inventory" element={<SalonInventory />} />
            <Route path="/tips" element={<SalonTips />} />
            <Route path="/settings/*" element={<SalonSettings />} />
            <Route
              path="/stylist-leave-management"
              element={<StylistLeaveManagement />}
            />
          </Routes>
        </div>
      </Container>
      <WarningPopup visible={showWarning} />
    </>
  );
};

export default OwnerDashboardPage;
