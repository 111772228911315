import React from "react";

const CouponIcon = ({ isActive }) => {
  const strokeColor = isActive ? "#6D31ED" : "#171A1F";
  const fillColor = isActive ? "#6D31ED" : "#171A1F";

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M18.8571 12C18.8571 10.5429 19.9714 9.42857 21.4286 9.42857V5.14286L2.57141 5.14286L2.57141 9.42857C4.02855 9.42857 5.14284 10.5429 5.14284 12C5.14284 13.4571 4.02855 14.5714 2.57141 14.5714L2.57141 18.8571L21.4286 18.8571V14.5714C19.9714 14.5714 18.8571 13.4571 18.8571 12Z" stroke={strokeColor} strokeWidth="2.05714" strokeLinecap="square" strokeMiterlimit="10" />
      <path d="M14.5714 9.42857L9.42859 14.5714" stroke={strokeColor} strokeWidth="2.05714" strokeLinecap="square" strokeMiterlimit="10" />
      <path d="M9.85713 10.2857C10.5672 10.2857 11.1428 9.71008 11.1428 9C11.1428 8.28992 10.5672 7.71429 9.85713 7.71429C9.14705 7.71429 8.57141 8.28992 8.57141 9C8.57141 9.71008 9.14705 10.2857 9.85713 10.2857Z" fill={fillColor} />
      <path d="M14.1428 16.2857C14.8529 16.2857 15.4285 15.7101 15.4285 15C15.4285 14.2899 14.8529 13.7143 14.1428 13.7143C13.4328 13.7143 12.8571 14.2899 12.8571 15C12.8571 15.7101 13.4328 16.2857 14.1428 16.2857Z" fill={fillColor} />
    </svg>
  );
};

export default CouponIcon;
