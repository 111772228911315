import React from "react";

const WalletIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
    <path d="M2.57227 8.37567L21.4294 8.37567" stroke="#6D31ED" stroke-width="2.05714" stroke-miterlimit="10" stroke-linecap="square"/>
    <path d="M2.57227 11.8042L21.4294 11.8042" stroke="#6D31ED" stroke-width="2.05714" stroke-miterlimit="10" stroke-linecap="square"/>
    <path d="M19.7151 20.3757L4.28655 20.3757C3.33941 20.3757 2.57227 19.6085 2.57227 18.6614L2.57227 6.66137C2.57227 5.71423 3.33941 4.94708 4.28655 4.94708L15.4294 4.94708L19.7151 4.94708C20.6623 4.94708 21.4294 5.71423 21.4294 6.66137L21.4294 18.6614C21.4294 19.6085 20.6623 20.3757 19.7151 20.3757Z" stroke="#6D31ED" stroke-width="2.05714" stroke-miterlimit="10" stroke-linecap="square"/>
    <path d="M6 16.0899H10.2857" stroke="#6D31ED" stroke-width="2.05714" stroke-miterlimit="10" stroke-linecap="square"/>
    <path d="M17.1426 16.0899H17.9997" stroke="#6D31ED" stroke-width="2.05714" stroke-miterlimit="10" stroke-linecap="square"/>
    </svg>
  );
};

export default WalletIcon;
