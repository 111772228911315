import React from "react";

const ReferIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
<path d="M7.0647 9.10947L5.5872 8.68707C5.53146 8.6712 5.48151 8.63952 5.44339 8.59586C5.40527 8.55221 5.38062 8.49845 5.3724 8.44107L5.2479 7.56957C5.56153 7.42631 5.82742 7.19597 6.01393 6.90597C6.20045 6.61596 6.29974 6.27848 6.3 5.93367V5.10927C6.30743 4.63195 6.13003 4.17023 5.80491 3.82068C5.47979 3.47112 5.03211 3.26079 4.5555 3.23367C4.31449 3.22624 4.07445 3.2673 3.84961 3.35443C3.62478 3.44155 3.41974 3.57296 3.24668 3.74085C3.07361 3.90874 2.93604 4.1097 2.84213 4.33178C2.74822 4.55386 2.69989 4.79255 2.7 5.03367L2.7 5.93367C2.70026 6.27848 2.79955 6.61596 2.98607 6.90597C3.17258 7.19597 3.43847 7.42631 3.7521 7.56957L3.6276 8.44107C3.61938 8.49845 3.59473 8.55221 3.55661 8.59586C3.51849 8.63952 3.46854 8.6712 3.4128 8.68707L1.9353 9.10947C1.80991 9.14527 1.69959 9.22094 1.62105 9.32503C1.5425 9.42913 1.50001 9.55597 1.5 9.68638L1.5 10.7337L7.5 10.7337V9.68638C7.49999 9.55597 7.4575 9.42913 7.37895 9.32503C7.30041 9.22094 7.19009 9.14527 7.0647 9.10947Z" stroke="white" stroke-width="0.72" stroke-miterlimit="10" stroke-linecap="square"/>
<path d="M8.70078 10.7337H10.5008V9.10228C10.5008 8.96843 10.4561 8.83841 10.3738 8.7329C10.2914 8.62739 10.1761 8.55244 10.0463 8.51998L8.29638 8.08258C8.23854 8.06809 8.18633 8.03669 8.14642 7.9924C8.1065 7.9481 8.08069 7.89291 8.07228 7.83388L7.94868 6.96958C8.26232 6.82631 8.5282 6.59598 8.71471 6.30597C8.90123 6.01596 9.00052 5.67849 9.00078 5.33368L9.00078 4.50928C9.00821 4.03196 8.83082 3.57024 8.50569 3.22068C8.18057 2.87113 7.73289 2.6608 7.25628 2.63368C6.92162 2.6229 6.59063 2.70604 6.30078 2.87368" stroke="white" stroke-width="0.72" stroke-miterlimit="10" stroke-linecap="square"/>
</svg>
  );
};

export default ReferIcon;
