import React from "react";

const MemberShipIcon = ({ isActive }) => {
  const strokeColor = isActive ? "#6D31ED" : "#171A1F";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 28 28"
      fill="none"
      style={{ cursor: "pointer" }}
    >
      
      <path
        d="M14 7.5C15.933 7.5 17.5 9.067 17.5 11C17.5 12.933 15.933 14.5 14 14.5C12.067 14.5 10.5 12.933 10.5 11C10.5 9.067 12.067 7.5 14 7.5ZM14 16.5C17.536 16.5 21 19.964 21 23.5H7C7 19.964 10.464 16.5 14 16.5Z"
        fill={strokeColor}
      />
    </svg>
  );
};

export default MemberShipIcon;
