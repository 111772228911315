import React from "react";
import { StyledButton } from "./Button.styled";

const Button = ({ text, type, width, height, borderColor, backgroundColor, color, onClick, icon }) => {
  return (
    <StyledButton
      type={type}
      width={width}
      height={height}
      borderColor={borderColor}
      backgroundColor={backgroundColor}
      color={color}
      onClick={onClick}
      icon={icon}
    >
      {icon && <img src={icon} alt="Icon" />}
      {text}
    </StyledButton>
  );
};

export default Button;
