import styled from "styled-components";
import { Theme } from "../../Themes/Theme";

export const Group = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid ${Theme.primaryBlack};
  gap: 10px;
  height: 30px;
  justify-content: center;
  align-items: center;
  padding-left: 4px;
  width:100%;
`;

export const TextInput = styled.input`
  width: 100%;
  border: none;
  outline: none;
`;
