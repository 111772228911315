import React from "react";
import {
  SalonSettingsContainer,
  SeparatorLine,
  Settings,
  SettingsContainer,
  SettingsDescription,
  SettingsHeader,
  SettingsName,
  SettingsNameText,
} from "./SalonSettings.styled";
import UserPermissionIcon from "../../Assets/Svgs/UserPermissionIcon";
import ReviewIcon from "../../Assets/Svgs/ReviewIcon";
import WalletIcon from "../../Assets/Svgs/WalletIcon";
import HeadSetIcon from "../../Assets/Svgs/HeadSetIcon";
import CrownIcon from "../../Assets/Svgs/CrownIcon";
import BrandingIcon from "../../Assets/Svgs/BrandingIcon";
import BillingIcon from "../../Assets/Svgs/BillingIcon";
import ShieldIcon from "../../Assets/Svgs/ShieldIcon";
import PaymentSettings from "../PaymentSettings/PaymentSettings";
import AdvancePaymentSettings from "../AdvancePaymentSettings/AdvancePaymentSettings";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PaymentMethod from "../PaymentMethod/PaymentMethod";
import HelpSetup from "../HelpSetup/HelpSetup";
import LoyaltyPoints from "../LoyaltyPoints/LoyaltyPoints";
import UserPermissions from "../UserPermissions/UserPermissions";
import ReviewSettings from "../ReviewSettings/ReviewSettings";
import BrandingSettings from "../BrandingSettings/BrandingSettings";
import BillingSettings from "../BillingSettings/BillingSettings";
import Urls from "../../Urls/Urls";

const SettingsItem = ({ icon, text, description, path }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(path);
  };

  return (
    <SettingsContainer onClick={handleClick}>
      <SettingsName>
        {icon}
        <SettingsNameText>{text}</SettingsNameText>
      </SettingsName>
      <SettingsDescription>{description}</SettingsDescription>
    </SettingsContainer>
  );
};

const SalonSettings = () => {
  const location = useLocation();

  const isPaymentSettingsRoute = location.pathname === Urls.paymentSettings;
  const isAdvancePaymentMethod = location.pathname === Urls.advancePaymentSettings;
  const isPaymentMethod = location.pathname === Urls.paymentMethod;
  const isHelpSetupRoute = location.pathname === Urls.helpSetup;
  const isLoyaltyPointsRoute = location.pathname === Urls.loyaltyPoints;
  const isUserPermissionRoute = location.pathname === Urls.userPermissions;
  const isReviewSettingsRoute = location.pathname === Urls.reviewSettings;
  const isBrandingSettingsRoute = location.pathname === Urls.brandingSettings;
  const isBillingSettingsRoute = location.pathname === Urls.billingSettings;

  let settingsHeaderText = "Settings";
  if (isAdvancePaymentMethod) {
    settingsHeaderText = (
      <>
        <Link to={Urls.settings}>Settings</Link> &gt;
        <Link to={Urls.paymentSettings}>Payment setting</Link>
        &gt; Advance Payment
      </>
    );
  } else if (isPaymentMethod) {
    settingsHeaderText = (
      <>
        <Link to={Urls.settings}>Settings</Link> &gt;
        <Link to={Urls.paymentSettings}>Payment setting</Link>
        &gt; Payment Method
      </>
    );
  } else if (isPaymentSettingsRoute) {
    settingsHeaderText = (
      <>
        <Link to={Urls.settings}>Settings</Link> &gt; Payment setting
      </>
    );
  } else if (isHelpSetupRoute) {
    settingsHeaderText = (
      <>
        <Link to={Urls.settings}>Settings</Link> &gt; Help Setup
      </>
    );
  } else if (isLoyaltyPointsRoute) {
    settingsHeaderText = (
      <>
        <Link to={Urls.settings}>Settings</Link> &gt; Loyalty points
      </>
    );
  } else if (isUserPermissionRoute) {
    settingsHeaderText = (
      <>
        <Link to={Urls.settings}>Settings</Link> &gt; User Permissions
      </>
    );
  } else if (isReviewSettingsRoute) {
    settingsHeaderText = (
      <>
        <Link to={Urls.settings}>Settings</Link> &gt; Review Settings
      </>
    );
  } else if (isBrandingSettingsRoute) {
    settingsHeaderText = (
      <>
        <Link to={Urls.settings}>Settings</Link> &gt; Branding Settings
      </>
    );
  } else if (isBillingSettingsRoute) {
    settingsHeaderText = (
      <>
        <Link to={Urls.settings}>Settings</Link> &gt; Billing Settings
      </>
    );
  }

  return (
    <>
      <SettingsHeader>{settingsHeaderText}</SettingsHeader>
      <SeparatorLine />

      <SalonSettingsContainer>
        {isAdvancePaymentMethod ? (
          <AdvancePaymentSettings />
        ) : isPaymentMethod ? (
          <PaymentMethod />
        ) : isPaymentSettingsRoute ? (
          <PaymentSettings />
        ) : isHelpSetupRoute ? (
          <HelpSetup />
        ) : isLoyaltyPointsRoute ? (
          <LoyaltyPoints />
        ) : isUserPermissionRoute ? (
          <UserPermissions />
        ) : isReviewSettingsRoute ? (
          <ReviewSettings />
        ) : isBrandingSettingsRoute ? (
          <BrandingSettings />
        ) : isBillingSettingsRoute ? (
          <BillingSettings />
        ) : (
          <>
            <Settings>
              <SettingsItem
                icon={<UserPermissionIcon />}
                text="User permission"
                description="Control access levels of employees"
                path={Urls.userPermissions}
              />
              <SettingsItem
                icon={<ReviewIcon />}
                text="Reviews"
                description="Manage Feedbacks of customers"
                path={Urls.reviewSettings}
              />
              <SettingsItem
                icon={<WalletIcon />}
                text="Payment settings"
                description="Setup payment setting & gateway"
                path={Urls.paymentSettings}
              />
              <SettingsItem
                icon={<HeadSetIcon />}
                text="Help setup"
                description="Manage help details"
                path={Urls.helpSetup}
              />
              <SettingsItem
                icon={<CrownIcon color={"#6D31ED"} />}
                text="Configure loyalty point"
                description="Manage loyal points"
                path={Urls.loyaltyPoints}
              />
              <SettingsItem
                icon={<BrandingIcon />}
                text="Branding"
                description="Setup logo, colour theme etc"
                path={Urls.brandingSettings}
              />
              <SettingsItem
                icon={<BillingIcon />}
                text="Billing"
                description="Monthly payment for product subscription"
                path={Urls.billingSettings}
              />
              <SettingsItem
                icon={<ShieldIcon />}
                text="Whitelabeling"
                description="Map your DNS records"
                path="#" // Update this path if necessary
              />
            </Settings>
          </>
        )}
      </SalonSettingsContainer>
    </>
  );
};

export default SalonSettings;
