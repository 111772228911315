import styled from "styled-components";
import { Fonts } from "../../Fonts/Fonts";

export const SettingsContainer = styled.div`
  background: rgba(255, 255, 255, 1);
  border-radius: 4px; 
  box-shadow: 0px 0px 1px rgba(23, 26, 31, 0.07),
    0px 0px 2px rgba(23, 26, 31, 0.12);
  border-radius: 4px;
  width: 30%;
  margin: 10px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  cursor: pointer;
`;

export const Heading = styled.div`
 font-family: ${Fonts.primaryFont};
  font-size: 18px; 
  line-height: 28px; 
  font-weight: 400; 
  color: rgba(23,26,31,1);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Description = styled.div`
  display: flex;
  font-family: ${Fonts.primaryFont};
  font-size: 14px; 
  line-height: 22px; 
  font-weight: 400; 
  color: rgba(23,26,31,1);
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  align-items: center;
`;
