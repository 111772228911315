import React from "react";

const SearchIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
<path d="M14.0001 14.5339L11.6001 12.1339" stroke="#171A1F" stroke-width="0.8" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.8 12.1339C9.45097 12.1339 11.6 9.98484 11.6 7.33387C11.6 4.68291 9.45097 2.53387 6.8 2.53387C4.14903 2.53387 2 4.68291 2 7.33387C2 9.98484 4.14903 12.1339 6.8 12.1339Z" stroke="#171A1F" stroke-width="0.8" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  );
};

export default SearchIcon;
