import styled from "styled-components";
import { Fonts } from "../../Fonts/Fonts";
import { Theme } from "../../Themes/Theme";

export const Header = styled.div`
  margin-top: 20px;
  width: 100%;
  height: 111px;
  background: rgba(255, 255, 255, 1);
  border-radius: 4px;
  box-shadow: 0px 0px 1px rgba(23, 26, 31, 0.07),
    0px 0px 2px rgba(23, 26, 31, 0.12);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const HeaderText = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 37px;
  font-weight: 400;
  line-height: 52px;
  color: ${Theme.primaryBlack};
`;
