import styled from "styled-components";
import { Fonts } from "../../../Fonts/Fonts";
import { Theme } from "../../../Themes/Theme";

export const ConfirmationContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 20px;
`;

export const BookingConfirmationText = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 20px; 
  line-height: 30px; 
  font-weight: 400; 
  color: ${Theme.salonColor};
`;

export const DetailsContainer = styled.div`
  width: 90%;
  background: rgba(255, 255, 255, 1);
  border-radius: 4px;
  border-width: 1px;
  border-color: rgba(222, 225, 230, 1);
  border-style: solid;
  padding: 10px;
  margin-top: 10px;
`;