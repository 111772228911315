import React, { useState, useEffect } from "react";
import { Table, Modal, message } from "antd";
import moment from "moment";
import {
  ButtonContainer,
  DeleteButton,
  HeadingRow,
  Name,
  NameRow,
} from "./StylistLeaveManagement.styled";

import {
  getRequestLeave,
  respondLeaveRequest,
} from "../../Api/ApiServerRequests/StylistsApiServerRequests";
import { EditButton } from "../EmployeeLeaveSchdeule/EmployeeLeaveSchdeule.styled";
import { getUserEmails } from "../../Components/UserEmails/userEmails";

const StylistLeaveManagement = () => {
  const [activeTab, setActiveTab] = useState("Submitted");
  const [dataSource, setDataSource] = useState([]);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showDenyModal, setShowDenyModal] = useState(false);
  const [selectedLeaveRequest, setSelectedLeaveRequest] = useState(null);

  const fetchData = async () => {
    const email = getUserEmails().salonOwnerEmail;
    let status = "";

    switch (activeTab) {
      case "Submitted":
        status = "pending";
        break;
      case "Approved":
        status = "approved";
        break;
      case "Denied":
        status = "denied";
        break;
      default:
        status = "";
    }

    try {
      const response = await getRequestLeave(email, "owner", status);
      //console.log("Response from GetRequestLeave:", response);
      if (response && response.data && response.data.data) {
        setDataSource(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [activeTab]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  let columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
    },
    {
      title: "Applied On",
      dataIndex: "created_at",
      key: "created_at",
      render: (text, record) => moment(text).format("YYYY-MM-DD"),
    },
  ];

  if (activeTab === "All") {
    columns.push({
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => text.charAt(0).toUpperCase() + text.slice(1),
    });
  }

  if (activeTab === "Submitted") {
    columns.push({
      title: "Action",
      key: "action",
      render: (text, record) => (
        <ButtonContainer>
          <EditButton
            onClick={() => {
              setSelectedLeaveRequest(record);
              setShowApproveModal(true);
            }}
          >
            Approve
          </EditButton>
          <DeleteButton
            onClick={() => {
              setSelectedLeaveRequest(record);
              setShowDenyModal(true);
            }}
          >
            Deny
          </DeleteButton>
        </ButtonContainer>
      ),
    });
  }

  const handleApprove = async () => {
    try {
      const response = await respondLeaveRequest(
        selectedLeaveRequest.stylist_email,
        selectedLeaveRequest.start_date,
        selectedLeaveRequest.end_date,
        true
      );
      if (response.data.status === 201) {
        //console.log("Approved Response", response);
        message.success("Time off request approved successfully");
        fetchData();
      } else {
        message.error("Time off request approval failed");
      }
    } catch (error) {
      console.error("Error approving leave request:", error);
      message.error("Time off request approval failed");
    } finally {
      setShowApproveModal(false);
      setSelectedLeaveRequest(null);
    }
  };

  const handleDeny = async () => {
    try {
      const response = await respondLeaveRequest(
        selectedLeaveRequest.stylist_email,
        selectedLeaveRequest.start_date,
        selectedLeaveRequest.end_date,
        false
      );
      //console.log("Denied Response", response);
      if (response.data.status === 201) {
        message.success("Time off request denied successfully");
        fetchData();
      } else {
        message.error("Time off request denial failed");
      }
    } catch (error) {
      console.error("Error denying leave request:", error);
      message.error("Time off request denial failed");
    } finally {
      setShowDenyModal(false);
      setSelectedLeaveRequest(null);
    }
  };

  return (
    <>
      <HeadingRow>
        <NameRow>
          <Name
            active={activeTab === "Submitted"}
            onClick={() => handleTabClick("Submitted")}
          >
            Submitted
          </Name>
          <Name
            active={activeTab === "Approved"}
            onClick={() => handleTabClick("Approved")}
          >
            Approved
          </Name>
          <Name
            active={activeTab === "Denied"}
            onClick={() => handleTabClick("Denied")}
          >
            Denied
          </Name>
          <Name
            active={activeTab === "All"}
            onClick={() => handleTabClick("All")}
          >
            All
          </Name>
        </NameRow>
      </HeadingRow>

      <Table dataSource={dataSource} columns={columns} />

      <Modal
        title="Approve Leave Request"
        visible={showApproveModal}
        onOk={handleApprove}
        onCancel={() => {
          setShowApproveModal(false);
          setSelectedLeaveRequest(null);
        }}
      >
        Are you sure you want to approve this leave request?
      </Modal>

      <Modal
        title="Deny Leave Request"
        visible={showDenyModal}
        onOk={handleDeny}
        onCancel={() => {
          setShowDenyModal(false);
          setSelectedLeaveRequest(null);
        }}
      >
        Are you sure you want to deny this leave request?
      </Modal>
    </>
  );
};

export default StylistLeaveManagement;
