import styled from "styled-components";
import { Theme } from "../../../Themes/Theme";
import { Fonts } from "../../../Fonts/Fonts";

export const CustomerWelcomePageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
`;

export const WelcomeTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap:0px;
  flex-direction: column;
  width: 70%;
`;

export const LogoContainer = styled.div`
  width: 100px;
  border-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 70%;
  gap: 20px;
`;

export const WelcomeText = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: rgba(66, 72, 86, 1);
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
`;

export const LoginButton = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${Fonts.primaryFont};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: ${Theme.salonColor};
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  border-radius: 3px;
  border-width: 1px;
  border-color: ${Theme.salonColor};
  border-style: solid;
  height: 33px;
`;

export const GetStartedButton = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${Fonts.primaryFont};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: #fff;
  background: ${Theme.salonColor};
  opacity: 1;
  border-radius: 3px;
  border-width: 1px;
  border-color: ${Theme.salonColor};
  border-style: solid;
  height: 33px;
`;

export const SalonNotAvailable = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;