import styled, { css } from "styled-components";
import { Fonts } from "../../../Fonts/Fonts";
import { Theme } from "../../../Themes/Theme";

export const Header = styled.div`
  display: flex;
  align-items: center;
  font-family: ${Fonts.primaryFont};
  font-size: 22px;
  font-weight: 400;
  line-height: 48px;
  color: ${Theme.primaryBlack};
  gap:10px;
`;

export const MainContainer = styled.div`
  padding: 0 20px;
  min-height: calc(100vh - 10vh);
  overflow: auto;
`;

export const ProfileContainer = styled.div`
  display: flex;
  margin-top: 12px;
  gap: 10px;
  width: 100%;
  overflow-x: auto;
  padding-bottom: 10px;
`;

export const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const RadioWrapper = styled.div`
  position: absolute;
  top: 2px;
  right: 0;
`;

export const MonthContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
  align-items: center;
`;

export const ChevronContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const DateContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  overflow-x: auto;
  gap: 8px;
`;

export const CalendarContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const TimeSlotContainer = styled.div`
  margin-top: 14px;
`;

export const CalendarWrapper = styled.div`
  display: flex;
  margin-top: 20px;
`;

export const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f8f9fa;
  padding: 0px 20px 10px;
  border-radius: 4px;
  color: #323743
    ${({ isSelected }) =>
      isSelected &&
      css`
        color: ${Theme.salonColor};
        border: 1.5px solid ${Theme.salonColor};
        background: #f5f1fe;
        border-radius: 4px;
      `};
`;

export const DateText = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 20px;
  font-weight: 600;
  line-height: 48px;
`;

export const WeekText = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 14px;
  font-weight: 400;
  color: #565d6d;
`;

export const TitleText = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 20px;
  font-weight: 600;
  line-height: 48px;
  color: #323743;
`;

export const StylistName = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 16px;
  font-weight: 600;
  color: #171a1f;
`;

export const StylistTitle = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 10px;
  font-weight: 400;
  color: #323743;
`;

export const TimeBadgeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 12px;
  width: 100%;
  height: ${({ hasWorkingHours }) => (hasWorkingHours ? "90px" : "auto")};
  overflow-y: ${({ hasWorkingHours }) =>
    hasWorkingHours ? "scroll" : "unset"};
`;

export const StylistContainer = styled.div`
  display: flex;
  align-items: start;
  margin-top: 12px;
  flex-direction: column;
`;

export const NoteText = styled.h5`
  font-family: ${Fonts.primaryFont};
  font-size: 11px;
  font-weight: 400;
  padding: 0px;
  margin: 0px;
  color: #171a1f;
`;

export const SubmitContainer = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
`;
