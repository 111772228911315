import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Input, message } from "antd";
import {
  FindContainer,
  // Header,
} from "./TrialMembership.styled";
import {
  deleteSalon,
  extendTrial,
  fetchSalon,
} from "../../Api/ApiServerRequests/SalonApiServerRequests";
import { revokeAccess } from "../../Api/ApiServerRequests/UsersApiServerRequests";
import { SearchContainer } from "../SalonServices/SalonServices.styled";
import SearchBox from "../../Components/SearchBox/SearchBox";
import { Theme } from "../../Themes/Theme";

const TrialMembership = () => {

  const [searchEmail, setSearchEmail] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [days, setDays] = useState("");
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteEmail, setDeleteEmail] = useState("");

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchSalon(true, "trial status");
        setData(response.data.data);
        setFilteredData(response.data.data)
      } catch (error) {
        console.error("Error fetching salon data:", error);
      }
    };

    fetchData();
  }, []);
  
  const handleExtendTrial = async (record) => {
    if (record.trial_membership_status === "Active") {
      console.log("Owner Email:", record.owner_email);
      const queryParams = new URLSearchParams({
        email: record.owner_email,
      });

      try {
        const response = await revokeAccess(queryParams);

        if (response.data.status === 201) {
          message.success(response.data.data);
          const updatedData = data.map((row) =>
            row.owner_email === record.owner_email
              ? {
                  ...row,
                  trial_membership_status: "Inactive",
                }
              : row
          );

          setData(updatedData);
        } else {
          message.error(response.data.data);
        }
      } catch (error) {
        console.error("Error extending trial:", error);
        message.error("An error occurred while revoking access.");
      }
    } else {
      setModalData(record);
      setIsModalVisible(true);
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setDays("");
  };

  const handleModalSave = async () => {
    console.log("Days:", days);
    console.log("Owner Email:", modalData.owner_email);

    const queryParams = new URLSearchParams({
      owner_email: modalData.owner_email,
      trial_duration: days,
    });

    try {
      const response = await extendTrial(queryParams);

      if (response.data.status === 201) {
        message.success(response.data.data);
        const updatedData = data.map((row) =>
          row.name === modalData.name
            ? {
                ...row,
                trial_membership_status: "Active",
              }
            : row
        );

        setData(updatedData);
        handleModalCancel();
      } else {
        message.error(response.data.data);
        handleModalCancel();
      }
    } catch (error) {
      console.error("Error extending trial:", error);
      message.error("An error occurred while extending the trial.");
      handleModalCancel();
    }
  };

  const handleSearch = (e) => {
    const email = e.target.value.trim().toLowerCase();
    setSearchEmail(email);
  
    if (email === "") {
      setFilteredData(data);
    } else {
      const filtered = data.filter((row) =>
        row.owner_email.toLowerCase().includes(email)
      );
      setFilteredData(filtered);
    }
  };  

  const handleDelete = (record) => {
    if (record.trial_membership_status === "Active") {
      message.warning("Salon needs to be inactive before it can be deleted.");
    } else {
      setDeleteEmail(record.owner_email);
      setIsDeleteModalVisible(true);
    }
  };  

  const handleDeleteConfirm = async () => {
    try {
      console.log("Deleting owner email:", deleteEmail);
      const response = await deleteSalon(deleteEmail);

      if (response.data.status === 200) {
        message.success(response.data.data);
        const updatedData = data.filter(
          (row) => row.owner_email !== deleteEmail
        );
        setData(updatedData);
        setIsDeleteModalVisible(false);
        setDeleteEmail("");
      } else {
        message.error(response.data.data);
      }
    } catch (error) {
      console.error("Error deleting salon:", error);
      message.error("An error occurred while deleting the salon.");
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalVisible(false);
    setDeleteEmail("");
  };

  const columns = [
    {
      title: "Salon Name",
      dataIndex: "name",
    },
    {
      title: "Owner",
      dataIndex: "owner_name",
    },
    {
      title: "Email",
      dataIndex: "owner_email",
    },
    {
      title: "Membership Status",
      dataIndex: "trial_membership_status",
    },
    {
      title: "Extend Trial",
      dataIndex: "extendTrial",
      render: (_, record) => (
        <Button
          style={{
            backgroundColor:
              record.trial_membership_status === "Active"
                ? "black"
                : "rgba(109,49,237,1)",
            color: "#fff",
            border: "none",
          }}
          onClick={() => handleExtendTrial(record)}
        >
          {record.trial_membership_status === "Active"
            ? "Revoke Access"
            : "Extend Trial"}
        </Button>
      ),
    },
    {
      title: "Delete",
      dataIndex: "delete",
      render: (_, record) => (
        <Button
          style={{
            backgroundColor: "rgba(109,49,237,1)",
            color: "#fff",
            border: "none",
          }}
          onClick={() => handleDelete(record)}
        >
          Delete
        </Button>
      ),
    },
  ];

  return (
    <>
      {/* <Header>Trial Membership Window</Header> */}
      <FindContainer>
        <SearchContainer><SearchBox onChange={handleSearch} /></SearchContainer>
      </FindContainer>
      <Table columns={columns} dataSource={filteredData} rowKey="name" />
      <Modal
        title="Extend Trial"
        visible={isModalVisible}
        onCancel={handleModalCancel}
        footer={[
          <Button key="cancel" onClick={handleModalCancel}>
            Cancel
          </Button>,
          <Button key="save" type="primary" onClick={handleModalSave} style={{ backgroundColor: Theme.primaryColor, borderColor: Theme.primaryColor}}>
            Save
          </Button>,
        ]}
      >
        {modalData && (
          <>
            <p>Salon Name: {modalData.name}</p>
            <p>Salon Owner: {modalData.owner_name}</p>
            <p>Trial Extension Upto:</p>
            <Input
              type="number"
              value={days}
              onChange={(e) => setDays(e.target.value)}
              placeholder="Days"
              style={{ width: "100px", marginLeft: "10px" }}
            />
          </>
        )}
      </Modal>
      <Modal
        title="Delete Confirmation"
        visible={isDeleteModalVisible}
        onCancel={handleDeleteCancel}
        footer={[
          <Button key="cancel" onClick={handleDeleteCancel}>
            Cancel
          </Button>,
          <Button key="confirm" type="primary" onClick={handleDeleteConfirm} style={{ backgroundColor: Theme.primaryColor, borderColor: Theme.primaryColor}}>
            Yes
          </Button>,
        ]}
      >
        <p>Do you really want to delete {deleteEmail}?</p>
      </Modal>
    </>
  );
};

export default TrialMembership;
