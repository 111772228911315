import React, { useEffect, useState } from "react";
import { CancelIcon } from "../Sidebar/Sidebar.styled";
import LeftChevronIcon from "../../Assets/Svgs/LeftChevronIcon";
import {
  UpcomingBookingTitle,
  TitleBar,
  BookingId,
  Titles,
  SeperateLine,
  DetailsContainer,
  StylistDetails,
  StylistContainer,
  ButtonContainer,
  ChangeDateButton,
  ImageContainer,
  ProfileImage,
  StylistInfo,
  Name,
  DescriptionText,
  DetailContainer,
  CostContainer,
  ServiceType,
  AdvancedPayment,
  PaymentContainer,
  DateContainer,
  DownloadButton,
  CancelButton,
  Footer,
  UpcomingBooking,
  GoBackButton,
  ConfirmButton,
  Buttons,
  LoadingSpinner,
} from "./UpcomingBookingDetails.styled";
import defaultImage from "../../Assets/Images/default_image.png";
import { Modal, DatePicker, Button } from "antd";
import TestingPDF from "../../Assets/PDF/Receipt.pdf";
import {
  cancelBooking,
  getBookingsInformation,
} from "../../Api/ApiServerRequests/BookingsApiServerRequests";
import {
  getStylists,
} from "../../Api/ApiServerRequests/StylistsApiServerRequests";
import { Spin, message } from "antd";
import SchedulePage from "../../Pages/CustomerPage/SchedulePage/SchedulePage";
import { handleDownloadReceipt } from "../../Helper/Helper";

const UpcomingBookingDetails = ({ onCancel, activityId }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [bookingInfo, setBookingInfo] = useState(null);
  const [stylistInfo, setStylistInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [reschedule, setReschedule] = useState(false);

  useEffect(() => {
    // console.log("Activity ID:", activityId);
    const fetchBookingInformation = async () => {
      try {
        const booking_id = activityId;
        if (booking_id) {
          const response = await getBookingsInformation(
            booking_id,
            "booking id"
          );
          // console.log("activity detail response", response.data.data);

          if (response.data.data && response.data.data.length > 0) {
            const bookingData = response.data.data[0];
            setBookingInfo(bookingData);
            if (bookingData.stylist_email) {
              // console.log(
              //   "bookingInfo.stylist_email",
              //   bookingData.stylist_email
              // );
              const stylistResponse = await getStylists(
                "stylist email",
                bookingData.stylist_email
              );
              setStylistInfo(stylistResponse.data.data);
              // console.log("Stylist information:", stylistInfo);
            }
          }
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching booking information:", error);
      }
    };

    fetchBookingInformation();
  }, [activityId]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      setIsModalVisible(false);
      const response = await cancelBooking(bookingInfo._id);
      if (response.data.status === 201) {
        message.success("Booking cancelled successfully");
        onCancel();
      } else {
        message.error("Failed to cancel booking");
      }
    } catch (error) {
      console.error("Error cancelling booking:", error);
      message.error("An error occurred while cancelling booking");
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "2-digit",
    });
  };

  const formatUnixDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  const formatTime = (timeArray) => {
    console.log("timeArray", timeArray);

    if (timeArray.length !== 2) return "";

    const [startHour, endHour] = timeArray;
    const startTimePeriod = startHour >= 12 ? "PM" : "AM";
    const endTimePeriod = endHour >= 12 ? "PM" : "AM";

    const formatHour = (hour) => {
      if (hour === 0) return 12;
      if (hour > 12) return hour - 12;
      return hour;
    };

    const formattedStartHour = formatHour(startHour % 12);
    const formattedEndHour = formatHour(endHour % 12);

    const startTime = `${formattedStartHour
      .toString()
      .padStart(2, "0")}:00 ${startTimePeriod}`;
    const endTime = `${formattedEndHour
      .toString()
      .padStart(2, "0")}:00 ${endTimePeriod}`;

    return `${startTime} - ${endTime}`;
  };

  const handleReschedule = () => {
    setReschedule(true);
  };

  if (reschedule) {
    return (
      <SchedulePage
        reschedule={"reschedule"}
        onCancel={onCancel}
        bookingID={activityId}
      />
    );
  }

  return (
    <>
      <UpcomingBooking>
        <TitleBar>
          <CancelIcon onClick={onCancel}>
            <LeftChevronIcon />
          </CancelIcon>
          {bookingInfo && (
            <Titles>
              <UpcomingBookingTitle>
                {formatDate(bookingInfo.date)},
                {formatTime(bookingInfo.selected_time)}
              </UpcomingBookingTitle>
              <BookingId>Booking Id: {bookingInfo._id}</BookingId>
            </Titles>
          )}
        </TitleBar>
        {loading ? (
          <LoadingSpinner>
            {" "}
            <Spin size="large" tip="Loading..." />
          </LoadingSpinner>
        ) : (
          <DetailsContainer>
            <SeperateLine></SeperateLine>
            <StylistDetails>
              {bookingInfo && bookingInfo.stylist_email && stylistInfo && (
                <StylistContainer>
                  <ImageContainer>
                    <ProfileImage
                      src={stylistInfo.file_path}
                      alt="Profile Image"
                    />
                  </ImageContainer>
                  <StylistInfo>
                    <Name>{stylistInfo.name}</Name>
                    <DescriptionText>{stylistInfo.role}</DescriptionText>
                  </StylistInfo>
                </StylistContainer>
              )}
              <ButtonContainer>
                <ChangeDateButton onClick={handleReschedule}>
                  Reschedule booking
                </ChangeDateButton>
              </ButtonContainer>
            </StylistDetails>
            <SeperateLine></SeperateLine>

            {bookingInfo && (
              <>
                <DetailContainer>
                  <Name>Location</Name>
                  <DescriptionText>
                    {bookingInfo.address.address}
                  </DescriptionText>
                </DetailContainer>
                <SeperateLine></SeperateLine>

                <DetailContainer>
                  <Name>Bill Details</Name>
                  <CostContainer>
                    {bookingInfo &&
                      bookingInfo.services.map((service) => (
                        <ServiceType key={service.name}>
                          <DescriptionText>{service.name}</DescriptionText>
                          <DescriptionText>
                            SAR {service.total_price}
                          </DescriptionText>
                        </ServiceType>
                      ))}

                    {bookingInfo &&
                      bookingInfo.products.map((products) => (
                        <ServiceType key={products.name}>
                          <DescriptionText>{products.name}</DescriptionText>
                          <DescriptionText>
                            SAR {products.total_price}
                          </DescriptionText>
                        </ServiceType>
                      ))}
                    {bookingInfo && bookingInfo.loyalty_points_used &&(
                        <ServiceType >
                          <DescriptionText>Loyalty Points</DescriptionText>
                          <DescriptionText>
                            - SAR {bookingInfo.loyalty_points_used}
                          </DescriptionText>
                        </ServiceType>
                      )}
                  </CostContainer>
                </DetailContainer>
                <SeperateLine></SeperateLine>

                <DetailContainer>
                  <CostContainer>
                    <ServiceType>
                      <Name>Total</Name>
                      <Name>SAR {bookingInfo.total_price}</Name>
                    </ServiceType>
                    <ServiceType>
                      <Name>Advanced payment</Name>
                      <AdvancedPayment color="rgba(76,161,16,1)">
                        SAR {bookingInfo.advance_payment}
                      </AdvancedPayment>
                    </ServiceType>
                  </CostContainer>
                </DetailContainer>
                <SeperateLine></SeperateLine>

                <DetailContainer>
                  <CostContainer>
                    <ServiceType>
                      <Name>Balance</Name>
                      <AdvancedPayment color="rgba(131,83,226,1)">
                        SAR {bookingInfo.total_balance}
                      </AdvancedPayment>
                    </ServiceType>
                  </CostContainer>
                </DetailContainer>
                <SeperateLine></SeperateLine>

                <DetailContainer>
                  <Name>Payment</Name>
                  <CostContainer>
                    <ServiceType>
                      <PaymentContainer>
                        <DescriptionText>
                          {bookingInfo.payment_data.source}
                        </DescriptionText>
                        <DateContainer>
                          {formatUnixDate(bookingInfo.payment_data.created_at)}
                        </DateContainer>
                      </PaymentContainer>
                      <DescriptionText>
                        SAR {bookingInfo.payment_data.paid_amount}
                      </DescriptionText>
                    </ServiceType>
                  </CostContainer>
                </DetailContainer>
              </>
            )}
            <Footer>
              <DownloadButton onClick={handleDownloadReceipt}>
                Download Receipt
              </DownloadButton>
              <CancelButton onClick={showModal}>Cancel Booking</CancelButton>
            </Footer>
          </DetailsContainer>
        )}
      </UpcomingBooking>

      <Modal
        title="Cancel Booking"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Buttons>
            <GoBackButton key="back" onClick={handleCancel}>
              No, Go back
            </GoBackButton>

            <ConfirmButton key="submit" type="primary" onClick={handleOk}>
              Yes, Confirm
            </ConfirmButton>
          </Buttons>,
        ]}
      >
        <p>Please note: Advance payments are non-refundable.</p>
      </Modal>
    </>
  );
};

export default UpcomingBookingDetails;
