import styled from "styled-components";

export const HelpSetupContainer = styled.div`
 width:90%;
 margin: 20px;
 display: flex;
 flex-direction: row;
 flex-wrap: wrap;
 justify-content: space-between;
`;

export const FormContainer = styled.div`
 display: flex;
 flex-direction: column;
 gap:30px;
`;