import React from "react";

const HeadSet = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M13.7144 21.4286H18.0001C19.4204 21.4286 20.5715 20.2774 20.5715 18.8571V16.2857" stroke="#6D31ED" stroke-width="2.05714" stroke-miterlimit="10" stroke-linecap="square"/>
<path d="M7.71418 12L3.42847 12L3.42847 16.2857C3.42847 17.2329 4.19561 18 5.14275 18H7.71418L7.71418 12Z" stroke="#6D31ED" stroke-width="2.05714" stroke-miterlimit="10" stroke-linecap="square"/>
<path d="M20.5714 12L16.2856 12V18H18.8571C19.8042 18 20.5714 17.2329 20.5714 16.2857V12Z" stroke="#6D31ED" stroke-width="2.05714" stroke-miterlimit="10" stroke-linecap="square"/>
<path d="M20.5713 12V11.1429C20.5713 6.40887 16.7339 2.57144 11.9999 2.57144C7.2659 2.57144 3.42847 6.40887 3.42847 11.1429V12" stroke="#6D31ED" stroke-width="2.05714" stroke-miterlimit="10" stroke-linecap="square"/>
</svg>
  );
};

export default HeadSet;
