import styled from "styled-components";
import { Fonts } from "../../Fonts/Fonts";
import { Theme } from "../../Themes/Theme";

export const InventoryNameContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const InventoryNameDescription = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  color: ${Theme.primaryBlack};
  margin-bottom: 8px;
  width: 100%;
`;

export const InventoryNameInput = styled.input`
  width: 99%;
  font-family: ${Fonts.primaryFont};
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: ${Theme.primaryBlack};
  border: 1px solid ${Theme.primaryBlack};
  border-radius: 4px;
  outline: none;

  ::placeholder {
    color: ${Theme.primaryBlack};
  }
`;

export const SeparateLine = styled.div`
  width: 98%;
  height: 0px;
  border-width: 1px;
  border-color: ${Theme.primaryBlack};
  border-style: solid;
  transform: rotate(0deg);
  margin-bottom: 10px;
`;

export const DropDownContainer = styled.div`
  width: 100%;
  margin: 5px;
  display: flex;
  justify-content: flex-end;
`;
