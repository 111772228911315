import React, { useEffect, useState } from "react";
import { FormContainer, HelpSetupContainer } from "./LoyaltyPoints.styled";
import { useLocation } from "react-router-dom";
import { Table, Input, Form, Button, Row, Col, message } from "antd";
import { setLoyaltyPoints } from "../../Api/ApiServerRequests/LoyaltyApiServerRequests";
import { getLoyaltyPoints } from "../../Api/ApiServerRequests/CustomersApiServerRequests";
import { fetchSalon } from "../../Api/ApiServerRequests/SalonApiServerRequests";
import { getUserEmails } from "../../Components/UserEmails/userEmails";

const LoyaltyPoints = () => {
  const location = useLocation();
  const ownerEmail=getUserEmails().salonOwnerEmail;
  const [loyaltyPoints, setLoyaltyPoints] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchSalon(ownerEmail, "email");
        // console.log("response", response);

        if (response.data.status === 200) {
          setLoyaltyPoints(response.data.data.loyalty_settings)
        }
        // console.log("response", response.data.data.help);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
      }
    };

    const fetchLoyaltyDetails = async () => {
      try {
        const response = await getLoyaltyPoints(ownerEmail);
        // console.log("response of loyalty", response.data.data);
        if (response.data.status === 200) {
          if (response.data.data.length > 0) {
            const formattedData = response.data.data.map((item, index) => ({
              key: index.toString(),
              customernumber: item.phone,
              totalPurchase: item.total_amount_spent,
              loyaltyPoints: item.loyalty_points,
            }));
            setData(formattedData);
          }
        }        
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
      }
    };

    fetchData();
    fetchLoyaltyDetails()
  }, [ownerEmail]);

  // const [data, setData] = useState([
  //   {
  //     key: "1",
  //     customernumber: "John Doe",
  //     totalPurchase: "$100",
  //     loyaltyPoints: 10,
  //   },
  //   {
  //     key: "2",
  //     customernumber: "Jane Smith",
  //     totalPurchase: "$200",
  //     loyaltyPoints: 20,
  //   },
  // ]);

  const [form] = Form.useForm();

  const columns = [
    {
      title: "Customer number",
      dataIndex: "customernumber",
      key: "customernumber",
    },
    {
      title: "Total Purchase Amount",
      dataIndex: "totalPurchase",
      key: "totalPurchase",
    },
    {
      title: "Total Loyalty Points",
      dataIndex: "loyaltyPoints",
      key: "loyaltyPoints",
    },
  ];

  const handleAddRule = async() => {
    const formValues = form.getFieldsValue();
    // console.log("Form Values:", formValues);
    if (formValues.leftValue && formValues.rightValue) {

      try {
        const queryParams = new URLSearchParams({
          owner_email: ownerEmail,
          amount_spent: formValues.leftValue ,
          points_earned: formValues.rightValue
        });
  
        const response = await setLoyaltyPoints(queryParams);
        // console.log(response.data.data);
        if (response.data.status === 201) {
          message.success(response.data.data)
          setLoyaltyPoints({
            amount_spent: formValues.leftValue,
            points_earned: formValues.rightValue
          });
        }else{
          message.error("Something went wrong")
        }
      } catch (error) {
        console.error("Error fetching data");
      } finally {
        form.resetFields();
      }

    } else {
      if (!formValues.leftValue) {
        message.error("Amount not given");
        return;
      }
      if (!formValues.rightValue) {
        message.error("Loyalty points not given");
        return;
      }
    }
    // setRule(formValues);
    form.resetFields();
  };

  return (
    <>
      <HelpSetupContainer>
        <FormContainer>
          <Form form={form} layout="vertical">
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name="leftValue"
                  rules={[
                    {
                      type: "number",
                      required: true,
                      message: "Please enter a number",
                    },
                  ]}
                >
                  <Input type="number" placeholder="SAR" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="rightValue"
                  rules={[
                    {
                      type: "number",
                      required: true,
                      message: "Please enter a number",
                    },
                  ]}
                >
                  <Input type="number" placeholder="Loyalty points" />
                </Form.Item>
              </Col>
            </Row>

            <Button
              type="primary"
              onClick={handleAddRule}
              style={{ backgroundColor: "rgba(109, 49, 237, 1)" }}
            >
              Add Rule
            </Button>
          </Form>

          {loyaltyPoints && (
            <div style={{ marginBottom: "8px" }}>
              {loyaltyPoints.amount_spent} SAR = {loyaltyPoints.points_earned} loyalty point
            </div>
          )}

          <Table dataSource={data} columns={columns} />
        </FormContainer>
      </HelpSetupContainer>
    </>
  );
};

export default LoyaltyPoints;
