import ApiServer from "../ApiServer";


export const fetchSalon = (parameter, component) => {
  let url = "/salon";

  if (component === "email") {
    url += `?email=${encodeURIComponent(parameter)}`;
  } else if (component === "name") {
    url += `?name=${encodeURIComponent(parameter)}`;
  } else if (component === "salon_url_name") {
    url += `?salon_url_name=${encodeURIComponent(parameter)}`;
  } else if (component === "trial status") {
    url += `?trial_status=${encodeURIComponent(parameter)}`;
  }else if (component === "premium status") {
    url += `?premium_status=${encodeURIComponent(parameter)}`;
  }

  return ApiServer({
    method: "GET",
    url: url,
  });
};

export const deleteSalon = (email) => {
  return ApiServer({
    method: "DELETE",
    url: `/salon?email=${email}`,
  });
};

export const editSalon = (email, data) => {
  return ApiServer({
    method: "PUT",
    url: `/salon?email=${email}`,
    data: data,
  });
};

export const createSalon = (queryParams, formData) => {
  return ApiServer({
    method: "POST",
    url: `/salon?${queryParams.toString()}`,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const updateSalon = (queryParams, formData) => {
  return ApiServer({
    method: "PUT",
    url: `/salon?${queryParams.toString()}`,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};


export const addSalonWorkingHours = (email, data) => {
  return ApiServer({
    method: "POST",
    url: `/salon/schedule?owner_email=${email}`,
    data: data,
  });
};

export const getSalonWorkingHours = (date, type, value) => {
  let url;
  if (type === "owner_email") {
    url = `/salon/schedule?date=${date}&owner_email=${value}`;
  } else if (type === "salon_url_name") {
    url = `/salon/schedule?date=${date}&salon_url_name=${value}`;
  } else {
    throw new Error("Invalid type provided");
  }

  return ApiServer({
    method: "GET",
    url: url,
  });
};

export const deleteSalonWorkingHours = (queryParams) => {
  return ApiServer({
    method: "PUT",
    url: `/salon/schedule?${queryParams.toString()}`,
  });
};

export const addHelpSetup = (queryParams) => {
  return ApiServer({
    method: "POST",
    url: `/salon/help?${queryParams.toString()}`,
  });
};

export const removeHelpSetup = (email, data) => {
  return ApiServer({
    method: "PUT",
    url: `/salon/help?owner_email=${email}&field=${data}`,
  });
};

export const setReview = (ownerEmail, enable_reviews) => {
  return ApiServer({
    method: "POST",
    url: `/salon/reviews/configure?owner_email=${ownerEmail}&enable_reviews=${enable_reviews}`
  });
};

export const editSalonBranding = (queryParams, data = null) => {
  let config = {
      method: "POST",
      url: `/salon/branding?${queryParams.toString()}`,
  };
  console.log("data", data)
  if (data) {
      config.data = data;
      config.headers = {
          "Content-Type": "multipart/form-data",
      };
  }

  return ApiServer(config);
};

export const setSalonDefaultTiming = (queryParams) => {
  return ApiServer({
    method: "POST",
    url: `/salon/schedule/default?${queryParams.toString()}`
  });
};

export const extendTrial = (queryParams) => {
  return ApiServer({
    method: "POST",
    url: `/salon/trial/extend?${queryParams.toString()}`,
  });
};

export const subscribeSalon = (queryParams) => {
  return ApiServer({
    method: "POST",
    url: `/salon/subscribe?${queryParams.toString()}`,
  });
};
