import React, { useEffect, useState } from "react";
import {
  LoginFormContainer,
  FormWrapper,
  FormGroup,
  TextInput,
  SignInButton,
  SignUpLink,
  ForgotPasswordContainer,
  ForgotPasswordText,
} from "./LoginForm.styled";
import { logInUser, signup } from "../../Api/ApiServerRequests/UsersApiServerRequests";
import { useNavigate } from "react-router-dom";
import EyeVisibleIcon from "../../Assets/Svgs/EyeVisibleIcon";
import { Modal, message } from "antd";
import Urls from "../../Urls/Urls";

const LoginForm = ({ mode }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [emptyPassword, setEmptyPassword] = useState(false);
  const navigate = useNavigate();
  const [confirmPassword, setConfirmPassword] = useState("");
  const [emptyConfirmPassword, setEmptyConfirmPassword] = useState(false);
  const [name, setName] = useState("");
  const [emptyName, setEmptyName] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [invalidName, setInvalidName] = useState(false);
  const [exceedsMaxLength, setExceedsMaxLength] = useState(false);

  // localStorage.removeItem("role");
  // localStorage.removeItem("email");

  // useEffect(()=>{
  //   if(localStorage.getItem("role")==="Salon Owner"){
  //     navigate("/dashboard/owner");
  //   }
  //   else if(localStorage.getItem("role")==="Salon Admin"){
  //     navigate("/dashboard/admin");
  //   }
  //   else if(localStorage.getItem("role")==="Salon Employee"){
  //     navigate("/dashboard/employee");
  //   }
  // })

  const handleSignIn = async () => {
    try {
      if (!email.trim() && !password.trim()) {
        setEmptyEmail(true);
        setEmptyPassword(true);
        return;
      }

      if (!email.trim()) {
        setEmptyEmail(true);
        return;
      }

      if (!password.trim()) {
        setEmptyPassword(true);
        return;
      }

      setLoading(true);
      setInvalidCredentials(false);

      const queryParams = new URLSearchParams({
        username: email,
        password: password,
      });
      const result = await logInUser(queryParams);

      // console.log("login Response:", result);

      if (result.data.message === "invalid credentials") {
        setInvalidCredentials(true);
        setLoading(false);
        return;
      }

      if (result.status === 200) {
        const email = result.data.email;
        const role_name = result.data.roles;
        let storedRoles = JSON.parse(localStorage.getItem("roles")) || {};
        if (storedRoles.hasOwnProperty(role_name)) {
          storedRoles[role_name] = email;
        } else {
          
          storedRoles[role_name] = email;
        }
      
        localStorage.setItem("roles", JSON.stringify(storedRoles));
        // localStorage.setItem("email", email);
        // localStorage.setItem("role", role_name);
      if(result.data.setup_store===false){
        navigate(Urls.setupStore);
      }else if (role_name === "Salon Owner") {
          localStorage.setItem("ownerSalonName", result.data.salon_name);
          setTimeout(() => {
            navigate(Urls.home);
            setLoading(false);
          }, 5000);
        } else if (role_name === "Salon Admin") {
          setTimeout(() => {
            navigate("/dashboard/admin");
            setLoading(false);
          }, 5000);
        } else if (role_name === "Salon Employee") {
          setTimeout(() => {
            navigate(Urls.employeeDashboard);
            setLoading(false);
          }, 5000);
        }
      }
    } catch (error) {
      console.error("Error during sign-in:", error);
    } finally {
    }
  };

  const handleSignUp = async () => {
    try {
      if (
        !name.trim() &&
        !email.trim() &&
        !password.trim() &&
        !confirmPassword.trim()
      ) {
        setEmptyName(true);
        setEmptyEmail(true);
        setEmptyPassword(true);
        setEmptyConfirmPassword(true);
        return;
      }

      if (!name.trim()) {
        setEmptyName(true);
        return;
      }

      if (!email.trim()) {
        setEmptyEmail(true);
        return;
      }

      if (!password.trim()) {
        setEmptyPassword(true);
        return;
      }

      if (!confirmPassword.trim()) {
        setEmptyConfirmPassword(true);
        return;
      }

      const emailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
      if (!emailFormat) {
        // setEmptyEmail(true);
        Modal.warning({
          title: "Invalid Email Format",
          content: "Please enter a valid email address.",
        });
        setLoading(false);
        return;
      }

      if (password !== confirmPassword) {
        setPasswordMatch(false);
        return;
      }

      if (password.length < 12) {
        message.error({
          title: "Weak Password",
          content: "Password must be at least 12 characters long.",
        });
        return;
      }
  
      if (!/[a-z]/.test(password)) {
        message.error({
          title: "Weak Password",
          content: "Password must contain at least one lowercase letter.",
        });
        return;
      }
  
      if (!/[A-Z]/.test(password)) {
        message.error({
          title: "Weak Password",
          content: "Password must contain at least one uppercase letter.",
        });
        return;
      }
  
      if (!/\d/.test(password)) {
        message.error({
          title: "Weak Password",
          content: "Password must contain at least one number.",
        });
        return;
      }
  
      if (!/[@$!%*?&]/.test(password)) {
        message.error({
          title: "Weak Password",
          content: "Password must contain at least one special character.",
        });
        return;
      }

      setLoading(true);
      setInvalidCredentials(false);
      setPasswordMatch(true);

      const queryParams = new URLSearchParams({
        name: name,
        email: email,
        password: password,
        role_name: "Salon Owner",
      });

      const result = await signup(queryParams);

      // console.log("Signup Response:", result);

      if (result.data.status === 500) {
        Modal.warning({
          title: "User Already Exists",
          content:
            "The email address is already registered. Please use a different email to register.",
        });
        setLoading(false);
        return;
      }

      if (
        result.data.status === 201 &&
        result &&
        result.data &&
        result.data.data
      ) {
        // const { email, role_name } = result.data.data;

        // localStorage.setItem("email", email);
        // localStorage.setItem("role", role_name);

        // console.log("Signup Successful");
        console.log(result.data.data)
        const email = result.data.data.email;
        const role_name = result.data.data.role_name;
        let storedRoles = JSON.parse(localStorage.getItem("roles")) || {};
        if (storedRoles.hasOwnProperty(role_name)) {
          storedRoles[role_name] = email;
        } else {
          
          storedRoles[role_name] = email;
        }
        // localStorage.setItem("ownerSalonName", result.data.data.salon_name);
        localStorage.setItem("roles", JSON.stringify(storedRoles));
        setTimeout(() => {
          navigate("/dashboard/setup-store");
          setLoading(false);
        }, 5000);
      }
    } catch (error) {
      console.error("Error during sign-up:", error);

      if (
        error.response &&
        error.response.data &&
        error.response.data.detail === "Invalid credentials"
      ) {
        setInvalidCredentials(true);
        setLoading(false);
      }
    } finally {
    }
  };

  const handleLinkClick = () => {
    if (mode === "Login") {
      navigate("/dashboard/register");
    } else if (mode === "Register") {
      navigate("/dashboard");
    }
  };

  const handleForgotPasswordClick = () => {
    navigate("/dashboard/Forgot-password");
  };

  return (
    <LoginFormContainer>
      <FormWrapper>
        {mode === "Register" && (
          <FormGroup>
            <TextInput
              type="text"
              placeholder="Enter your name"
              value={name}
              onChange={(e) => {
                const enteredName = e.target.value;
                const hasSpecialCharacters = /[!@#$%^&*(),.?":{}|<>]/.test(
                  enteredName
                );
                const exceedsMaxLength = enteredName.length > 30;
                if (!hasSpecialCharacters && !exceedsMaxLength) {
                  setName(enteredName);
                  setEmptyName(false);
                  setInvalidName(false);
                } else {
                  if (hasSpecialCharacters) {
                    setInvalidName(true);
                  }
                  if (exceedsMaxLength) {
                    setExceedsMaxLength(true);
                  }
                }
              }}
            />
          </FormGroup>
        )}
        {emptyName && <p style={{ color: "red" }}>Please provide your name.</p>}
        {invalidName && (
          <p style={{ color: "red" }}>
            Name should not contain special characters.
          </p>
        )}
        {exceedsMaxLength && (
          <p style={{ color: "red" }}>Name should be within 30 characters.</p>
        )}

        <FormGroup>
          <TextInput
            type="text"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setEmptyEmail(false);
            }}
          />
        </FormGroup>
        {emptyEmail && (
          <p style={{ color: "red" }}>Please provide an email address.</p>
        )}

        <FormGroup>
          <TextInput
            type={showPassword ? "text" : "password"}
            placeholder="Enter your password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setEmptyPassword(false);
            }}
          />
          <EyeVisibleIcon onClick={() => setShowPassword(!showPassword)} />
        </FormGroup>
        {emptyPassword && (
          <p style={{ color: "red" }}>Please provide a password.</p>
        )}

        {mode === "Register" && (
          <FormGroup>
            <TextInput
              type={showConfirmPassword ? "text" : "password"}
              placeholder="Confirm your password"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                setEmptyConfirmPassword(false);
              }}
            />
            <EyeVisibleIcon
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            />
          </FormGroup>
        )}
        {emptyConfirmPassword && (
          <p style={{ color: "red" }}>Please confirm your password.</p>
        )}

        {!passwordMatch && (
          <p style={{ color: "red" }}>
            Passwords do not match. Please try again.
          </p>
        )}

        {mode === "Login" && (
          <ForgotPasswordContainer>
            <ForgotPasswordText onClick={handleForgotPasswordClick}>
              Forgot Password?
            </ForgotPasswordText>
          </ForgotPasswordContainer>
        )}

        {invalidCredentials && (
          <p style={{ color: "red" }}>
            Invalid credentials. Please check your email or password.
          </p>
        )}

        <SignInButton
          onClick={mode === "Login" ? handleSignIn : handleSignUp}
          disabled={loading}
        >
          {loading
            ? mode === "Login"
              ? "Logging..."
              : "Registering..."
            : mode === "Login"
            ? "Log In"
            : "Register"}
        </SignInButton>

        <SignUpLink onClick={handleLinkClick}>
          {mode === "Login"
            ? "Don't have an account? SignUp"
            : "Already have an account? Login"}
        </SignUpLink>
      </FormWrapper>
    </LoginFormContainer>
  );
};

export default LoginForm;
