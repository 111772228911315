import React, { useEffect, useState } from "react";
import { Modal, Button, DatePicker, Input, Select, message } from "antd";
import {
  Leave,
  StylistHeader,
  StylistName,
  StylistNameContainer,
  WarningMessage,
} from "./RequestTimeOff.styled";
import { getStylists, requestLeave } from "../../Api/ApiServerRequests/StylistsApiServerRequests";
import { WarningText } from "../../Pages/OwnerPage/SetupStore/SetupStore.styled";
import { getUserEmails } from "../../Components/UserEmails/userEmails";

const RequestTimeOff = ({ visible, onClose }) => {
  const { Option } = Select;

  const [leaveFrom, setLeaveFrom] = useState(null);
  const [leaveTo, setLeaveTo] = useState(null);
  const [reason, setReason] = useState("");
  const [timeOptions] = useState(getTimeOptions());
  const [timeRangeApplied, setTimeRangeApplied] = useState(false);
  const [selectedTimes, setSelectedTimes] = useState({});
  const [stylistName, setStylistName] = useState("");
  const [reasonCharCount, setReasonCharCount] = useState(false);
  const [charCount, setCharCount] = useState(0);
  const stylistEmail = getUserEmails().salonEmployeeEmail;

  useEffect(() => {
    getStylists("stylist email", stylistEmail)
      .then((response) => {
        // console.log("Stylist details:", response.data);
        setStylistName(response.data.data.name);
      })
      .catch((error) => {
        console.error("Error fetching stylist details:", error);
      });
  }, []);

  const setTimeSlot = (date, slot, value) => {
    setSelectedTimes((prev) => ({
      ...prev,
      [date]: {
        ...(prev[date] || {}),
        [slot]: value,
      },
    }));
  };

  const resetState = () => {
    setLeaveFrom(null);
    setLeaveTo(null);
    setReason("");
    setReasonCharCount(false);
    setCharCount(0);
  };

  const onSubmit = () => {
    if (!leaveFrom || !leaveTo) {
      message.error("Please select leave dates");
      return;
    }
    if (Object.keys(selectedTimes).length === 0) {
      message.error("Please select at least one time slot for applying leave");
      return;
    }
    const missingDates = [];
    Object.keys(selectedTimes).forEach((date) => {
      const { from, to } = selectedTimes[date];
      if (!from || !to) {
        missingDates.push(date);
      }
    });

    if (missingDates.length > 0) {
      const errorMessage = `Missing data in ${missingDates.join(", ")}`;
      message.error(errorMessage);
      return;
    }
    if (!reason) {
      message.error("Please enter a reason");
      return;
    }
    const selectedDates = {};
    Object.keys(selectedTimes).forEach((date) => {
      const { from, to } = selectedTimes[date];
      const fromTime = convertToRailwayTime(from);
      const toTime = convertToRailwayTime(to);
      selectedDates[date] = [fromTime, toTime];
    });

    const formattedLeaveFrom = leaveFrom.format("YYYY-MM-DD");
    const formattedLeaveTo = leaveTo.format("YYYY-MM-DD");

    requestLeave(stylistEmail, selectedDates, reason, formattedLeaveFrom, formattedLeaveTo)
      .then((response) => {
        // console.log(response.data.status);
        if (response.data.status === 201) {
          // console.log("Request submitted successfully:", response);
          message.success("Leave request submitted successfully");
          onClose();
          resetState();
        } else {
          // console.log("Unexpected success response:", response);
          message.error(response.data.data);
          onClose();
          resetState();
        }
      })
      .catch((error) => {
        console.error("Error submitting request:", error);
        message.error("Failed to submit request. Please try again later.");
        onClose();
        resetState();
      });
  };

  const convertToRailwayTime = (timeString) => {
    const [hourStr, minuteStr, period] = timeString.split(/:| /);
    let hour = parseInt(hourStr);

    if (period === "PM" && hour !== 12) {
      hour += 12;
    } else if (period === "AM" && hour === 12) {
      hour = 0;
    }

    return hour.toString();
  };

  const disabledDate = () => {
    return false;
  };

  function getTimeOptions() {
    const timeOptions = [];
    for (let i = 0; i < 12; i++) {
      const hour = i === 0 ? 12 : i;
      timeOptions.push(
        <Option
          key={`${hour}:00 AM`}
          value={`${hour}:00 AM`}
        >{`${hour}:00 AM`}</Option>
      );
    }
    timeOptions.push(
      <Option key={`12:00 PM`} value={`12:00 PM`}>{`12:00 PM`}</Option>
    );
    for (let i = 1; i < 12; i++) {
      const hour = i === 0 ? 12 : i;
      timeOptions.push(
        <Option
          key={`${hour}:00 PM`}
          value={`${hour}:00 PM`}
        >{`${hour}:00 PM`}</Option>
      );
    }
    return timeOptions;
  }

  const handleAddToAll = () => {
    setTimeRangeApplied(!timeRangeApplied);
  };

  const renderDateRange = () => {
    const dates = [];
    let currentDate = leaveFrom;
    while (currentDate <= leaveTo) {
      const formattedDate = currentDate.format("YYYY-MM-DD");
      dates.push(
        <div
          key={formattedDate}
          style={{ display: "flex", alignItems: "center", marginTop: "5px" }}
        >
          <span>{currentDate.format("DD-MM-YYYY")}</span>
          <span style={{ marginLeft: "10px" }}>
            {currentDate.format("ddd")}
          </span>
          <Select
            id={`from-${formattedDate}`}
            style={{ width: "100px", marginLeft: "auto", marginRight: "5px" }}
            placeholder="From"
            onChange={(value) => {
              setTimeSlot(formattedDate, "from", value);
            }}
            disabled={timeRangeApplied}
          >
            {timeOptions}
          </Select>
          <span style={{ margin: "0 5px" }}>to</span>
          <Select
            id={`to-${formattedDate}`}
            style={{ width: "100px" }}
            placeholder="To"
            onChange={(value) => {
              setTimeSlot(formattedDate, "to", value);
            }}
            disabled={timeRangeApplied}
          >
            {timeOptions}
          </Select>
        </div>
      );
      currentDate = currentDate.clone().add(1, "day");
    }
    return <div style={{ maxHeight: "200px", overflowY: "auto" }}>{dates}</div>;
  };

  const handleReasonChange = (e) => {
    const inputValue = e.target.value;
    setCharCount(inputValue.length);

    if (inputValue.length <= 100) {
      setReason(inputValue);
      setReasonCharCount(false);
    } else {
      setReasonCharCount(true);
    }
  };

  return (
    <Modal
      title={`Request for leave`}
      visible={visible}
      onCancel={() => {
        onClose();
        resetState();
      }}
      footer={[
        <Button key="Cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="Submit" type="primary" onClick={onSubmit}>
          Set
        </Button>,
      ]}
    >
      <StylistNameContainer>
        <StylistHeader>Stylist</StylistHeader>
        <StylistName>{stylistName}</StylistName>
      </StylistNameContainer>
      <StylistHeader>Time span</StylistHeader>
      <Leave>
        <DatePicker.RangePicker
          disabledDate={disabledDate}
          placeholder={["Leave From", "Leave To"]}
          value={[leaveFrom, leaveTo]}
          onChange={(dates) => {
            setLeaveFrom(dates[0]);
            setLeaveTo(dates[1]);
          }}
        />
      </Leave>
      {leaveFrom && leaveTo && (
        <div>
          <StylistHeader>Selected Dates</StylistHeader>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {renderDateRange()}
          </div>
        </div>
      )}
      <StylistHeader>Reason</StylistHeader>
      <Input
        placeholder="Enter reason"
        value={reason}
        onChange={handleReasonChange}
      />
      <p>Character: {charCount}/100</p>
      <div>
        {reasonCharCount && (
          <WarningText>
            Please enter a maximum of 100 characters for the reason.
          </WarningText>
        )}
      </div>
    </Modal>
  );
};

export default RequestTimeOff;
