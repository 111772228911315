import React, { useState, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import AdminDashboard from "../Pages/AdminPage/AdminDashboardPage/AdminDashboardPage";
import CustomerWelcomePage from "../Pages/CustomerPage/CustomerWelcomePage/CustomerWelcomePage";
import LoginPage from "../Pages/LoginPage/LoginPage";
import WarningPopup from "../Components/WarningPopup/WarningPopup";
import RegisterPage from "../Pages/RegisterPage/RegisterPage";
import SetupStore from "../Pages/OwnerPage/SetupStore/SetupStore";
import OwnerDashboardPage from "../Pages/OwnerPage/OwnerDashboardPage/OwnerDashboardPage";
import CustomerLoginPage from "../Pages/CustomerPage/CustomerLoginPage/CustomerLoginPage";
import GettingStartedScreen from "../Pages/CustomerPage/GettingStartedScreen/GettingStartedScreen";
import CustomerHomePage from "../Pages/CustomerPage/CustomerHomePage/CustomerHomePage";
import ForgotPassword from "../Pages/ForgotPassword/ForgotPassword";
import EmployeeDashboardPage from "../Pages/Employee/EmployeeDashboardPage/EmployeeDashboardPage";
import CartPage from "../Pages/CustomerPage/CartPage/Cart";
import SchedulePage from "../Pages/CustomerPage/SchedulePage/SchedulePage";
import AddressPage from "../Pages/CustomerPage/AddressPage/AddressPage";
import BookingDetails from "../Pages/CustomerPage/BookingDetails/BookingDetails";
import BookingConfirmation from "../Pages/CustomerPage/BookingConfirmation/BookingConfirmation";
import PaymentDiscounts from "../Pages/CustomerPage/PaymentDiscounts/PaymentDiscounts";
import Urls from "../Urls/Urls";
import Tipping from "../Pages/CustomerPage/Tipping/Tipping";
import TippingConfimation from "../Pages/CustomerPage/TippingConfimation/TippingConfimation";

const AppRoutes = () => {
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    const pathArray = window.location.pathname.split("/");
    const potentialSalonName = pathArray[1];
    if (pathArray.length === 2 && !potentialSalonName) {
      window.location.href = "/dashboard";
      return;
    }
    if (!pathArray[2]) {
      localStorage.removeItem("salonName");
    }
    if (!localStorage.getItem("salonName")) {
      if (potentialSalonName !== "dashboard") {
        localStorage.setItem("salonName", potentialSalonName);
        window.location.href = `/${potentialSalonName}/customer`;
      }
    }
  }, []);

  const AdminRoute = ({ element }) => {
    const storedRoles = JSON.parse(localStorage.getItem("roles")) || {};
    const isAdmin = storedRoles.hasOwnProperty("Salon Admin");

    return isAdmin ? (
      element
    ) : (
      <>
        <Navigate to="/dashboard" />
        {setShowWarning(true)}
      </>
    );
  };

  const OwnerLogin = ({ element }) => {
    const storedRoles = JSON.parse(localStorage.getItem("roles")) || {};
    const isOwner = storedRoles.hasOwnProperty("Salon Owner");

    return isOwner ? (
      element
    ) : (
      <>
        <Navigate to="/dashboard" />
        {setShowWarning(true)}
      </>
    );
  };

  const OwnerRoute = ({ element }) => {
    const storedRoles = JSON.parse(localStorage.getItem("roles")) || {};
    const isOwner = storedRoles.hasOwnProperty("Salon Owner") && localStorage.getItem("ownerSalonName");

    return isOwner ? (
      element
    ) : (
      <>
        <Navigate to="/dashboard" />
        {setShowWarning(true)}
      </>
    );
  };

  const CustomerRoute = ({ element }) => {
    if (localStorage.getItem("role") === "Salon Customer") {
      return element;
    } else {
      const salonName = localStorage.getItem("salonName");
      if (salonName) {
        return (
          <>
            <Navigate to={`/${salonName}/login`} />
            {setShowWarning(true)}
          </>
        );
      } else {
        return null;
      }
    }
  };

  const closeWarning = () => {
    setShowWarning(false);
  };

  const EmployeeRoute = ({ element }) => {
    const storedRoles = JSON.parse(localStorage.getItem("roles")) || {};
    const isEmployee = storedRoles.hasOwnProperty("Salon Employee");

    return isEmployee ? (
      element
    ) : (
      <>
        <Navigate to="/dashboard" />
        {setShowWarning(true)}
      </>
    );
  };

  return (
    <>
      <Routes>
        <Route path="/dashboard" element={<LoginPage />} />
        <Route path="/dashboard/register" element={<RegisterPage />} />

        {/* Employee routes */}
        <Route
          path={Urls.employeeDashboard + "/*"}
          element={<EmployeeRoute element={<EmployeeDashboardPage />} />}
        />

        {/* Admin routes */}
        <Route
          path={Urls.adminDashboard + "/*"}
          element={<AdminRoute element={<AdminDashboard />} />}
        />

        {/* Owner routes */}
        <Route
          path={Urls.home + "/*"}
          element={<OwnerRoute element={<OwnerDashboardPage />} />}
        />
        <Route
          path={Urls.setupStore}
          element={<OwnerLogin element={<SetupStore />} />}
        />
        <Route
          path={Urls.forgotPassword}
          element={<ForgotPassword />}
        />

        {/* Customer routes */}
        <Route
          path={Urls.customerLoginPage}
          element={<CustomerLoginPage />}
        />
        <Route
          path={Urls.gettingStarted}
          element={<GettingStartedScreen />}
        />
        <Route
          path={Urls.customerWelcomePage}
          element={<CustomerWelcomePage />}
        />
        <Route
          path={Urls.customerHomePage}
          element={<CustomerRoute element={<CustomerHomePage />} />}
        />
        <Route
          path={Urls.customerCart}
          element={<CustomerRoute element={<CartPage />} />}
        />
        <Route
          path={Urls.customerSchedule}
          element={<CustomerRoute element={<SchedulePage />} />}
        />
        <Route
          path={Urls.customerAddress}
          element={<CustomerRoute element={<AddressPage />} />}
        />
        <Route
          path={Urls.addCustomerAddress}
          element={<CustomerRoute element={<AddressPage address={true} />} />}
        />
        <Route
          path={Urls.customerOffers}
          element={<CustomerRoute element={<PaymentDiscounts />} />}
        />
        <Route
          path={Urls.bookingDetails}
          element={<CustomerRoute element={<BookingDetails />} />}
        />
        <Route
          path={Urls.bookingConfirmation}
          element={<CustomerRoute element={<BookingConfirmation />} />}
        />
        <Route
          path={Urls.tipping}
          element={<CustomerRoute element={<Tipping />} />}
        />
        <Route
          path={Urls.tippingConfirmation}
          element={<CustomerRoute element={<TippingConfimation />} />}
        />
      </Routes>
      <WarningPopup visible={showWarning} onCancel={closeWarning} />
    </>
  );
};

export default AppRoutes;
