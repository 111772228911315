import React from "react";

const ScissorsIcon = ({ isActive }) => {
  const strokeColor = isActive ? "#6D31ED" : "#171A1F";

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M12 14.5714C13.4202 14.5714 14.5714 13.4201 14.5714 12C14.5714 10.5798 13.4202 9.42856 12 9.42856C10.5799 9.42856 9.42859 10.5798 9.42859 12C9.42859 13.4201 10.5799 14.5714 12 14.5714Z" stroke={strokeColor}stroke-width="2.05714" stroke-miterlimit="10" stroke-linecap="square"/>
<path d="M18.8572 12C18.8561 11.5062 18.8021 11.014 18.696 10.5317L21.0223 8.7703L19.308 5.80116L16.614 6.93773C15.8845 6.2689 15.0186 5.76621 14.076 5.4643L13.7143 2.57144L10.2857 2.57144L9.92401 5.4643C8.98145 5.76621 8.11555 6.2689 7.38601 6.93773L4.69201 5.80116L2.97772 8.7703L5.30401 10.5317C5.0892 11.4988 5.0892 12.5012 5.30401 13.4683L2.97772 15.2297L4.69201 18.1989L7.38601 17.0623C8.11555 17.7311 8.98145 18.2338 9.92401 18.5357L10.2857 21.4286H13.7143L14.076 18.5357C15.0186 18.2338 15.8845 17.7311 16.614 17.0623L19.308 18.1989L21.0223 15.2297L18.696 13.4683C18.8021 12.986 18.8561 12.4938 18.8572 12Z" stroke={strokeColor} stroke-width="2.05714" stroke-miterlimit="10" stroke-linecap="square"/>
</svg>
  );
};

export default ScissorsIcon;