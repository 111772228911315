import React, { useEffect, useState } from "react";
import { Table, Tag, Select, message } from "antd";
import { Heading, NameRow, Name } from "./EmployeeBookings.styled";
import { getBookingsInformation, updateBookingInformation } from "../../Api/ApiServerRequests/BookingsApiServerRequests";
import { getUserEmails } from "../../Components/UserEmails/userEmails";

const EmployeeBookings = () => {
  const [activeTab, setActiveTab] = useState("all");
  const { salonEmployeeEmail } = getUserEmails();
  const [bookings, setBookings] = useState([]);
  const { Option } = Select;

  useEffect(() => {
    const fetchBookingInformation = async () => {
      try {
        const response = await getBookingsInformation(
          salonEmployeeEmail,
          "stylist email"
        );
        // console.log("Booking response owner email", response);
        if (response.data.status === 200) {
          setBookings(response.data.data);
        }
        // console.log("booking", bookings);
      } catch (error) {
        console.error("Error fetching booking information:", error);
      }
    };

    fetchBookingInformation();
  }, [salonEmployeeEmail]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const formattedBookingsData = bookings.map((booking) => ({
    key: booking._id,
    name: booking.address.name,
    address: booking.address.address,
    service: [
      ...booking.services.map((service) => service.name),
      ...booking.products.map((product) => product.name),
    ].join(", "), 
    invoiceAmount: `$${booking.total_price}`,
    completionStatus: booking.processed ? "Completed" : "Incomplete",
    paymentStatus: booking.payment ? "Paid" : "Unpaid",
    // tip: "SAR 100"
  }));

  const columns = [
    { title: "Customer Name", dataIndex: "name", key: "name" },
    { title: "Customer Address", dataIndex: "address", key: "address" },
    { title: "Choosen Service", dataIndex: "service", key: "service" },
    {
      title: "Invoice Amount",
      dataIndex: "invoiceAmount",
      key: "invoiceAmount",
    },
    // {
    //   title: "Payment Status",
    //   dataIndex: "paymentStatus",
    //   key: "paymentStatus",
    //   render: (status) => (
    //     <Tag
    //       color={status === "Paid" ? "green" : "red"}
    //       style={{
    //         background:
    //           status === "Paid" ? "rgba(17,123,52,0.1)" : "rgba(255,0,0,0.1)",
    //         borderRadius: "14px",
    //       }}
    //     >
    //       {status}
    //     </Tag>
    //   ),
    // },
    // { title: "Tip", dataIndex: "tip", key: "tip" },
    {
      title: "Completion Status",
      dataIndex: "completionStatus",
      key: "completionStatus",
      render: (status, record) => (
        <Select
          defaultValue={status}
          onChange={(value) => handleCompletionStatusChange(record.key, value)}
        >
          <Option value="Completed">Completed</Option>
          <Option value="Incomplete">Incomplete</Option>
        </Select>
      ),
    },
  ];

  const handleCompletionStatusChange = async (bookingId, status) => {
    console.log(bookingId, status);
    try {
      const response = await updateBookingInformation(bookingId, status === "Completed");
      if(response.data.status===201){
        message.success(response.data.data);
      }
      // console.log(response);
    } catch (error) {
      console.error("Error updating booking information:", error);
    }
  };

  return (
    <>
      <Heading>Bookings
      {/* <Name>Total Amount: SAR 100</Name> */}
      </Heading>
      <NameRow>
        <Name
          active={activeTab === "all"}
          onClick={() => handleTabClick("all")}
        >
          All
        </Name>
      </NameRow>
      {activeTab === "all" && (
        <Table dataSource={formattedBookingsData} columns={columns} />
      )}
    </>
  );
};

export default EmployeeBookings;
