import styled from "styled-components";
import { Theme } from "../../Themes/Theme";
import { Fonts } from "../../Fonts/Fonts";

export const HeadingRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NameRow = styled.div`
  display: flex;
  margin-top: 40px;
  gap: 40px;
  margin-bottom: 30px;
`;

export const Name = styled.div`
  cursor: pointer;
  color: ${(props) => (props.active ? Theme.primaryColor : "#000")};
  position: relative;

  &::after {
    content: "";
    display: ${(props) => (props.active ? "block" : "none")};
    position: absolute;
    bottom: -12px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${Theme.primaryColor};
  }
`;

export const EditButton = styled.button`
  /* padding: 3px 29px; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${Fonts.primaryFont};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: ${Theme.primaryColor};
  background: ${Theme.lightViolet};
  opacity: 1;
  border: none;
  border-radius: 3px;
  width: 68px;
  height: 28px;
  cursor: pointer;
`;

export const DeleteButton = styled.button`
  /* padding: 3px 29px; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${Fonts.primaryFont};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: ${Theme.primaryRed};
  background: ${Theme.lightRed};
  opacity: 1;
  border: none;
  border-radius: 3px;
  width: 68px;
  height: 28px;
  cursor: pointer;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap:5px;
`;

export const TimeOffRequest = styled.div`
  display: flex;
  margin-top: 40px;
  margin-bottom: 30px;
  margin-right: 30px;
  gap: 20px;
`;

export const TimeOffRequestButton = styled.button`
  color: rgba(255, 255, 255, 1);
  background: ${Theme.primaryColor};
  opacity: 1;
  border: none;
  border-radius: 4px;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
`;

