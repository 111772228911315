import React, { useEffect, useState } from "react";
import {
  AppointmentDetail,
  AppointmentName,
  AppointmentScrolling,
  Appointments,
  AppointmentsContainer,
  CustomHomePageContainer,
  Detail,
  HelloText,
  Points,
  PointsContainer,
  PointsText,
  WelcomeContainer,
  YellowContainer,
} from "./CustomerHomePage.styled";
import CustomHeader from "../../../Modules/CustomHeader/CustomHeader";
import LocationIcon from "../../../Assets/Svgs/LocationIcon";
import OfferContainer from "../../../Components/OfferContainer/OfferContainer";
import ServiceAndProductCustomerDisplay from "../../../Modules/ServiceAndProductCustomerDisplay/ServiceAndProductCustomerDisplay";
import CrownIcon from "../../../Assets/Svgs/CrownIcon";
import CalendarIcon from "../../../Assets/Svgs/CalendarIcon";
import ScissorsIcon from "../../../Assets/Svgs/ScissorsIcon";
import Sidebar from "../../../Modules/Sidebar/Sidebar";
import { getUpcomingActivites } from "../../../Api/ApiServerRequests/BookingsApiServerRequests";
import { getCustomerAddress } from "../../../Api/ApiServerRequests/CustomersApiServerRequests";

const CustomerHomePage = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const salonName=localStorage.getItem("salonName")
  const customerNumber = localStorage.getItem("customerPhoneNumber");
  const [upcomingActivities, setUpcomingActivities] = useState([]);
  const [loyaltyPoints, setLoyaltyPoints] = useState(null);
  
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const pathArray = window.location.pathname.split("/");
    const potentialSalonName = pathArray[1];
    if (potentialSalonName!==salonName) {
      localStorage.setItem("salonName", potentialSalonName);
        window.location.href = `/${potentialSalonName}`;
    }
  }, []);

  useEffect(() => {
    const fetchBookingInformation = async () => {
      try {
        const queryParams = new URLSearchParams({
          phone: customerNumber,
          processed: false,
          salon_url_name: salonName
        });
        const response = await getUpcomingActivites(queryParams);
        if (response.data.status===200) {
          setUpcomingActivities(response.data.data);
          console.log("upcomingActivities",upcomingActivities);
        } else {
          console.error("Invalid data format: expected array");
        }
      } catch (error) {
        console.error("Error fetching booking information:", error);
      }
    };

    const fetchCustomerInformation = async () => {
      try {
        const queryParams = new URLSearchParams({
          phone: customerNumber,
          salon_url_name: salonName
        });
        const response = await getCustomerAddress(queryParams);
        if (response.data.status===200) {
          setLoyaltyPoints(response.data.data[0].loyalty_points);
          console.log("loyalty", response.data.data[0].loyalty_points)
        } else {
          console.error("Invalid data format: expected array");
        }
      } catch (error) {
        console.error("Error fetching booking information:", error);
      }
    };

    fetchBookingInformation();
    fetchCustomerInformation();
  }, [customerNumber]);

  return (
    <CustomHomePageContainer>
      <CustomHeader onMenuClick={toggleSidebar} menuIcon={true} salonName={salonName}/>

      {loyaltyPoints !== null && (
      <WelcomeContainer>
        <YellowContainer>
          <PointsText>Your Loyal Points</PointsText>
          <PointsContainer>
            <CrownIcon />
            <Points>{loyaltyPoints}</Points>
          </PointsContainer>
        </YellowContainer>
      </WelcomeContainer>
      )}

      <AppointmentsContainer>
        <HelloText>Upcoming/Missed Appointments</HelloText>
        <AppointmentScrolling>
          {upcomingActivities.map((activity) => (
            <Appointments key={activity._id}>
              <AppointmentName>
              {activity.services.map((service, index) => (
                    <React.Fragment key={index}>
                    <span>{service.name}</span>
                    {index !== activity.services.length - 1 && (
                      <span>, </span>
                    )}
                  </React.Fragment>
                ))}
                  {activity.services.length > 0 &&
                    activity.products.length > 0 && <span>, </span>}
                  {activity.products.map((product, index) => (
                    <React.Fragment key={index}>
                      <span>{product.name}</span>
                      {index !== activity.products.length - 1 && (
                        <span>, </span>
                      )}
                    </React.Fragment>
                  ))}
              </AppointmentName>

              <AppointmentDetail>
                <CalendarIcon />
                <Detail>{activity.date}</Detail>
              </AppointmentDetail>

              {activity.stylist_name && (
                <AppointmentDetail>
                  <ScissorsIcon />
                  <Detail>{activity.stylist_name}</Detail>
                </AppointmentDetail>
              )}

              <AppointmentDetail>
                <LocationIcon />
                <Detail>{activity.address.address}</Detail>
              </AppointmentDetail>
            </Appointments>
          ))}
        </AppointmentScrolling>
      </AppointmentsContainer>

      <OfferContainer />

      <ServiceAndProductCustomerDisplay type={"Home"}/>
      <Sidebar isOpen={isSidebarOpen} onClose={toggleSidebar} />
    </CustomHomePageContainer>
  );
};

export default CustomerHomePage;
