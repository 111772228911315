import ApiServer from "../ApiServer";

export const getInventoryDetails = (owner_email) => {
  return ApiServer({
    method: "GET",
    url: `/inventory?owner_email=${owner_email}`
  });
};

export const addInventoryDetails = (queryParams) => {
  return ApiServer({
    method: "POST",
    url: `/inventory?${queryParams.toString()}`
  });
};

export const deleteInventoryDetails = (queryParams) => {
  return ApiServer({
    method: "PUT",
    url: `/inventory?${queryParams.toString()}`
  });
};
