import React from "react";

const BellIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M17.9999 11.1429V8.57144C17.9999 6.98014 17.3678 5.45402 16.2425 4.3288C15.1173 3.20358 13.5912 2.57144 11.9999 2.57144C10.4086 2.57144 8.88247 3.20358 7.75725 4.3288C6.63204 5.45402 5.9999 6.98014 5.9999 8.57144V11.1429C5.9999 13.9714 3.42847 14.6572 3.42847 16.2857C3.42847 17.7429 6.77132 18.8572 11.9999 18.8572C17.2285 18.8572 20.5713 17.7429 20.5713 16.2857C20.5713 14.6572 17.9999 13.9714 17.9999 11.1429Z" stroke="#6D31ED" stroke-width="2.05714" stroke-miterlimit="10" stroke-linecap="square"/>
<path d="M12.0001 20.5714C11.1335 20.5714 10.3193 20.5423 9.55298 20.4857C9.71634 21.0068 10.0418 21.4622 10.482 21.7855C10.9221 22.1088 11.454 22.2831 12.0001 22.2831C12.5463 22.2831 13.0781 22.1088 13.5183 21.7855C13.9584 21.4622 14.2839 21.0068 14.4473 20.4857C13.681 20.5423 12.8667 20.5714 12.0001 20.5714Z" fill="#6D31ED"/>
</svg>
  );
};

export default BellIcon;
