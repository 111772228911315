const Urls = {
    home: "/dashboard/owner",
    services: "/dashboard/owner/services",
    offers: "/dashboard/owner/offers",
    stylists: "/dashboard/owner/stylists",
    schedule: "/dashboard/owner/schedule",
    inventory: "/dashboard/owner/inventory",
    tips: "/dashboard/owner/tips",
    settings: "/dashboard/owner/settings",
    stylistLeaveManagement: "/dashboard/owner/stylist-leave-management",
    paymentSettings: "/dashboard/owner/settings/payment-settings",
    advancePaymentSettings: "/dashboard/owner/settings/payment-settings/advance-payment",
    paymentMethod: "/dashboard/owner/settings/payment-settings/payment-method",
    helpSetup: "/dashboard/owner/settings/help-setup",
    loyaltyPoints: "/dashboard/owner/settings/loyalty-points",
    userPermissions: "/dashboard/owner/settings/user-permission",
    reviewSettings: "/dashboard/owner/settings/review-settings",
    brandingSettings: "/dashboard/owner/settings/branding-settings",
    billingSettings: "/dashboard/owner/settings/billing-settings",
    employeeDashboard: "/dashboard/employee",
    employeeSchedule: "/dashboard/employee/schedule",
    adminDashboard: "/dashboard/admin",
    setupStore: "/dashboard/setup-store",
    forgotPassword: "/dashboard/forgot-password",
    customerLoginPage: "/:salonName/login",
    gettingStarted: "/:salonName/getting-started",
    customerWelcomePage: "/:salonName/customer",
    customerHomePage: "/:salonName/home",
    customerCart: "/:salonName/cart",
    customerSchedule: "/:salonName/schedule",
    customerAddress: "/:salonName/address",
    addCustomerAddress: "/:salonName/address/add",
    customerOffers: "/:salonName/offers",
    bookingDetails: "/:salonName/booking-details",
    bookingConfirmation: "/:salonName/booking-confirmation",
    tipping: "/:salonName/tipping",
    tippingConfirmation: "/:salonName/tipping-confirmation",
    adminAddSalon: "/dashboard/admin/add-salon",
    adminEditSalon: "/dashboard/admin/edit-salon",
    adminTrialMembership: "/dashboard/admin/trial-membership"
  };
  
  export default Urls;
  