import ApiServer from "../ApiServer";


export const insertService = (queryParams, formData) => {
  return ApiServer({
    method: "POST",
    url: `/services?${queryParams.toString()}`,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getServices = (parameter = "", value = "") => {
  let url = "/services";

  if (value && parameter === "email") {
    url += `?owner_email=${value}`;
  } else if (value && parameter === "name") {
    url += `?salon_url_name=${value}`;
  }

  return ApiServer({
    method: "GET",
    url: url,
  });
};


export const deleteServices = (name, email) => {
  return ApiServer({
    method: "DELETE",
    url: `/services?name=${name}&owner_email=${email}`,
  });
};

export const updateServices = (queryParams, formData) => {
  return ApiServer({
    method: "PUT",
    url: `/services?${queryParams.toString()}`,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const updateRequirements = (queryParams) => {
  return ApiServer({
    method: "PUT",
    url: `/services/required-items?${queryParams.toString()}`,
  });
};