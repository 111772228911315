import React from 'react';
import LoginForm from '../../Modules/LoginForm/LoginForm';
import { Layout} from 'antd';
import {LoginFormContainer,Header, LoginWrapper} from './RegisterPage.styled';
import { useEffect } from 'react';


const RegisterPage = () => {

  return (
    <LoginFormContainer>
       <Header> 
        Register
      </Header>     
      <LoginForm mode="Register" />
    </LoginFormContainer>
  );
};

export default RegisterPage;
