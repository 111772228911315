import ApiServer from "../ApiServer";


export const getCustomerOTP = (queryParams) => {
  return ApiServer({
    method: "POST",
    url: `/customers/login?${queryParams.toString()}`,
  });
};

export const verifyCustomerOTP = (queryParams) => {
  return ApiServer({
    method: "POST",
    url: `/customers/verify?${queryParams.toString()}`,
  });
};

export const getCustomerAddress = (queryParams) => {
  return ApiServer({
    method: "GET",
    url: `/customers?${queryParams.toString()}`,
  });
};

export const deleteCustomerAddress = (queryParams) => {
  return ApiServer({
    method: "DELETE",
    url: `/customers/address?${queryParams.toString()}`,
  });
};

export const addCustomerAddress = (queryParams,formData) => {
  return ApiServer({
    method: "POST",
    url: `/customers/address?${queryParams.toString()}`,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const editCustomerAddress = (queryParams,formData) => {
  return ApiServer({
    method: "PUT",
    url: `/customers/address?${queryParams.toString()}`,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};


export const deleteCustomer = (queryParams) => {
  return ApiServer({
    method: "DELETE",
    url: `/customers?${queryParams.toString()}`
  });
};

export const getLoyaltyPoints = (ownerEmail) => {
  return ApiServer({
    method: "GET",
    url: `/loyalty-points/customers?owner_email=${ownerEmail}`
  });
};
