import ApiServer from "../ApiServer";

export const insertProduct = (queryParams, formData) => {
  return ApiServer({
    method: "POST",
    url: `/products?${queryParams.toString()}`,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getProducts = (parameter = "", value = "") => {
  let url = "/products";

  if (value && parameter === "email") {
    url += `?owner_email=${value}`;
  } else if (value && parameter === "name") {
    url += `?salon_url_name=${value}`;
  }

  return ApiServer({
    method: "GET",
    url: url,
  });
};

export const deleteProducts = (name, email) => {
  return ApiServer({
    method: "DELETE",
    url: `/products?name=${name}&owner_email=${email}`,
  });
};

export const updateProducts = (queryParams, formData) => {
  return ApiServer({
    method: "PUT",
    url: `/products?${queryParams.toString()}`,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};