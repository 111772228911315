import styled from "styled-components";
import { Theme } from "../../Themes/Theme";
import { Fonts } from "../../Fonts/Fonts";

export const CheckboxContainer = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`;

export const StylistNameContainer = styled.div`
  margin: 20px 0;
`;

export const StylistHeader = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: rgba(50, 55, 67, 1);
`;

export const StylistName = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  color: ${Theme.primaryBlack};
`;

export const SchdeuleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
  margin-left: 50px;
  width: 50%;
`;

export const SchdeuleHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap:10px;
`;

export const Leave = styled.div`
  margin-bottom: 10px;
  display: flex;
  margin-top: 30px;
  width: 70%;
  flex-direction: column;
`;

export const DateSelector = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`;

export const AddButton = styled.button`
  color: rgba(255, 255, 255, 1);
  background: ${Theme.primaryColor};
  opacity: 1;
  border: none;
  border-radius: 4px;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  cursor: pointer;
  padding: 10px;
`;

export const CancelIconContainer = styled.button`
  margin-left: 5px;
  align-items: center;
  display: flex;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
`;