import styled from "styled-components";
import { Theme } from "../../Themes/Theme";
import { Fonts } from "../../Fonts/Fonts";

export const AllOfferContainer = styled.div`
 width:90%;
   display: flex;
   flex-direction: row;
   margin-top: 30px;
overflow-x: auto;
`;

export const OfferCodeContainer = styled.div`
  height: 25px;
  background: rgba(240, 249, 255, 1);
  border-radius: 4px;
  border-width: 1px;
  border-color: rgba(185, 229, 255, 1);
  border-style: solid;
  font-family: ${Fonts.primaryFont};
  font-size: 11px;
  font-weight: 400;
  line-height: 18px;
  color: ${Theme.primaryBlack};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 5px;
`;


export const OffersContainer = styled.div`
   flex-shrink: 0; 
  width: 300px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: rgba(240, 249, 255, 1);
  padding: 10px;
  margin-right: 10px;
`;

export const OfferInformtion = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  color: ${Theme.primaryDark};;
  width:200px;
  word-wrap: break-word;  
  word-break: break-word; 
  white-space: normal; 
`;