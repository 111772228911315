import React from "react";

const StoreIcon = ({ isActive }) => {
  const strokeColor = isActive ? "#6D31ED" : "#171A1F";
  const fillColor = isActive ? "#6D31ED" : "#171A1F"; 

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M18.8572 6L5.14288 6" stroke={strokeColor} strokeWidth="2.05714" strokeMiterlimit="10" strokeLinecap="square"/>
      <path d="M4.28571 11.1429L4.28571 21.4286H12L12 16.2857L16.2857 16.2857V21.4286H19.7143V11.1429" stroke={strokeColor} strokeWidth="2.05714" strokeMiterlimit="10" strokeLinecap="square"/>
      <path d="M18.8571 6L21.4286 9.42857L21.4286 11.1429L2.57141 11.1429L2.57141 9.42857L5.14284 6L5.14284 2.57143L18.8571 2.57143V6Z" stroke={strokeColor} strokeWidth="2.05714" strokeMiterlimit="10" strokeLinecap="square" fill={fillColor}/>
      <path d="M9.42855 13.7143H6.85712L6.85712 16.2857H9.42855V13.7143Z" fill={strokeColor}/>
    </svg>
  );
};

export default StoreIcon;
