import React, { useEffect, useState } from "react";
import { Link, Route, Routes, useNavigate, useLocation, Navigate } from "react-router-dom";
import {
  Container,
  Sidebar,
  SidebarItem,
} from "./EmployeeDashboardPage.styled";
import DashboardHomeIcon from "../../../Assets/Svgs/DashboardHomeIcon";
import CalendarIcon from "../../../Assets/Svgs/CalendarIcon";
import Header from "../../../Modules/Header/Header";
import EmployeeLeaveSchdeule from "../../../Modules/EmployeeLeaveSchdeule/EmployeeLeaveSchdeule";
import EmployeeBookings from "../../../Modules/EmployeeBookings/EmployeeBookings";
import WarningPopup from "../../../Components/WarningPopup/WarningPopup";
import Urls from "../../../Urls/Urls";

const EmployeeDashboardPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showWarning, setShowWarning] = useState(false);

  const EmployeeRoute = ({ element }) => {
    const storedRoles = JSON.parse(localStorage.getItem("roles")) || {};
    const isEmployee = storedRoles.hasOwnProperty("Salon Employee");
  
    return isEmployee ? (
      element
    ) : (
      <>
        <Navigate to="/dashboard" />
        {setShowWarning(true)}
      </>
    );
  };  

  const sidebarItems = [
    {
      path: Urls.employeeDashboard,
      icon: (
        <EmployeeRoute
          element={<DashboardHomeIcon isActive={location.pathname === Urls.employeeDashboard} />}
          text="Home"
          path={Urls.employeeDashboard}
        />
      ),
    },
    {
      path: Urls.employeeSchedule, 
      icon: (
        <EmployeeRoute
          element={<CalendarIcon isActive={location.pathname === Urls.employeeSchedule} />}
          text="Schedule"
          path={Urls.employeeSchedule}
        />
      ),
    },    
  ];

  const handleContainerClick = (path) => {
    navigate(path);
  };

  return (
    <>
      <Header user="Salon Employee"/>
      <Container>
        <Sidebar>
          {sidebarItems.map((item, index) => (
            <SidebarItem key={index} isActive={location.pathname === item.path} onClick={() => handleContainerClick(item.path)}>
              {item.icon}
              <Link
                to={item.path}
                style={{
                  color: location.pathname === item.path ? "#6D31ED" : "black",
                  textDecoration: "none",
                }}
              >
                {item.text}
              </Link>
            </SidebarItem>
          ))}
        </Sidebar>

        <div style={{ flex: "1" }}>
          <Routes>
          <Route path="/" element={<EmployeeBookings />} />
          <Route path="/schedule" element={<EmployeeLeaveSchdeule />} />
          </Routes>
        </div>
      </Container>
      <WarningPopup visible={showWarning}/>
    </>
  );
};

export default EmployeeDashboardPage;
