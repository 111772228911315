import styled from "styled-components";
import { Fonts } from "../../Fonts/Fonts";

export const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Info = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

`;

export const InfoHeading = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 14px; 
  line-height: 22px; 
  font-weight: 400; 
  color: rgba(23,26,31,1);
`;

export const InfoDescription = styled.div`
   font-family: ${Fonts.primaryFont};
  font-size: 16px; 
  line-height: 26px; 
  font-weight: 400; 
  color: rgba(23,26,31,1);
`;