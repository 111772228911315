import React, { useState } from "react";
import { Switch, Input, message, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import {
  ReviewSetupContainer,
} from "./ReviewSettings.styled";
import Button from "../../Components/Button/Button";
import { getUserEmails } from "../../Components/UserEmails/userEmails";
import { setReview } from "../../Api/ApiServerRequests/SalonApiServerRequests";
import { Theme } from "../../Themes/Theme";

const ReviewSettings = () => {
  const [isStylistRecommended, setIsStylistRecommended] = useState(false);
  const ownerEmail = getUserEmails().salonOwnerEmail;

  const handleToggleChange = async(checked) => {
    setIsStylistRecommended(checked);
    console.log(checked);
    try {
      const response = await setReview(ownerEmail, checked);
      if (response.data.status === 201) {
        message.success(response.data.data);
      }else{
        message.error(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } 
  };


  return (
    <ReviewSetupContainer>
      <div>
        <span><Tooltip 
            title="By enabling it, this will display the reviews of the stylist to the customers during bookings"
            placement="right"
          >
            Show stylist review to customers during bookings
            <InfoCircleOutlined style={{ marginLeft: "5px", color: `${Theme.primaryColor}`  }} />
          </Tooltip></span>
        <Switch
          checked={isStylistRecommended}
          onChange={handleToggleChange}
          style={{ 
            marginLeft: "10px",  
            backgroundColor: isStylistRecommended ? `${Theme.primaryColor}` : "gray" 
          }}
          />
      </div>

    </ReviewSetupContainer>
  );
};

export default ReviewSettings;
