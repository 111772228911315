import React, { useEffect, useState } from "react";
import {
  HeaderContainer,
  LeftHeaderContainer,
  RightHeaderContainer,
  ProfileMenu,
  AccountHeading,
  ProfileUserName,
  ProfileButton,
} from "./Header.styled";
import HeadSet from "../../Assets/Svgs/HeadSet";
import BellIcon from "../../Assets/Svgs/BellIcon";
import ProfileIcon from "../../Assets/Svgs/ProfileIcon";
import { useNavigate } from "react-router-dom";
import { getAlerts, markAsSeen } from "../../Api/ApiServerRequests/AlertsApiServerRequests";
import {
  Modal,
  Card,
  Button,
  message,
  Spin,
  Badge,
  Dropdown,
  Menu,
} from "antd";
import { getUserEmails } from "../../Components/UserEmails/userEmails";

const Header = ({ handleHelpSetupClick, user }) => {
  const [isProfileMenuOpen, setProfileMenuOpen] = useState(false);
  const [isHelpMenuOpen, setHelpMenuOpen] = useState(false);
  const navigate = useNavigate();
  const [alerts, setAlerts] = useState([]);
  const [loading, setLoading] = useState(true);
  const { salonOwnerEmail, salonEmployeeEmail, salonAdminEmail } = getUserEmails();

  const fetchData = async () => {
    try {
      const queryParams = new URLSearchParams({
        owner_email: salonOwnerEmail,
        marked_as_seen: false,
      });

      const response = await getAlerts(queryParams);
      // console.log(response.data.data);
      if (response.data.status === 200) {
        setAlerts(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if(user==="Salon Owner"){
      fetchData();
    }
  }, []);

  const handleProfileClick = () => {
    setProfileMenuOpen(!isProfileMenuOpen);
  };

  const handleHeadsetClick = () => {
    setHelpMenuOpen(!isHelpMenuOpen);
  };

  const handleLogout = () => {
    // localStorage.removeItem("role");
    // localStorage.removeItem("email");
    localStorage.removeItem("ownerSalonName");

    if (user === "Salon Admin") {
      const storedRoles = JSON.parse(localStorage.getItem("roles")) || {};
      delete storedRoles["Salon Admin"];
      localStorage.setItem("roles", JSON.stringify(storedRoles));
    }else if (user === "Salon Owner") {
      const storedRoles = JSON.parse(localStorage.getItem("roles")) || {};
      delete storedRoles["Salon Owner"];
      localStorage.setItem("roles", JSON.stringify(storedRoles));
    }else if (user === "Salon Employee") {
      const storedRoles = JSON.parse(localStorage.getItem("roles")) || {};
      delete storedRoles["Salon Employee"];
      localStorage.setItem("roles", JSON.stringify(storedRoles));
    }
  

    navigate("/dashboard");
  };

  const handleMarkAsSeen = async (alertId) => {
    try {
      const queryParams = new URLSearchParams({
        id: alertId,
      });

      const response = await markAsSeen(queryParams);
      // console.log(response.data.data);
      if (response.data.status === 200) {
        message.success(response.data.data);
        setAlerts((prevAlerts) =>
          prevAlerts.filter((alert) => alert._id !== alertId)
        );
      } else {
        message.error("Something went wrong");
      }
    } catch (error) {
      console.error("Error fetching data");
    }
  };

  const bellMenu = (
    <Menu style={{ maxHeight: "650px", overflowY: "auto" }}>
      {alerts.map((alert) => (
        <Menu.Item key={alert._id}>
          <Card style={{ marginBottom: 16 }}>
            <p>{alert.alert_message}</p>
            <Button onClick={() => handleMarkAsSeen(alert._id)}>
              Mark as seen
            </Button>
          </Card>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <HeaderContainer>
      <LeftHeaderContainer>
        <p>LOGO</p>
      </LeftHeaderContainer>

      <RightHeaderContainer>
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleHelpSetupClick();
            handleHeadsetClick();
          }}
        >
          {
            user!=="Salon Employee" &&(<HeadSet />)
          }
          
          {user === "Salon Admin" && isHelpMenuOpen && (
            <ProfileMenu>
              <>
                <ProfileUserName>Contact email:</ProfileUserName>
                <AccountHeading>admin@sopss-sa.com</AccountHeading>
              </>
              <ProfileUserName>Contact Number:</ProfileUserName>
              <AccountHeading>+966 59 445 8888</AccountHeading>
            </ProfileMenu>
          )}
        </span>
        <Badge count={alerts.length} offset={[5, -1]}>
          <Dropdown overlay={bellMenu} trigger={["click"]}>
            <span style={{ cursor: "pointer" }}>
              <BellIcon />
            </span>
          </Dropdown>
        </Badge>
        <span onClick={handleProfileClick}>
          <ProfileIcon />
          {isProfileMenuOpen && (
            <ProfileMenu>
              <ProfileUserName>Account</ProfileUserName>
              <AccountHeading>
                {
                  user==="Salon Owner" && salonOwnerEmail
                }
                {
                  user==="Salon Admin" && salonAdminEmail
                }
                {
                  user==="Salon Employee" && salonEmployeeEmail
                }
                </AccountHeading>
              {user === "Salon Owner" && (
                <>
                  <ProfileUserName>Salon Name</ProfileUserName>
                  <AccountHeading>
                    {localStorage.getItem("ownerSalonName")}
                  </AccountHeading>
                </>
              )}
              <ProfileButton onClick={handleLogout}>Logout</ProfileButton>
            </ProfileMenu>
          )}
        </span>
      </RightHeaderContainer>
    </HeaderContainer>
  );
};

export default Header;
