import React, { useState } from "react";
import { Link, Route, Routes, useNavigate, Navigate } from "react-router-dom";
import PlusIcon from "../../../Assets/Svgs/PlusIcon";
import MembershipIcon from "../../../Assets/Svgs/MemberShipIcon";
import SalonList from "../../../Modules/SalonList/SalonList";
import AddSalon from "../../../Modules/AddSalon/AddSalon";
import { useLocation } from "react-router-dom";
import EditSalon from "../../../Modules/EditSalon/EditSalon";
import TrialMembership from "../../../Modules/TrialMembership/TrialMembership";
import Header from "../../../Modules/Header/Header";
import WarningPopup from "../../../Components/WarningPopup/WarningPopup";
import { Container, Sidebar, SidebarItem } from "../../OwnerPage/OwnerDashboardPage/OwnerDashboardPage.styled";
import DashboardHomeIcon from "../../../Assets/Svgs/DashboardHomeIcon";
import Urls from "../../../Urls/Urls";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showWarning, setShowWarning] = useState(false);

  const handleContainerClick = (path) => {
    navigate(path);
  };

  const handleHelpSetupIconClick = () => {};

  const AdminRoute = ({ element }) => {
    const storedRoles = JSON.parse(localStorage.getItem("roles")) || {};
    const isAdmin = storedRoles.hasOwnProperty("Salon Admin");
  
    return isAdmin ? (
      element
    ) : (
      <>
        <Navigate to="/dashboard" />
        {setShowWarning(true)}
      </>
    );
  };  

  const sidebarItems = [
    {
      icon: <DashboardHomeIcon isActive={location.pathname === Urls.adminDashboard} />,
      text: "Salon list",
      path: Urls.adminDashboard,
    },
    {
      icon: <PlusIcon color={location.pathname === Urls.adminAddSalon ? "#6D31ED" : "#000"} />,
      text: "Add Salon",
      path: Urls.adminAddSalon,
    },    
    {
      icon: <MembershipIcon isActive={location.pathname === Urls.adminTrialMembership} />,
      text: "Trial Membership",
      path: Urls.adminTrialMembership,
    },
  ];

  return (
    <>
      <Header handleHelpSetupClick={handleHelpSetupIconClick} user="Salon Admin"/>
      <Container>
        <Sidebar>
          {sidebarItems.map((item, index) => (
            <SidebarItem
              key={index}
              isActive={location.pathname === item.path}
              onClick={() => handleContainerClick(item.path)}
            >
              {item.icon}
              <Link
                to={item.path}
                style={{
                  color: location.pathname === item.path ? "#6D31ED" : "black",
                  textDecoration: "none",
                }}
              >
                {item.text}
              </Link>
            </SidebarItem>
          ))}
        </Sidebar>

        <div style={{ flex: "1" }}>
          <Routes>
          <Route path="/" element={<AdminRoute element={<SalonList />} />}/>
          <Route path="/add-salon" element={<AdminRoute element={<AddSalon />} />}/>
          <Route path="/edit-salon" element={<AdminRoute element={<EditSalon />} />}/>
          <Route path="/trial-membership" element={<AdminRoute element={<TrialMembership />} />}/>
          </Routes>
        </div>
      </Container>
      <WarningPopup visible={showWarning} />
    </>
  );
};

export default AdminDashboard;
